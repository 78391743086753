import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { FaFacebookF } from 'react-icons/fa';
import { AiFillLinkedin, AiFillYoutube, AiFillInstagram } from 'react-icons/ai';

import Logo from '../Logo';
import MediaMatch from '../MediaMatch';

import logo from '../../assets/logo_rodape.png';

import * as S from './styles';

const Footer = () => {
  const [isOpenHospital, setIsOpenHospital] = useState(false);
  const [isOpenEspecialidades, setIsOpenEspecialidades] = useState(false);
  const [isOpenServicos, setIsOpenServicos] = useState(false);

  return (
    <S.Container>
      <MediaMatch lessThan="large">
        <S.LogoWrapper>
          <img height={80} src={logo} alt="Logo Hapvida | Notredame Intermédica | Hospital Humaniza" />
        </S.LogoWrapper>
        <S.MenuWrapper>
          <S.MenuOptionButton>
            <S.ButtonOption onClick={() => setIsOpenHospital(!isOpenHospital)}>
              <span>O Hospital</span>

              <S.IconWrapper open={isOpenHospital}>
                <IoIosArrowDown size={25} color="#fff" />
              </S.IconWrapper>
            </S.ButtonOption>
            <S.SubMenuOptionWrapper open={isOpenHospital} borderColor="blue">
              <S.SubMenuOptionLink to="/institucional">
                Institucional
              </S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="#">Grupo CCG</S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="/estrutura">
                Estrutura
              </S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="/o-hospital/modelo-assistencial">Modelo Assistencial</S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="/o-hospital/direitos-deveres-do-paciente">Direitos e Deveres do Paciente</S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="/clinicas">Clinicas</S.SubMenuOptionLink>
              {/* <S.SubMenuOptionLink to="#">
                Portal da Transparência
              </S.SubMenuOptionLink> */}
            </S.SubMenuOptionWrapper>
          </S.MenuOptionButton>
          <S.MenuOptionButton>
            <S.ButtonOption onClick={() => setIsOpenServicos(!isOpenServicos)}>
              <span>Serviços</span>
              <S.IconWrapper open={isOpenServicos}>
                <IoIosArrowDown size={20} color="#fff" />
              </S.IconWrapper>
            </S.ButtonOption>
            <S.SubMenuOptionWrapper
              open={isOpenServicos}
              borderColor="poloBlue"
            >
              <S.SubMenuOptionLink to="/servicos/orientacoes-para-internacao">
                Orientações para Internação
              </S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="/servicos/pronto-atendimento-adulto">
                Pronto Atendimento Adulto
              </S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="/servicos/emergencia">
                Emergência
              </S.SubMenuOptionLink>
              {/* <S.SubMenuOptionLink to="#">
                Ambulatório de Especialidades
              </S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="#">
                Centro de Diagnóstico por Imagem
              </S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="#">
                Centro Integrado de Oncologia
              </S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="#">Emergência</S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="#">Laboratório</S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="#">Radioterapia</S.SubMenuOptionLink> */}
            </S.SubMenuOptionWrapper>
          </S.MenuOptionButton>
          <S.MenuOptionButton>
            <S.ButtonOption
              onClick={() => setIsOpenEspecialidades(!isOpenEspecialidades)}
            >
              <span>Especialidades</span>
              <S.IconWrapper open={isOpenEspecialidades}>
                <IoIosArrowDown size={20} color="#fff" />
              </S.IconWrapper>
            </S.ButtonOption>
            <S.SubMenuOptionWrapper
              open={isOpenEspecialidades}
              borderColor="turquoise"
            >
              {/* <S.SubMenuOptionLink to="#">Cardiologia</S.SubMenuOptionLink> */}
              <S.SubMenuOptionLink to="/especialidades/oncologia">
                Centro de Infusões e Oncologia
              </S.SubMenuOptionLink>
              {/* <S.SubMenuOptionLink to="#">
                Ortopedia e Traumatologia
        </S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="#">
                Gastroenterologia
              </S.SubMenuOptionLink>
              <S.SubMenuOptionLink to="#">Medicina Interna</S.SubMenuOptionLink>
        <S.SubMenuOptionLink to="#">Cirurgia Geral</S.SubMenuOptionLink> */}
            </S.SubMenuOptionWrapper>
          </S.MenuOptionButton>

          <S.MenuOptionLink>
            <S.SubMenuOptionLink to="/info-covid">COVID-19</S.SubMenuOptionLink>
            {/* <S.SubMenuOptionLink to="/corpo-clinico">
              Corpo Clinico
            </S.SubMenuOptionLink> */}
            {/* <S.SubMenuOptionLink to="/fale-conosco">
              Fale Conosco
            </S.SubMenuOptionLink> */}
            {/* <S.SubMenuOptionLink to="#">Notícias</S.SubMenuOptionLink> */}
            {/* <S.SubMenuOptionLink to="#">
              Orientações pré-procedimentos
            </S.SubMenuOptionLink> */}
            {/* <S.SubMenuOptionLink to="#">
              Direitos e deveres do paciente
            </S.SubMenuOptionLink> */}
          </S.MenuOptionLink>

          <S.Relationship>
            <strong>CENTRAL DE MARCAÇÃO DE CONSULTAS</strong>
            <span>(51) 2312-1505</span>
            <strong>AGENDAMENTO DE EXAMES</strong>
            <span>(51) 2312-1505</span>
            <strong>CENTRAL DE RELACIONAMENTO</strong>
            <span>0800 942 9702</span>
          </S.Relationship>

          <S.CentralAdmin>
            <span>CENTRO ADMINISTRATIVO</span>
            <p>
              Rua Ramiro Barcelos, 621 | Independência
              {' '}
              <br />
              {' '}
              CEP: 90035-005 |
              Porto Alegre – RS
              {' '}
              <br />
              {' '}
              CNPJ: 01.890.028/0001-04
              <br />
              TELEFONE: (51) 3030-4050
            </p>
          </S.CentralAdmin>

          <S.Technician>
            <span>RESPONSÁVEL TÉCNICO</span>
            <p>
              Gustavo Almeida Bielinski
              {' '}
              <br />
              {' '}
              CREMERS 25901
            </p>
          </S.Technician>

          <S.SocialMedia>
            <S.SocialMediaLink href="https://www.linkedin.com/company/gndi/">
              <AiFillLinkedin size={25} color="#fff" />
            </S.SocialMediaLink>

            <S.SocialMediaLink href="https://www.youtube.com/c/GrupoNotreDameInterm%C3%A9dica ">
              <AiFillYoutube size={25} color="#fff" />
            </S.SocialMediaLink>
          </S.SocialMedia>
        </S.MenuWrapper>
      </MediaMatch>

      {/**
       * web
       */}
      <MediaMatch greaterThan="large">
        <S.DesktopWrapper>
          <S.Wrapper>
            <S.LogoWrapper>
              <img height={50} src={logo} alt="Logo Hapvida | Notredame Intermédica | Hospital Humaniza" />
            </S.LogoWrapper>
            {/* hospital */}
            <S.MenuOptionDesktop>
              <S.TitleMenuOptionDesktop>O Hospital</S.TitleMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="/institucional">
                Quem somos
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="/estrutura">
                Estrutura
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="/clinicas">
                Clinicas
              </S.LinkMenuOptionDesktop>
              <S.ExternalLinkMenuOptionDesktop href="https://atendimento.ccgsaude.com.br/ccg-saude/ouvidoria">
                Ouvidoria
              </S.ExternalLinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="/o-hospital/direitos-deveres-do-paciente">
                <span>Direitos e Deveres do Paciente</span>
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="/o-hospital/modelo-assistencial">
                <span>Modelo Assistencial</span>
              </S.LinkMenuOptionDesktop>
              {/* <S.LinkMenuOptionDesktop to="#">
                Portal da Transparência
              </S.LinkMenuOptionDesktop> */}
            </S.MenuOptionDesktop>
            {/* especialidades */}
            <S.MenuOptionDesktop>
              <S.TitleMenuOptionDesktop>
                Especialidades
              </S.TitleMenuOptionDesktop>
              {/* <S.LinkMenuOptionDesktop to="#">
                Cardiologia
            </S.LinkMenuOptionDesktop> */}
              <S.LinkMenuOptionDesktop to="/especialidades/oncologia">
                Centro de Infusões e Oncologia
              </S.LinkMenuOptionDesktop>
              {/* <S.LinkMenuOptionDesktop to="#">
                Ortopedia e Traumatologia
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="#">
                Gastroenterologia
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="#">
                Medicina Interna
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="#">
                Cirurgia Geral
              </S.LinkMenuOptionDesktop> */}
            </S.MenuOptionDesktop>
            {/* serviços */}
            <S.MenuOptionDesktop>
              <S.TitleMenuOptionDesktop>Serviços</S.TitleMenuOptionDesktop>

              <S.LinkMenuOptionDesktop to="servicos/orientacoes-para-internacao">
                Orientações para Internação
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="/servicos/pronto-atendimento-adulto">
                Pronto Atendimento Adulto
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="/servicos/emergencia">
                Emergência
              </S.LinkMenuOptionDesktop>
              {/* <S.LinkMenuOptionDesktop to="#">
                Centro de Diagnóstico por Imagem
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="#">
                Unidade de Terapia Intensiva
              </S.LinkMenuOptionDesktop>
            */}
            </S.MenuOptionDesktop>
            {/* links */}
            <S.WrapperLinks>
              <S.LinkMenuOptionDesktop to="/info-covid">
                COVID-19
              </S.LinkMenuOptionDesktop>
              {/* <S.LinkMenuOptionDesktop to="#">
                Corpo Clínico
              </S.LinkMenuOptionDesktop> */}
              {/* <S.LinkMenuOptionDesktop to="/fale-conosco">
                Fale Conosco
              </S.LinkMenuOptionDesktop> */}
              {/* <S.LinkMenuOptionDesktop to="#">Notícias</S.LinkMenuOptionDesktop> */}
              {/* <S.LinkMenuOptionDesktop to="#">
                Orientações pré-procedimentos
              </S.LinkMenuOptionDesktop>
              <S.LinkMenuOptionDesktop to="#">
                Direitos e deveres do paciente
              </S.LinkMenuOptionDesktop> */}
            </S.WrapperLinks>
          </S.Wrapper>
          <S.WrapperDetails>
            <S.RelatioshipDetails>
              <div className="test">
                <S.RelatioshipDetailsInfo>
                  <strong>CENTRAL DE MARCAÇÃO DE CONSULTAS</strong>
                  <span>(51) 2312-1505</span>
                  <strong>AGENDAMENTO DE EXAMES</strong>
                  <span>(51) 2312-1505</span>
                  <strong>CENTRAL DE RELACIONAMENTO</strong>
                  <span>0800 942 9702</span>
                </S.RelatioshipDetailsInfo>
                <S.CentralAdminDetails>
                  <strong>CENTRO ADMINISTRATIVO</strong>
                  <span>
                    Rua Ramiro Barcelos, 621 | Independência
                    {' '}
                    <br />
                    {' '}
                    CEP:
                    90035-005 | Porto Alegre – RS
                    {' '}
                    <br />
                    {' '}
                    CNPJ:
                    01.890.028/0001-04
                    {/* <br />
                    TELEFONE: (51) 3030-4050 */}
                  </span>
                </S.CentralAdminDetails>
              </div>
              <S.RelatioshipDetailsLinks>
                <S.SocialMediaLink href="https://www.linkedin.com/company/gndi/">
                  <AiFillLinkedin size={25} />
                </S.SocialMediaLink>

                <S.SocialMediaLink href="https://www.youtube.com/c/GrupoNotreDameInterm%C3%A9dica ">
                  <AiFillYoutube size={25} />
                </S.SocialMediaLink>
              </S.RelatioshipDetailsLinks>
            </S.RelatioshipDetails>

            <S.TechnitionDetails>
              <S.TechnicianInfoDetails>
                <strong>RESPONSÁVEL TÉCNICO</strong>
                <span>
                  Gustavo Almeida Bielinski
                  {' '}
                  <br />
                  CREMERS 25901
                </span>
              </S.TechnicianInfoDetails>
              <S.TechnicianInfoDetailsLinks>
                {/*  <S.LinkMenuOptionDesktop to="#">
                  Política de Cookies
                </S.LinkMenuOptionDesktop> */}
                <S.LinkMenuOptionDesktop to="/lgpd/politica-privacidade-pacientes">
                  Política de Privacidade
                </S.LinkMenuOptionDesktop>
                <S.LinkMenuOptionDesktop to="/lgpd/termos-de-uso">
                  Termos de Uso
                </S.LinkMenuOptionDesktop>
              </S.TechnicianInfoDetailsLinks>
            </S.TechnitionDetails>
          </S.WrapperDetails>
          <S.Copyright>
            <span>© Hospital Humaniza. Todos os direitos reservados.</span>
          </S.Copyright>
        </S.DesktopWrapper>
      </MediaMatch>
    </S.Container>
  );
};

export default Footer;
