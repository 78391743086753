import React, { useCallback, useState } from 'react';
import {
  Title,
  Description,
  SupportText,
} from '../../components/TextComponents';
import GlobalContainer from '../../components/_layout/Container';
import * as S from './styles';
import Accordion from '../../components/AcordionMenu';

import { array } from './Data';
import Form from '../../components/FormPacotesCirurgicos';

export default function PacotesCirurgicos() {
  const [open, setOpen] = useState({});

  const toggleSelected = useCallback(
    (name) => {
      setOpen({
        [name]: !open[name],
      });
    },
    [open],
  );

  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TitleWrapper>
          <Title
            size={{ web: '7.6rem', mobile: '3.6rem' }}
            label="Pacotes Cirúrgicos"
          />
          <Description
            width="79rem"
            label="O Hospital Humaniza oferece diversos pacotes para procedimentos cirúrgicos e atende diferentes modalidades de convênios e particulares."
          />
        </S.TitleWrapper>
        <Title
          size={{ web: '2.3rem', mobile: '1.5rem' }}
          label="Confira os pacotes disponíveis e solicite seu orçamento através do formulário."
        />
      </S.Wrapper>
      {array.map((item) => (
        <Accordion
          open={open}
          titulo={item.titulo}
          onclick={() => toggleSelected(item.id)}
          id={item.id}
          pacotes={item.pacotes}
        />
      ))}
      <div id="form">
        <Form />
      </div>
    </GlobalContainer>
  );
}
