import React, { useState } from 'react';
import { IoIosArrowDown, IoIosSearch } from 'react-icons/io';

import Logo from '../Logo';
import MediaMatch from '../MediaMatch';
import menuHanburguer from '../../assets/menuIcon.svg';
import close from '../../assets/close.svg';

import * as S from './styles';

export default function Menu() {
  const [isOpen, setIsOpen] = useState(false);

  const [isOpenHospital, setIsOpenHospital] = useState(false);
  const [isOpenEspecialidades, setIsOpenEspecialidades] = useState(false);
  const [isOpenServicos, setIsOpenServicos] = useState(false);

  function open(menuName) {
    if (menuName === 'hospital') {
      setIsOpenHospital(!isOpenHospital);
      setIsOpenEspecialidades(false);
      setIsOpenServicos(false);
    }
    if (menuName === 'especialidades') {
      setIsOpenHospital(false);
      setIsOpenEspecialidades(!isOpenEspecialidades);
      setIsOpenServicos(false);
    }
    if (menuName === 'servicos') {
      setIsOpenHospital(false);
      setIsOpenEspecialidades(false);
      setIsOpenServicos(!isOpenServicos);
    }
  }

  return (
    <S.Container>
      <S.Wrapper>
        <S.HeaderNav>
          <S.LogoWrapper to="/">
            <Logo size="normal" />
          </S.LogoWrapper>

          <MediaMatch greaterThan="large">
            <S.WrapperMenuDesktop>
              <S.MenuActionButtonDescktop onClick={() => open('hospital')}>
                <span>O Hospital</span>
                <S.IconWrapperDesktop open={isOpenHospital}>
                  <IoIosArrowDown size={15} />
                </S.IconWrapperDesktop>

                <S.SubMenuDesktop open={isOpenHospital} color="blue">
                  <S.LinkDesktop
                    open={isOpenHospital}
                    to="/o-hospital/direitos-deveres-do-paciente"
                  >
                    <span>Direitos e Deveres do Paciente</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/institucional">
                    <span>Institucional</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/o-hospital/modelo-assistencial">
                    <span>Modelo Assistencial</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/estrutura">
                    <span>Estrutura</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/clinicas">
                    <span>Clínicas</span>
                  </S.LinkDesktop>
                  <S.ExternalLinkDesktop href="https://atendimento.ccgsaude.com.br/ccg-saude/ouvidoria">
                    <span>Ouvidoria</span>
                  </S.ExternalLinkDesktop>
                </S.SubMenuDesktop>
              </S.MenuActionButtonDescktop>
              <S.MenuActionButtonDescktop
                onClick={() => open('especialidades')}
              >
                <span>Especialidades</span>
                <S.IconWrapperDesktop open={isOpenEspecialidades}>
                  <IoIosArrowDown size={20} />
                </S.IconWrapperDesktop>

                <S.SubMenuDesktop open={isOpenEspecialidades} color="poloBlue">
                  <S.LinkDesktop to="/especialidades/oncologia">
                    <span>Centro de Infusões e Oncologia</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/especialidades/cardiologia">
                    <span>Centro de Cardiologia</span>
                  </S.LinkDesktop>
                  <S.LinkDesktopVariant to="/especialidades/cardiologia/centro-resolutivo">
                    <span />
                    {' '}
                    <p>Centro de Resolução de Cardiologia</p>
                  </S.LinkDesktopVariant>
                  {/*
                  <S.LinkDesktop to="/especialidades/ortopedia-traumatologia">
                    <span>Centro de Ortopedia e Traumatologia</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/especialidades/gastroenterologia">
                    <span>Centro de Gastroenterologia</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/especialidades/centro-cirurgico">
                    <span>Centro Cirúrgico e CTI</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="#">
                    <span>Centro de Endoscopia e Colonoscopia</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="#">
                    <span>Medicina Interna</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="#">
                    <span>Cirurgia Geral</span>
                  </S.LinkDesktop>
                  */}
                </S.SubMenuDesktop>
              </S.MenuActionButtonDescktop>

              <S.MenuActionButtonDescktop onClick={() => open('servicos')}>
                <span>Serviços</span>
                <S.IconWrapperDesktop open={isOpenServicos}>
                  <IoIosArrowDown size={20} />
                </S.IconWrapperDesktop>

                <S.SubMenuDesktop open={isOpenServicos} color="turquoise">
                  <S.LinkDesktop to="/servicos/orientacoes-para-internacao">
                    <span>Orientações para Internação</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/servicos/pronto-atendimento-adulto">
                    <span>Pronto Atendimento Adulto</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/servicos/emergencia">
                    <span>Emergência</span>
                  </S.LinkDesktop>
                  {/* <S.LinkDesktop to="#">
                    Unidade de Terapia Intensiva
                  </S.LinkDesktop>
                  <S.LinkDesktop to="#">
                    <span>Centro de Diagnóstico por Imagem</span>
                  </S.LinkDesktop>
                  <S.LinkDesktop to="/servicos/pacotes-cirurgicos">
                    <span>Pacotes Cirurgicos</span>
                  </S.LinkDesktop>
                   */}
                </S.SubMenuDesktop>
              </S.MenuActionButtonDescktop>
              {/* <S.LinkDesktopMenuMain to="/planos-ccg">
                <span>Planos CCG</span>
              </S.LinkDesktopMenuMain> */}
              {/*  <S.LinkDesktopMenuMain to="/equipe-humaniza">
                <span>Equipe Humaniza</span>
              </S.LinkDesktopMenuMain>

               <S.LinkDesktopMenuMain to="#">
                <span>Planos e Convênios</span>
              </S.LinkDesktopMenuMain>
              <S.LinkDesktopMenuMain to="/noticias">
                <span>Notícias</span>
              </S.LinkDesktopMenuMain>
              */}
              <S.LinkDesktopMenuMain to="/info-covid">
                <span>COVID-19</span>
              </S.LinkDesktopMenuMain>
              {/*  <S.LinkDesktopMenuMain to="/fale-conosco">
                <span>Fale Conosco</span>
              </S.LinkDesktopMenuMain> */}
              <S.LinkDesktopMenuMain to="">
                <span>
                  <IoIosSearch size={20} />
                </span>
              </S.LinkDesktopMenuMain>
            </S.WrapperMenuDesktop>
          </MediaMatch>

          <MediaMatch lessThan="large">
            <S.MenuHanburguer onClick={() => setIsOpen(!isOpen)}>
              <img src={menuHanburguer} alt="" />
            </S.MenuHanburguer>
          </MediaMatch>
        </S.HeaderNav>

        {/** mobile */}

        <MediaMatch lessThan="large">
          <S.MenuNavFull isOpen={isOpen}>
            <S.WrapperMenuHeader>
              <S.LogoWrapper to="/">
                <Logo size="normal" />
              </S.LogoWrapper>

              <S.MenuHanburguer onClick={() => setIsOpen(!isOpen)}>
                <img src={close} alt="" />
              </S.MenuHanburguer>
            </S.WrapperMenuHeader>
            <S.MenuBody isOpen={isOpen}>
              <S.OptionMenu>
                <S.MenuButtom
                  onClick={() => setIsOpenHospital(!isOpenHospital)}
                >
                  <span>O Hospital</span>
                  <S.IconMenuButtom open={isOpenHospital}>
                    <IoIosArrowDown size={20} />
                  </S.IconMenuButtom>
                </S.MenuButtom>
                <S.MenuSubOptionsHospital color="blue" open={isOpenHospital}>
                  <S.LinkMenu
                    open={isOpenHospital}
                    to="/o-hospital/direitos-deveres-do-paciente"
                  >
                    <span>Direitos e Deveres do Paciente</span>
                  </S.LinkMenu>
                  <S.LinkMenu open={isOpenHospital} to="/institucional">
                    <span>Institucional</span>
                  </S.LinkMenu>
                  <S.LinkMenu
                    open={isOpenHospital}
                    to="/o-hospital/modelo-assistencial"
                  >
                    <span>Modelo Assistencial</span>
                  </S.LinkMenu>
                  <S.LinkMenu open={isOpenHospital} to="/estrutura">
                    <span>Estrutura</span>
                  </S.LinkMenu>
                  <S.LinkMenu open={isOpenHospital} to="/clinicas">
                    <span>Clinicas</span>
                  </S.LinkMenu>
                  <S.LinkMenu open={isOpenHospital} to="/ouvidoria">
                    <span>Ouvidoria</span>
                  </S.LinkMenu>
                </S.MenuSubOptionsHospital>
              </S.OptionMenu>

              <S.OptionMenu>
                <S.MenuButtom
                  onClick={() => setIsOpenEspecialidades(!isOpenEspecialidades)}
                >
                  <span>Especialidades</span>
                  <S.IconMenuButtom open={isOpenEspecialidades}>
                    <IoIosArrowDown size={20} />
                  </S.IconMenuButtom>
                </S.MenuButtom>
                <S.MenuSubOptionsHospital
                  color="turquoise"
                  open={isOpenEspecialidades}
                >
                  {/* <S.LinkMenu to="#">
                    <span>Centro de Endoscopia e Colonoscopia</span>
                  </S.LinkMenu>
                  <S.LinkMenu to="/especialidades/centro-cirurgico">
                    <span>Centro Cirúrgico e CTI</span>
                  </S.LinkMenu> */}
                  <S.LinkMenu to="/especialidades/cardiologia">
                    <span> Centro de Cardiologia</span>
                  </S.LinkMenu>
                  <S.LinkVariant to="/especialidades/cardiologia/centro-resolutivo">
                    <span />
                    <p>Centro de Resolução de Cardiologia</p>
                  </S.LinkVariant>
                  <S.LinkMenu to="/especialidades/oncologia">
                    <span>Centro de Infusões e Oncologia</span>
                  </S.LinkMenu>
                  {/* <S.LinkMenu to="/especialidades/ortopedia-traumatologia">
                    <span>Centro de Ortopedia e Traumatologia</span>
                  </S.LinkMenu>
                  <S.LinkMenu to="/especialidades/gastroenterologia">
                    <span>Gastroenterologia</span>
                  </S.LinkMenu>
                  <S.LinkMenu to="#">
                    <span>Medicina Interna</span>
                  </S.LinkMenu>
                  <S.LinkMenu to="#">
                    <span>Cirurgia Geral</span>
                  </S.LinkMenu> */}
                </S.MenuSubOptionsHospital>
              </S.OptionMenu>

              <S.OptionMenu>
                <S.MenuButtom
                  onClick={() => setIsOpenServicos(!isOpenServicos)}
                >
                  <span>Serviços </span>
                  <S.IconMenuButtom open={isOpenServicos}>
                    <IoIosArrowDown size={20} />
                  </S.IconMenuButtom>
                </S.MenuButtom>
                <S.MenuSubOptionsHospital
                  color="poloBlue"
                  open={isOpenServicos}
                >
                  <S.LinkMenu to="/servicos/orientacoes-para-internacao">
                    <span>Orientações para Internação</span>
                  </S.LinkMenu>
                  <S.LinkMenu to="/servicos/pronto-atendimento-adulto">
                    <span>Pronto Atendimento Adulto</span>
                  </S.LinkMenu>
                  <S.LinkMenu to="/servicos/emergencia">
                    <span>Emergência</span>
                  </S.LinkMenu>
                  {/* <S.LinkMenu to="#">
                    <span>Unidade de Terapia Intensiva</span>
                  </S.LinkMenu>
                  <S.LinkMenu to="#">
                    <span>Centro de Diagnóstico por Imagem</span>
                  </S.LinkMenu>

                  <S.LinkMenu to="/servicos/pacotes-cirurgicos">
                    <span>Pacotes Cirurgicos</span>
                  </S.LinkMenu>
                  <S.LinkMenu to="/servicos/pronto-atendimento">
                    <span>Pronto Atendimento</span>
            </S.LinkMenu> */}
                </S.MenuSubOptionsHospital>
              </S.OptionMenu>
              {/* <S.OptionMenu>

                <S.LinkMenu to="/planos-ccg" link="true">
                  <span>Planos CCG</span>
                </S.LinkMenu>
              </S.OptionMenu>

              <S.OptionMenu>
                <S.LinkMenu to="/info-covid" link="true">
                  <span>COVID-19</span>
                </S.LinkMenu>
              </S.OptionMenu>

              {/*
              <S.OptionMenu>
                <S.LinkMenu to="/equipe-humaniza" link="true">
                  <span>Equipe Humaniza</span>
                </S.LinkMenu>
              </S.OptionMenu>

              <S.OptionMenu>
                <S.LinkMenu to="/noticias" link="true">
                  <span>Notícias</span>
                </S.LinkMenu>
              </S.OptionMenu>
              */}
              {/* <S.OptionMenu>
                <S.LinkMenu to="/fale-conosco" link="true">
                  <span>Fale Conosco</span>
                </S.LinkMenu>
              </S.OptionMenu> */}
            </S.MenuBody>
            {/* <S.Footer>
              <Button>Área do Medico</Button>
              <Button color="blueLight">Área do Pasciente</Button>
            </S.Footer> */}
          </S.MenuNavFull>
        </MediaMatch>
      </S.Wrapper>
    </S.Container>
  );
}
