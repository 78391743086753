import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as DescriptionStyles from '../../../../components/TextComponents/Description/styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${media.greaterThan('medium')`
      margin: 0 auto;
      width: 100%;
      display: grid;
      max-width: ${theme.maxwidth};
      grid-template-areas: 'image info';
      grid-template-columns: 1.3fr 2fr;
      padding: 12rem 0;
    `}
  `}
`;

export const ImageSection = styled.div`
  grid-area: image;

  img {
    width: 100%;
    max-width: 47.5rem;
    height: 37.5rem;

    ${media.greaterThan('medium')`
      max-width: 48rem;
      height: 47.5rem;
    `}
  }
`;
export const InfoSection = styled.div`
  grid-area: info;
`;

export const TextWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 7rem;
    padding: 0 ${theme.spacings.small} 12rem;

    ${DescriptionStyles.Description} {
      font-size: ${theme.font.sizes.small};

      ${media.greaterThan('medium')`
        font-size: ${theme.font.sizes.medium}
      `}
    }
  `}
`;
