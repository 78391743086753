import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Form as Formik } from 'formik';

export const Container = styled.div`
  max-width: 70rem;
  width: 100%;
`;
export const Wrapper = styled.div``;
export const Form = styled(Formik)`
  padding: 0 1.6rem;

  ${media.greaterThan('medium')`
    margin-bottom: 4rem;
  `}
`;
export const InputBox = styled.div``;
export const LocationButton = styled.button`
  ${({ theme }) => css`
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: ${theme.font.colors.primary};
    font-family: ${theme.font.bold};
    background: transparent;
    border: 0;

    span {
      color: ${theme.font.colors.primary};
      margin-right: 0.5rem;
    }
  `}
`;
export const WrapperButtonsSelection = styled.div`
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: 3.2rem;
`;

const ButtonModifiers = {
  selectedButton: (theme) => css`
    border: 1px solid
      ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.primary};
    background: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.primary};
    color: ${theme.name === 'contraste' ? '#000' : '#fff'};
  `,
};

export const ButtonsSelection = styled.button`
  ${({ theme, selected }) => css`
    color: ${theme.font.colors.primary};
    background: transparent;
    text-transform: uppercase;
    width: 50%;
    height: 4.8rem;
    border: 1px solid
      ${theme.name === 'contraste' ? '#fff' : theme.colors.primary};
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.large};
    text-align: center;

    ${selected && ButtonModifiers.selectedButton(theme)}
  `}
`;
export const FilterWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 1.6rem;
    margin-bottom: 3rem;

    span {
      text-transform: uppercase;
      color: ${theme.font.colors.primary};
      font-family: ${theme.font.medium};
    }
  `}
`;
export const FilterOptions = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  max-width: 64.2rem;
  flex-wrap: wrap;
  margin-right: 0.5rem;
  align-items: center;
`;
export const Options = styled.div`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
    height: 3.4rem;
    padding: 0 1.6rem;
    font-family: ${theme.font.medium};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${theme.name === 'contraste'
    ? `2px solid ${theme.colors.globalYellow}`
    : ''};
    span {
      margin-right: 0.5rem;
    }

    margin-bottom: 0.8rem;
    margin-right: 0.8rem;
    margin-left: 0.8rem;
  `}
`;
export const MapWrapper = styled.div`
  display: none;
  .leaflet-container {
    width: 100%;
    height: 40rem;
  }

  ${media.lessThan('medium')`
    display: ${({ selectedMap }) => (selectedMap ? 'block' : 'none')};  
  `}
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 2rem;

  ${media.greaterThan('medium')`
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    padding: 10px;

  `}

  ${media.lessThan('medium')`
    display: ${({ selectedList }) => (selectedList ? 'flex' : 'none')};  
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
  `}

  max-height: 100%;
`;
