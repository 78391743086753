import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import InfoCovid from '../pages/InfoCovid';
import Ouvidoria from '../pages/Ouvidoria';
import FaleConosco from '../pages/FaleConosco';
import Estrutura from '../pages/Estrutura';
import Institucional from '../pages/Institucional';
import Clinicas from '../pages/Clinicas';
import EquipeMedica from '../pages/EquipeMedica';
import Oncologia from '../pages/Oncologia';
import Ortopedia from '../pages/Ortopedia';
import Gastroenterologia from '../pages/Gastroenterologia';
import { ModeloAssistencial } from '../pages/ModeloAssistencial';
import CentroCirurgico from '../pages/CentroCirurgico';
import Noticias from '../pages/Noticias';
import NoticiasInternas from '../pages/NoticiasInternas';
import Emergencia from '../pages/Emergencia';
import Cardiologia from '../pages/Cardiologia';
import PlanosConvenios from '../pages/PlanosConvenios';
import Orcamento from '../pages/Orcamento';
import ProntoAtendimento from '../pages/ProntoAtendimento';
import PacotesCirurgicos from '../pages/PacotesCirurgicos';
import DownloadoPdfOncologia from '../pages/Download/Oncologia';
import DireitoSDeveres from '../pages/DireitosDeveres';
import OrientacaoInternacao from '../pages/OrientacoesInternacao';
import PlanosCcg from '../pages/PlanosCcg';
import SobreLgpd from '../pages/SobreLgpd';
import TermosDeUso from '../pages/TermosDeUso';
import PoliticaPrivacidadePacientes from '../pages/PoliticaPrivacidadePacientes';
import CodigoCondutaParceiros from '../pages/CodigoCondutaParceiros';
import CentroResolucaoCardiologia from '../pages/CentroResolucaoCardiologia';
import ResultadosExames from '../pages/ResultadosExames';

const Routes = () => (
  <>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/info-covid" component={InfoCovid} />
      <Route path="/ouvidoria" component={Ouvidoria} />
      <Route path="/resultado-de-exames" component={ResultadosExames} />
      {/* <Route path="/fale-conosco" component={FaleConosco} /> */}
      <Route path="/estrutura" component={Estrutura} />
      <Route path="/institucional" component={Institucional} />
      <Route path="/clinicas" component={Clinicas} />
      <Route path="/equipe-humaniza" component={EquipeMedica} />
      <Route path="/especialidades/cardiologia" component={Cardiologia} exact />
      <Route path="/especialidades/cardiologia/centro-resolutivo" component={CentroResolucaoCardiologia} />
      <Route
        path="/servicos/pacotes-cirurgicos"
        component={PacotesCirurgicos}
      />
      <Route
        path="/servicos/orientacoes-para-internacao"
        component={OrientacaoInternacao}
      />
      <Route
        path="/especialidades/centro-cirurgico"
        component={CentroCirurgico}
      />
      <Route
        exact
        path="/especialidades/gastroenterologia"
        component={Gastroenterologia}
      />
      <Route exact path="/especialidades/oncologia" component={Oncologia} />
      <Route
        exact
        path="/especialidades/ortopedia-traumatologia"
        component={Ortopedia}
      />
      <Route
        exact
        path="/o-hospital/modelo-assistencial"
        component={ModeloAssistencial}
      />
      <Route exact path="/planos-ccg" component={PlanosCcg} />
      <Route path="/noticias" component={Noticias} />
      <Route
        path="/o-hospital/direitos-deveres-do-paciente"
        component={DireitoSDeveres}
      />
      <Route path="/noticias-internas" component={NoticiasInternas} />
      <Route exact path="/servicos/emergencia" component={Emergencia} />
      <Route exact path="/planos-e-convenios" component={PlanosConvenios} />
      <Route exact path="/orcamento" component={Orcamento} />
      <Route
        exact
        path="/servicos/pronto-atendimento-adulto"
        component={ProntoAtendimento}
      />
      <Route
        exact
        path="/download-guia-paciente"
        component={DownloadoPdfOncologia}
      />
      <Route exact path="/lgpd" render={() => { window.location.href = '/politica-privacidade.html'; }} />
      <Route exact path="/lgpd/termos-de-uso" render={() => { window.location.href = '/politica-privacidade.html'; }} />
      <Route
        exact
        path="/lgpd/politica-privacidade-pacientes"
        render={() => { window.location.href = '/politica-privacidade.html'; }}
      />
      <Route
        exact
        path="/lgpd/conduta-parceiros"
        render={() => { window.location.href = '/politica-privacidade.html'; }}
      />
      <Route
        exact
        path="/lgpd/politica-privacidade"
        render={() => { window.location.href = '/politica-privacidade.html'; }}
      />
    </Switch>
  </>
);

export default Routes;
