import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 2.4rem;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
`;
export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.large};

    margin-bottom: ${theme.spacings.xxsmall};
  `}
`;
export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
  `}
`;

export const IconWrapper = styled.div`
  margin-right: 0.8rem;
`;
