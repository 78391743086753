import React from 'react';
import TimerCard from '../../../../components/TimerCard';
import MediaMatch from '../../../../components/MediaMatch';
import {
  LittleTitle,
  Title,
  Description,
} from '../../../../components/TextComponents';

import * as S from './styles';

export default function InfoTimerCard() {
  return (
    <S.Wrapper>
      <div className="size">
        <S.TitleWrapper>
          <LittleTitle color="secondary" label="CLASSIFICAÇÃO DE RISCO" />

          <Title
            size={{ web: '2.4rem', mobile: '2.4rem' }}
            label="Entenda o código de cores usado para classificar nossos atendimentos:"
          />
          <Description label="" />
        </S.TitleWrapper>
        <S.TimersWrapper>
          <S.Table>
            <S.Item>COR E CLASSIFICAÇÃO</S.Item>
            <S.Item>Tempo de espera</S.Item>
            <MediaMatch greaterThan="medium">
              <S.Item>Descrição</S.Item>
            </MediaMatch>
          </S.Table>
          <TimerCard
            color="#FF103C"
            littleTitle="VERMELHO"
            title="Emergência"
            time="0 minutos"
            footer="Caso gravíssimo, com necessidade de atendimento imediato e risco de morte."
          />
          <TimerCard
            color="#FF7800"
            littleTitle="laranja"
            title="Muita urgência"
            time="10 minutos"
            footer="Caso grave e risco significativo de evoluir para morte. Atendimento urgente."
          />
          <TimerCard
            color="#E8B635"
            littleTitle="amarelo"
            title="Urgência"
            time="60 minutos"
            footer="Caso de gravidade moderada, necessidade de atendimento médico, sem risco imediato."
          />
          <TimerCard
            color="#27AE60"
            littleTitle="verde"
            title="Pouca urgência"
            time="120 minutos"
            footer="Caso para atendimento preferencial em ambulatório de especialidades com hora agendada."
          />
          <TimerCard
            color="#2f80ed"
            littleTitle="azul"
            title="Não urgência"
            time="240 minutos"
            footer="Caso para atendimento ambulatorial de especialidade com hora agendada."
          />
        </S.TimersWrapper>
      </div>
    </S.Wrapper>
  );
}
