import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.maxwidth};
    width: 100%;
    margin: 0 auto;
    ${media.greaterThan('medium')`
      padding: 8rem 6rem 0;
    `}
    ${media.lessThan('medium')`
      margin: 0 auto;
    `}
  `}
`;

export const TextWrapper = styled.div`
  margin-bottom: 5.5rem;
`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.greaterThan('medium')`
    flex-direction: row;
    margin-bottom: 5rem;
  `}
`;

const menuOptionsModifiers = {
  selected: (theme) => css`
    background: ${theme.colors.secondary};
    border: 0;
    color: #fff;
  `,
};

const optionModifiers = {
  normal: (theme) => css`
    border: 0.1rem solid ${theme.colors.secondary};
  `,
  contraste: (theme, selected) => css`
    border: 0.1rem solid ${theme.colors.globalYellow};

    ${!!selected
    && css`
      background: ${theme.colors.globalYellow};
      color: #000;
    `};
  `,
};

export const Button = styled.button`
  ${({ theme, selected }) => css`
    height: 8rem;
    padding: 0 3.8rem;
    background: transparent;
    border-radius: ${theme.border.radius};
    font-weight: bold;
    margin-left: 4rem;
    color: ${theme.font.colors.primary};
    ${media.greaterThan('medium')`
        height: 6rem;
        width: 20%;
      
      `}
    ${media.lessThan('medium')`
        height: 4rem;
        width: 80%;
        margin-left: 2rem;
        margin-bottom: 1rem;
      `}
  
      ${!!selected && menuOptionsModifiers.selected(theme)}
      ${optionModifiers[theme.name](theme, selected)};
  `}
`;

export const WrapperDiv = styled.div``;
