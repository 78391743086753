import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Table = styled.div`
  padding: 2.4rem 1.6rem;
`;
export const Heading = styled.div`
  ${({ theme }) => css`
    color: ${theme.font.colors.secondary};
    font-weight: bold;
  `}
`;
export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-weight: bold;
    margin-top: 0.3rem;
  `}
`;
export const TextWrapper = styled.div``;
export const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${media.greaterThan('medium')`
    flex-direction: row;
    justify-content: space-between;
  `}
`;
export const RowItem = styled.div`
  ${({ flex }) => css`
    margin-bottom: 0.8rem;
    display: ${!!flex && 'flex'};
    justify-content: space-between;
  `}
`;

export const RemoveButton = styled.button`
  ${({ theme }) => css`
    height: 4.8rem;
    width: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: ${theme.font.colors.primary};
    font-weight: bold;
    background: none;
    border-radius: ${theme.border.radius};
    border: 0.2rem solid ${theme.font.colors.primary};

    svg {
      margin-right: 1.2rem;
    }
  `}
`;
export const RemoveButtonWrapper = styled.div``;

export const Div = styled.div`
  display: ${({ display }) => display};
`;
