import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  input[type='file'] {
    visibility: hidden;
    pointer-events: none;
  }
`;
export const IconWrapper = styled.div`
  ${({ color }) => css`
    color: ${color};
  `}
`;
export const Label = styled.label`
  ${({ theme }) => css`
    width: 25rem;
    height: 5.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0.2rem solid ${theme.font.colors.secondary};
    border-radius: ${theme.border.radius};
  `}
`;

export const FilesWrapper = styled.div`
  margin-top: 2rem;
`;
export const FilesCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 47rem;
    height: 4rem;
    padding: 0 2.4rem;
    border: 0.1rem solid
      ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondWhite};
    background: ${theme.name === 'contraste'
    ? 'none'
    : theme.colors.secondWhite};
  `}
`;
export const FilesName = styled.div`
  flex: 1;
  ${({ theme }) => css`
    background: none;
    display: flex;
    align-items: center;
    border: 0;
    color: ${theme.font.colors.primary};
    margin-left: 1rem;
  `}
`;
export const Exclude = styled.button`
  ${({ theme }) => css`
    background: none;
    display: flex;
    align-items: center;
    border: 0;
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
  `}
`;
