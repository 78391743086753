import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Button = ({
  to, children, color, size,
}) => (
  <Container size={size} to={to} color={color}>
    <span>{children}</span>
  </Container>
);

Button.defaultProps = {
  color: 'rose',
  size: 'normal',
};

Button.propTypes = {
  to: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'large']),
};

export default Button;
