import styled, { css } from 'styled-components';

const containerModifiers = {
  normal: () => css`
    background: #fff;
  `,
  contraste: () => css`
    background: #000;
  `,
};

export const Container = styled.div`
  ${({ theme }) => css`
    ${theme && containerModifiers[theme.name]}
  `}
`;
export const ChildrenWrapper = styled.div``;
