import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import bgSlider from '../../assets/estrutura-assets/bg-slider.svg';

export const Container = styled.div``;
export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.maxwidth};
    width: 100%;
    margin: 0 auto;
  `}
`;
export const TitleWrapper = styled.div`
  padding: 6rem 1.6rem;

  ${media.lessThan('medium')`
    padding: 6rem 2.4rem;
  `}
`;

export const BannerWrapper = styled.div`
  width: 100%;
  margin-bottom: 8rem;

  ${media.greaterThan('medium')`
    margin-bottom: 12rem;
  `}
`;

export const SupporTextWrapper = styled.div`
  margin-bottom: 8rem;
  padding: 0 2.4rem;

  ${media.greaterThan('medium')`
    margin-bottom: 12rem;
    padding: 0 1rem;

  `}
`;

export const SlideWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.small} 5rem;

    ul.slick-dots li button::before {
      content: '';
      width: 1.1rem;
      height: 1.1rem;
      background: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : '#999594'};
      border-radius: 50%;
    }

    ul.slick-dots li.slick-active button::before {
      background: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
    }
  `}
`;

export const KnowMoreWrapper = styled.div`
  ${({ theme }) => css`
    padding: 9rem ${theme.spacings.small};
    background: ${theme.name === 'contraste'
    ? '#000'
    : theme.colors.secondWhite};
  `}
`;

export const SliderBox = styled.div`
  width: 100%;
  max-width: 136rem;

  ${media.greaterThan('medium')`
    margin-left: auto;  
    margin-right: 0;
  `}
`;

const bgModifier = {
  customColor: (theme) => (theme.name === 'normal'
    ? `url(${bgSlider}),linear-gradient(180deg, #00205b 0%, #001843 100%)`
    : '#000'),
};

export const SliderWrapper = styled.div`
  ${({ theme }) => css`
    padding: 5rem 0;
    width: 100%;

    background: ${bgModifier.customColor(theme)};
    background-repeat: no-repeat;
    background-position-x: 0;
    background-size: contain;

    .image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .slick-list .slick-slide > div {
      margin-left: 0.3rem;
    }

    [data-index].slick-slide .image ${TitleWrapper} {
      opacity: 0;
    }
    [data-index].slick-current .image ${TitleWrapper} {
      opacity: 1;
    }

    [data-index] {
      transition: all 0.3s ease;
    }

    [data-index] img {
      transition: all 0.3s ease;

      opacity: 0.3;
    }

    div.slick-current div img {
      opacity: 1;
    }
  `}
`;

export const InfoBox = styled.div`
  width: 100%;
  max-width: 200rem;
  margin-top: 3rem;
  margin-right: 0;
  margin-left: auto;

  .button__wrapper {
    margin-top: 2rem;
    max-width: 136rem;
    margin-left: auto;

    ${media.lessThan('medium')`
      padding: 0 1.6rem;
    `}
  }
`;
export const TitleInfo = styled.h1`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.font.colors.white};
    font-size: 3.6rem;
  `}
`;
export const DescriptionInfo = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.white};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    display: block;
    margin-top: 2rem;
    line-height: 2.8rem;
  `}
`;
export const Button = styled.button`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          width: 8.8rem;
          height: 5.4rem;
          border: 2px solid ${theme.colors.secondary};
          color: ${theme.colors.secondary};
          background: transparent;
          border-radius: ${theme.border.radius};

          & + button {
            margin-left: 1rem;
          }
        `
    : css`
          width: 8.8rem;
          height: 5.4rem;
          border: 2px solid ${theme.colors.globalYellow};
          color: ${theme.colors.globalYellow};
          background: transparent;
          border-radius: ${theme.border.radius};

          & + button {
            margin-left: 1rem;
          }
        `)}
`;

export const BgNews = styled.div`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          background: linear-gradient(
            0deg,
            ${theme.colors.secondWhite} 72.5%,
            rgba(255, 255, 255, 1) 50%
          );

          ${media.greaterThan('medium')`
      background: linear-gradient(
        0deg,
        ${theme.colors.secondWhite} 45%,
        rgba(255, 255, 255, 1) 44%
      );
    `}
        `
    : css`
          background: #000;
        `)}
`;
