/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { MdRemoveCircleOutline } from 'react-icons/md';

import MediaMatch from '../../../../../components/MediaMatch';

import {
  Fields,
  InputBox,
  Input,
  Title,
  RadioWrapper,
  Radio,
  Wrapper,
  Button,
  ButtonWrapper,
  Counter,
} from '../common';

import * as S from './styles';

export default function MateriaisEspeciais() {
  const [radio, setRadio] = useState('sim');
  const [descricao, setDescricao] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [marca, setMarca] = useState('');
  const [array, setArray] = useState([]);

  function addArray(value) {
    setArray([...array, value]);
  }

  function removeItem(index) {
    const newItens = array.filter((item) => item.id !== index);
    setArray(newItens);
  }

  return (
    <Wrapper>
      <Title label="Materiais Especiais" />
      <RadioWrapper margin="4rem 0 0">
        <Fields display="flex">
          <Radio
            value="sim"
            id="sim"
            name="materiais"
            label="Sim"
            onClick={() => setRadio('sim')}
          />
          <Radio
            id="nao"
            value="nao"
            name="materiais"
            label="Não"
            onClick={() => setRadio('nao')}
          />
        </Fields>
      </RadioWrapper>

      <Counter
        display={radio === 'sim' ? 'block' : 'none'}
        length={array.length}
      >
        {array.map((material) => (
          <S.Table>
            <S.Row>
              <S.RowItem>
                <S.Heading>Descrição</S.Heading>
                <S.Description>{material.descricao}</S.Description>
              </S.RowItem>
              <S.RowItem>
                <S.Heading>Marca</S.Heading>
                <S.Description>{material.marca}</S.Description>
              </S.RowItem>
              <S.RowItem flex>
                <S.TextWrapper>
                  <S.Heading>Quantidade</S.Heading>
                  <S.Description>{material.quantidade}</S.Description>
                </S.TextWrapper>
                <MediaMatch lessThan="medium">
                  <S.RemoveButtonWrapper>
                    <S.RemoveButton onClick={() => removeItem(material.id)}>
                      <MdRemoveCircleOutline size={20} />
                      Remover
                    </S.RemoveButton>
                  </S.RemoveButtonWrapper>
                </MediaMatch>
              </S.RowItem>
              <MediaMatch greaterThan="medium">
                <S.RowItem>
                  <S.RemoveButtonWrapper>
                    <S.RemoveButton onClick={() => removeItem(material.id)}>
                      <MdRemoveCircleOutline size={20} />
                      Remover
                    </S.RemoveButton>
                  </S.RemoveButtonWrapper>
                </S.RowItem>
              </MediaMatch>
            </S.Row>
          </S.Table>
        ))}
      </Counter>

      <S.Div display={radio === 'sim' ? 'block' : 'none'}>
        <Fields>
          <InputBox>
            <Input
              name="descricao"
              label="descrição"
              onChange={(event) => setDescricao(event.target.value)}
              value={descricao}
            />
          </InputBox>
          <InputBox flex>
            <div className="wrapperFlex">
              <Input
                name="quantidade"
                label="quantidade"
                type="number"
                onChange={(event) => setQuantidade(event.target.value)}
                value={quantidade}
              />
            </div>
            <div className="wrapperFlex">
              <Input
                name="marca"
                label="Marca"
                onChange={(event) => setMarca(event.target.value)}
                value={marca}
              />
            </div>
          </InputBox>
        </Fields>
        <ButtonWrapper noMargin>
          <Button
            onClick={() => addArray({
              id: array.length, descricao, marca, quantidade,
            })}
          >
            <AiOutlinePlusCircle size={20} />
            <span>Adicionar Materiais</span>
          </Button>
        </ButtonWrapper>
      </S.Div>
    </Wrapper>
  );
}
