import React, { useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Field, Formik } from 'formik';

import AccessibilityMenu from '../../components/AccessibilityMenu';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import InputText from '../../components/InputText';
import Select from '../../components/Select';
import Counter from '../../components/Counter';
import DoctorCard from '../../components/DoctorCard';
import Breadcrumbs from '../../components/Breadcrumbs';
import SectionInfoCard from '../../components/SectionInfoCard';

import * as S from './styles';

import virtual from '../../assets/institucional-assets/virtual.svg';
import ButtonOutlined from '../../components/Button/Outline';

export default function CorpoClinico() {
  const data = [1, 2, 3, 4];

  const initialValues = {
    drName: '',
    select: '',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <S.Container>
      <AccessibilityMenu />
      <Menu />
      <Breadcrumbs />

      <S.Wrapper>
        <S.TitleWrapper>
          <div className="ajust__sizes">
            <h1 className="title">Equipe humaniza</h1>
            <S.SubTitle>
              O Hospital Humaniza conta com um corpo clínico altamente
              qualificado, com especialistas nas mais diversas áreas de
              atendimento. Confira nossa lista de profissionais e suas
              especialidades.
            </S.SubTitle>
          </div>
        </S.TitleWrapper>
        <S.FormWrapper>
          <S.FormLabel>
            <h3>Pesquisar profissional ou especialidade:</h3>
          </S.FormLabel>
          <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {() => (
              <S.Form>
                <S.InputBox>
                  <InputText name="drName" label="nome do médico" type="text" />
                </S.InputBox>
                <S.InputBox>
                  <Field name="select">
                    {({ field }) => (
                      <Select {...field} label="Especialidades">
                        <option value="cardiologia">Cardiologia</option>
                        <option value="oncologia">Oncologia</option>
                      </Select>
                    )}
                  </Field>
                </S.InputBox>

                <S.SubmitButton type="submit">
                  <S.IconButton>
                    <AiOutlineSearch size={20} color="#fff" />
                  </S.IconButton>
                  <span>Buscar</span>
                </S.SubmitButton>
              </S.Form>
            )}
          </Formik>
        </S.FormWrapper>

        <S.CardResult>
          <Counter length={data.length} />
        </S.CardResult>

        <S.WrapperResults>
          <S.GridContainer>
            <DoctorCard />
            <DoctorCard />
            <DoctorCard />
            <DoctorCard />
          </S.GridContainer>
        </S.WrapperResults>
        <div className="button__wrapper">
          <ButtonOutlined size="large" label="Carregar mais" />
        </div>
      </S.Wrapper>
      <SectionInfoCard
        title="Planos e convênios atendidos pelo Hospital Humaniza"
        littleTitle="PLANOS E CONVÊNIOS"
        description="Confira os planos e convênios atendidos pelo Hospital Humaniza."
        background="blue"
        url="/"
        image={virtual}
        alignment="left"
      />
      <SectionInfoCard
        title="Planos e convênios atendidos pelo Hospital Humaniza"
        littleTitle="PLANOS E CONVÊNIOS"
        description="Confira os planos e convênios atendidos pelo Hospital Humaniza."
        background="white"
        url="/"
        image={virtual}
        alignment="right"
      />
      <Footer />
    </S.Container>
  );
}
