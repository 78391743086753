import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
    margin: 0 auto;
    width: 100%;
    max-width: ${theme.maxwidth};
  `}
`;
export const TextWrapper = styled.div`
  margin-top: 3rem;

  ${media.greaterThan('medium')`
    margin-top: 8rem;
  `}
`;

export const CardResult = styled.div``;
export const Grid = styled.div`
  display: grid;

  grid-column-gap: 1rem;
  grid-row-gap: 2.4rem;
  grid-template-columns: repeat(auto-fit, minmax(316px, auto));

  ${media.lessThan('medium')`
    grid-row-gap: 0.8rem;
    grid-column-gap: 0;
  `}
`;

export const ResultsWrapper = styled.div`
  .button__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;

    ${media.greaterThan('medium')`
      margin: 9.5rem 0;      
    `}
  }
`;
