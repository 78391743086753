import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: auto;
    display: flex;
    display: flex;
    flex-direction: column;
  `}
  ${media.greaterThan('medium')`
  flex-direction: row;
    padding-left: 15rem;

  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: auto;

    background: transparent;
    display: flex;
    flex-direction: column;
    margin: 0;
  `}
  ${media.greaterThan('medium')`
      width: 25%;
      margin-left: 4rem;
  `}
  ${media.lessThan('medium')`
    width: 100%;
  `}
  ${media.lessThan('small')`
      margin-bottom: 4rem;
  `}
`;

export const CardImage = styled.div`
  ${({ backgroundImage, theme }) => css`
    width: 100%;
    background: url(${backgroundImage});
    background-repeat: no-repeat;

    // background-size: cover;
    //background-color: blue;
    display: flex;
    flex-direction: column;
    margin-bottom: -1.9rem;
  `}
`;

export const CardImageUp = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 15rem;
    margin-bottom: 2rem;
    text-align: center;
  `}
`;

export const CardImageDown = styled.div`
  width: 100%;
  display: flex;
`;

export const Logo = styled.div`
  ${({ theme, backgroundImage }) => css`
    background: url(${backgroundImage});
    background-repeat: no-repeat;
    height: 5rem;
    margin-top: 3rem;
    margin-left: 6rem;
    margin-bottom: 1.5rem;
    width: auto;
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray};
    height: 55rem;
  `}
`;

export const Button = styled.button`
  ${({ theme, border, background }) => css`
    margin: 1.5rem;
    height: 5rem;
    width: 80%;
    border-radius: 0.4rem;
    border: 0px;
    background: ${background};
    border: ${border};
    color: #01426a;
    font-size: 1.2rem;
  `}
`;

export const Span = styled.span`
  ${({ theme }) => css`
    font-family: Calibri, sans-serif;
    font-weight: 700;
    font-size: 4.2rem;
    color: #fff;
    vertical-align: middle;
  `}
`;

export const Small = styled.small`
  font-weight: 700;
  font-size: 1.8rem;
  font-family: Raleway, sans-serif;
  color: #fff;
`;

export const P = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    color: #fff;
    vertical-align: middle;
  `}
`;

export const LittleP = styled.p`
  ${({ theme }) => css`
    font-size: 1.2rem;
    color: #fff;
  `}
`;

export const DivButtn = styled.div`
  width: 49%;
  margin-left: 0.5rem;
  ${media.greaterThan('medium')`
     margin-left: 1.5rem;
  `}
`;

export const SubTitulo = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  color: #fff;
  width: 27.2rem;
  text-align: center;
  font-family: Raleway, sans-serif;
  margin: 1.5rem;
`;

export const TitleDescription = styled.p`
  ${({ theme }) => css`
    margin-top: 2rem;
    margin-left: 2rem;
    color: ${theme.colors.primary};
    font-family: ${theme.font.diodrum};
    padding-top: 1rem;
  `}
`;

export const SubTitleDescription = styled.p`
  ${({ theme }) => css`
    margin-top: 1rem;
    margin-left: 2rem;
    color: ${theme.colors.blackFonts};
    font-family: ${theme.font.diodrum};
    padding-top: 1rem;
  `}
`;
export const Hr = styled.hr`
  ${({ theme }) => css`
    color: gray;
    margin-top: 2rem;
  `}
`;

export const Icon = styled.div`
  font-size: 1.5rem;
  position: absolute;
  margin-top: 1.5rem;
  margin-left: 32rem;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    background: ${theme.colors.secondWhite};
    height: 5rem;
    border: 0.5rem;
    margin-top: 2rem;
  `}
`;

export const Content = styled.div`
  ${({ theme, open }) => css`
    width: 100%;
    display: ${open ? 'block' : 'none'};
  `}
`;
