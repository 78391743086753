import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  padding: 9.2rem 0;
`;
export const Wrapper = styled.div`
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 0 5rem;

  ${media.greaterThan('large')`
    padding: 0 8rem 5rem;
    display: flex;
  `}
`;

export const MapWrapper = styled.div`
  ${media.greaterThan('large')`
    margin-right: 7.3rem;
    width: 60rem;
  `}
`;

export const LocationWrapper = styled.div`
  width: 100%;
  ${({ theme }) => css`
    padding: 3rem ${theme.spacings.xsmall} 0;
    ${media.greaterThan('large')`
      width: 40%;
  `}
  `}
`;
export const SmallTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    text-transform: uppercase;
    font-size: ${theme.font.sizes.xsmall};
    letter-spacing: 0.2rem;
    line-height: 1.6rem;
    font-family: ${theme.font.medium};
  `}
`;
export const BigTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-size: ${theme.font.sizes.xxxlarge};
    font-family: ${theme.font.bold};
    margin-top: 1rem;
    margin-bottom: 1rem;

    ${media.greaterThan('large')`
    font-size: ${theme.font.sizes.xxxlarge};

  `}
  `}
`;
export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.blackFonts};
    font-size: ${theme.font.sizes.medium};
    margin-bottom: 1.5rem;
    line-height: 2.8rem;
  `}
`;

export const LinkToMap = styled.a`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          border: 0;
          height: 4.8rem;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: ${theme.border.radius};
          font-size: ${theme.font.sizes.small};
          letter-spacing: 0.05rem;
          background: ${theme.colors.secondary};
          width: 20rem;
        `
    : css`
          border: 1px solid ${theme.colors.globalYellow};
          height: 4.8rem;
          color: ${theme.font.colors.white};
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: ${theme.border.radius};
          font-size: ${theme.font.sizes.small};
          letter-spacing: 0.05rem;
          width: 20rem;
          transition: all 0.3s ease-in-out;

          &:hover {
            background: #fff;
            color: #000;
          }
        `)}
`;
