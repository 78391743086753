import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall} 5rem;
    background: ${theme.name === 'contraste' ? '#000' : '#fff'};
  `}
`;
export const Wrapper = styled.div`
  width: 100%;
  max-width: 95.8rem;
  margin: 0 auto 15rem;
  padding-top: 10rem;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 7.5rem;
  margin-right: 2rem;
`;
export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.bold};
    color: ${theme.font.colors.primary};
    font-size: 6.3rem;

    ${media.lessThan('medium')`
      font-size: 4.2rem;
    `}
  `}
`;
export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blackFonts};
    font-size: ${theme.font.sizes.large};
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const AskCardWrapper = styled.div`
  width: 100%;
`;
export const AskCard = styled.div`
  width: 100%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 2rem 2rem 0;
`;
export const Question = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-family: ${theme.font.bold};
    text-align: left;
    max-width: 90%;
  `}
`;
export const PlusDetails = styled.button`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.primary};
    font-size: ${theme.font.sizes.large};
    margin-bottom: ${theme.spacings.xsmall};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    background: none;

    .span__text {
      display: block;
      color: ${theme.colors.secondary};
      margin-right: 1rem;
      text-transform: uppercase;
      font-size: ${theme.font.sizes.small};

      ${media.lessThan('medium')`
        display: none;
      `}
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  `}
`;
export const SubCardQuestionWrapper = styled.div`
  ${({ open }) => css`
    display: ${open ? 'block' : 'none'};
  `}
`;
export const SubcardQuestion = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.blackFonts};
    font-size: ${theme.font.sizes.medium};
    padding: 0 0 2rem;
  `}
`;

export const Link = styled.div`
  ${({ theme }) => css`
    margin-top: 4rem;
    font-size: 1.6rem;

    color: ${theme.font.colors.primary};
    font-size: ${theme.font.sizes.medium};

    a {
      font-family: ${theme.font.bold};
      text-decoration: underline;
      color: ${theme.colors.secondary};
    }
  `}
`;
