import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
  `}
  ${media.greaterThan('medium')`
    width: 80%;
  `}
`;
export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.2rem;
    background: ${theme.name === 'contraste'
    ? '#000'
    : theme.colors.secondWhite};
    cursor: pointer;
    font-size: 2.2rem;
    border-radius: 0.5rem;
  `}
`;

export const TextHeader = styled.div`
  ${({ theme }) => css`
    color: gray;
  `}
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    font-size: 1.5rem;
    margin-right: 1rem;
  `}
`;

export const Content = styled.div`
  ${({ theme, open }) => css`
    display: ${open ? 'block' : 'none'};
  `}
`;

export const ContentItem = styled.div`
  ${({ theme }) => css`
    justify-content: space-between;
    display: flex;
    padding: 1.8rem;
    align-items: center;
    background: white;
    height: 15rem;
    width: 100%;
  `}
  ${media.greaterThan('medium')`
    height: 9rem;
  `}
`;

export const ItemText = styled.div`
  ${({ theme }) => css`
    width: 60%;
    color: gray;
    font-size: 1.5rem;
  `}
  ${media.greaterThan('medium')`
    width: 40%;
  `}
`;

export const ItemPreco = styled.div``;

export const ButtonOrcacamento = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding: 1rem;
    align-items: center;
    background: ${theme.colors.primary};
    color: ${theme.colors.secondWhite};
    border: 0px;
    border-radius: 0.5rem;
    height: 4rem;
  `}
`;

export const Text = styled.div`
  ${({ theme }) => css`
    font-size: 1.5rem;
    padding-left: 1rem;
    margin-left: 1rem;
    background: ${theme.colors.primary};
  `}
`;
export const Hr = styled.hr`
  ${({ theme }) => css`
    color: gray;
  `}
`;

export const A = styled.a`
  ${({ theme }) => css`
    border-style: none;
    background: #fff;
    height: 0.2rem;
  `}
  ${media.greaterThan('medium')`
    width: 20%;
    height: 4rem;

  `}
`;
