import React from 'react';
import { useSelector } from 'react-redux';
import GoogleMaps from '../GoogleMaps';

import * as S from './styles';

const Map = () => (
  <S.Container>
    <S.Wrapper>
      <S.MapWrapper>
        <GoogleMaps
          initialPosition={{ lat: -30.0248143, lng: -51.2124206 }}
          height="37rem"
        />
      </S.MapWrapper>
      <S.LocationWrapper>
        <S.SmallTitle>localização</S.SmallTitle>
        <S.BigTitle>
          Localização privilegiada
          {' '}
          <br />
          {' '}
          e com fácil acesso.
        </S.BigTitle>
        <S.Description>
          No centro de tudo e perto de você:
          {' '}
          <br />
          Rua Ramiro Barcelos, 621
          {' '}
          <br />
          {' '}
          Porto Alegre.
        </S.Description>

        <S.LinkToMap href=" https://www.google.com.br/maps/@-30.0248143,-51.2124206,17z">
          Saiba como chegar
        </S.LinkToMap>
      </S.LocationWrapper>
    </S.Wrapper>
  </S.Container>
);

export default Map;
