const string = '<p>O CCG Saúde sabe que a sua saúde merece toda a atenção.</p><br>'
               + '<p>Por isso, para facilitar o acesso aos clientes que necessitem de um atendimento cardiológico ágil e resolutivo, e que não desejem aguardar a agenda com os profissionais das nossas Clínicas, criamos o Centro de Resolução de Cardiologia no Hospital Humaniza.</p><br>'
               + '<p>Um serviço formado por profissionais de diferentes formação, todos especializados no trato das doenças do coração.</p><br>'
               + '<p>No Centro de Resolução de Cardiologia, você terá um atendimento ágil, resolutivo e muito especializado</p><br>';

const string2 = '<b class="title">Como funciona?</b>'
                + '<p><b class="secondary">1.</b> O paciente<b class="bold"> entra em contato via Central de Marcações do CCG Saúde (51 2312.1505)</b>  e solicita uma agenda para o serviço.</p><br>'
                + '<p><b class="secondary">2.</b> <b class="bold"> Em até dois dias úteis, ocorre o primeiro atendimento, via telemedicina,</b> com uma enfermeira do Centro de Resolução de Cardiologia. Esse primeiro atendimento servirá para entender melhor o caso e direcioná-lo ao profissional especialista mais indicado para a sequência do seu tratamento. Você sairá dessa triagem já com a sua consulta presencial agendada. </p><br>'
                + '<p><b class="secondary">3.</b>  Após a triagem, <b class="bold">em até 7 dias úteis será feita a primeira consulta presencial no Centro de Resolução de Cardiologia,</b> onde será feito o seu plano de tratamento com o profissional mais indicado para o seu caso. </p>';
const slideSettings = {
  slidesToShow: 1,
  arrows: false,
  infinite: true,
  dots: true,
  lazyLoad: 'ondemand',
  speed: 500,
  autoplaySpeed: 4000,
  initialSlide: 0,
  autoplay: true,
};

export { string, string2, slideSettings };
