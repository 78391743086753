import React from 'react';

import { Field, Formik } from 'formik';

import { FaSearch } from 'react-icons/fa';
import Select from '../../../../components/Select';
import Checkbox from '../../../../components/Checkbox';
import { Title } from '../../../../components/TextComponents';

import * as S from './styles';

export default function Form() {
  return (
    <S.FormWrapper>
      <Title
        label="Pesquisar planos e convênios atendidos:"
        size={{ web: '2.8rem', mobile: '2.4rem' }}
      />
      <Formik>
        {() => (
          <S.Form>
            <S.Fields>
              <S.SelectBox>
                <Field
                  render={() => (
                    <Select label="Convênio">
                      <option value="ccg">Centro Clínico Gaúcho</option>
                    </Select>
                  )}
                />
                <Field
                  render={() => (
                    <Select label="Planos">
                      <option value="planos">CCG Multi</option>
                    </Select>
                  )}
                />
              </S.SelectBox>
              <S.CheckboxWrapper>
                <S.Label>FILTRAR POR TIPO DE COBERTURA:</S.Label>
                <S.CheckboxContainer>
                  <Checkbox
                    label="Consultas"
                    id="consultas"
                    name="filter.consultas"
                  />
                  <Checkbox
                    label="Emêrgencia"
                    id="emergencia"
                    name="filter.emergencia"
                  />
                  <Checkbox label="Exames" id="exames" name="filter.exames" />
                  <Checkbox
                    label="Internação"
                    id="internacao"
                    name="filter.internacao"
                  />
                </S.CheckboxContainer>
              </S.CheckboxWrapper>
            </S.Fields>
            <S.SubmitButtonWrapper>
              <S.SubmitButton>
                <FaSearch size={14} />
                Buscar
              </S.SubmitButton>
            </S.SubmitButtonWrapper>
          </S.Form>
        )}
      </Formik>
    </S.FormWrapper>
  );
}
