import React from 'react';

import * as S from './styles';

const TextArea = ({
  label, value, name, error, ...rest
}) => (
  <S.Container>
    <S.Label error={error}>
      <span>{!!label && label}</span>
    </S.Label>
    <S.TextArea
      error={error}
      name={name}
      as="textarea"
      value={value}
      {...rest}
    />
  </S.Container>
);

export default TextArea;
