/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import AccessibilityMenu from '../../components/AccessibilityMenu';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';

import * as S from './styles';

import icon from '../../assets/estrutura-assets/check.svg';
import imageCourolcel_1 from '../../assets/estrutura-assets/slide/slide_1.png';
import imageCourolcel_2 from '../../assets/estrutura-assets/slide/slide_2.png';
import imageCourolcel_3 from '../../assets/estrutura-assets/slide/slide_3.png';

import DiferencialCard from '../../components/DiferencialCard';
import Breadcrumbs from '../../components/Breadcrumbs';

const Estrutura = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sliderRef = React.createRef();

  const next = () => sliderRef.current.slickNext();
  const prev = () => sliderRef.current.slickPrev();

  const settings = {
    arrows: false,
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 500,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
      },
    ],
  };

  return (
    <S.Container className="e essa">
      <AccessibilityMenu />
      <Menu />
      <Breadcrumbs />

      <S.Wrapper>
        <S.TitleWrapper>
          <S.SmallTitle>estrutura</S.SmallTitle>
          <S.Title size="7.6rem">
            Sua nova referência em saúde em Porto Alegre
          </S.Title>
          <S.Description />
        </S.TitleWrapper>
        <S.IframeWrapper>
          <S.Iframe
            title="video"
            src="https://www.youtube.com/embed/pkb9ad-N7lA"
            frameBorder="0"
            allowFullScreen
          />
        </S.IframeWrapper>
        <S.DiferecialWrapper>
          <S.TitleWrapper>
            <S.Title size="42rem">Diferenciais do Hospital Humaniza:</S.Title>
          </S.TitleWrapper>
          <S.Caracteristics>
            <DiferencialCard
              icon={icon}
              message="Centro cirúrgico moderno com 10 salas e hemodinâmica"
            />

            <DiferencialCard
              icon={icon}
              message="Moderna tecnologia em diagnósticos por imagem"
            />

            <DiferencialCard
              icon={icon}
              message="Atendimento de Urgência e Emergência 24h"
            />

            <DiferencialCard
              icon={icon}
              message="Centro cirúrgico moderno com
              10 salas e hemodinâmica"
            />

            <DiferencialCard
              icon={icon}
              message="Modelo assistencial de excelência, com corpo clínico qualificado"
            />

            <DiferencialCard
              icon={icon}
              message="Hospital geral com 220 leitos"
            />

            <DiferencialCard
              icon={icon}
              message="Centros de Especialidades: Cardiologia, Oncologia, Ortopedia e Traumatologia, Gastroenterologia, Medicina interna e Cirurgia geral"
            />

            <DiferencialCard
              icon={icon}
              message="Conforto e privacidade para tratamentos e aplicações de medicamentos"
            />
            <DiferencialCard
              icon={icon}
              message="20 leitos individuais de UTI"
            />
          </S.Caracteristics>
        </S.DiferecialWrapper>
      </S.Wrapper>
      <S.SliderWrapper>
        <S.SliderBox>
          <Slider {...settings} ref={sliderRef}>
            <div className="image">
              <img src={imageCourolcel_1} alt="" />
              <S.TitleWrapper>
                <S.TitleInfo>
                  Pronto atendimento e emergência 24 horas
                </S.TitleInfo>
                <S.DescriptionInfo>
                  Estrutura completa para atendimento de casos complexos.
                </S.DescriptionInfo>
              </S.TitleWrapper>
            </div>
            <div className="image">
              <img src={imageCourolcel_2} alt="" />

              <S.TitleWrapper>
                <S.TitleInfo>
                  Modernidade e tecnologia no centro cirúrgico e UTI
                </S.TitleInfo>
                <S.DescriptionInfo>
                  Instalações e equipamentos modernos, com corpo clínico
                  qualificado.
                </S.DescriptionInfo>
              </S.TitleWrapper>
            </div>
            <div className="image">
              <img src={imageCourolcel_3} alt="" />
              <S.TitleWrapper>
                <S.TitleInfo>
                  Maior conforto e atendimento completo na jornada do paciente
                </S.TitleInfo>
              </S.TitleWrapper>
            </div>
          </Slider>
        </S.SliderBox>
        <S.InfoBox>
          <div className="button__wrapper">
            <S.Button onClick={() => prev()}>
              <IoIosArrowBack size={20} />
            </S.Button>
            <S.Button onClick={() => next()}>
              <IoIosArrowForward size={20} />
            </S.Button>
          </div>
        </S.InfoBox>
      </S.SliderWrapper>
      <Footer />
    </S.Container>
  );
};

export default Estrutura;
