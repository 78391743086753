import React from 'react';

import GlobalContainer from '../../components/_layout/Container';
import { Title, Description } from '../../components/TextComponents';
import Card from './components/Card';
import MediaMatch from '../../components/MediaMatch';
import Slider from '../../components/Slider';
import DepoimentCard from '../../components/DepoimentCard';
import SectionInfoCard from '../../components/SectionInfoCard';
import { ImageBanner } from '../../components/MediaContainer/modules';
import SupporText from '../../components/TextComponents/SupportText';

import { text, settings } from './utils/mock';
import bannerMobile from '../../assets/emergencia/ImageMobile.png';
import bannerWeb from '../../assets/emergencia/Image.png';
import virtual from '../../assets/institucional-assets/virtual.svg';

import * as S from './styles';

export default function Emergencia() {
  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TextWrapper>
          <Title
            size={{ web: '7.6rem', mobile: '4.2rem' }}
            label="Emergência"
          />
          <Description
            width="90rem"
            label="Com capacidade para realizar procedimentos de urgência e emergência, exames de diversas especialidades e suporte cirúrgico, o Hospital Humaniza tem a estrutura completa para atender você independente da situação"
          />
        </S.TextWrapper>
      </S.Wrapper>
      <S.ApplyBackground>
        <S.ImageWrapper>
          <ImageBanner backgrounds={{ bannerMobile, bannerWeb }} />
        </S.ImageWrapper>
        <S.SupportTextWrapper color="secondWhite">
          <SupporText
            size={{
              web: '2.8rem',
              mobile: '2rem',
            }}
            lineHeight={{ web: '4.4rem' }}
            justify="center"
            text="A Emergência do Hospital Humaniza tem capacidade para realizar procedimentos de urgência e emergência, exames de diversas especialidades, suporte cirúrgico e muito mais. Aqui, você tem total apoio, com uma infraestrutura que está sempre pronta para atender você, independente da situação."
          />
        </S.SupportTextWrapper>
      </S.ApplyBackground>
      <S.Wrapper>
        <S.SupportTextWrapper style={{ padding: '12rem 0' }}>
          <SupporText text={text} />
        </S.SupportTextWrapper>
      </S.Wrapper>
      {/* <S.Grid>
        <Card
          color="primary"
          title="Bloco #02"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Facilisis pellentesque faucibus viverra turpis a sit. Tempor nullam erat potenti pretium.
          Vivamus bibendum massa ultrices rhoncus quisque mattis vitae. In imperdiet iaculis viverra odio laoreet volutpat ipsum eu dis.
          Urna, nisl risus duis ac vestibulum nunc turpis integer. Nibh laoreet facilisis habitant risus, varius ullamcorper neque natoque. Ultrices a condimentum in."
        />
        <Card mobileNone color="secondary" />
      </S.Grid> */}
      {/* <S.SupportTextWrapper
        color="secondWhite"
        style={{ padding: '12rem 1.6rem' }}
      >
        <SupporText title="Bloco #03" text={text.replace(/\n/g, '<br />')} />
      </S.SupportTextWrapper> */}

      {/*
      <S.Wrapper>
        <S.SlideWrapper>
          <MediaMatch lessThan="medium">
            <Slider {...settings}>
              <DepoimentCard type="mobile" />
              <DepoimentCard type="mobile" />
            </Slider>
          </MediaMatch>

          <MediaMatch greaterThan="medium">
            <Slider {...settings}>
              <DepoimentCard type="web" />
              <DepoimentCard type="web" />
            </Slider>
          </MediaMatch>
        </S.SlideWrapper>
      </S.Wrapper>
      <SectionInfoCard
        title="Confira a lista de espera para o atendimento"
        littleTitle="PrONTO ATENDIMENTO"
        description="Confira a lista de espera para o atendimento."
        background="blue"
        url="/"
        image={virtual}
        alignment="left"
      />
      */}
    </GlobalContainer>
  );
}
