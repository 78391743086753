import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';

import * as S from './styles';

export default function Slider({ children, ...rest }) {
  return (
    <S.Wrapper>
      <SlickSlider {...rest}>{children}</SlickSlider>
      ;
    </S.Wrapper>
  );
}

Slider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
