import React from 'react';

import * as S from './styles';

import atention from '../../assets/infoCovid/atention.svg';

const InformationCard = ({ title, description }) => (
  <S.Container>
    <S.IconWrapper>
      <img src={atention} alt="" />
    </S.IconWrapper>
    <S.TitleWrapper>
      <S.Title>
        <span>{title}</span>
      </S.Title>
      <S.Description>{description}</S.Description>
    </S.TitleWrapper>
  </S.Container>
);

export default InformationCard;
