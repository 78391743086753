import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
    width: 100%;
    margin: 0 auto;
    padding: 0rem 0 5rem;
    background: ${theme.name === 'contraste' ? '#000' : '#fff'};
  `}
`;
