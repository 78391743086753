import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme, gridArea }) => css`
    border-top: 0.2rem solid ${theme.font.colors.secondary};
    border-radius: ${theme.border.radius};
    display: flex;
    align-items: flex-start;
    padding: 4rem 2rem;
    box-shadow: 0px 4px 20px rgba(123, 169, 188, 0.16);
    grid-area: ${gridArea};
  `}
`;
export const ImageWrapper = styled.div`
  margin-right: 2.4rem;

  img {
    width: 4.8rem;
    height: 4.8rem;

    ${media.greaterThan('medium')`
      width: 8rem;
      height: 8rem;
    `}
  }
`;
export const TextWrapper = styled.div``;
export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-weight: 500;
    line-height: 2.4rem;
    margin: 1.6rem 0;
  `}
`;
