import React from 'react';

import MediaMatch from '../MediaMatch';

import * as S from './styles';

const Banner = () => (
  <S.Container>
    <S.Banner>
      <MediaMatch greaterThan="large">
        <div className="bg-banner" />
      </MediaMatch>
      <S.TitleBannerWrapper>
        <S.MediumTitle>HOSPITAL HUMANIZA</S.MediumTitle>
        <S.Title>
          Cuidado para
          {' '}
          <br />
          toda a vida
        </S.Title>
        <S.Button to="/institucional">Saiba mais</S.Button>
      </S.TitleBannerWrapper>
    </S.Banner>
  </S.Container>
);

export default Banner;
