import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import {
  Title as TitleComponents,
  Description,
} from '../../../../components/TextComponents';
import Input from '../../../../components/InputText';
import Counter from '../../../../components/Counter';
import TextArea from '../../../../components/TextArea';
import Radio from '../../../../components/Radio';
import * as RadioStyles from '../../../../components/Radio/styles';

const Wrapper = styled.div`
  margin: 8rem 0;
`;

const ButtonWrapper = styled.div`
  ${({ noMargin }) => css`
    width: 100%;

    display: flex;
    justify-content: flex-start;
    margin-top: ${noMargin ? '0' : '4.8rem'};

    ${media.greaterThan('medium')`
      justify-content: flex-end;
    `}
  `}
`;

const buttonModifiers = {
  normal: (theme) => css`
    background: ${theme.colors.primary};
    color: ${theme.colors.whitePure};
    border: 0.2rem solid ${theme.colors.primary};
  `,
  contraste: (theme) => css`
    background: none;
    color: ${theme.colors.globalYellow};
    border: 0.2rem solid ${theme.colors.globalYellow};
  `,
};

const Button = styled.button`
  ${({ theme }) => css`
    width: 23rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.border.radius};

    span {
      display: block;
      line-height: 1.8rem;
      font-weight: bold;
    }

    svg {
      margin-right: 2rem;
    }

    ${buttonModifiers[theme.name](theme)};
  `}
`;

const InputBox = styled.div`
  padding: 2rem 0;
  display: ${({ display }) => display};

  .wrapperFlex {
    width: 100%;
  }

  ${media.lessThan('small')`
    div + div {
      margin-top: 4rem;
    }
  `}

  ${media.greaterThan('medium')`
    padding: 4rem 0;
    ${({ flex }) => !!flex
      && css`
        display: flex;
        justify-content: space-between;

        .wrapperFlex {
          width: 45%;
        }
      `}
    `}
`;

const fieldsModifiers = {
  flex: () => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2.4rem;
  `,
};

const Fields = styled.div`
  ${({ display }) => css`
    ${!!display && fieldsModifiers[display]()};

    ${RadioStyles.Container} {
      margin-right: 3rem;
      margin-top: 1.2rem;
    }
  `}
`;

const Title = styled(TitleComponents).attrs({
  size: { web: '2.8rem', mobile: '2.4rem' },
})``;

const RadioWrapper = styled.div`
  ${({ margin }) => css`
    margin: ${margin};
  `}
`;
const RadioLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

export {
  Wrapper,
  Fields,
  Input,
  InputBox,
  Title,
  Radio,
  RadioWrapper,
  RadioLabel,
  Button,
  ButtonWrapper,
  Counter,
  TextArea,
  Description,
};
