/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import * as S from './styles';

const InputText = ({
  label,
  type,
  sizeLimited,
  icon: Icon,
  iconSize,
  name,
  error,
  ...rest
}) => (
  <S.InputWrapper size={sizeLimited}>
    <S.Container>
      <S.Input
        name={name}
        type={type}
        placeholder="nao remove esse place"
        {...rest}
      />
      <S.Label error={error}>
        <span className="content__name">{label}</span>
      </S.Label>
      {Icon && (
      <S.IconWrapper>
        <Icon size={iconSize} />
      </S.IconWrapper>
      )}
    </S.Container>
  </S.InputWrapper>
);

export default InputText;
