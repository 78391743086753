import React from 'react';

import {
  Fields, Title, Wrapper, TextArea,
} from '../common';

export default function Observacoes() {
  return (
    <Wrapper>
      <Title label="Observações adicionais" />
      <Fields>
        <TextArea name="observacoes" placeholder="Observações adicionais" />
      </Fields>
    </Wrapper>
  );
}
