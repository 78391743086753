import React from 'react';

import { Carousel } from 'react-bootstrap';
import Banner from '../Banner';
import * as S from './styles';

export default function SliderCarouselMenu() {
  return (
    <Carousel>
      <Carousel.Item>
        <Banner />
      </Carousel.Item>
    </Carousel>
  );
}
