export const array = [
  {
    id: 1,
    titulo: 'CIRURGIA GERAL',
    pacotes: [
      {
        id: 1,
        texto:
          'Apendicectomia/Apendicectomia por videolaporoscopia (Internado) 1 dia de internação em quarto semi privativo',
        preco: '',
      },
      {
        id: 2,
        texto:
          'Colecistectomia por Videolaparoscopia com/sem Colangiografia (Internado) 1 dia de internação em quarto semi privativo',
        preco: '',
      },
      {
        id: 3,
        texto:
          'Colecistectomia por Videolaparoscopia com/sem Colangiografia (Ambulatorial)',
        preco: '',
      },
      {
        id: 4,
        texto:
          'Herniorrafia Inguinal - Unilateral (Internado) 1 dia de internação em quarto semi privativo',
        preco: '',
      },
      {
        id: 5,
        texto: 'Herniorrafia Inguinal - Unilateral (ambulatorial)',
      },
      {
        id: 6,
        texto: 'Herniorrafia Umbilical (Internado) 1 dia de internação',
      },
      {
        id: 7,
        texto: 'Herniorrafia Umbilical (Ambulatorial)',
      },
      {
        id: 8,
        texto:
          'Hernioplastia Epigástrica (Internado) 1 dia de internação em quarto semi privativo',
      },
    ],
  },
  {
    id: 2,
    titulo: 'UROLOGIA',
    pacotes: [
      {
        id: 1,
        texto:
          'Ureterolitotripsia Transureteroscópica Semi Rigido Com e Sem laser com Duplo',
      },
      {
        id: 2,
        texto:
          'Ureterolitotripsia Transureteroscópica Flexível Com Laser Com Duplo',
      },
      {
        id: 3,
        texto: 'Postectomia',
      },
      {
        id: 4,
        texto: 'Ressecção Endoscópica da Próstata',
      },
      {
        id: 5,
        texto: 'Tumor Vesical',
      },
      {
        id: 6,
        texto: 'Nefrectomia parcial por videolaparoscopia/aberta',
      },
      {
        id: 7,
        texto: 'Nefrectomia radical ou por tumor por videolaparoscopia/aberta',
      },
      {
        id: 8,
        texto: 'Varicocele bilateral (correção cirúrgica)',
      },
      {
        id: 9,
        texto: 'Vasectomia para reversão de vasectomia',
      },
      {
        id: 10,
        texto: 'Eletrocoagulação',
      },
      {
        id: 11,
        texto: 'Plástica de freio',
      },
      {
        id: 12,
        texto: 'Meatoplastia',
      },
      {
        id: 13,
        texto: 'Cistostomia supra púbica',
      },
      {
        id: 14,
        texto: 'Orquiectomia com colocação de prótese testicular',
      },
      {
        id: 15,
        texto: 'Orquiectomia radical',
      },
      {
        id: 16,
        texto: 'Colocação de prótese peniana inflável/rígida',
      },
      {
        id: 17,
        texto: 'Colocação de prótese testicular',
      },
      {
        id: 18,
        texto: 'Epididimectomia',
      },
      {
        id: 19,
        texto: 'Orquiodopexia',
      },
      {
        id: 20,
        texto: 'Prostatectomia a céu aberto',
      },
    ],
  },
  {
    id: 3,
    titulo: 'TRAUMATOLOGIA E ORTOPEDIA',
    pacotes: [
      { id: 1, texto: 'Tratamento cirúrgico da hérnia cervical' },
      { id: 2, texto: 'Tratamento cirúrgico de lesão do manguito rotador' },
      { id: 3, texto: 'Artroplastia de quadril' },
      { id: 4, texto: 'Artroplastia de joelho' },
      { id: 5, texto: 'Enxerto ósseo' },
      { id: 6, texto: 'Meniscectomia' },
      { id: 7, texto: 'Tenólise em túnel ósteo fibroso' },
      { id: 8, texto: 'Tratamento Cirúrgico - Dedo em gatilho' },
      { id: 9, texto: 'Túnel Do Carpo - Descompressão Videoartroscópica' },
      { id: 10, texto: 'Videoartroscopia Joelho (Menisco)' },
      { id: 11, texto: 'Videoartroscopia Joelho (Ligamento)' },
    ],
  },
];
