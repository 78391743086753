import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div``;

export const Wrapper = styled.div`
  ${({ backgroundImage, theme }) => css`
    background: url(${backgroundImage});
    height: 45rem;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: ${theme.spacings.medium};
    color: #fff;

    ${media.greaterThan('huge')`
      height: 60rem;
    `}

    ${media.lessThan('medium')`
      border-radius: 0;
      height: 36.7rem;
      width: 100%;
    `}

    border-radius: .4rem;
  `}
`;
export const SmallTitle = styled.span`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-family: ${theme.font.medium};
    font-size: ${theme.font.sizes.xsmall};
    letter-spacing: 0.2rem;

    margin-bottom: 0.8rem;
  `}
`;
export const BigTitle = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.extraLarge};
    font-family: ${theme.font.bold};
    margin-bottom: 0.8rem;
    line-height: 45px;
  `}
`;

export const Description = styled.p`
  margin-bottom: 3rem;
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
  `}
`;

export const TextWrapper = styled.div`
  ${({ backgroundImage, theme }) => css`
    background: url(${backgroundImage});
    width: 100%;
    height: 40rem;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: ${theme.spacings.medium};
    color: #fff;

    ${media.greaterThan('huge')`
      height: 50rem;
    `}

    ${media.lessThan('medium')`
      border-radius: 0;
    `}

    border-radius: ${theme.border.radius};

    ${BigTitle} {
      margin-bottom: 0.5rem;
      font-family: ${theme.font.bold};
      font-size: ${theme.font.sizes.xxxlarge};
    }

    ${Description} {
      font-size: ${theme.font.sizes.xxlarge};
      font-family: ${theme.font.bold};
    }
  `}
`;
