import styled, { css } from 'styled-components';

export const MobileWrapper = styled.div``;
export const TextWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xlarge};
    color: ${theme.font.colors.primary};
    line-height: 2.8rem;
    margin-bottom: 3.2rem;
  `}
`;
export const InfoWrapper = styled.div`
  display: flex;
`;
export const Image = styled.div`
  img {
    width: 8rem;
    height: 8rem;
  }
`;
export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: ${theme.spacings.small};
    justify-content: center;

    span.name {
      font-size: 2.4rem;
      font-family: ${theme.font.diodrum};
      color: ${theme.font.colors.primary};
      margin-bottom: 1rem;
    }

    span.case {
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.medium};
      color: ${theme.font.colors.primary};
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  `}
`;

export const AspasWrapper = styled.div`
  ${(props) => {
    delete props.children;
    return css`
      ${props}
    `;
  }}
`;

// web

export const WebWrapper = styled.div`
  display: grid;
  grid-template-areas: 'image info';
  grid-template-columns: 0.8fr 2fr;
`;

export const ImageSection = styled.div`
  grid-area: image;

  ${Image} {
    img {
      width: 30rem;
      height: 30rem;
    }
  }
`;
export const InfoSection = styled.div`
  grid-area: info;

  ${TextWrapper} {
    max-width: 79.5rem;
    width: 100%;
    margin: 0 auto 3rem;
  }

  ${Info} {
    max-width: 79.5rem;
    width: 100%;
    margin: 0 auto;
  }
`;
