import React from 'react';

import NewsCard from '../NewsCard';
import MediaMatch from '../MediaMatch';

import * as S from './styles';

import services from '../../assets/services/services.png';

const News = ({ btnLabel, btnFullsize }) => (
  <S.Container>
    <div className="wrapper">
      <S.Header>
        <S.Title>Notícias</S.Title>

        <MediaMatch greaterThan="medium">
          <S.LinkOutlined to="/noticias">Veja todas</S.LinkOutlined>
        </MediaMatch>
      </S.Header>
      <S.Wrapper>
        <NewsCard
          image={services}
          date="06 de Novembro"
          color="blue"
          description="Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital"
        />

        <NewsCard
          image={services}
          date="06 de Novembro"
          color="blue"
          description="Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital"
        />

        <NewsCard
          image={services}
          date="06 de Novembro"
          color="rose"
          description="Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital"
        />

        <NewsCard
          image={services}
          date="06 de Novembro"
          color="poloBlue"
          description="Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital"
        />
        <MediaMatch lessThan="small">
          <S.ButtonWrapper>
            <S.LinkOutlined to="/noticias" fullsize={btnFullsize}>
              {btnLabel}
            </S.LinkOutlined>
          </S.ButtonWrapper>
        </MediaMatch>
      </S.Wrapper>
    </div>
  </S.Container>
);

export default News;
