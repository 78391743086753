import styled, { css } from 'styled-components';
import { Form as FormFormik } from 'formik';
import media from 'styled-media-query';
import * as CheckboxStyles from '../../../../components/Checkbox/styles';
import * as SelectStyles from '../../../../components/Select/styles';

export const FormWrapper = styled.div`
  margin-top: 7rem;
`;
export const SelectBox = styled.div`
  ${media.greaterThan('medium')`
      display: flex;
      align-items: center;
        
      ${SelectStyles.Container} {
        & + div {
          margin-top: 0;
          margin-left: 4rem;
          max-width: 44rem;
        }
      }
    `}

  ${media.lessThan('medium')`      
      ${SelectStyles.Container} {
        & + div {
          margin-top: 3.2rem;
        }
      }
    `}
`;
export const Form = styled(FormFormik)`
  ${media.greaterThan('medium')`
    display: grid;
    grid-template-columns: 1fr 0.5fr;
  `}
`;

export const CheckboxWrapper = styled.div`
  ${media.greaterThan('medium')`
    display: flex;
    align-items: center;
  `}
`;
export const CheckboxContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;

  ${media.greaterThan('medium')`
    display: flex;
    margin-top: 0;
    margin-left: 2.4rem;
  `}

  ${CheckboxStyles.Wrapper} {
    margin-right: 3rem;
    margin-bottom: 0.8rem;
  }
`;
export const Label = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-weight: bold;
    color: ${theme.font.colors.primary};
  `}
`;

export const Fields = styled.div`
  margin-top: 3.2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4rem;
  width: 100%;

  ${media.greaterThan('medium')`
    grid-row-gap: 2.4rem;  
  `}
`;
export const SubmitButtonWrapper = styled.div`
  margin-top: 3.2rem;
`;

const buttonModifiers = {
  contraste: (theme) => css`
    background: #000;
    border: 0.2rem solid ${theme.colors.globalYellow};
    color: ${theme.colors.globalYellow};
  `,
  normal: (theme) => css`
    background: ${theme.colors.primary};
    color: ${theme.colors.whitePure};
    border: 0.2rem solid ${theme.colors.primary};
  `,
};

export const SubmitButton = styled.button`
  ${({ theme }) => css`
    height: 4.8rem;
    width: 100%;
    border-radius: ${theme.border.radius};
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 1rem;
      line-height: 0.8rem;
    }

    ${media.greaterThan('medium')`
      max-width: 31.7rem;
      margin-top: 0.8rem;
      margin-left: 4rem;
    `};

    ${buttonModifiers[theme.name](theme)};
  `}
`;
