import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const wrapperModifiers = {
  blue: (theme) => css`
    background-color: ${theme.name === 'contraste'
    ? '#000'
    : theme.colors.secondWhite};
  `,
  white: (theme) => css`
    background-color: ${theme.name === 'contraste' ? '#000' : '#fff'};
  `,
};

export const Wrapper = styled.div`
  ${({ background, theme }) => css`
    height: 58rem;
    width: 100%;

    ${media.greaterThan('medium')`
      height: 78rem;
      width: 100%;

      padding: 12rem 0;
    `} ${!!background && wrapperModifiers[background](theme)};
  `}
`;

export const SectionTxt = styled.div`
  ${({ theme }) => css`
    padding: 4rem 1.6rem 0;
    grid-area: sectionTxt;

    div.little__text {
      margin-bottom: ${theme.spacings.small};
    }
    div.title {
      margin-bottom: ${theme.spacings.small};
    }
    div.description {
      margin-bottom: ${theme.spacings.small};
    }

    div.wrapper__text {
      ${media.greaterThan('medium')`
        max-width: 47rem;
        width: 100%;
      `}
    }

    ${media.greaterThan('medium')`
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    `}
  `}
`;

const gridModifiers = {
  left: () => css`
    grid-template-areas: 'sectionTxt image';
  `,
  right: () => css`
    grid-template-areas: 'image sectionTxt';

    ${SectionTxt} {
      align-items: flex-end;

      div.wrapper__text {
        ${media.greaterThan('medium')`
          text-align: left;
        `}
      }
    }
  `,
};

export const GridContainer = styled.div`
  ${({ theme, alignment }) => css`
    display: grid;
    grid-template-areas: 'image' 'sectionTxt';

    ${media.greaterThan('medium')`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      max-width: ${theme.maxwidth};
      margin: 0 auto;
      height: 54rem;
      ${!!alignment && gridModifiers[alignment]()}
    `};
  `}
`;

export const SectionImage = styled.div`
  grid-area: image;
`;

export const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: 22rem;
    object-fit: cover;

    ${media.greaterThan('medium')`
      width: 100%;
      height: 52rem;
      border-radius: 0.4rem;
    `}
  }
`;
