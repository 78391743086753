import { darken } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const modifiers = {
  custom: (color, theme) => css`
    background: ${theme.colors[color]};

    &:hover {
      background: ${theme.colors[color] && darken(0.08, theme.colors[color])};
    }
  `,

  large: () => css`
    width: 20rem;
  `,

  normal: () => css`
    width: 14.8rem;
  `,
};

export const Container = styled(Link)`
  ${({ theme, color = 'rose', size = 'normal' }) => css`
    border: 0;
    height: 4.8rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.border.radius};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.05rem;
    transition: all 0.3s ease;

    ${!!color && modifiers.custom(color, theme)};
    ${!!size && modifiers[size]()};
  `}
`;
