import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import * as DescriptionStyles from '../Description/styles';

export const Title = styled.h1`
  ${({
    theme, size, lineHeight, color,
  }) => css`
    color: ${theme.name === 'contraste' ? theme.colors.globalYellow : color};

    font-size: ${size.mobile};
    line-height: ${lineHeight.mobile};
    font-family: ${theme.font.bold};

    & + ${DescriptionStyles.Description} {
      margin-top: ${theme.spacings.small};
    }

    ${media.greaterThan('medium')`
      font-size: ${size.web};
      line-height:${lineHeight.web};

    `}
  `}
`;
