import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.small};
    margin: 3.8rem auto 0;
    width: 100%;
    max-width: ${theme.maxwidth};

    ${media.greaterThan('medium')`
      margin: 8rem auto 0;
    `}
  `}
`;

export const TextWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
  `}
`;
export const BannerWrapper = styled.div`
  ${({ theme, small }) => css`
    margin: 2.2rem auto 0;
    max-width: ${theme.maxwidth};

    ${!!small
    && css`
      max-width: 95rem;
    `}

    ${media.greaterThan('medium')`
      margin: 8.8rem auto 0;
    `}
  `}
`;

export const ImageLabel = styled.span`
  ${({ theme }) => css`
    display: block;
    padding: 0.8rem ${theme.spacings.small};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.font.colors.primary};
  `}
`;

export const BgNews = styled.div`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          background: linear-gradient(
            0deg,
            ${theme.colors.secondWhite} 72.5%,
            rgba(255, 255, 255, 1) 50%
          );

          ${media.greaterThan('medium')`
            background: linear-gradient(
              0deg,
              ${theme.colors.secondWhite} 45%,
              rgba(255, 255, 255, 1) 44%
            );
    `}
        `
    : css`
          background: #000;
        `)}
`;
