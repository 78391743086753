import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { RiSendPlaneLine } from 'react-icons/ri';
import { ThemeContext } from 'styled-components';

import * as S from './styles';
import { setLocationToMap } from '../../store/modules/clinicasLocation/actions';

const CardFilter = ({
  image, name, endereco, telefone, location, horario,
}) => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const handleLocation = useCallback(
    (coords) => {
      dispatch(setLocationToMap(coords));
    },
    [dispatch],
  );

  return (
    <S.Container>
      <S.ImageWrapper>
        <img src={image} alt="" />
      </S.ImageWrapper>
      <S.TitleWrapper>
        <S.Title>{name}</S.Title>
        <S.FieldsWrapper>
          <S.Fields>Horário de atendimento:</S.Fields>
          <S.Description>{horario}</S.Description>
        </S.FieldsWrapper>

        <S.FieldsWrapper>
          <S.Fields>Endereço:</S.Fields>
          <S.Description>{endereco}</S.Description>
        </S.FieldsWrapper>

        <S.FieldsWrapper>
          <S.Fields>Fone:</S.Fields>
          <S.Description>{telefone}</S.Description>
        </S.FieldsWrapper>
      </S.TitleWrapper>
      <S.ButtonWrapper>
        <S.Button onClick={() => handleLocation(location)} color="primary">
          <RiSendPlaneLine
            color={
              theme.name === 'contraste'
                ? `${theme.colors.globalYellow}`
                : '#fff'
            }
            size={20}
          />
          Como chegar
        </S.Button>
        {/* <S.Button color="secondary">Ligar</S.Button> */}
      </S.ButtonWrapper>
    </S.Container>
  );
};

export default CardFilter;
