import React from 'react';

import * as S from './styles';

import timer from '../../assets/prontoAtendimento/relogio.svg';

export default function TimerCard({
  littleTitle, title, color, time, footer,
}) {
  return (
    <S.Wrapper>
      <S.HeaderCard color={color}>
        <S.TitleWrapper>
          <span>{littleTitle}</span>
          <S.Title>{title}</S.Title>
        </S.TitleWrapper>
        <S.TimerWrapper color={color}>
          <S.ImageWrapper>
            <img src={timer} alt="" />
          </S.ImageWrapper>
          <span>{time}</span>
        </S.TimerWrapper>
      </S.HeaderCard>
      <S.Footer>
        <p>{footer}</p>
      </S.Footer>
    </S.Wrapper>
  );
}
