/* eslint-disable consistent-return */
import React, { useState } from 'react';
import {
  Map, GoogleApiWrapper, Marker, InfoWindow,
} from 'google-maps-react';

import mapsIcon from '../../assets/Fotos_Clinicas/maps_icon.png';

import * as S from './styles';

const GoogleMaps = ({
  google,
  initialPosition,
  height,
  arrayLocations,
  marker,
}) => {
  const [selectedPlaces, setSelectedPlaces] = useState({});
  const [showInfo, setShowInfo] = useState(false);

  const onMarkerClick = (item) => {
    setSelectedPlaces(item);
    setShowInfo(true);
  };

  return (
    <S.MapWrapper height={height}>
      <Map
        google={google}
        zoom={8}
        style={{
          width: '100%',
          height: '100%',
        }}
        initialCenter={initialPosition}
      >
        {arrayLocations && marker !== undefined ? (
          arrayLocations.map((item, index) => {
            const icon = marker.lat === item.lat ? `${mapsIcon}` : '';

            return (
              <Marker
                icon={icon}
                onClick={() => onMarkerClick(item)}
                /* eslint-disable-next-line react/no-array-index-key */
                key={index.toString()}
                position={{
                  lat: item.lat,
                  lng: item.lng,
                }}
              />
            );
          })
        ) : (
          <Marker icon={mapsIcon} position={marker} />
        )}
        {/* {selectedPlaces.lat && (
          <InfoWindow
            style={{
              padding: '40px',
            }}
            visible={showInfo}
            position={{
              lat: selectedPlaces.lat,
              lng: selectedPlaces.lng,
            }}
          >
            <S.InfoDetails>
              <S.Label>Clinica</S.Label>
              <S.Name>{selectedPlaces.name}</S.Name>
              <S.Label>Contato</S.Label>
              <S.Cell>{selectedPlaces.telefone}</S.Cell>
            </S.InfoDetails>
          </InfoWindow>
        )} */}
      </Map>
    </S.MapWrapper>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD-FFANz2GSOm6qP3wVd9qyeuSvutBWg6s',
  language: 'pt',
})(GoogleMaps);
