import React, { useState } from 'react';

import * as S from './styles';

import {
  Fields,
  InputBox,
  Input,
  Title,
  RadioWrapper,
  RadioLabel,
  Radio,
} from '../common';

export default function InformacoesMedicas() {
  const [radio, setRadio] = useState('ccg');

  return (
    <S.Wrapper>
      <Title label="Informações médicas" />
      <Fields>
        <RadioWrapper margin="3.2rem 0 0">
          <RadioLabel>SELECIONE SEU PLANO DE SAÚDE</RadioLabel>
          <Fields display="flex">
            <Radio
              id="ccg"
              label="CCG Saúde"
              name="planoSaude"
              value="ccg"
              onClick={() => setRadio('ccg')}
            />
            <Radio
              id="particular"
              label="Particular"
              name="planoSaude"
              value="particular"
              onClick={() => setRadio('particular')}
            />
          </Fields>
        </RadioWrapper>
        <InputBox display={radio === 'ccg' ? 'block' : 'none'}>
          <Input
            name="codigoCartaoConvenio"
            label="Código do cartão do convênio"
          />
        </InputBox>
        <InputBox>
          <Input name="doctorName" label="Nome do médico" />
        </InputBox>
        <InputBox flex>
          <div className="wrapperFlex">
            <Input name="doctorEmail" label="Email *" />
          </div>
          <div className="wrapperFlex">
            <Input name="doctorPhone" label="Telefone *" />
          </div>
        </InputBox>
      </Fields>
    </S.Wrapper>
  );
}
