import React from 'react';

import { Title } from '../../../../components/TextComponents';
import { ButtonOutlined } from '../../../../components/Button/modules';

import * as S from './styles';

import atention from '../../../../assets/prontoAtendimento/atention.svg';

export default function Grid({ gridArea }) {
  return (
    <S.Wrapper gridArea={gridArea}>
      <div className="size">
        <S.TextWrapper>
          <Title
            color="#009cde"
            label="Nossa Missão"
            size={{ web: '2.8rem', mobile: '1.8rem' }}
          />
        </S.TextWrapper>
        <S.CardWrapper>
          <S.Card>
            <span> Preservar a saúde e a qualidade de vida das pessoas através de um atendimento profissional, ético, humano e personalizado. </span>
          </S.Card>
          <S.Card>
            <span>
              Esperamos que a sua permanência seja breve e que em pouco tempo você possa voltar às atividades.
              {' '}
            </span>
          </S.Card>
          <S.Card>
            <span> Durante sua estadia, nos esforçaremos para oferecer a você todo o conforto e uma assistência permanente e qualificada. </span>
          </S.Card>
          <S.Card>
            <span>Para tanto, recomendamos a leitura deste Guia, que contém informações a respeito de nossas rotinas internas. </span>
          </S.Card>
          <S.Card>
            <span>
              Agradecemos a preferência pelo Hospital Humaniza.
            </span>
          </S.Card>
        </S.CardWrapper>
      </div>
    </S.Wrapper>
  );
}
