import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const IframeVideo = styled.iframe`
  ${({ theme }) => css`
    width: 100%;
    max-width: 128rem;
    height: 70rem;
    margin-bottom: 4.8rem;
    border-radius: ${theme.border.radius};

    ${media.lessThan('medium')`
      width:100%;
      height: 30rem;
      border-radius: 0;
    `}
  `}
`;
