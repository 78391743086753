import React, {
  createContext, useCallback, useContext, useState,
} from 'react';

const FilterContext = createContext({} || null);

export function FilterProvider2({ children }) {
  const [openMenuFilter, setOpenMenuFilter] = useState(false);
  const [filterOptions] = useState([
    'Internação',
    'Assistência Médica e Enfermagem',
    'Diárias e Despesas',
    'Hotelaria',
    'Nutrição',
    'Acompanhantes',
    'Visitas',
    'Objetos Pessoais',
    'Estacionamento',
    'Ouvidoria',
    'Altas',
    'Informações importantes',
  ]);

  const [selected, setSelected] = useState({
    'Horário de visita': true,
  });

  const toggleSelected = useCallback(
    (name) => {
      setSelected({
        [name]: !selected[name],
      });
    },
    [selected],
  );

  const handleFilter = useCallback(() => {
    console.log('filtro ativado');
    setOpenMenuFilter(false);
  }, []);

  return (
    <FilterContext.Provider
      value={{
        openMenuFilter,
        setOpenMenuFilter,
        filterOptions,
        toggleSelected,
        handleFilter,
        selected,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export function useGuidanceFilter() {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('this hook must be used whith FilterContext');
  }

  return context;
}
