/* eslint-disable import/no-duplicates */
import React from 'react';
import GlobalContainer from '../../components/_layout/Container';
import * as S from './style';
import {
  Title,
  Description,
  SupportText,
} from '../../components/TextComponents';
import {
  ImageBanner,
  VideoBanner,
} from '../../components/MediaContainer/modules';
import bannerWeb from '../../assets/image_banner_direitos/banner.jpg';
import bannerMobile from '../../assets/image_banner_direitos/banner.jpg';
import SectionInfoCard from '../../components/SectionInfoCard';
import virtual from '../../assets/institucional-assets/virtual.svg';

export default function DireitoSDeveres() {
  const string = 'Direcionamos a conduta dos nossos profissionais baseados em princípios éticos, comprometidos com um atendimento de excelência, seguro, respeitando a privacidade.  Estimulamos a participação e a colaboração de todos os pacientes e de seus familiares no tratamento, acima de tudo, seus direitos e dignidade.';

  const direitos = `<ul class="ul">
  <li class="list"><p class="text">Ter um atendimento digno, atencioso e respeitoso;</li>
  <li class="list"><p class="text">Ser identificado e tratado pelo seu nome;</li>
  <li class="list"><p class="text">Ter resguardado sigilo sobre seus dados pessoais, desde que não acarrete riscos a terceiros ou à saúde pública;
  </li>
  <li class="list"><p class="text">Identificar as pessoas responsáveis direta e indiretamente por sua assistência, através de crachás visíveis e legíveis;</li>
  <li class="list"><p class="text">Receber informações claras, objetivas e compreensíveis sobre:
  </li>
  <a class="item">a)</a>hipóteses diagnósticas;<br>
  <a class="item">b)</a>diagnósticos realizados;<br>
  <a class="item">c)</a>exames solicitados;<br>
  <a class="item">d)</a>ações terapêuticas;<br>
  <a class="item">e)</a>riscos, benefícios e inconvenientes das medidas diagnósticas e terapêuticas propostas;<br>
  <a class="item">f)</a>duração prevista do tratamento;<br>
  <a class="item">g)</a>procedimentos diagnósticos e terapêuticos invasivos, quanto: à necessidade ou não de anestesia, ao tipo de anestesia a ser aplicada, ao instrumental a ser utilizado, às partes do corpo afetadas, aos efeitos colaterais, aos riscos e consequências indesejáveis e à duração esperada do procedimento;<br>
  <a class="item">h)</a>exames e condutas a que será submetido;<br>
  <a class="item">i)</a>a finalidade dos materiais coletados para exame;<br>
  <a class="item">j)</a>alternativas de diagnóstico e terapêuticas.<br>
  </ul>`;

  const deveres = `
  <ul class="ul">
  <li class="list"><p class="text">O paciente ou seu responsável legal tem o dever de fornecer informações completas e verdadeiras sobre seu estado de saúde atual, doenças prévias, procedimentos médicos anteriores e outros problemas relacionados à sua saúde.</li>
  <li class="list"><p class="text">O paciente ou seu responsável legal tem o dever de informar mudanças inesperadas em sua condição de saúde para os profissionais responsáveis pelo seu tratamento.</li>
  <li class="list"><p class="text">O paciente e seus acompanhantes têm o dever de respeitar os direitos de outros pacientes, acompanhantes e profissionais da instituição.</li>
  <li class="list"><p class="text">O paciente e seus acompanhantes devem seguir as instruções recomendadas pela equipe multiprofissional que o assiste, sendo responsável pelas consequências de sua recusa.</li>
  <li class="list"><p class="text">O paciente e seus acompanhantes têm o dever de preservar os recursos e o ambiente   colocados à sua disposição.</li>
  <li class="list"><p class="text">O paciente e seus acompanhantes têm o dever de participar do seu plano de tratamento e alta hospitalar ou indicar quem o possa fazer.</li>
  <li class="list"><p class="text">O paciente e seus acompanhantes têm o dever de atender e respeitar a proibição de fumo nas dependências do hospital, conforme o Decreto nº 47.662 de 14/12/2010, vigente no Estado do Rio Grande do Sul;</li>
  <li class="list"><p class="text">O paciente ou seu responsável legal é responsável pelo pagamento das despesas decorrentes de sua internação quando particular ou não cobertas pelo seu plano de saúde ou fonte pagadora.</li>
  </ul>`;

  return (
    <GlobalContainer>
      <S.WrapperDiv>
        <S.Wrapper>
          <S.TextWrapper>
            <Title
              size={{ web: '7.6rem', mobile: '4.2rem' }}
              lineHeight={{ web: '8.3rem', mobile: '4.8rem' }}
              label="Direitos e Deveres do Paciente"
            />
            <Description width="90rem" />
          </S.TextWrapper>
        </S.Wrapper>
        <S.BannerWrapper>
          <ImageBanner backgrounds={{ bannerWeb, bannerMobile }} />
        </S.BannerWrapper>
        <S.Wrapper>
          <S.SupporTextWrapper>
            <SupportText title="Direitos e deveres" text={string} />
          </S.SupporTextWrapper>
        </S.Wrapper>
        <S.Wrapper>
          <S.SupporTextWrapper>
            <SupportText title="Direitos do paciente" text={direitos} />
          </S.SupporTextWrapper>
        </S.Wrapper>
        <S.Wrapper>
          <S.SupporTextWrapper>
            <SupportText title="Deveres do Paciente" text={deveres} />
          </S.SupporTextWrapper>
        </S.Wrapper>
      </S.WrapperDiv>
    </GlobalContainer>
  );
}
