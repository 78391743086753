import styled, { css } from 'styled-components';

const modifiers = {
  background: (theme, color) => css`
    background-color: ${theme.colors[color]};
  `,
};

export const Container = styled.div`
  ${({ color, theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacings.medium};

    img {
      margin-bottom: ${theme.spacings.small};
    }

    span {
      color: #fff;
      font-size: ${theme.font.sizes.large};
    }

    ${!!color && modifiers.background(theme, color)};
  `}
`;
