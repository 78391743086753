import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.bg_contraste};
    padding-bottom: 16rem;

    ${media.lessThan('medium')`
    padding-bottom: 4rem;
  `}
  `}
`;

export const WrapperCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;
  margin: 0 auto;
  padding: 5rem 0 5rem;
  height: 100%;
  grid-gap: 0.4rem;
`;
