import React from 'react';

import { LittleTitle } from '../../../../components/TextComponents';
import { ButtonOutlined } from '../../../../components/Button/modules';

import * as S from './styles';

import atention from '../../../../assets/prontoAtendimento/atention.svg';

export default function DetailsCovid({ gridArea }) {
  return (
    <S.Wrapper gridArea={gridArea}>
      <div className="size">
        <S.TextWrapper>
          <LittleTitle color="secondary" label="Covid-19" />
          <S.Text>
            Devido à Pandemia, pedimos a todos que tomem os cuidados necessários
            antes de se dirigir ao Hospital Humaniza:
          </S.Text>
        </S.TextWrapper>
        <S.CardWrapper>
          <S.Card>
            <S.ImageWrapper>
              <img src={atention} alt="atention" />
            </S.ImageWrapper>
            <span>É obrigatório o uso de máscara.</span>
          </S.Card>
          <S.Card>
            <S.ImageWrapper>
              <img src={atention} alt="atention" />
            </S.ImageWrapper>
            <span>
              Acompanhantes serão permitidos apenas para pacientes menores de 12
              anos, maiores de 65, gestantes e pessoas com necessidades
              especiais. Estes poderão ser acompanhados de apenas 1 pessoa.
            </span>
          </S.Card>
          <S.Card>
            <S.ImageWrapper>
              <img src={atention} alt="atention" />
            </S.ImageWrapper>
            <span>
              Mantenha o distanciamento mínimo de 2 metros, inclusive dos
              atendentes, evitando aglomerações.
            </span>
          </S.Card>
          <S.Card>
            <S.ImageWrapper>
              <img src={atention} alt="atention" />
            </S.ImageWrapper>
            <span>
              Lembre-se da etiqueta da tosse: mesmo de máscara, cubra a boca com
              o braço ao tossir/espirrar.
            </span>
          </S.Card>
          <S.Card>
            <S.ImageWrapper>
              <img src={atention} alt="atention" />
            </S.ImageWrapper>
            <span>
              Higienize frequentemente as mãos e utilize o álcool gel.
            </span>
          </S.Card>
        </S.CardWrapper>
        <S.LinkWrapper>
          <ButtonOutlined
            label=" Mais informações sobre COVID-19"
            size="fullsize"
            url="/info-covid"
          />
        </S.LinkWrapper>
      </div>
    </S.Wrapper>
  );
}
