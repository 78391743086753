import React from 'react';
import { SupportText } from '../../../../components/TextComponents';
import * as S from './styles';

export default function Table() {
  return (
    <S.Wrapper>
      <S.B>As refeições são distribuídas nos seguintes horários:</S.B>
      <br />
      <br />
      <br />
      <S.Table>
        <S.Tr>
          <S.Th>Refeição</S.Th>
          {' '}
          <S.Th>Horário</S.Th>
        </S.Tr>
        <S.Tr>
          <S.Td className="refeicao">Café da manhã</S.Td>
          <S.Td className="horario">7h30 às 8h30</S.Td>
        </S.Tr>
        <S.Tr>
          <S.Td className="refeicao">Almoço</S.Td>
          <S.Td className="horario">11h30 às 12h30</S.Td>
        </S.Tr>
        <S.Tr>
          <S.Td className="refeicao">Lanche da tarde</S.Td>
          <S.Td className="horario">14h30 às 15h30</S.Td>
        </S.Tr>
        <S.Tr>
          <S.Td className="refeicao">Jantar</S.Td>
          <S.Td className="horario">18h às 19h</S.Td>
        </S.Tr>
        <S.Tr>
          <S.Td className="refeicao">Ceia</S.Td>
          {' '}
          <S.Td className="horario">20h às 21h</S.Td>
        </S.Tr>
      </S.Table>
    </S.Wrapper>
  );
}
