import React from 'react';
import PropTypes from 'prop-types';

import Menu from '../../Menu';
import Footer from '../../Footer';
import Breadcrumbs from '../../Breadcrumbs';
import AccessibilityMenu from '../../AccessibilityMenu';

import * as S from './styles';

export default function GlobalContainer({ children }) {
  return (
    <S.Container>
      <AccessibilityMenu />
      <Menu />
      <Breadcrumbs />
      <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
      <Footer />
    </S.Container>
  );
}

GlobalContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
