import React from 'react';

import * as S from './styles';

const SliderTimeLine = ({
  cardStyle = 'normal',
  image,
  dateYears,
  years,
  description,
}) => (
  <S.Container cardStyle={cardStyle}>
    {cardStyle === 'normal' && (
    <S.InfoBox>
      <S.ImageWrapper>
        <img src={image} alt="" />
      </S.ImageWrapper>
      <S.TitleWrapper>
        <S.Date>{dateYears}</S.Date>
        <S.Description>{!!description[0] && description[0]}</S.Description>
        <S.Description>{!!description[1] && description[1]}</S.Description>
      </S.TitleWrapper>
    </S.InfoBox>
    )}

    {cardStyle === 'date' && (
    <div className="contentDate">
      <S.DateWrapper>
        <span>{years}</span>
      </S.DateWrapper>
    </div>
    )}
  </S.Container>
);

export default SliderTimeLine;
