import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ cardStyle }) => (cardStyle === 'date' ? 'transparent' : '#fff')};

  .contentDate {
    height: 415px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    margin-bottom: ${theme.spacings.medium};

    img {
      width: 100%;
      max-height: 210px;
      object-fit: cover;
    }
  `}
`;

export const InfoBox = styled.div`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.4rem 1rem;
`;

export const Date = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.large};
    margin-bottom: 1rem;
  `}
`;
export const Description = styled.span`
  ${({ theme }) => css`
    color: ${theme.name === 'normal' ? theme.colors.blackFonts : '#424242'};
    margin-top: ${theme.spacings.xsmall};
    margin-bottom: 1rem;
  `}
`;

export const DateWrapper = styled.div`
  ${({ theme }) => css`
    padding: 2rem 0;
    background-color: #fff;
    width: 10rem;
    margin: 5px auto;
    border-radius: 0.8rem;
    border: 2px solid ${theme.colors.secondary};

    span {
      color: ${theme.colors.primary};
      font-family: ${theme.font.bold};

      text-transform: uppercase;
      display: block;
      text-align: center;
    }
  `}
`;
