import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  max-width: 95rem;
  width: 100%;
  margin: 0 auto;
`;
export const Paragraph = styled.span`
  ${({
    theme, justify, size, lineHeight,
  }) => css`
    display: block;
    color: ${theme.font.colors.primary};
    font-size: ${size.mobile};
    line-height: ${lineHeight.mobile};
    text-align: ${justify};
    word-spacing: 1px;

    ${media.greaterThan('medium')`
      font-size: ${size.web};
      line-height: ${lineHeight.web};

    `}
  `}
`;
