const string = `<p>O Hospital Humaniza aposta na continuidade do cuidado para tratamento das mais diversas patologias do coração dos pacientes adultos. Sabemos que diagnóstico e tratamento precoce das doenças cardiovasculares são os meios mais eficazes.</p><br>
                <p>Por isso, no sétimo andar, demos forma e espaço a um centro de resolução rápida que já se tornou referência de cardiologia em nosso sistema de saúde. Nossa meta é acabar com as filas de espera para os tratamentos das doenças do coração, criando entrosamento entre as equipes de internação e as equipes ambulatoriais.</p><br>
                <p>O andar da cardiologia e clínica médica, além de 14 consultórios, tem ainda uma sala para testes ergométricos, duas salas para ecocardiograma e outros exames, e 53 leitos de internação clínica e cirúrgica.</p><br>`;
const slideSettings = {
  slidesToShow: 1,
  arrows: false,
  infinite: true,
  dots: true,
  lazyLoad: 'ondemand',
  speed: 500,
  autoplaySpeed: 4000,
  initialSlide: 0,
  autoplay: true,
};

export { string, slideSettings };
