/* eslint camelcase: 0 */
/* eslint no-param-reassign: 0 */
import produce from 'immer';

import { actions_types } from './actions';

const INITIAL_STATE = {
  location: {
    lat: null,
    lng: null,
  },
};

export default function location(state = INITIAL_STATE, actions) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    switch (actions.type) {
      case actions_types.SET_LOCATION: {
        const { coords } = actions.payload;
        draft.location.lat = coords.lat;
        draft.location.lng = coords.lng;
        break;
      }
      default:
        return state;
    }
  });
}
