import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.primary};
    padding: 9rem 0 5rem;
  `}
`;
// mobile
export const MobileWrapper = styled.div``;
export const LogoWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 4rem;
    background: ${theme.colors.primary};
    margin-right: 1rem;
    margin-left: 1rem;
  `}
`;
// menu
export const MenuWrapper = styled.div`
  width: 100%;
`;
export const MenuOptionButton = styled.div`
  margin-bottom: 2rem;
`;
export const ButtonOption = styled.button`
  ${({ theme }) => css`
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    font-size: ${theme.font.sizes.large};
    color: ${theme.name === 'contraste' ? theme.colors.globalYellow : '#fff'};
    width: 100%;
    padding: 0.5rem 0;
    width: 100%;
    background: ${theme.colors.primary};
    padding: 0 ${theme.spacings.xsmall} 0;

    span {
      margin-bottom: 0.5rem;
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ open }) => css`
    svg {
      transition: 0.3s ease-in-out;
      transform: ${open ? 'rotate(-180deg)' : ''};
    }
  `}
`;

const borderColorSubMenuModifiers = {
  blue: (theme) => css`
    border-left: 4px solid ${theme.colors.secondary};
  `,

  turquoise: (theme) => css`
    border-left: 4px solid ${theme.colors.turquoise};
  `,

  poloBlue: (theme) => css`
    border-left: 4px solid ${theme.colors.poloBlue};
  `,
};

export const SubMenuOptionWrapper = styled.div`
  ${({ theme, borderColor, open }) => css`
    padding-left: ${theme.spacings.xlarge};
    display: ${open ? 'flex' : 'none'};
    flex-direction: column;

    ${!!borderColor && borderColorSubMenuModifiers[borderColor](theme)}
  `}
`;
export const SubMenuOptionLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.name === 'contraste' ? theme.colors.globalYellow : '#fff'};

    padding: ${theme.spacings.xsmall} 0;
  `}
`;
export const MenuOptionLink = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.spacings.xsmall} 0;
  `}
`;
// menu
// relacionamentos
export const Relationship = styled.div`
  ${({ theme }) => css`
    padding: 3rem ${theme.spacings.xsmall} 1rem;

    span {
      color: ${theme.name === 'contraste' ? theme.colors.globalYellow : '#fff'};
      font-weight: bold;
      margin-bottom: 1rem;
      display: block;
    }

    p {
      color: ${theme.colors.secondary};
      font-size: ${theme.font.sizes.large};
      font-family: ${theme.font.bold};
      margin-bottom: 1rem;
    }
    strong {
      color: ${theme.name === 'contraste' ? theme.colors.globalYellow : '#fff'};
    }
  `}
`;

// atendimento
export const CentralAdmin = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 3rem ${theme.spacings.xsmall} 1rem;

    span {
      color: ${theme.name === 'contraste' ? theme.colors.globalYellow : '#fff'};
      font-weight: bold;
      margin-bottom: 0.3rem;
      display: block;
    }

    p {
      color: #fff;
      font-size: ${theme.font.sizes.small};
    }
  `}
`;

// tecnico
export const Technician = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 3rem ${theme.spacings.xsmall} 1rem;

    span {
      color: ${theme.name === 'contraste' ? theme.colors.globalYellow : '#fff'};
      font-weight: bold;
      margin-bottom: 0.3rem;
      display: block;
    }

    p {
      color: #fff;
      font-size: ${theme.font.sizes.small};
    }
  `}
`;

export const SocialMedia = styled.div`
  ${({ theme }) => css`
    margin-top: 2rem;
    width: 100%;
    padding: 0 ${theme.spacings.xsmall};
    display: flex;
    align-items: baseline;
    max-width: 50%;
  `}
`;
export const SocialMediaLink = styled.a`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    color: ${theme.font.colors.white};
    a + a {
      margin-left: 1rem;
    }
  `}
`;

// mobile

// desktop
export const DesktopWrapper = styled.div`
  width: 100%;
  max-width: 136rem;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
export const LinkMenuOptionDesktop = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.font.colors.white} !important;
    font-size: 1.4rem !important;
  `}
`;

export const ExternalLinkMenuOptionDesktop = styled.a`
  ${({ theme }) => css`
    color: ${theme.font.colors.white} !important;
    font-size: 1.4rem !important;
    margin-top: 0.5rem;
    padding: 0.4rem 0;
    font-family: diodrum-light;
  `}
`;

export const MenuOptionDesktop = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    ${LinkMenuOptionDesktop} {
      color: #eeeeee;
      font-family: ${theme.font.light};
      padding: 0.4rem 0;

      :hover {
        color: #ddd;
      }

      & + ${LinkMenuOptionDesktop} {
        margin-top: 0.5rem;
        font-size: ${theme.font.sizes.small};
      }
    }
  `}
`;

export const TitleMenuOptionDesktop = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.font.colors.white};
    font-weight: bold;
    margin-bottom: 1rem;
  `}
`;
export const WrapperLinks = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    ${LinkMenuOptionDesktop} {
      font-size: ${theme.font.sizes.medium};
      color: #fff;
      font-weight: bold;
      margin-bottom: 2rem;
    }
  `}
`;

export const WrapperDetails = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 ${theme.spacings.xsmall};
    margin: ${theme.spacings.xxlarge} 0 0;
  `}
`;

export const RelatioshipDetails = styled.div`
  .test {
    width: 68rem;
    display: flex;
    justify-content: space-between;
  }
`;
export const RelatioshipDetailsInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacings.large};

    strong {
      color: ${theme.font.colors.white};
    }

    span {
      display: block;
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: ${theme.colors.secondary};
      font-family: ${theme.font.bold};
    }
  `}
`;

export const RelatioshipDetailsLinks = styled.div`
  display: flex;
  max-width: 10rem;
`;

export const CentralAdminDetails = styled.div`
  ${({ theme }) => css`
    strong {
      color: ${theme.font.colors.white};
      display: block;
      margin-bottom: 0.9rem;
    }

    span {
      color: #ffff;
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.light};
    }
  `}
`;

export const TechnitionDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    strong {
      color: ${theme.font.colors.white};
      display: block;
      margin-bottom: 0.9rem;
    }

    span {
      color: #ffff;
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.light};

      text-align: right;
    }
  `}
`;

export const TechnicianInfoDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    strong {
      color: ${theme.font.colors.white};
      display: block;
      margin-bottom: 0.9rem;
    }

    span {
      color: #ffff;
      font-size: ${theme.font.sizes.small};
      text-align: right;
    }
  `}
`;

export const TechnicianInfoDetailsLinks = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacings.medium};
    align-items: center;

    ${LinkMenuOptionDesktop} {
      font-size: ${theme.font.sizes.medium};
      color: #fff;

      + ${LinkMenuOptionDesktop} {
        margin-left: 6.5rem;
      }
    }
  `}
`;

export const Copyright = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 136rem;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    margin-top: 2.4rem;

    span {
      display: block;
      margin-right: 3.6rem;
      color: ${theme.colors.poloBlue};
    }
  `}
`;
