import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  ${({ theme }) => css`
    padding-bottom: 3.2rem;
    border-radius: 4px;
    overflow: hidden;
    background-color: ${theme.name === 'contraste' ? '#000' : '#fff'};
    display: flex;
    border: ${theme.name === 'contraste'
    ? `2px solid ${theme.colors.globalYellow}`
    : ''};
    flex-direction: column;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.08);
  `}
`;
export const ImageWrapper = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;
export const TitleWrapper = styled.div`
  width: 100%;
  margin-top: 3.2rem;
  padding: 0 1.6rem;
  flex: 1;
`;
export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.large};
    margin-bottom: 1.6rem;
    padding: 0 1.6rem;
  `}
`;
export const FieldsWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  padding: 0 1.6rem;
`;
export const Fields = styled.div`
  ${({ theme }) => css`
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
    font-size: ${theme.font.sizes.small};
  `}
`;
export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blackFonts};
    margin-top: 0.5rem;
  `}
`;

const buttonModifiers = {
  customColor: (color, theme) => css`
    background: ${theme.colors[color]};
  `,
};

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 1.6rem;
  margin: auto;
`;
export const Button = styled.button`
  ${({ color, theme }) => (theme.name === 'normal'
    ? css`
          ${color && buttonModifiers.customColor(color, theme)}
          padding: 1rem 1.6rem;
          color: #fff;
          display: flex;
          align-items: center;
          font-family: ${theme.font.bold};
          border: 0;
          border-radius: 4px;
          transition: all 0.3s ease-in-out;

          :hover {
            background: ${lighten(0.1, theme.colors.primary)};
          }

          svg {
            margin-right: 1rem;
          }
          & + button {
            margin-left: 1rem;
          }
        `
    : css`
          background: #000;
          padding: 1rem 1.6rem;
          color: ${theme.colors.globalYellow};
          display: flex;
          align-items: center;
          font-family: ${theme.font.bold};
          border: 2px solid ${theme.colors.globalYellow};
          border-radius: 4px;
          transition: all 0.3s ease-in-out;

          :hover {
            background: ${lighten(0.1, theme.colors.primary)};
          }

          svg {
            margin-right: 1rem;
          }
          & + button {
            margin-left: 1rem;
          }
        `)}
`;
