import React from 'react';

import GlobalContainer from '../../components/_layout/Container';
import { Title } from '../../components/TextComponents';
import { ButtonOutlined } from '../../components/Button/modules';
import NewsCard from '../../components/NewsCard';
import NewsLetter from './components/NewsLetter';
import Filter from './components/Filter';

import { news } from './utils/mock';

import * as S from './styles';

export default function Noticias() {
  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TextWrapper>
          <Title
            label="Notícias"
            size={{
              web: '4.2rem',
              mobile: '4.2rem',
            }}
          />
        </S.TextWrapper>
        <S.FilterWrapper>
          <Filter />
        </S.FilterWrapper>
      </S.Wrapper>
      <S.NewsLetterWrapper>
        <NewsLetter />
      </S.NewsLetterWrapper>
      <S.Wrapper>
        <S.TextWrapper>
          <Title
            label="Mais recentes"
            size={{ web: '3.6rem', mobile: '2.4rem' }}
          />
        </S.TextWrapper>
        <S.GridNews>
          {news.map((newsItem) => (
            <NewsCard
              color={newsItem.color}
              date={newsItem.date}
              description={newsItem.description}
              image={newsItem.image}
              key={newsItem.id}
            />
          ))}
        </S.GridNews>
        <S.LoadMoreNewWrapper>
          <ButtonOutlined size="large" label="Carregar mais" />
        </S.LoadMoreNewWrapper>
      </S.Wrapper>
    </GlobalContainer>
  );
}
