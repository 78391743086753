import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

export default function Description({ label, width }) {
  return <S.Description width={width}>{label}</S.Description>;
}

Description.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};
