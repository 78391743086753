import React, { useCallback, useState } from 'react';
import { IoLocationOutline, IoSearchSharp } from 'react-icons/io5';
import { MdLocationOn } from 'react-icons/md';

import { Formik } from 'formik';
import InputText from '../InputText';
import MediaMatch from '../MediaMatch';
import CardFilter from '../CardFilter';
import arrayLocations from '../../utils/static/static';

import CCG_multiclinica from '../../assets/Fotos_Clinicas/CCG-Multiclinica.png';
import Central_de_Odontologia_de_Gravataí from '../../assets/Fotos_Clinicas/Central-de-Odontologia-de-Gravataí.png';
import Central_de_Odontologia_de_PortoAlegre from '../../assets/Fotos_Clinicas/Central-de-Odontologia-de-PortoAlegre.png';
import Central_de_Odontologia_Fisioterapia from '../../assets/Fotos_Clinicas/Central-de-Odontologia-Fisioterapia.png';
import Clinica_Alvorada from '../../assets/Fotos_Clinicas/Clínica-Alvorada.png';
import Clinica_Cachoeirinha from '../../assets/Fotos_Clinicas/Clinica-Cachoeirinha.png';
import Clinica_Canoas from '../../assets/Fotos_Clinicas/Clínica-Canoas.png';
import Clinica_da_Mulher from '../../assets/Fotos_Clinicas/Clínica-da-Mulher.png';
import Clinica_Estar_Bem from '../../assets/Fotos_Clinicas/Clínica-Estar-Bem.png';
import Clinica_Gravataí from '../../assets/Fotos_Clinicas/Clínica-Gravataí.png';
import Clinica_Guaiba from '../../assets/Fotos_Clinicas/Clínica-Guaiba.png';
import Clinica_Mais from '../../assets/Fotos_Clinicas/Clínica-Mais.png';
import Clinica_Montenegro from '../../assets/Fotos_Clinicas/Clínica-Montenegro.png';
import Clinica_São_Leopoldo from '../../assets/Fotos_Clinicas/Clínica-São-Leopoldo.png';
import Clinica_Taquari from '../../assets/Fotos_Clinicas/Clínica-Taquari.png';
import Clinica_Viamao from '../../assets/Fotos_Clinicas/Clínica-Viamao.png';
import Clinica_Wallig from '../../assets/Fotos_Clinicas/Clínica-Wallig.png';
import Clinica_Zona_Sul from '../../assets/Fotos_Clinicas/Clínica-Zona-Sul.png';
import Imagem_clinica_hospital from '../../assets/Fotos_Clinicas/imagem-clinica-hospital.jpg';

import * as S from './styles';
import GoogleMaps from '../GoogleMaps';

const FiltroClinicas = () => {
  const [selectedMap, setSelectedMap] = useState(true);
  const [selectedList, setSelectedList] = useState(false);

  const handleSubmit = (values) => {
    console.log(values);
  };

  const handleSelectedbutton = useCallback((name) => {
    if (name === 'map') {
      setSelectedMap(true);
      setSelectedList(false);
    }
    if (name === 'list') {
      setSelectedMap(false);
      setSelectedList(true);
    }
  }, []);

  const HandleSelectColor = useCallback((tag) => {
    switch (tag) {
      case 'secondary': {
        return '#009cde';
      }
      case 'polo': {
        return '#8290C8';
      }

      case 'lilac': {
        return '#be99bb';
      }

      default:
        return '#fff';
    }
  }, []);

  const initialValues = { search: '' };

  return (
    <S.Container>
      <S.Wrapper>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={() => (
            <S.Form>
              <MediaMatch lessThan="medium">
                <S.InputBox>
                  <InputText
                    name="search"
                    type="text"
                    label="pesquisar por clinicas..."
                    icon={IoSearchSharp}
                    iconSize={18}
                  />
                </S.InputBox>
              </MediaMatch>

              <MediaMatch greaterThan="medium">
                <InputText
                  type="text"
                  name="search"
                  label="PESQUISAR CLÍNICAS POR ENDEREÇO, CEP, CIDADE"
                  icon={IoSearchSharp}
                  iconSize={18}
                />
              </MediaMatch>
              <S.LocationButton type="button">
                <span>
                  <IoLocationOutline size={20} />
                </span>
                usar minha localização
              </S.LocationButton>
            </S.Form>
          )}
        />

        <MediaMatch lessThan="medium">
          <S.WrapperButtonsSelection>
            <S.ButtonsSelection
              selected={selectedMap}
              onClick={() => handleSelectedbutton('map')}
            >
              Mapa
            </S.ButtonsSelection>
            <S.ButtonsSelection
              selected={selectedList}
              onClick={() => handleSelectedbutton('list')}
            >
              Lista
            </S.ButtonsSelection>
          </S.WrapperButtonsSelection>
        </MediaMatch>

        {/* <S.FilterWrapper>
          <span>Filtrar: </span>
          <S.FilterOptions>
            <S.Options>
              <span>
                <MdLocationOn color={HandleSelectColor('lilac')} size={20} />
              </span>
              Cuidado Continuado
            </S.Options>
            <S.Options>
              <span>
                <MdLocationOn
                  color={HandleSelectColor('secondary')}
                  size={20}
                />
              </span>
              Hospital
            </S.Options>
            <S.Options>
              <span>
                <MdLocationOn color={HandleSelectColor('polo')} size={20} />
              </span>
              Baixa Complexidade
            </S.Options>
            <S.Options>
              <span>
                <MdLocationOn
                  color={HandleSelectColor('secondary')}
                  size={20}
                />
              </span>
              Hospital
            </S.Options>
            <S.Options>
              <span>
                <MdLocationOn
                  color={HandleSelectColor('secondary')}
                  size={20}
                />
              </span>
              Hospital
            </S.Options>
          </S.FilterOptions>
        </S.FilterWrapper> */}
        <S.MapWrapper selectedMap={selectedMap}>
          <GoogleMaps
            initialPosition={{ lat: -30.0282758, lng: -51.221474 }}
            height="40rem"
            arrayLocations={arrayLocations}
          />
        </S.MapWrapper>
        <S.CardsWrapper selectedList={selectedList}>
          <CardFilter
            image={Clinica_Mais}
            horario="Segunda a sexta-feira, das 7h às 19h"
            name="Clínica Mais"
            endereco="Av. Alberto Bins, 509 – Centro, Porto Alegre"
            telefone="(51) 2312-2700"
            location={{ lat: -30.0282758, lng: -51.221474 }}
          />
          <CardFilter
            image={Clinica_Zona_Sul}
            name="Clínica Zona Sul"
            horario="Segunda a sexta-feira das 8h às 19h
            Sábados, das 10h às 18h"
            endereco="Av. Juca Batista, 4425 – Cavalhada, Porto Alegre"
            telefone="(51) 3030-9191"
            location={{ lat: -30.1588134, lng: -51.1910388 }}
          />

          <CardFilter
            image={Clinica_Wallig}
            horario=" Segunda a sábado, das 8h às 20h"
            name="Clínica Wallig"
            endereco="Av. Assis Brasil, 2611. 4º andar - Cristo Redentor, Porto Alegre"
            telefone="(51) 3500-8370"
            location={{ lat: -30.0119314, lng: -51.1607565 }}
          />
          <CardFilter
            image={Clinica_Estar_Bem}
            horario=" Segunda a sexta-feira, das 7h30 às 19h"
            name="Clínica Estar Bem"
            endereco="Av. Farrapos, 1740 – Floresta, Porto Alegre"
            telefone="(51) 3287-9695"
            location={{ lat: -30.0161556, lng: -51.2071812 }}
          />
          <CardFilter
            image={Clinica_da_Mulher}
            name="Clínica da Mulher"
            horario="Segunda a quinta-feira, das 8h às 18h
            Sexta-feira, das 8h às 17h"
            endereco="Av. Cristóvão Colombo, 542 – Floresta, Porto Alegre"
            telefone="(51) 3227-1022"
            location={{ lat: -30.0254259, lng: -51.212595 }}
          />
          {/* <CardFilter
            image={Central_de_Odontologia_de_PortoAlegre}
            horario="Segunda a sexta-feira, das 7h às 18h30"
            name="Central de Odontologia de Porto Alegre"
            endereco="Praça 15 de Novembro, 16/2° e 6° andar"
            telefone="(51) 3226-6106"
            location={{ lat: -30.0286371, lng: -51.2276042 }}
          /> */}

          <CardFilter
            image={Clinica_Cachoeirinha}
            name="Clínica Cachoeirinha"
            horario="Segunda a sexta-feira, das 7h30 às 18h45"
            endereco="Av. Lídio Batista Soares, 57 – Guitandinha, Cachoeirinha"
            telefone="(51) 3441-9000"
            location={{ lat: -29.9491207, lng: -51.0968812 }}
          />
          <CardFilter
            image={Imagem_clinica_hospital}
            name="Clínica Campo Bom"
            horario="Segunda a sexta-feira, das 7h às 18h45."
            endereco="Av. Brasil, 3300 - Centro, Campo Bom"
            telefone="(51) 3287-9255"
            location={{ lat: -29.6802453, lng: -51.0545159 }}
          />
          <CardFilter
            image={Clinica_Canoas}
            name="Clínica Canoas"
            horario="Domingo a domingo, das 7h às 21h30
            Feriados - das 8h às 20h"
            endereco="Av. Boqueirão, 33 - Igara, Canoas "
            telefone="(51) 3462-9200"
            location={{ lat: -29.904179, lng: -51.1760986 }}
          />

          {/* <CardFilter
            image={Central_de_Odontologia_de_Gravataí}
            horario="De segunda a sexta-feira, das 7h40 às 20h"
            name="Central de Odontologia de Gravataí"
            endereco="Rua Dr. Luiz Bastos do Prado, 1867 – Centro, Gravataí "
            telefone=" (51) 3488-4933"
            location={{ lat: -29.9386669, lng: -50.9940472 }}
         /> */}
          <CardFilter
            image={Clinica_Gravataí}
            horario="Segunda a sexta-feira, das 7h30 às 20h45"
            name="Clínica Gravataí"
            endereco="Av. José Loureiro da Silva, 1843 – Centro, Gravataí  "
            telefone="(51) 3484-8400"
            location={{ lat: -29.9390261, lng: -50.9947448 }}
          />

          <CardFilter
            image={Clinica_Guaiba}
            horario="Segunda a Sexta-feira das 8h às 19h45
            Sábados das 8h às 17h45
            Domingos e Feriados das 8h às 13h45"
            name="Clínica Guaíba"
            endereco="R. Vinte de Setembro, 370 - Centro, Guaíba"
            telefone="(51) 3491-2999"
            location={{ lat: -30.1052371, lng: -51.317701 }}
          />
          <CardFilter
            image={Clinica_Montenegro}
            horario="De segunda a sexta-feira, das 8h às 17h"
            name="Clínica Montenegro"
            endereco=" R. Buarque de Macedo, 145 - Centro, Montenegro"
            telefone=" (51) 3178-3222"
            location={{ lat: -29.6834669, lng: -51.4578542 }}
          />
          <CardFilter
            image={Imagem_clinica_hospital}
            horario="24 Horas"
            name="Clínica Novo Hamburgo"
            endereco="R. David Canabarro, 30 - Centro, Novo Hamburgo"
            telefone="(51) 3287-9050"
            location={{ lat: -29.6848324, lng: -51.1294256 }}
          />
          {/* <CardFilter
            image={Clinica_São_Leopoldo}
            horario="24 Horas"
            name="Clínica São Leopoldo"
            endereco="R. João Alberto, 313 - Centro, São Leopoldo "
            telefone="(51) 3579-4600"
            location={{ lat: -29.7776183, lng: -51.1484849 }}
          /> */}
          <CardFilter
            image={Clinica_Viamao}
            horario="De segunda a sexta-feira, das 8h às 20h"
            name="Clínica Viamão"
            endereco="R. Rev. Américo Vespúcio Cabral, 96 - Centro, Viamão"
            telefone="(51) 3434-3505"
            location={{ lat: -30.0824213, lng: -51.0355666 }}
          />

          {/* <CardFilter
            image={CCG_multiclinica}
            horario="Segunda a sexta-feira das 8h às 18h"
            name="Unidade Multiclínica São Leopoldo"
            endereco="R. Bento Gonçalves, 806 - Centro, São Leopoldo"
            telefone="(51) 3589-1000"
            location={{ lat: -29.7670053, lng: -51.1472388 }}
          /> */}
          {/* <CardFilter
            image={Central_de_Odontologia_Fisioterapia}
            name="Central de Fisioterapia"
            horario="Segunda a sexta-feira, das 7h30 às 20h"
            endereco="R. Argentina, 171 - São Luís, Canoas"
            telefone="(51) 3477-6111 "
            location={{ lat: -29.8889622, lng: -51.176835 }}
          /> */}
        </S.CardsWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default FiltroClinicas;
