const string = `<p>Ao chegar na recepção do Pronto Atendimento Adulto, tudo o que uma pessoa que está precisando de ajuda quer é rapidez e resolutividade. Pensando nisso, treinamos nossa equipe de pronto-socorro para trabalhar em protocolos bem definidos de cuidados. Desse modo, conseguimos alinhar com precisão a atuação entre as equipes durante todo o fluxo do paciente no Hospital, o que nos dá maior agilidade para lidar com as emergências; e ainda gera dados que alimentam indicadores controlados para otimizar cada vez mais o processo. Com todo o atendimento baseado em evidências, garantimos segurança e tratamento humanizado às pessoas.
</p><br>
<p>Assim que o paciente é identificado, acessamos seu histórico médico (se for cliente CCG Saúde ou já tiver usado um dos serviços disponíveis) ou damos entrada em uma nova ocorrência no software de gestão do Hospital. Deste momento até a alta, ele será acolhido e terá toda sua jornada monitorada pelos indicadores de qualidade do Humaniza.
</p><br>
<p>Os procedimentos de triagem são feitos em um dos seis consultórios de avaliação médica. Esse primeiro contato é realizado por uma equipe de enfermagem treinada especialmente para isso, seguindo diretrizes claras de classificação de risco. Caso seja necessária a realização de exames com equipamentos mais específicos, o paciente é encaminhado para o Centro de Diagnóstico de Imagem, que fica no mesmo andar. Também estão à disposição uma sala de sutura e curativos, uma sala de eletrocardiograma, salas de aplicação de medicação e inalação, e salas de observação.
</p><br>
<p>No Pronto Atendimento Adulto, estamos preparados para atender a todas as especialidades do Hospital (Traumatologia, Ortopedia, Gastroenterologia, Oncologia, Cardiologia) e as principais especialidades médicas.
</p><br>
`;

export { string };
