import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 10rem;
    width: 92%;
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: ${theme.maxwidth};
    ${media.greaterThan('medium')`
      margin-left: 28rem;
      width: 40%;
      margin-bottom: 10rem;
    `}
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    background-color: ${theme.colors.secondWhite};
    border-collapse: collapse;
    border-radius: 10px;
    margin-bottom: 5rem;
    width: 88%;
    height: 30rem;
    box-shadow: 1rem 1rem 1rem ${theme.colors.gray};
    ${media.greaterThan('medium')}
    width:100%;
  `}
  border-radius: 1rem;
`;

export const Tr = styled.tr`
  ${({ theme }) => css`
    background: ${theme.colors.secondWhite};
    .refeicao {
      font-family: ${theme.font.bold};
      color: ${theme.colors.primary};
    }
    text-align: center;
    .horario {
      color: ${theme.font.colors.primary};
    }
  `}
`;

export const Th = styled.th`
  ${({ theme }) => css`
    background: #fff;
    font-size: 1.1rem;
    margin-bottom: 20rem;
    box-shadow: none;
    color: ${theme.colors.primary};
  `}
`;

export const Td = styled.td`
  ${({ theme }) => css``}
`;

export const P = styled.p`
  ${({ theme }) => css`
    margin-bottom: 10rem;
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.medium};
  `}
`;

export const B = styled.b`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    margin-left: 7rem;
  `}
`;
