import React, { useCallback, useState } from 'react';
import { MdAccessibility } from 'react-icons/md';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useHistory } from 'react-router-dom';

import MediaMatch from '../MediaMatch';

import { useFontContext } from '../../hooks/useUpdateSizeFonts';
import { useTheme } from '../../hooks/useTheme';

import * as S from './styles';

import contraste from '../../assets/contraste.svg';
import libras from '../../assets/libras.svg';
import mais from '../../assets/mais.svg';
import menos from '../../assets/menos.svg';
import Logo from '../Logo';

export default function AccessibilityMenu() {
  const { toggleTheme } = useTheme();
  const { enlargeGlobalFonts, decreaseGlobalFonts } = useFontContext();
  const [open, setOpen] = useState(false);

  const handleTheme = useCallback(() => toggleTheme(), [toggleTheme]);

  const history = useHistory();

  const goToLgdp = () => {
    history.push('/lgpd');
  };

  return (
    <S.Wrapper>
      <S.NavMenu>
        <button type="button" className="button" onClick={goToLgdp}>
          <span className="lgpd">Lei Geral de Proteção de Dados</span>
        </button>
        <S.MenuTitle>
          <button type="button" onClick={() => setOpen(!open)}>
            <span className="first-icon">
              <MdAccessibility size={16} color="#fff" />
            </span>
            Acessibilidade
            <span className="secound-icon">
              {open ? (
                <IoIosArrowUp size={10} color="#fff" />
              ) : (
                <IoIosArrowDown size={10} color="#fff" />
              )}
            </span>
          </button>

          <S.MenuGroup open={open}>
            <S.Container>
              <MediaMatch greaterThan="large">
                <div className="define__clolor">
                  <Logo size="normal" />
                </div>
              </MediaMatch>
              <S.WrapperMenu>
                <S.OptionWrapper onClick={handleTheme}>
                  <S.IconWrapper>
                    <img draggable="false" src={contraste} alt="" />
                  </S.IconWrapper>
                  <S.OptionName>Contraste</S.OptionName>
                </S.OptionWrapper>

                {/* <S.OptionWrapper>
                  <S.IconWrapper>
                    <img src={libras} alt="" />
                  </S.IconWrapper>
                  <S.OptionName>libras</S.OptionName>
                </S.OptionWrapper> */}

                <S.OptionWrapper onClick={enlargeGlobalFonts}>
                  <S.IconWrapper>
                    <img draggable="false" src={mais} alt="" />
                  </S.IconWrapper>
                  <MediaMatch greaterThan="medium">
                    <S.OptionName>aumentar fonte</S.OptionName>
                  </MediaMatch>
                  <MediaMatch lessThan="medium">
                    <S.OptionName>a</S.OptionName>
                  </MediaMatch>
                </S.OptionWrapper>

                <S.OptionWrapper onClick={decreaseGlobalFonts}>
                  <S.IconWrapper>
                    <img src={menos} alt="" />
                  </S.IconWrapper>
                  <MediaMatch greaterThan="medium">
                    <S.OptionName>Diminuir fonte</S.OptionName>
                  </MediaMatch>
                  <MediaMatch lessThan="medium">
                    <S.OptionName>a</S.OptionName>
                  </MediaMatch>
                </S.OptionWrapper>
              </S.WrapperMenu>
            </S.Container>
          </S.MenuGroup>
        </S.MenuTitle>
        {/* <MediaMatch greaterThan="large">
          <S.LinkWrapper>
            <S.NavLink to="#">Área do Paciente</S.NavLink>
          </S.LinkWrapper>
        </MediaMatch>
        <MediaMatch greaterThan="large">
          <S.LinkWrapper>
            <S.NavLink to="#">Área do Médico</S.NavLink>
          </S.LinkWrapper>
        </MediaMatch> */}
      </S.NavMenu>
    </S.Wrapper>
  );
}
