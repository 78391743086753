import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ image, theme }) => css`
    background: url(${image});
    background-size: cover;
    height: 36rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: ${theme.spacings.small} 4rem 3rem;
    border-radius: 0.4rem;
    width: 100%;

    ${media.lessThan('small')`
        height: 32rem;
     `}
  `}
`;
export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.bold};
    font-size: 3.6rem;
    margin-bottom: 1.6rem;
  `}
  color: #fff;
`;
export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: #fff;
    line-height: 2.8rem;
  `}
`;
