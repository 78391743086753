import React, {
  createContext, useCallback, useContext, useState,
} from 'react';

import normalTheme from '../../styles/themes/theme';
import contrasteTheme from '../../styles/themes/ContrasteTheme';

const LocalThemeContext = createContext(normalTheme || null);

function LocalThemeProvider({ children }) {
  const [theme, setTheme] = useState(normalTheme);

  const toggleTheme = useCallback(
    () => setTheme(theme.name === 'normal' ? contrasteTheme : normalTheme),
    [theme.name],
  );

  return (
    <LocalThemeContext.Provider value={{ toggleTheme, theme }}>
      {children}
    </LocalThemeContext.Provider>
  );
}

function useTheme() {
  const context = useContext(LocalThemeContext);

  if (!context) {
    throw new Error('useTheme must be used whith LocalThemeContex');
  }

  return context;
}

export { useTheme, LocalThemeProvider };
