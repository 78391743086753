import React, { useCallback, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import theme from '../../styles/themes/theme';

import * as S from './styles';

const AskedQuestions = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSelected = useCallback(
    (name) => {
      setIsOpen({
        [name]: !isOpen[name],
      });
    },
    [isOpen],
  );

  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleWrapper>
          <S.Title>Dúvidas Frequentes</S.Title>
          <S.Description>
            Confira as principais dúvidas sobre o COVID-19, métodos de prevenção
            e como identificar casos suspeitos.
          </S.Description>
        </S.TitleWrapper>
        <S.AskCardWrapper>
          <S.AskCard>
            <S.PlusDetails onClick={() => toggleSelected('1')}>
              <S.Question>O que é cornavírus ?</S.Question>
              <div>
                <span className="span__text">veja mais</span>
                <IoIosArrowDown color={theme.colors.secondary} size={15} />
              </div>
            </S.PlusDetails>
            <S.SubCardQuestionWrapper open={isOpen['1']}>
              <S.SubcardQuestion>
                Os coronavírus (CoV) compõem uma grande família de vírus,
                conhecidos desde a década de 1960. Eles receberam esse nome
                devido ao seu formato, que lembra uma coroa.
                {' '}
                <br />
                {' '}
                <br />
                Os coronavírus causam doenças em humanos e animais. Em pessoas,
                normalmente provocam infecções respiratórias leves a moderadas,
                semelhantes a um resfriado comum. Porém, alguns coronavírus já
                levaram a outras doenças bem mais graves, com a Síndrome
                Respiratória Aguda Grave (SARS), identificada em 2002, e a
                Síndrome Respiratória do Oriente Médio (MERS), identificada em
                2012.
                {' '}
                <br />
                {' '}
                <br />
                {' '}
                O novo coronavírus, que apareceu na China em
                dezembro de 2019, é uma variação dentro da família do vírus.
                Chamado de 2019-nCov, causa a doença COVID-19.
              </S.SubcardQuestion>
            </S.SubCardQuestionWrapper>
          </S.AskCard>

          <S.AskCard>
            <S.PlusDetails onClick={() => toggleSelected('2')}>
              <S.Question>O que é a COVID-19 ?</S.Question>
              <div>
                <span className="span__text">veja mais</span>
                <IoIosArrowDown color={theme.colors.secondary} size={15} />
              </div>
            </S.PlusDetails>
            <S.SubCardQuestionWrapper open={isOpen['2']}>
              <S.SubcardQuestion>
                Os coronavírus (CoV) compõem uma grande família de vírus,
                conhecidos desde a década de 1960. Eles receberam esse nome
                devido ao seu formato, que lembra uma coroa.
                {' '}
                <br />
                {' '}
                <br />
                Os coronavírus causam doenças em humanos e animais. Em pessoas,
                normalmente provocam infecções respiratórias leves a moderadas,
                semelhantes a um resfriado comum. Porém, alguns coronavírus já
                levaram a outras doenças bem mais graves, com a Síndrome
                Respiratória Aguda Grave (SARS), identificada em 2002, e a
                Síndrome Respiratória do Oriente Médio (MERS), identificada em
                2012.
                {' '}
                <br />
                {' '}
                <br />
                {' '}
                O novo coronavírus, que apareceu na China em
                dezembro de 2019, é uma variação dentro da família do vírus.
                Chamado de 2019-nCov, causa a doença COVID-19.
              </S.SubcardQuestion>
            </S.SubCardQuestionWrapper>
          </S.AskCard>

          <S.AskCard>
            <S.PlusDetails onClick={() => toggleSelected('3')}>
              <S.Question>Quais são os sintomas da COVID-19?</S.Question>
              <div>
                <span className="span__text">veja mais</span>
                <IoIosArrowDown color={theme.colors.secondary} size={15} />
              </div>
            </S.PlusDetails>
            <S.SubCardQuestionWrapper open={isOpen['3']}>
              <S.SubcardQuestion>
                Os coronavírus (CoV) compõem uma grande família de vírus,
                conhecidos desde a década de 1960. Eles receberam esse nome
                devido ao seu formato, que lembra uma coroa.
                {' '}
                <br />
                {' '}
                <br />
                Os coronavírus causam doenças em humanos e animais. Em pessoas,
                normalmente provocam infecções respiratórias leves a moderadas,
                semelhantes a um resfriado comum. Porém, alguns coronavírus já
                levaram a outras doenças bem mais graves, com a Síndrome
                Respiratória Aguda Grave (SARS), identificada em 2002, e a
                Síndrome Respiratória do Oriente Médio (MERS), identificada em
                2012.
                {' '}
                <br />
                {' '}
                <br />
                {' '}
                O novo coronavírus, que apareceu na China em
                dezembro de 2019, é uma variação dentro da família do vírus.
                Chamado de 2019-nCov, causa a doença COVID-19.
              </S.SubcardQuestion>
            </S.SubCardQuestionWrapper>
          </S.AskCard>

          <S.AskCard>
            <S.PlusDetails onClick={() => toggleSelected('4')}>
              <S.Question>Como a COVID-19 é transmitida?</S.Question>
              <div>
                <span className="span__text">veja mais</span>
                <IoIosArrowDown color={theme.colors.secondary} size={15} />
              </div>
            </S.PlusDetails>
            <S.SubCardQuestionWrapper open={isOpen['4']}>
              <S.SubcardQuestion>
                Os coronavírus (CoV) compõem uma grande família de vírus,
                conhecidos desde a década de 1960. Eles receberam esse nome
                devido ao seu formato, que lembra uma coroa.
                {' '}
                <br />
                {' '}
                <br />
                Os coronavírus causam doenças em humanos e animais. Em pessoas,
                normalmente provocam infecções respiratórias leves a moderadas,
                semelhantes a um resfriado comum. Porém, alguns coronavírus já
                levaram a outras doenças bem mais graves, com a Síndrome
                Respiratória Aguda Grave (SARS), identificada em 2002, e a
                Síndrome Respiratória do Oriente Médio (MERS), identificada em
                2012.
                {' '}
                <br />
                {' '}
                <br />
                {' '}
                O novo coronavírus, que apareceu na China em
                dezembro de 2019, é uma variação dentro da família do vírus.
                Chamado de 2019-nCov, causa a doença COVID-19.
              </S.SubcardQuestion>
            </S.SubCardQuestionWrapper>
          </S.AskCard>

          <S.AskCard>
            <S.PlusDetails onClick={() => toggleSelected('5')}>
              <S.Question>
                Já existe uma vacina, medicamento ou tratamento para a COVID-19?
              </S.Question>
              <div>
                <span className="span__text">veja mais</span>
                <IoIosArrowDown color={theme.colors.secondary} size={15} />
              </div>
            </S.PlusDetails>
            <S.SubCardQuestionWrapper open={isOpen['5']}>
              <S.SubcardQuestion>
                Os coronavírus (CoV) compõem uma grande família de vírus,
                conhecidos desde a década de 1960. Eles receberam esse nome
                devido ao seu formato, que lembra uma coroa.
                {' '}
                <br />
                {' '}
                <br />
                Os coronavírus causam doenças em humanos e animais. Em pessoas,
                normalmente provocam infecções respiratórias leves a moderadas,
                semelhantes a um resfriado comum. Porém, alguns coronavírus já
                levaram a outras doenças bem mais graves, com a Síndrome
                Respiratória Aguda Grave (SARS), identificada em 2002, e a
                Síndrome Respiratória do Oriente Médio (MERS), identificada em
                2012.
                {' '}
                <br />
                {' '}
                <br />
                {' '}
                O novo coronavírus, que apareceu na China em
                dezembro de 2019, é uma variação dentro da família do vírus.
                Chamado de 2019-nCov, causa a doença COVID-19.
              </S.SubcardQuestion>
            </S.SubCardQuestionWrapper>
          </S.AskCard>

          <S.Link>
            Para saber mais sobre o COVID-19 e tudo que o CCG Saúde tem feito
            para ajudar a comunidade, acesse:
            {' '}
            <a href="https://ccgporvoce.com.br/">https://ccgporvoce.com.br/</a>
          </S.Link>
        </S.AskCardWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default AskedQuestions;
