import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const miediaMatchModifier = {
  lessThan: (size) => css`
    ${media.lessThan(size)`
      display: block;
    `}
  `,
  greaterThan: (size) => css`
    ${media.greaterThan(size)`
      display: block;
    `}
  `,
};

export default styled.div`
  ${({ lessThan, greaterThan }) => css`
    display: none;

    ${!!lessThan && miediaMatchModifier.lessThan(lessThan)}
    ${!!greaterThan && miediaMatchModifier.greaterThan(greaterThan)}
  `}
`;
