import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Logo from '../Logo';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.bg_contraste};
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-shadow: 0px 5px 10px 0px rgba(123, 169, 188, 0.16);
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.maxwidth};
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const MenuHanburguer = styled.button`
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderNav = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacings.xsmall};

    ${MenuHanburguer} {
      img {
        width: 33px;
      }
    }
  `}
`;

export const LogoWrapper = styled(Link)`
  ${({ theme }) => css`
    svg {
      color: ${theme.colors.logo};
    }
  `}
`;

export const WrapperMenuHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    box-shadow: 0px 5px 10px 0px rgba(123, 169, 188, 0.16);
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacings.xsmall};
    margin-bottom: ${theme.spacings.xxsmall};
    background: ${theme.colors.bg_contraste};

    ${MenuHanburguer} {
      img {
        width: 33px;
      }
    }
  `}
`;

export const MenuNavFull = styled.nav`
  ${({ isOpen, theme }) => css`
    opacity: ${isOpen ? 1 : 0};
    position fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: ${theme.colors.bg_contraste};   
    height: 100vh;
    pointer-events: ${isOpen ? 'all' : 'none'};
    transition: 0.3s ease;
    overflow: auto;
    display: flex;
    flex-direction: column;
    z-index: 99999;
  `}
`;

export const MenuBody = styled.div`
  ${({ theme, isOpen }) => css`
    width: 100%;
    padding: 0 0 ${theme.spacings.small};

    transform: ${isOpen ? 'translateY(0)' : 'translateY(3rem)'};
    transition: transform 0.3s ease-in-out;
    flex: 1;
    margin-bottom: 30%;
  `}
`;

export const OptionMenu = styled.div`
  margin-bottom: 1rem;
`;

export const IconMenuButtom = styled.div`
  ${({ theme, open }) => css`
    svg {
      transition: transform 0.3s ease;
      color: ${theme.name === 'contraste'
    ? theme.font.colors.primary
    : theme.colors.secondary};
      transform: ${open ? 'rotate(180deg)' : ''};
    }
  `}
`;

export const MenuButtom = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 5.6rem;
    padding: 0 3rem;

    > span {
      font-size: ${theme.font.sizes.large};
      color: ${theme.font.colors.primary};
      font-weight: 600;
    }
  `}
`;

const colorModifier = {
  blue: (theme) => css`
    border-left: 4px solid ${theme.colors.secondary};
  `,
  turquoise: (theme) => css`
    border-left: 4px solid ${theme.colors.turquoise};
  `,
  poloBlue: (theme) => css`
    border-left: 4px solid ${theme.colors.poloBlue};
  `,
};

export const MenuSubOptionsHospital = styled.div`
  ${({ open, color, theme }) => css`
    height: ;
    opacity: ${open ? 1 : 0};
    display: ${open ? 'flex' : 'none'};
    flex-direction: column;
    padding-left: ${theme.spacings.xlarge};
    transition: opacity 0.3s ease;

    ${!!color && colorModifier[color](theme)}
  `}
`;

const linkModifiers = {
  custom: (theme) => css`
    font-size: ${theme.font.sizes.large};
    color: ${theme.font.colors.primary};
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  `,
};

export const LinkMenu = styled(Link)`
  ${({ theme, link }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.font.colors.primary};

    height: 5.6rem;
    padding: 0 3rem;
    ${link === 'true' && linkModifiers.custom(theme)}
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px ${theme.spacings.xsmall};
  `}
`;

export const MenuActionButtonDescktop = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    background: none;
    font-size: ${theme.font.sizes.medium};
    color: ${theme.font.colors.primary};
    font-weight: bold;
    position: relative;

    & + button {
      margin-left: 1.6rem;
    }
  `}
`;

export const LinkDesktopMenuMain = styled(Link)`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.font.colors.primary};
    font-weight: bold;
  `}
`;

export const WrapperMenuDesktop = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    ${MenuActionButtonDescktop} + ${MenuActionButtonDescktop}, 
    ${LinkDesktopMenuMain}, 
    ${MenuActionButtonDescktop} {
      margin-left: 1.6rem;
      transition: color 0.3s ease;

      :hover {
        color: ${theme.colors.secondary};
      }
    }
  `}
`;

export const LinkDesktop = styled(Link)`
  ${({ theme }) => css`
    flex: 1;
    padding: 1rem 0;
    font-size: ${theme.font.sizes.medium};
    color: ${theme.font.colors.primary};
    transition: color 0.3s ease;
    :hover {
      color: ${theme.colors.secondary};
    }
  `}
`;

export const ExternalLinkDesktop = styled.a`
  ${({ theme }) => css`
    flex: 1;
    padding: 1rem 0;
    font-size: ${theme.font.sizes.medium};
    color: ${theme.font.colors.primary};
    transition: color 0.3s ease;
    :hover {
      color: ${theme.colors.secondary};
    }
  `}
`;

export const LinkVariant = styled(Link)`
  ${({ theme }) => css`
    flex: 1;
    color: ${theme.font.colors.primary};
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    padding-bottom: 4rem;
    span {
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      background-color: ${theme.colors.rose};
      margin-right: 1rem;
      margin-left: 1rem;
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
    }

    :hover {
      color: ${theme.colors.secondary};
    }
  `}
`;

export const LinkDesktopVariant = styled(Link)`
  ${({ theme }) => css`
    flex: 1;
    color: ${theme.font.colors.primary};
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    
    span {
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      background-color: ${theme.colors.rose};
      margin-right: 1rem;
      margin-left: 1rem;
    }

    p {
      font-size: ${theme.font.sizes.small};
    }

    :hover {
      color: ${theme.colors.secondary};
    }
  `}
`;

export const IconWrapperDesktop = styled.div`
  ${({ theme, open }) => css`
    svg {
      color: ${theme.name === 'contraste' ? '#fff' : theme.colors.secondary};
      margin-left: 0.5rem;
      transform: ${open ? 'rotate(180deg)' : ''};
    }
  `}
`;

const subMenuColorModifier = {
  blue: (theme) => css`
    border-top: 4px solid ${theme.colors.secondary};
  `,
  turquoise: (theme) => css`
    border-top: 4px solid ${theme.colors.turquoise};
  `,
  poloBlue: (theme) => css`
    border-top: 4px solid ${theme.colors.poloBlue};
  `,
};

export const SubMenuDesktop = styled.div`
  ${({ open, theme, color }) => css`
    background-color: ${theme.colors.bg_contraste};
    position: absolute;
    width: 39rem;
    top: 230%;
    z-index: 9999;
    right: 50%;
    transform: translateX(50%);
    pointer-events: ${open ? 'all' : 'none'};
    opacity: ${open ? 1 : 0};
    padding: ${theme.spacings.small};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    box-shadow: 1px 1px 15px -8px #000;
    ${!!color && subMenuColorModifier[color](theme)};
  `}
`;
