import React, {
  createContext, useCallback, useContext, useState,
} from 'react';

const FilterContext = createContext({} || null);

export function FilterProvider({ children }) {
  const [openMenuFilter, setOpenMenuFilter] = useState(false);
  const [filterOptions] = useState([
    'Todos',
    'COVID-19',
    'Institucional',
    'Na Mídia',
    'Responsabilidade Social',
    'Saúde Pessoal',
  ]);

  const [selected, setSelected] = useState({
    Todos: true,
  });

  const toggleSelected = useCallback(
    (name) => {
      setSelected({
        [name]: !selected[name],
      });
    },
    [selected],
  );

  const handleFilter = useCallback(() => {
    console.log('filtro ativado');
    setOpenMenuFilter(false);
  }, []);

  return (
    <FilterContext.Provider
      value={{
        openMenuFilter,
        setOpenMenuFilter,
        filterOptions,
        toggleSelected,
        handleFilter,
        selected,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export function useNewsletterFilter() {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('this hook must be used whith FilterContext');
  }

  return context;
}
