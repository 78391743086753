import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.maxwidth};
    width: 100%;
    margin: 0 auto;
    padding: 0 ${theme.spacings.xsmall};
  `}
`;
export const TextWrapper = styled.div`
  margin-top: 3rem;

  ${media.greaterThan('medium')`
    margin-top: 8rem;
  `}
`;

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 9.3rem;
    max-width: ${theme.maxwidth};
  `}

  ${media.greaterThan('medium')`
    margin: 8rem auto 0;
  `}
`;

const suppportColorsmodifiers = {
  custom: (theme, color) => css`
    ${theme.name === 'normal'
    && css`
      background: ${theme.colors[color]};
    `}
    ${theme.name === 'contraste'
    && css`
      background: #000;
    `}
  `,
};

export const SupportTextWrapper = styled.div`
  ${({ theme, color }) => css`
    padding: 9.5rem 0;
    width: 100%;

    ${!!color && suppportColorsmodifiers.custom(theme, color)}
  `};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${media.greaterThan('medium')`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const SlideWrapper = styled.div`
  ${({ theme }) => css`
    padding: 5rem ${theme.spacings.small} 5rem;

    ${media.greaterThan('medium')`
      padding: 15rem ${theme.spacings.small} 15rem;

    `}

    ul.slick-dots li button::before {
      content: '';
      width: 1.1rem;
      height: 1.1rem;
      background: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : '#999594'};
      border-radius: 50%;
    }

    ul.slick-dots li.slick-active button::before {
      background: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
    }
  `}
`;
export const ApplyBackground = styled.div`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          background: linear-gradient(
            0deg,
            ${theme.colors.secondWhite} 77.5%,
            rgba(255, 255, 255, 1) 50%
          );
        `
    : css`
          background: #000;
        `)}
`;
