/* eslint-disable react/no-this-in-sfc */
import React, { useState } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Field, Formik } from 'formik';

import InputText from '../InputText';
import Radio from '../Radio';
import TextArea from '../TextArea';
import Select from '../Select';

import * as S from './styles';
import api from '../../services/api';
import { useItem } from '../../context/Item';

const Form = () => {
  const { item } = useItem();
  const [loading, setLoading] = useState(false);
  const [pacote, setPacote] = useState('');
  const [especialidade, setEspecialidade] = useState('');

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      console.log(values);

      console.log({
        plano: values.plano,
        convenio: values.convenio,
        data: values.data,
        nome: values.name,
        email: values.email,
        telefone: values.telefone,
        mensagem: values.message,
        especialidade: item.especialidade,
        pacote: item.pacote,
      });

      await api.post('/email-send/pacotes-cirurgicos/', {
        plano: values.plano,
        convenio: values.convenio,
        data: values.data,
        nome: values.name,
        email: values.email,
        telefone: values.telefone,
        mensagem: values.message,
        especialidade: item.especialidade,
        pacote: item.pacote,
      });

      setLoading(false);

      Swal.fire({
        title: '<h1 style="font-family:diodrum-bold">Formulário enviado</h1>',
        html:
          '<span style="font-size: 1.6rem; font-family:diodrum">Agradecemos o seu contato. Reforçamos que conforme RN nº. 323 da Agência Nacional de Saúde Suplementar (ANS), as solicitações serão respondidas em até sete (7) dias úteis após a abertura do chamado.</span>',
        icon: 'success',
        width: '50rem',

        confirmButtonColor: '#6EC6E6',
        confirmButtonText: 'Fechar',
      });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: '<h1 style="font-family:diodrum-bold">Oops...</h1>',

        html:
          '<span style="font-size: 1.6rem; font-family:diodrum">Algo deu errado no envio, tente novamente</span>',
      });
    }
  };

  const schemaValidate = Yup.object().shape({
    name: Yup.string()
      .required('O nome e obrigatório')
      .min(5, 'Insira um nome válido')
      .max(100, 'Insira um nome válido'),
    email: Yup.string()
      .email('Digite um e-mail valido')
      .required('O e-mail e obrigatório'),
    telefone: Yup.number().required('O numero de telefone e obrigatório'),
    message: Yup.string().required('A mensagem e obrigatória'),
    data: Yup.date().required('A data de nascimento é obrigatória'),
    especialidade: Yup.string().required('Especialidade é obrigatório'),
    pacote: Yup.string().required('Pacote é obrigatório'),
    convenio: Yup.string().required('o convênio é obrigatório'),
    plano: Yup.string().required('o plano é obrigatório'),
  });

  const initialValues = {
    data: '',
    name: '',
    email: '',
    telefone: '',
    message: '',
    especialidade: 'a',
    pacote: 'a',
    convenio: '',
    plano: '',
  };

  // console.log(especialidade);
  // console.log(pacote);
  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={schemaValidate}
      initialValues={initialValues}
      render={({ errors, touched }) => (
        <S.Form>
          <S.TextWrapper>
            <h1>Solicite seu orçamento</h1>
          </S.TextWrapper>

          <S.FieldsWrapper>
            <S.InputBox>
              <InputText
                error={errors.name && touched.name && errors.name}
                name="name"
                type="text"
                label="nome completo *"
              />
              {errors.name && touched.name && <S.Error>{errors.name}</S.Error>}
            </S.InputBox>
            <S.InputBox flex>
              <div className="wrapperFlex">
                <InputText
                  error={errors.email && touched.email && errors.email}
                  name="email"
                  type="text"
                  label="e-mail *"
                />
                {errors.email && touched.email && (
                  <S.Error>{errors.email}</S.Error>
                )}
              </div>
              <div className="wrapperFlex">
                <InputText
                  error={errors.telefone && touched.telefone && errors.telefone}
                  name="telefone"
                  sizeLimited="true"
                  type="text"
                  label="telefone *"
                />
                {errors.telefone && touched.telefone && (
                  <S.Error>{errors.telefone}</S.Error>
                )}
              </div>
            </S.InputBox>

            <S.InputBox>
              <InputText
                error={errors.data && touched.data && errors.data}
                sizeLimited="true"
                name="data"
                type="date"
                label="data de nascimento *"
              />
              {errors.data && touched.data && <S.Error>{errors.data}</S.Error>}
            </S.InputBox>
            <S.InputBox flex>
              <div className="wrapperFlex">
                <InputText
                  error={errors.convenio && touched.convenio && errors.convenio}
                  name="convenio"
                  sizeLimited="true"
                  type="text"
                  label="convênio *"
                />
                {errors.convenio && touched.convenio && (
                  <S.Error>{errors.convenio}</S.Error>
                )}
              </div>
              <div className="wrapperFlex">
                <InputText
                  error={errors.plano && touched.plano && errors.plano}
                  name="plano"
                  sizeLimited="true"
                  type="text"
                  label="plano *"
                />
                {errors.plano && touched.plano && (
                  <S.Error>{errors.plano}</S.Error>
                )}
              </div>
            </S.InputBox>
            <div className="alignSelect">
              <div className="select1">
                <S.WithSelectWrapper>
                  <Field name="especialidade">
                    {({ field }) => (
                      <Select
                        onChange={(event) => setEspecialidade(event.target.value)}
                        {...field}
                        label="especialidade"
                      >
                        <option
                          onClick={() => setEspecialidade(item.especialidade)}
                          value={especialidade}
                        >
                          {item.especialidade}
                        </option>
                      </Select>
                    )}
                  </Field>
                </S.WithSelectWrapper>
              </div>
              <div className="select2">
                <S.WithSelectWrapper>
                  <Field name="pacote">
                    {({ field }) => (
                      <Select
                        onChange={(event) => setPacote(event.target.value)}
                        {...field}
                        label="pacote"
                      >
                        <option
                          onClick={() => setPacote(item.pacote)}
                          value={pacote}
                        >
                          {item.pacote}
                        </option>
                      </Select>
                    )}
                  </Field>
                </S.WithSelectWrapper>
              </div>
            </div>

            <S.InputBox>
              <Field
                name="message"
                render={({ field }) => (
                  <TextArea
                    {...field}
                    placeholder="Digite sua mensagem"
                    label="Observações adicionais *"
                    error={errors.message && touched.message && errors.message}
                  />
                )}
              />
              {errors.message && touched.message && (
                <S.Error>{errors.message}</S.Error>
              )}
            </S.InputBox>

            <S.ButtonWrapper>
              <S.SubmitButton type="submit">
                {loading ? 'Enviando, aguarde...' : 'Enviar Solicitação'}
              </S.SubmitButton>
            </S.ButtonWrapper>
          </S.FieldsWrapper>
        </S.Form>
      )}
    />
  );
};

export default Form;
