import React from 'react';

import * as S from './styles';

import banner from '../../assets/infoCovid/banner.svg';
import bigBanner from '../../assets/infoCovid/bigBanner.svg';
import InformationCard from '../InformationCard';

const TogetherAgainstCOVID = () => (
  <S.Container>
    <S.Wrapper>
      <S.TitleWrapper>
        <div className="ajust__sizes">
          <h1 className="title">Juntos contra o COVID-19</h1>
          <S.SubTitle>
            O
            {' '}
            <strong>CCG Saúde</strong>
            {' '}
            tomou a frente da batalha contra o
            COVID-19, atuando de forma resolutiva para preparar, proteger e
            educar clientes e colaboradores nesta pandemia.
          </S.SubTitle>
        </div>
      </S.TitleWrapper>
      <S.Banner background={[banner, bigBanner]} />
      <div className="bg">
        <S.Description>
          <span>
            Conter o avanço do Coronavírus é um esforço de todos, onde cada
            ação pode fazer a diferença para muitas vidas. Por isso, confira
            essas considerações para saber como agir durante a pandemia
          </span>
        </S.Description>
        <S.InformationWrapper>
          <InformationCard title="Antes de sair para consultar, certifique-se que o caso é urgente." />
          <InformationCard title="O Hospital é área de risco, por isso, tente procurá-lo somente em momentos de extrema importância." />
          <InformationCard
            description="Assim, evita-se o contágio de mais pessoas. Com exceção de menores de idade, idosos e pessoas com necessidades especiais, que podem ser acompanhados de uma pessoa."
            title="Caso o atendimento seja necessário, não leve acompanhantes."
          />

          <InformationCard title="Em caso de dúvidas, ligue para 0800.888.9599 e receba orientações para o seu atendimento." />
          <InformationCard title="Evitar aglomerações é muito importante para conter o avanço da pandemia." />
        </S.InformationWrapper>

        <S.FooterInformation>
          <p>
            Por isso, pedimos que evite aglomerações e programe suas consultas
            para depois de controlada a pandemia. Você também pode utilizar
            nosso serviço de telemedicina e deixar a prioridade presencial
            para casos urgentes.
          </p>

          <span>
            Agradecemos pela ajuda que você estará dando para conter o avanço
            da COVID-19.
          </span>
        </S.FooterInformation>
      </div>
    </S.Wrapper>
  </S.Container>
);

export default TogetherAgainstCOVID;
