import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.secondWhite};
    margin: ${theme.spacings.medium} 0;
    width: 100%;
    padding: ${theme.spacings.small} 0;
    display: ${({ display }) => display};

    span {
      padding-left: ${theme.spacings.small};
      font-size: ${theme.font.sizes.large};
    }
  `}
`;
