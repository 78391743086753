import React from 'react';

import GlobalContainer from '../../components/_layout/Container';
import ShareComponent from '../../components/ShareComponent';
import News from '../../components/News';
import {
  LittleTitle,
  Title,
  Description,
  SupportText,
} from '../../components/TextComponents';
import { ImageBanner } from '../../components/MediaContainer/modules';

import * as S from './styles';

import bannerWeb from '../../assets/modelo_assistencial_assets/modeloAss_banner.jpg';
import bannerMobile from '../../assets/modelo_assistencial_assets/modeloAss_mobile.jpg';

export default function NoticiasInternas() {
  const string = `
  Aenean ut congue magna dis ut placerat vulputate fames. Id sed pellentesque consequat tempor. At auctor habitant condimentum elementum in faucibus et vulputate porttitor. Nibh mi sed nunc vitae nisl consequat posuere lectus iaculis. Nunc imperdiet adipiscing pulvinar nulla mi, diam ornare aliquet velit. Tristique sed eu vitae senectus risus habitant.
  Pellentesque amet, mattis nulla arcu convallis. Tempor sit aliquam viverra nunc cursus nec. Neque in pulvinar in fringilla. Faucibus.

  Aenean ut congue magna dis ut placerat vulputate fames. Id sed pellentesque consequat tempor. At auctor habitant condimentum elementum in faucibus et vulputate porttitor. Nibh mi sed nunc vitae nisl consequat posuere lectus iaculis. Nunc imperdiet adipiscing pulvinar nulla mi, diam ornare aliquet velit. Tristique sed eu vitae senectus risus habitant.
Pellentesque amet, mattis nulla arcu convallis. Tempor sit aliquam viverra nunc cursus nec. Neque in pulvinar in fringilla. Faucibus.

Aenean ut congue magna dis ut placerat vulputate fames. Id sed pellentesque consequat tempor. At auctor habitant condimentum elementum in faucibus et vulputate porttitor. Nibh mi sed nunc vitae nisl consequat posuere lectus iaculis. Nunc imperdiet adipiscing pulvinar nulla mi, diam ornare aliquet velit. Tristique sed eu vitae senectus risus habitant.
Pellentesque amet, mattis nulla arcu convallis. Tempor sit aliquam viverra nunc cursus nec. Neque in pulvinar in fringilla. Faucibus.

Aenean ut congue magna dis ut placerat vulputate fames. Id sed pellentesque consequat tempor. At auctor habitant condimentum elementum in faucibus et vulputate porttitor. Nibh mi sed nunc vitae nisl consequat posuere lectus iaculis. Nunc imperdiet adipiscing pulvinar nulla mi, diam ornare aliquet velit. Tristique sed eu vitae senectus risus habitant.
Pellentesque amet, mattis nulla arcu convallis. Tempor sit aliquam viverra nunc cursus nec. Neque in pulvinar in fringilla. Faucibus.
  `;

  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TextWrapper>
          <LittleTitle label="06 de Novembro" />
          <Title
            size={{
              web: '4.2rem',
              mobile: '2.8rem',
            }}
            label="CCG Saúde lança novo hospital em Porto Alegre"
          />
          <Description label="Com investimento de R$ 250 milhões e consultoria do Hospital Albert Einstein, empreendimento vai gerar 900 empregos direto. " />
        </S.TextWrapper>
      </S.Wrapper>
      <S.BannerWrapper>
        <ShareComponent alignment="right" />
        <ImageBanner backgrounds={{ bannerMobile, bannerWeb }} />
        <S.ImageLabel>
          A cerimônia contou com a participação do Presidente da Assembleia e
          lorem ipsum dolor sit.
        </S.ImageLabel>
      </S.BannerWrapper>
      <S.Wrapper>
        <SupportText
          title="Uma nova referência em saúde em Porto Alegre"
          text={string.replace(/\n/g, '<br />')}
        />
      </S.Wrapper>
      <S.BannerWrapper small>
        <ImageBanner backgrounds={{ bannerMobile, bannerWeb }} />
        <S.ImageLabel>
          A cerimônia contou com a participação do Presidente da Assembleia e
          lorem ipsum dolor sit.
        </S.ImageLabel>
        <ShareComponent alignment="right" />
      </S.BannerWrapper>

      <S.Wrapper>
        <SupportText
          title="Uma nova referência em saúde em Porto Alegre"
          text={string.replace(/\n/g, '<br />')}
        />
      </S.Wrapper>
      <S.BgNews>
        <News btnLabel="Ver todas" />
      </S.BgNews>
    </GlobalContainer>
  );
}
