/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

import PDFViewer from 'mgr-pdf-viewer-react';
import pdf from './doc/AFHumaniza_Guia_Paciente_Oncologico.pdf';
import * as S from './style';
import CustomNavigation, {
  CustomPrevButton,
  CustomNextButton,
  CustomPages,
} from './Navigation';

export default function DownloadoPdfOncologia() {
  function download() {
    window.open(pdf);
  }
  return (
    <PDFViewer
      document={{
        file: pdf,
      }}
      scale={1.5}
      css="customViewer"
      navigation={CustomNavigation}
      onDocumentClick={() => download()}
    />
  );
}
