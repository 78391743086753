import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div``;

export const ContainerMenu = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
    background: transparent;
    position: fixed;
    z-index: 999;
    bottom: 0;
    width: 100%;
    transition: all 0.5s ease;

    ${media.greaterThan('large')`
      display: flex;
      justify-content: center;
    `};
  `}
`;

export const IconFastMenuWrapper = styled.div`
  margin-right: 1rem;
`;

export const BodyMenuWrapper = styled.div`
  ${({ theme }) => css`
    ${media.greaterThan('large')`
      background: #fff;
      height: 6.4rem;      
      display: flex;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
      width: 100%;
      max-width: ${theme.maxwidth};
      justify-content: center;
      margin: 0 auto;
      
    `}
    border-radius: 8px;
  `}
`;

export const OptionsFastMenuWrapper = styled.div`
  ${({ theme, open }) => css`
    box-shadow: 0px 3px 14px -4px rgba(0, 0, 0, 0.5);
    padding: ${theme.spacings.xsmall};
    display: ${open ? 'block' : 'none'};
    background: #fff;

    ${media.greaterThan('large')`
      height: 8rem;      
      display: flex;
      box-shadow: 0px 4px 6px -3px rgba(0,0,0,0.5);
      justify-content: center;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: ${theme.border.radius};
      border-bottom-left-radius: ${theme.border.radius};
      opacity: ${open ? 1 : 0};
      pointer-events: ${open ? 'all' : 'none'};
      transform: ${open ? 'translateX(0)' : 'translateX(-3rem)'};
      transition: 0.3s ease-in-out;

    `};
  `}
`;

export const LinkFastMenu = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacings.xsmall} 0;
    span {
      color: ${theme.colors.primary};
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.medium};
      transition: all 0.3s ease-in-out;
    }

    .wrapperphone {
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        font-size: 1.2rem;
        color: ${theme.colors.secondary};
      }
    }

    &:hover {
      span {
        color: ${theme.colors.secondary};
      }
    }

    ${media.greaterThan('large')`
      padding: 0;
      
      & + a {
      margin-left: 5rem;
    }
    `}
  `}
`;

export const ExternalLinkFastMenu = styled.a`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacings.xsmall} 0;
    span {
      color: ${theme.colors.primary};
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.medium};
      transition: 0.3s all ease-in-out;
    }

    &:hover {
      span {
        color: ${theme.colors.secondary};
      }
    }

    ${media.greaterThan('large')`
      padding: 0;
      
      & + a {
      margin-left: 5rem;
    }
    `}
  `}
`;

export const FastMenu = styled.button`
  ${({ theme, openDesktop }) => css`
    background: ${theme.colors.turquoise};
    height: 8rem;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;
    padding: ${theme.spacings.small};
    border-top-right-radius: ${theme.border.radius};
    border-top-left-radius: ${theme.border.radius};
    color: #fff;
    font-size: ${theme.font.sizes.small};
    width: 100%;
    border: 0;
    transition: 0.3s ease;

    span {
      margin-left: 1.6rem;
    }

    ${openDesktop
    && css`
      svg {
        transform: rotate(180deg);
      }
    `}

    ${media.greaterThan('large')`
      width: 20.6rem;      
      border-top-right-radius: ${theme.border.radius};
      border-bottom-right-radius: ${theme.border.radius};

      ${
  !openDesktop
    ? css`
              border-radius: 8px;
            `
    : css`
              border-top-left-radius: 0;
            `
}
      box-shadow: 0px 4px 6px -3px rgba(0,0,0,0.5);
    `};
  `}
`;
