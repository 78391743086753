import { Field } from 'formik';
import React, { useState } from 'react';

import * as S from './styles';

export default function Checkbox({
  isChecked = false,
  onCheck,
  label,
  id,
  name,
}) {
  const [checked, setChecked] = useState(isChecked);

  const onChange = () => {
    const status = !checked;
    setChecked(status);

    if (onCheck) onCheck(status);
  };

  return (
    <Field name={name}>
      {({ field }) => (
        <S.Wrapper>
          <S.InputWrapper>
            <S.Input
              {...field}
              id={id}
              type="checkbox"
              onChange={onChange}
              checked={checked}
            />
          </S.InputWrapper>
          <S.Label htmlFor={id}>{label}</S.Label>
        </S.Wrapper>
      )}
    </Field>
  );
}
