import React from 'react';

import { Container } from './styles';

const SymptomsCard = ({ color, image, description }) => (
  <Container color={color}>
    <img src={image} alt="" />

    <span>{description}</span>
  </Container>
);

export default SymptomsCard;
