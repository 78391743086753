import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 6rem 0 0;
    margin-top: ${theme.spacings.extraLarge};
  `}

  ${media.lessThan('medium')`
    margin-top: 12rem;
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
  /* max-width: 128rem; */
  margin: 0 auto;
`;
export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
    width: 100%;
    max-width: 200rem;
    padding-left: 16rem;
    margin-right: 0;
    margin-bottom: 2.5rem;

    .title_ajust__sizes {
      max-width: 79rem;
    }

    ${media.lessThan('medium')`
      padding-left: 3rem;
    `}
  `}
`;
export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-size: ${theme.font.sizes.extraLarge};
    font-family: ${theme.font.bold};
    margin-bottom: 2rem;
  `}
`;
export const SmallTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    text-transform: uppercase;
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.medium};
    margin-bottom: 0.5rem;
  `}
`;
export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.blackFonts};
    font-size: ${theme.font.sizes.medium};
    line-height: 28px;
  `}
`;

export const SlideWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 32.8rem;
    background-color: ${theme.name === 'contraste'
    ? '#000'
    : theme.colors.secondWhite};
    background-position-x: 3000px;
    padding: 8rem 0 0rem;
    padding-left: 6rem;
    margin-right: 0;

    .slick-track,
    .slick-list {
      display: flex;
    }

    .slick-slide > div {
      margin: 0 5px;
      flex: 1 0 auto;
    }
    .slick-list {
      margin: 0 -15px;
      transform: translate(0, -285px);
      width: 100%;
    }

    ${media.lessThan('medium')`
      padding-left: 1.6rem;
    `}
  `}
`;
