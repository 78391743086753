import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, gridArea }) => css`
    grid-area: ${gridArea};
    background: ${theme.name === 'contraste'
    ? 'none'
    : theme.colors.secondWhite};
    padding: 4rem 2.2rem;

    .size {
      max-width: 55rem;
      width: 100%;
    }
  `}
`;
export const TextWrapper = styled.div``;
export const ImageWrapper = styled.div`
  margin-right: 1.6rem;
`;
export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    line-height: 2.8rem;
    margin: 1.6rem 0;
  `}
`;
export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    span {
      color: ${theme.font.colors.primary};
      font-family: ${theme.font.bold};
    }
  `}
`;
export const CardWrapper = styled.div`
  ${Card} + ${Card} {
    margin-top: 1rem;
  }
`;
export const LinkWrapper = styled.div`
  margin-top: 1.6rem;
`;
