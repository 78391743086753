import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import * as S from './styles';

const Select = ({ label, children, ...rest }) => (
  <S.Container>
    <strong className="label">{label}</strong>
    <div className="wrapper">
      <S.Select {...rest}>{children}</S.Select>

      <S.Icon>
        <IoIosArrowDown size={20} color="#424242" />
      </S.Icon>
    </div>
  </S.Container>
);

export default Select;
