import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import bg from '../../assets/estrutura-assets/bg.svg';
import bgSlider from '../../assets/estrutura-assets/bg-slider.svg';

export const Container = styled.div`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          background-image: url(${bg}),
            linear-gradient(
              0deg,
              ${theme.colors.secondWhite} 77.5%,
              rgba(255, 255, 255, 1) 50%
            );

          background-position-x: 110%;
          background-repeat: no-repeat;

          ${media.lessThan('medium')`
            background-image: linear-gradient(
              0deg,
              ${theme.colors.secondWhite} 83.3%,
              rgba(255, 255, 255, 1) 50%
            );
          `}
        `
    : css`
          background: ${theme.colors.bg_contraste};
        `)}
`;
export const Wrapper = styled.div`
  width: 100%;
  max-width: 128rem;
  padding: 5rem 0;
  margin: 0 auto;
`;
export const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  transition: all 0.3s ease-out;

  ${media.lessThan('medium')`
    padding: 0 1.6rem;
    margin-bottom: 2rem;  
  `}
`;
export const SmallTitle = styled.span`
  ${({ theme }) => css`
    margin-bottom: 1rem;
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.medium};
    text-transform: uppercase;
    color: ${theme.font.colors.primary};
    letter-spacing: 0.2rem;
  `}
`;
export const Title = styled.h1`
  ${({ theme, size }) => css`
    font-size: ${theme.font.sizes.xxxlarge};
    font-family: ${theme.font.bold};
    color: ${theme.font.colors.primary};

    ${media.greaterThan('medium')`
      font-size: ${size};
    `}
  `}
`;
export const Description = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
    color: ${theme.colors.blackFonts};
    line-height: 1.2;

    ${media.greaterThan('medium')`
      font-size: ${theme.font.sizes.large};
    `}
  `}
`;
export const IframeWrapper = styled.div`
  width: 100%;
  max-width: 127.8rem;
  margin-top: 5rem;
  margin-bottom: 16rem;
  height: 34rem;

  ${media.greaterThan('medium')`
    padding: 0 1.6rem;
    height: 60rem;
  `}
`;
export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 0;

  ${media.greaterThan('medium')`
    border-radius: 0.8rem; 
  `}
`;

export const DiferecialWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 1.6rem;
    margin-bottom: 16rem;
    ${Title} {
      font-size: ${theme.font.sizes.xxxlarge};
      text-align: center;
      width: 100%;
      margin: 0 auto;
      display: block;

      ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.xxlarge};
      `}
    }
  `}
`;

export const Caracteristics = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
`;

export const SliderBox = styled.div`
  width: 100%;
  max-width: 136rem;

  ${media.greaterThan('medium')`
    margin-left: auto;  
    margin-right: 0;
  `}
`;

const bgModifier = {
  customColor: (theme) => (theme.name === 'normal'
    ? `url(${bgSlider}),linear-gradient(180deg, #00205b 0%, #001843 100%)`
    : '#000'),
};

export const SliderWrapper = styled.div`
  ${({ theme }) => css`
    padding: 5rem 0;
    width: 100%;

    background: ${bgModifier.customColor(theme)};
    background-repeat: no-repeat;
    background-position-x: 0;
    background-size: contain;

    .image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .slick-list .slick-slide > div {
      margin-left: 0.3rem;
    }

    [data-index].slick-slide .image ${TitleWrapper} {
      opacity: 0;
    }
    [data-index].slick-current .image ${TitleWrapper} {
      opacity: 1;
    }

    [data-index] {
      transition: all 0.3s ease;
    }

    [data-index] img {
      transition: all 0.3s ease;

      opacity: 0.3;
    }

    div.slick-current div img {
      opacity: 1;
    }
  `}
`;

export const InfoBox = styled.div`
  width: 100%;
  max-width: 200rem;
  margin-top: 3rem;
  margin-right: 0;
  margin-left: auto;

  .button__wrapper {
    margin-top: 2rem;
    max-width: 136rem;
    margin-left: auto;

    ${media.lessThan('medium')`
      padding: 0 1.6rem;
    `}
  }
`;
export const TitleInfo = styled.h1`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.font.colors.white};
    font-size: 3.6rem;
  `}
`;
export const DescriptionInfo = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.white};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    display: block;
    margin-top: 2rem;
    line-height: 2.8rem;
  `}
`;

export const Button = styled.button`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          width: 8.8rem;
          height: 5.4rem;
          border: 2px solid ${theme.colors.secondary};
          color: ${theme.colors.secondary};
          background: transparent;
          border-radius: ${theme.border.radius};

          & + button {
            margin-left: 1rem;
          }
        `
    : css`
          width: 8.8rem;
          height: 5.4rem;
          border: 2px solid ${theme.colors.globalYellow};
          color: ${theme.colors.globalYellow};
          background: transparent;
          border-radius: ${theme.border.radius};

          & + button {
            margin-left: 1rem;
          }
        `)}
`;
