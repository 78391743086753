import React from 'react';
import * as S from './styles';

export default function Agenda() {
  return (
    <S.Wrapper>
      <S.Title>Agende sua consulta no Centro de Resolução de Cardiologia:</S.Title>
      <S.Number>(51) 2312.1505</S.Number>
    </S.Wrapper>
  );
}
