import React from 'react';

import SymptomsCard from '../SymptomsCard';

import * as S from './styles';

import termometro from '../../assets/infoCovid/termometro.svg';
import cabeca from '../../assets/infoCovid/cabeca.svg';
import pulmao from '../../assets/infoCovid/pulmao.svg';
import triste from '../../assets/infoCovid/triste.svg';

const Symptoms = () => (
  <S.Container>
    <S.Wrapper>
      <S.TitleWrapper>
        <h1 className="title">Conheça os sintomas</h1>
      </S.TitleWrapper>
      <S.GridWrapper>
        <SymptomsCard
          color="turquoise"
          description="Febre igual ou acima de 38º"
          image={termometro}
        />

        <SymptomsCard color="rose" description="Falta de ar" image={cabeca} />

        <SymptomsCard
          color="lilac"
          description="Dor no peito ao respirar"
          image={pulmao}
        />

        <SymptomsCard
          color="poloBlue"
          description="Piora progressiva no estado geral"
          image={triste}
        />
      </S.GridWrapper>
      <S.CardInformation>
        <div className="wrapper">
          <h3>Se você apresentar algum sintoma grave, não saia de casa.</h3>
          <p>
            Ligue para
            {' '}
            <span className="bold"> 0800.888.9599</span>
            {' '}
            e receba a
            orientação adequada.
          </p>
        </div>
      </S.CardInformation>
    </S.Wrapper>
  </S.Container>
);

export default Symptoms;
