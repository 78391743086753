import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.bg_contraste};
  `}
`;
export const Wrapper = styled.div`
  ${({ theme }) => css`
    .wrapper_1 {
      margin-top: 8.8rem;
      max-width: 81.7rem;
    }

    ${media.greaterThan('medium')`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 80vh;


    .wrapper_1 {
      width: 100%;
      margin-top: 2rem;
      margin-left: auto;
      margin-right: 0;
      overflow: auto;
      ::-webkit-scrollbar {
        width: .8rem;
      }

      ::-webkit-scrollbar-thumb {
        background: ${
  theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary
};
      }
    }
  `}
  `}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  max-width: 128rem;
  padding: 0 1.6rem;
  margin: 0 auto 3rem;
`;
export const SmallTitle = styled.span`
  ${({ theme }) => css`
    margin-bottom: 1rem;
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.medium};
    text-transform: uppercase;
    color: ${theme.font.colors.primary};
  `}
`;
export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.extraLarge};
    font-family: ${theme.font.bold};
    color: ${theme.font.colors.primary};
    display: block;
    max-width: 78.8rem;

    ${media.greaterThan('medium')`
      font-size: ${theme.font.sizes.xextraLarge};
    `}
  `}
`;
export const Description = styled.p`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.blackFonts};
    line-height: 1.2;
    max-width: 78.8rem;
    line-height: 2.8rem;

    ${media.greaterThan('medium')`
      font-size: ${theme.font.sizes.medium};
    `}
  `}
`;

export const MapWrapper = styled.div`
  display: none;
  .leaflet-container {
    width: 100%;
    height: 80vh;
  }

  ${media.greaterThan('medium')`
    display: block;
  `}
`;
