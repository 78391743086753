import styled, { css } from 'styled-components';
import { Form as FormikForm } from 'formik';
import media from 'styled-media-query';

import banner from '../../assets/infoCovid/bannerOuvidoria.svg';

export const Container = styled.div`
  ${({ theme }) => css`
    background: #fff url(${banner});
    background-repeat: no-repeat;
    background-position-x: 100%;
    height: 100%;

    .button__wrapper {
      width: 100%;
      max-width: ${theme.maxwidth};
      display: flex;
      justify-content: center;
      padding-top: 8rem;
      margin-bottom: 24rem;

      ${media.lessThan('medium')`
        margin-bottom: 7.1rem;
        padding-top: 4rem;
      `}
    }
  `}
`;
export const Wrapper = styled.main`
  ${({ theme }) => css`
    max-width: ${theme.maxwidth};
    margin: 8rem auto 0;
    width: 100%;
  `}
`;
export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
    max-width: ${theme.maxwidth};
    width: 100%;
    margin: 0 auto 7.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ajust__sizes .title {
      font-family: ${theme.font.bold};
      color: ${theme.colors.primary};
      font-size: ${theme.font.sizes.xxxlarge};
      margin-bottom: ${theme.spacings.xsmall};

      ${media.greaterThan('medium')`
        width: 88rem;
        font-size: 4.2rem;
        .ajust__sizes {
          width: 79rem;
        }
      `}
    }
  `}
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.blackFonts};
    margin-bottom: ${theme.spacings.large};
    width: 100%;
    max-width: 88rem;
  `}
`;

export const FormWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
  `}
`;
export const FormLabel = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.bold};
    font-size: 2.4rem;
    color: ${theme.colors.primary};
    margin-bottom: 2rem;
  `}
`;
export const Form = styled(FormikForm)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    max-width: ${theme.maxwidth};
    flex-direction: column;

    ${media.greaterThan('medium')`
      flex-direction: row;
      align-items: flex-end;
   `};
  `}
`;
export const InputBox = styled.div`
  flex: 1;

  ${media.lessThan('medium')`
    margin-bottom: 3.2rem;
  `};

  ${media.greaterThan('medium')`
    & + div {
      margin-left: 1rem;
    }

    & + button {
      margin-left: 2rem;
    }
  `};
`;

export const IconButton = styled.div``;

export const SubmitButton = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    margin-top: 3.2rem;
    width: 100%;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 0.4rem;

    ${media.greaterThan('medium')`
      max-width: 31rem;
      margin-top: 0;
    `};

    span {
      color: white;
      font-size: ${theme.font.sizes.large};
    }

    ${IconButton} + span {
      margin-left: 2rem;
    }
  `}
`;

export const CardResult = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
  `}
`;

export const WrapperResults = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
    max-width: ${theme.maxwidth};
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  `}
`;
export const GridContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.maxwidth};
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, minmax(160px, 320px));
    grid-gap: 1rem;

    ${media.lessThan('medium')`
      grid-template-columns: 1fr;
    `}
  `}
`;
