import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import * as S from './styles';

function ShareComponent({ alignment }) {
  return (
    <S.Wrapper alignment={alignment}>
      <S.TextWrapper>
        <span>Compartilhe:</span>
      </S.TextWrapper>
      <S.SocialShareWrapper>
        <S.Item>
          <FacebookShareButton url={window.location.href}>
            <FaFacebookF size={20} />
          </FacebookShareButton>
        </S.Item>
        <S.Item>
          <TwitterShareButton url={window.location.href}>
            <FaTwitter size={20} />
          </TwitterShareButton>
        </S.Item>
        <S.Item>
          <LinkedinShareButton url={window.location.href}>
            <FaLinkedinIn size={20} />
          </LinkedinShareButton>
        </S.Item>
        <S.Item>
          <EmailShareButton url={window.location.href}>
            <MdEmail size={20} />
          </EmailShareButton>
        </S.Item>
      </S.SocialShareWrapper>
    </S.Wrapper>
  );
}

export default ShareComponent;
