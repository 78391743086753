import React from 'react';

import * as S from './styles';

const CardEspecialidades = ({ image, title, description }) => (
  <S.Container image={image}>
    <S.Title>{title}</S.Title>
    <S.Description>{description}</S.Description>
  </S.Container>
);

export default CardEspecialidades;
