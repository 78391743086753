import React from 'react';
import CardServices from '../CardServices';

import service from '../../assets/services/services.png';
import covid from '../../assets/services/covid.png';
import procedimentos from '../../assets/services/procedimentos.png';

import { Container } from './styles';

const Especification = () => (
  <Container>
    <CardServices
      especification
      title="+1500"
      description="funcionarios em atividade"
      url="#"
      backgroundImage={service}
    />
    <CardServices
      especification
      title="1043 horas"
      description="de treinamento"
      url="#"
      backgroundImage={covid}
    />
    <CardServices
      especification
      title="1100"
      description="testes realizados"
      url="#"
      backgroundImage={procedimentos}
    />
  </Container>
);

export default Especification;
