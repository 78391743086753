import styled, { css } from 'styled-components';
import { Link as LinkReactDom } from 'react-router-dom';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme, backgroundImg }) => css`
    background: url(${backgroundImg});
    position: relative;
    width: 100%;
    height: 44.1rem;
    display: flex;
    background-position: center center;
    background-repeat: no-repeat;
    justify-content: flex-start;
    align-items: flex-end;
    background-size: cover;
    padding: 11rem ${theme.spacings.small};

    ${media.greaterThan('medium')`
      width: 100%;
      height: 100%;
    `}
  `}
`;
export const Content = styled.div`
  z-index: 2;
  width: 100%;
`;
export const Link = styled(LinkReactDom)`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};

    text-transform: uppercase;
    font-family: ${theme.font.medium};
    position: relative;
    letter-spacing: 0.2rem;
    margin-top: 1.6rem;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      border-bottom: 0.4rem solid
        ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};

      left: 0;
      bottom: -0.6rem;
      width: 100%;
      margin-top: 0.6rem;
    }
  `}
`;
export const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
`;
