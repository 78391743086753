import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import { useGuidanceFilter } from '../../../../hooks/useGuidanceFilter';

import MediaMatch from '../../../../components/MediaMatch';
import { ButtonOutlined } from '../../../../components/Button/modules';

import * as S from './styles';

export default function Filter() {
  const {
    openMenuFilter,
    setOpenMenuFilter,
    filterOptions,
    toggleSelected,
    selected,
    handleFilter,
  } = useGuidanceFilter();

  const closeMenu = () => setOpenMenuFilter(false);
  const openMenu = () => setOpenMenuFilter(true);

  return (
    <>
      <MediaMatch lessThan="medium">
        <S.Wrapper>
          <S.ButtonWrapper>
            <S.Button onClick={openMenu}>
              <div className="filter__text">
                <span className="nouppercase">{Object.keys(selected)}</span>
              </div>
              <div className="icon">
                <IoIosArrowForward size={20} />
              </div>
            </S.Button>
          </S.ButtonWrapper>
          <S.MenuFilter isOpen={openMenuFilter}>
            <S.MenuHeader>
              <S.CloseButton onClick={closeMenu}>
                <IoIosArrowForward size={25} />
              </S.CloseButton>
            </S.MenuHeader>
            <S.MenuBody>
              <S.MenuBodyHeader />
              <S.MenuFilterWrapper>
                {filterOptions.map((filter) => (
                  <a href={`#${filter}`}>
                    <S.MenuOption
                      selected={selected[filter]}
                      onClick={() => toggleSelected(filter)}
                      key={filter}
                    >
                      {filter}
                    </S.MenuOption>
                  </a>
                ))}
              </S.MenuFilterWrapper>
            </S.MenuBody>
            <S.ButtonWrapper>
              <ButtonOutlined
                onClick={handleFilter}
                size="fullsize"
                label="Aplicar"
              />
            </S.ButtonWrapper>
          </S.MenuFilter>
        </S.Wrapper>
      </MediaMatch>
      <MediaMatch greaterThan="medium">
        <S.WebWrapper>
          <S.MenuBody>
            <S.MenuFilterWrapper>
              {filterOptions.map((filter) => (
                <a href={`#${filter}`}>
                  <S.MenuOption
                    selected={selected[filter]}
                    onClick={() => {
                      toggleSelected(filter);
                      handleFilter();
                    }}
                    key={filter}
                  >
                    {filter}
                  </S.MenuOption>
                </a>
              ))}
            </S.MenuFilterWrapper>
          </S.MenuBody>
        </S.WebWrapper>
      </MediaMatch>
    </>
  );
}
