import React from 'react';

import GlobalContainer from '../../components/_layout/Container';
import { Description, Title } from '../../components/TextComponents';
import { ButtonOutlined } from '../../components/Button/modules';
import Counter from '../../components/Counter';
import Form from './components/Form';
import CardClinic from './components/CardClinic';

import * as S from './styles';

import { data } from './utils/mock';
import virtual from '../../assets/institucional-assets/virtual.svg';
import SectionInfoCard from '../../components/SectionInfoCard';

export default function PlanosConvenios() {
  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TextWrapper>
          <Title
            label="Planos e Convênios"
            size={{ web: '4.2rem', mobile: '4.2rem' }}
            lineHeight={{ mobile: '4.5rem' }}
          />
          <Description
            label="Confira os planos e convênios atendidos pelo Hospital Humaniza. Para saber mais detalhes sobre serviços e procedimentos cobertos pelo seu plano, entre em contato com o seu convênio. "
            width="88rem"
          />
        </S.TextWrapper>
        <Form />
        <S.CardResult>
          <Counter length={data.length} />
        </S.CardResult>

        <S.ResultsWrapper>
          <S.Grid>
            {data.map((item) => (
              <CardClinic
                key={item.id}
                title={item.title}
                image={item.image}
                phone={item.phone}
                siteUrl={item.siteUrl}
              />
            ))}
          </S.Grid>
          <div className="button__wrapper">
            <ButtonOutlined size="large" label="Carregar mais" />
          </div>
        </S.ResultsWrapper>
      </S.Wrapper>
      <SectionInfoCard
        title="Confira a lista de espera para o atendimento"
        littleTitle="PrONTO ATENDIMENTO"
        description="Confira a lista de espera para o atendimento."
        background="blue"
        url="/"
        image={virtual}
        alignment="left"
      />
      <SectionInfoCard
        title="Confira a lista de espera para o atendimento"
        littleTitle="PrONTO ATENDIMENTO"
        description="Confira a lista de espera para o atendimento."
        background="white"
        url="/"
        image={virtual}
        alignment="right"
      />
    </GlobalContainer>
  );
}
