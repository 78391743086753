import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const containerModifiers = {
  color: (color, theme) => css`
    background: ${theme.colors[color]};
  `,
};

export const Container = styled.div`
  ${({ theme, color }) => css`
    width: 50%;
    height: 65rem !important;

    .slick-dots li button:before {
      font-size: 13px;
      padding-top: 20px;
    }

    ${!!color && containerModifiers.color(color, theme)}

    ${media.lessThan('medium')`
    height: 50rem !important;

    .slick-dots li button:before {
      padding-top: 0px;
    }
    `}
  `}

  ${media.lessThan('large')`
    width: 100%;
    height: 100%;
  `} 

  ${media.greaterThan('medium')`
    height: 50rem;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 12rem ${theme.spacings.xsmall};
    ${media.greaterThan('medium')`
      height: 50rem;
    `}

    ${media.lessThan('medium')`
      padding: 7rem ${theme.spacings.xsmall};
    `}
  `}
`;

export const TitleWrapper = styled.div`
  margin-left: 7rem;
  margin-top: 10rem;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 100%;
    max-width: 47.7rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  ${media.lessThan('medium')`
    margin-top: 0;
    margin-left: 0;
  `}
`;
export const Title = styled.h1`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.font.colors.white};
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.xxxlarge};
    margin-bottom: 2rem;
  `}
`;
export const Description = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    color: ${theme.font.colors.white};
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.spacings.xmedium};
  `}
`;

export const SliderWrapper = styled.div``;

export const SliderItem = styled.div`
  height: 100%;
`;

export const SliderItemImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`;
export const SliderItemTitle = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;

  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.xxlarge};
    text-align: center;
  `}
`;
