import styled, { css } from 'styled-components';

import media from 'styled-media-query';
import banner from '../../assets/infoCovid/bannerOuvidoria.svg';
import bg from '../../assets/bg.svg';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100%;

    .bg__2 {
      background: ${theme.colors.bg_contraste} url(${bg});
      background-repeat: no-repeat;

      ${media.lessThan('medium')`
        background: ${theme.colors.bg_contraste};
      `}
    }

    .bg {
      ${
  theme.name === 'normal'
    ? css`
              /* background-image: url(${banner}),
              linear-gradient(
                0deg,
                ${theme.colors.secondWhite} 72.5%,
                rgba(255, 255, 255, 1) 50%
              ); */
              background-repeat: no-repeat;
              background-position-x: 100%;
              background-position-y: 72%;
              height: 100%;
              background: ${theme.colors.secondWhite};

              ${media.lessThan('medium')`
              background: ${theme.colors.secondWhite};
            `}
            `
    : css`
              background: url(${banner}), #000;
              background-repeat: no-repeat;
              background-position-x: 100%;
              background-position-y: 72%;
              height: 100%;

              ${media.lessThan('medium')`
              background: #000;
            `}
            `
}
    }
    .cookieButton {
      margin-top: 2rem;
      margin-right: 4rem;
      height: 4rem;
      width: 16rem;
      padding: 0 3.8rem;
      background: transparent;
      font-weight: bold;
      color: ${theme.font.colors.primary};
      border: 0.1rem solid #009cde;
      border-radius: 0.5rem;

      :hover {
        background: ${theme.colors.secondary};
        color: #fff;
      }
      ${media.lessThan('medium')`
        margin-left: 9rem;
        margin-bottom: 1rem;
      `}
    }
    .link {
      color: ${theme.colors.primary};
      :hover {
        color: ${theme.colors.secondary};
      }
    }

    .alignBanner {
      z-index: 1;
      height: auto;
      margin-left: 5%
      width: 90%;
      background: ${theme.colors.whitePure};
      color: ${theme.colors.blackFonts};
      display: flex;
      justify-content: space-between;
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    margin-top: 2rem;
    margin-right: 4rem;
    height: 4rem;
    width: 16rem;
    padding: 0 3.8rem;
    background: transparent;
    font-weight: bold;
    color: ${theme.font.colors.primary};
    border: 0.1rem solid #009cde;
    border-radius: 0.5rem;

    :hover {
      background: ${theme.colors.secondary};
      color: #fff;
    }
  `}
`;
