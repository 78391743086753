import React from 'react';
import PropTypes from 'prop-types';
import { LittleTitle, Title } from '../../../../components/TextComponents';

import * as S from './styles';

export default function CardNewsLetter({
  date, title, img, itemID,
}) {
  return (
    <S.Wrapper backgroundImg={img}>
      <S.Content>
        <LittleTitle color="#fff" label={date} />
        <Title
          color="#fff"
          size={{ web: '3.6rem', mobile: '2.8rem' }}
          lineHeight={{ web: '4rem', mobile: '3rem' }}
          label={title}
        />

        <S.Link>Leia mais</S.Link>
      </S.Content>
      <S.Overlay />
    </S.Wrapper>
  );
}

CardNewsLetter.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  itemID: PropTypes.number.isRequired,
};
