import React from 'react';

import * as S from './styles';

const DiferencialCard = ({ icon, message }) => (
  <S.Container>
    <S.IconWrapper>
      <img src={icon} alt="" />
    </S.IconWrapper>
    <S.MessageWrapper>
      <S.Message>{message}</S.Message>
    </S.MessageWrapper>
  </S.Container>
);

export default DiferencialCard;
