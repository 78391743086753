import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.name === 'contraste' ? '#000' : '#fff'};
  `}
`;
export const Wrapper = styled.div`
  width: 100%;
  max-width: 77.6rem;
  margin: 0 auto;
  padding: 5rem 0;
`;
export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xxlarge};
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${theme.font.colors.primary};
    font-size: ${theme.font.sizes.large};
    font-family: ${theme.font.bold};
    text-align: center;

    ${media.lessThan('medium')`
      .title {
        width: 30rem;
      }
    `}
  `}
`;
export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  ${media.greaterThan('medium')`
    grid-gap: 1rem;  
  `}
`;
export const CardInformation = styled.div`
  ${({ theme }) => css`
    background: #f3f9fc;
    border-radius: ${theme.border.radius};

    .wrapper {
      width: 100%;
      margin-top: 2.7rem;
      padding: 2rem;

      p {
        margin-top: 2rem;
        color: ${theme.colors.primary};
        text-align: center;
        display: block;
        .bold {
          font-weight: bold;
        }
      }

      h3 {
        color: ${theme.colors.primary};
        text-align: center;
        display: block;
      }
    }
  `}
`;
