import React, { useState, useEffect } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import CookieConsent, { Cookies } from 'react-cookie-consent';
import moment from 'moment';
import AccessibilityMenu from '../../components/AccessibilityMenu';
import Menu from '../../components/Menu';
import Banner from '../../components/Banner';
import SliderCarouselMenu from '../../components/SliderCarouselMenu';
import MenuBanner from '../../components/MenuBanner';
import InfoCard from '../../components/InfoCard';

import ServiceOptions from '../../components/ServiceOptions';
import News from '../../components/News';
import Map from '../../components/Map';
import Highlights from '../../components/Highlights';
import Footer from '../../components/Footer';
import Especialidades from '../../components/Especialidades';
import { Container, Button } from './styles';
import theme from '../../styles/themes/theme';
import api from '../../services/api';
import PopUpHome from '../../components/PopUpHome';

export default function Home() {
  const [margin, setMargin] = useState('');
  const [marginLeft, setMarginLeft] = useState('');
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [windowWidth, setWindowWidth] = useState('10rem');
  const [ipUser, setIpUser] = useState('');
  const [date, setDate] = useState('');

  /*   useEffect(() => {
    setDate(moment().format('YYYY/MM/DD HH:mm'));
    fetch('https://api.ipify.org?format=jsonp?callback=?', {
      method: 'GET',
      headers: {},
    })
      .then((res) => res.text())
      .then((ip) => {
        Cookies.set('user_ip', ip, {
          expires: 100,
        });
        setIpUser(ip);
      });
  }, [ipUser]);

  async function componentDiMount() {
    try {
      // console.log(ipUser);
      // console.log(date);

      await api.post('/cookies/site-humaniza/', {
        ipUser,
        date,
      });
    } catch (error) {
      console.log(error);
    }
  }
 */
  useEffect(
    () => {
      setWindowWidth(window.innerWidth);
      if (windowWidth === 1536) {
        setMargin('12rem');
        setHeight('auto');
        setWidth('90%');
        setMarginLeft('5%');
      }

      if (windowWidth === 1440) {
        setMargin('12rem');
        setHeight('auto');
        setWidth('90%');
        setMarginLeft('5%');
      }
      if (windowWidth === 1366) {
        setMargin('4rem');
        setHeight('auto');
        setWidth('90%');
        setMarginLeft('5%');
      }
      if (windowWidth === 414) {
        setMargin('12rem');
        setHeight('auto');
        setMarginLeft('7.5%');
        setWidth('85%');
      }
      if (windowWidth === 411) {
        setMargin('12rem');
        setHeight('auto');
        setMarginLeft('7.5%');
        setWidth('85%');
      }
      if (windowWidth === 393) {
        setMargin('12rem');
      }
      if (windowWidth === 360) {
        setMargin('14rem');
        setHeight('18rem');
        setWidth('100%');
        setMarginLeft('0');
      }
      if (windowWidth === 375) {
        setMargin('14rem');
        setHeight('18rem');
        setWidth('100%');
        setMarginLeft('0');
      }
      if (windowWidth === 320) {
        setMargin('12rem');
        setHeight('auto');
        setWidth('100%');
        setMarginLeft('0');
      }

      // console.log(windowWidth);
    },
    [windowWidth],
    [margin],
  );

  // console.log(ipUser);

  return (
    <Container>
      <CookieConsent
        // onAccept={() => componentDiMount()}
        location="bottom"
        style={{
          background: theme.colors.whitePure,
          width,
          height,
          'margin-bottom': margin,
          'margin-left': marginLeft,
          color: theme.colors.blackFonts,
        }}
        disableButtonStyles
        buttonClasses="cookieButton"
        buttonText="Aceitar"
      >
        <p>
          Utilizamos cookies e outras tecnlogias para melhorar a sua
          experiência. Ao continuar navegando você concorda com a
        </p>
        nossa
        {'   '}
        <a className="link" href="/lgpd/politica-privacidade">
          Política de Privacidade.
        </a>
      </CookieConsent>
      <AccessibilityMenu />
      <Menu />
      <SliderCarouselMenu />
      <MenuBanner />
      <InfoCard />
      {/* <div className="bg__2">
        <Especialidades title="Especialidades médicas" />{' '}
      </div> */}
      {/* <ServiceOptions /> */}
      <div className="bg">
        {/* <News btnLabel="ver todas" /> */}
        <Map />
      </div>
      {/* <Highlights /> */}
      <Footer />
    </Container>
  );
}
