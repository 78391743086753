import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ul.slick-dots {
      bottom: 10px;

      & li button::before {
        content: '';
        background-color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : '#fff'};
        border-radius: 50%;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  `}
`;

export const NewsHighlights = styled.div`
  ${({ theme }) => css`
    display: grid;
    max-width: ${theme.maxwidth};
    width: 100%;
    margin: 6rem auto;
    border-radius: ${theme.border.radius};
    overflow: hidden;
    grid-gap: 0.4rem;

    grid-template-columns: repeat(2, 1fr);
  `}
`;

export const Item = styled.div`
  &:nth-child(1) {
    grid-row: 1/3;
  }
`;
