import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import bg from '../../assets/institucional-assets/bg-header.svg';
import bgMvv from '../../assets/institucional-assets/bg-mvv.svg';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.bg_contraste} url(${bg});
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 22rem;
    height: 100%;

    .bg-mvv {
      position: absolute;
      left: 0;
      height: 100%;
      width: 50%;

      background: url(${bgMvv});
      background-repeat: no-repeat;
      background-position-x: -3rem;
    }

    ${media.lessThan('medium')`
    background: ${theme.colors.bg_contraste};

    .bg-mvv {
      display: none;
    }
  `}
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 5rem 0 0 0;

    background: ${theme.colors.bg_contraste};
  `}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  max-width: 128rem;
  padding: 0 1.6rem;
  margin: 0 auto;
`;

export const SmallTitle = styled.span`
  ${({ theme }) => css`
    margin-bottom: 1rem;
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.medium};
    text-transform: uppercase;
    color: ${theme.font.colors.primary};
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.bold};
    font-size: 3.6rem;
    color: ${theme.font.colors.primary};
    display: block;

    ${media.greaterThan('medium')`
      font-size: 7.6rem;
    `}
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    margin-top: ${theme.spacings.xsmall};
    color: ${theme.colors.blackFonts};
    line-height: ${theme.spacings.xmedium};
    text-align: justify;
    te

    ${media.greaterThan('medium')`
      font-size: ${theme.font.sizes.large};
    `}
  `}
`;

export const Banner = styled.div`
  ${({ background, theme }) => css`
    margin-top: ${theme.spacings.xxxlarge};
    background: url(${background[1]});
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
    margin-bottom: ${theme.spacings.large};
    height: 34rem;
    width: 100%;

    ${media.lessThan('medium')`
      margin-top: ${theme.spacings.large};
    `}

    ${media.greaterThan('medium')`
    background: url(${background[0]});
    background-position: center 0;
    background-size: cover;
    height: 44rem;
    width: 100%;
    margin-bottom: ${theme.spacings.xxlarge};
    `}

    ${media.greaterThan('huge')`
    background-position: center 0;
    background-size: cover;
    height: 64rem;
    margin-bottom: ${theme.spacings.xxlarge};
    `}
  `}
`;

export const DescriptionWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
    max-width: 95.8rem;
    margin: 200px auto;

    ${media.lessThan('medium')`
      margin: 10rem auto;
    `}

    ${Description} {
      color: ${theme.font.colors.primary};
      font-size: 2.4rem;
      line-height: ${theme.spacings.large};
      text-align: center;
      text-indent: center;
      width: 90%;
      margin: 0 auto;

      ${media.lessThan('medium')`
      line-height: ${theme.font.sizes.xxxlarge};
      `}
    }
  `}
`;

export const CardsWrapper = styled.div`
  ${media.greaterThan('large')`
    display: flex;
    flex-direction: row;
  `}
`;

export const VirtualWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.secondWhite};
    width: 100%;
    margin: 0 auto;
    padding-bottom: ${theme.spacings.xxxlarge};

    display: flex;
    align-items: center;
    justify-content: center;

    ${media.lessThan('medium')`
      flex-direction: column-reverse;
    `}

    ${media.greaterThan('medium')`
    
      display: flex;
      align-items: center;
      
    `}

    ${TitleWrapper} {
      max-width: 60rem;
      width: 100%;
      order: 1;
      margin: 0;

      ${Description} {
        margin-bottom: 30px;
      }
    }
  `}
`;

export const ImageWrapper = styled.div`
  max-width: 63.7rem;
  width: 100%;
  margin-bottom: 20px;
  order: 2;

  img {
    width: 100%;
  }
`;
