import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    border-bottom: 0.2rem solid ${theme.colors.secondary};
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.08);
    min-width: 16.2rem;
    width: 100%;
  `}
`;
export const ImageWrapper = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;
export const DetailsWrapper = styled.div`
  padding: 1.6rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;

  span + span {
    margin-top: 0.5rem;
  }
`;
export const DoctorName = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.medium};
  `}
`;
export const DoctorCRM = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};
    text-transform: uppercase;
  `}
`;

export const Specialties = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 0.8rem;
`;
export const DetailsFields = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};
    font-size: ${theme.font.sizes.xxsmall};
    font-family: ${theme.font.medium};
  `}
`;
export const FielsdsOptions = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.font.sizes.small};
  `}
`;
export const Phone = styled.div``;

export const ButtonWrapper = styled.div`
  margin-top: 0.8rem;
`;
export const IconButton = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & + span {
      margin-left: 1rem;
    }
  `}
`;
export const Button = styled.button`
  ${({ theme }) => css`
    background: transparent;
    border: 0.2rem solid ${theme.colors.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9.2rem;
    height: 4rem;
    font-size: ${theme.font.sizes.medium};
    border-radius: 0.4rem;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.colors.primary};
      font-family: ${theme.font.bold};
      height: 100%;
    }
  `}
`;
