import styled, { css } from 'styled-components';

export const MapWrapper = styled.div`
  ${({ height }) => css`
    width: 100%;
    height: ${height};
    position: relative;

    .gm-style-iw {
      border-radius: 4px;
    }

    .gm-style-iw-d {
      padding: 2rem;
      overflow: auto !important;
    }

    .gm-style .gm-style-iw-c {
      padding: 0;
    }
  `}
`;

export const InfoDetails = styled.div``;
export const Name = styled.div``;
export const Cell = styled.div``;
export const Label = styled.label``;
