import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Banner = styled.div`
  ${({ backgrounds }) => css`
    background: url(${backgrounds.bannerMobile});
    height: 34rem;
    background-size: cover;
    background-position: center 0;

    ${media.greaterThan('small')`
      background: url(${backgrounds.bannerWeb}) no-repeat;
      background-size: cover;
      width: 100%;
      height: 70rem;
      background-position: center 0;

    `}
  `}
`;
