import React, { useRef } from 'react';
import SlickSlider from 'react-slick';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import GlobalContainer from '../../components/_layout/Container';
import DepoimentCard from '../../components/DepoimentCard';
import MediaMatch from '../../components/MediaMatch';
import Slider from '../../components/Slider';
import KnowMore from '../../components/KnowMore';
import {
  ImageBanner,
  VideoBanner,
} from '../../components/MediaContainer/modules';
import {
  Title,
  Description,
  SupportText,
} from '../../components/TextComponents';
import DoctorSection from './components/DoctorSection';
import Especialidades from '../../components/Especialidades';
import News from '../../components/News';

import bannerWeb from '../../assets/image_banner_oncologia/web.png';
import bannerMobile from '../../assets/image_banner_oncologia/mobile.png';
import virtual from '../../assets/image_banner_oncologia/foto-tratamento-onco.png';
import imageCourolcel_1 from '../../assets/estrutura-assets/slide/slide_1.png';
import imageCourolcel_2 from '../../assets/estrutura-assets/slide/slide_2.png';
import imageCourolcel_3 from '../../assets/estrutura-assets/slide/slide_3.png';
import SectionInfoCard from '../../components/SectionInfoCard';
import pdf from '../../assets/image_banner_oncologia/download-guia-paciente.pdf';
import { string, slideSettings } from './utils/mock';

import * as S from './styles';

export default function Oncologia() {
  const sliderRef = useRef(null);

  const next = () => sliderRef.current.slickNext();
  const prev = () => sliderRef.current.slickPrev();

  const settings = {
    arrows: false,
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 500,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
      },
    ],
  };

  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TitleWrapper>
          <Title
            size={{ web: '7.6rem', mobile: '4rem' }}
            label="Centro de Infusões e Oncologia"
          />
          <Description
            width="79rem"
            label="A oncologia é uma especialidade que trabalha em conjunto com a psicologia, a nutrição, a fisioterapia, a farmácia clínica e a enfermagem, bem como com outras especialidades médicas clínicas e cirúrgicas, para tratar pacientes com câncer."
          />
        </S.TitleWrapper>
      </S.Wrapper>
      <S.BannerWrapper>
        <ImageBanner backgrounds={{ bannerMobile, bannerWeb }} />
      </S.BannerWrapper>

      <S.Wrapper>
        <S.SupporTextWrapper>
          <SupportText text={string} />
        </S.SupporTextWrapper>
        <SectionInfoCard
          title="Confira as orientações para pacientes em tratamento oncológico"
          littleTitle="ORIENTAÇÕES PARA PACIENTES"
          description=""
          background="white"
          url="/download-guia-paciente"
          alignment="left"
          botao="Acesse"
          image={virtual}
        />
        {/* <S.BannerWrapper>
          <VideoBanner url="https://www.youtube.com/embed/h8yU14KDW7o" />
        </S.BannerWrapper> */}

        {/* <S.SlideWrapper>
          <MediaMatch lessThan="medium">
            <Slider {...slideSettings}>
              <DepoimentCard type="mobile" />
              <DepoimentCard type="mobile" />
            </Slider>
          </MediaMatch>

          <MediaMatch greaterThan="medium">
            <Slider {...slideSettings}>
              <DepoimentCard type="web" />
              <DepoimentCard type="web" />
            </Slider>
          </MediaMatch>
       </S.SlideWrapper> */}
      </S.Wrapper>
      <S.KnowMoreWrapper to="/o-hospital/modelo-assistencial">
        <KnowMore
          text="O tratamento dos sintomas é prioridade, cuidando do indivíduo como um
        todo e não da doença somente."
        />
      </S.KnowMoreWrapper>

      {/* <DoctorSection /> */}
      <S.SliderWrapper>
        <S.SliderBox>
          <SlickSlider {...settings} ref={sliderRef}>
            <div className="image">
              <img src={imageCourolcel_1} alt="" />
              <S.TitleWrapper>
                <S.TitleInfo>
                  Pronto atendimento e emergência 24 horas
                </S.TitleInfo>
                <S.DescriptionInfo>
                  Estrutura completa para atendimento de casos complexos.
                </S.DescriptionInfo>
              </S.TitleWrapper>
            </div>
            <div className="image">
              <img src={imageCourolcel_2} alt="" />

              <S.TitleWrapper>
                <S.TitleInfo>
                  Modernidade e tecnologia no centro cirúrgico e UTI
                </S.TitleInfo>
                <S.DescriptionInfo>
                  Instalações e equipamentos modernos, com corpo clínico
                  qualificado.
                </S.DescriptionInfo>
              </S.TitleWrapper>
            </div>
            <div className="image">
              <img src={imageCourolcel_3} alt="" />
              <S.TitleWrapper>
                <S.TitleInfo>
                  Maior conforto e atendimento completo na jornada do paciente
                </S.TitleInfo>
              </S.TitleWrapper>
            </div>
          </SlickSlider>
        </S.SliderBox>
        <S.InfoBox>
          <div className="button__wrapper">
            <S.Button onClick={() => prev()}>
              <IoIosArrowBack size={20} />
            </S.Button>
            <S.Button onClick={() => next()}>
              <IoIosArrowForward size={20} />
            </S.Button>
          </div>
        </S.InfoBox>
      </S.SliderWrapper>

      {/* <Especialidades title="Outras Especialidades" />
      <S.BgNews>
        <News btnFullsize btnLabel="Ver todas" />
      </S.BgNews> */}
    </GlobalContainer>
  );
}
