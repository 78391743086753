import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as TimerCardStyles from '../../../../components/TimerCard/styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 5rem 2.4rem;
    background: ${theme.name === 'contraste'
    ? 'none'
    : theme.colors.secondWhite};
    div.size {
      ${media.greaterThan('medium')`
      padding: 12rem 1rem;
    `}

      max-width: ${theme.maxwidth};
      width: 100%;
      margin: 0 auto;
    }
  `}
`;
export const TimersWrapper = styled.div`
  ${TimerCardStyles.Wrapper} + ${TimerCardStyles.Wrapper} {
    margin-top: 0.8rem;
  }
`;
export const TitleWrapper = styled.div`
  margin-bottom: 5rem;
`;
export const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  column-gap: 0.4rem;
  margin-bottom: 1.6rem;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr 1fr;  
  `}
`;
export const Item = styled.div`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.6rem;
    letter-spacing: 0.2rem;
    text-align: left;
    text-transform: uppercase;
  `}
`;
