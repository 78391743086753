import React, { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { FiChevronLeft } from 'react-icons/fi';
import { useFontContext } from '../../hooks/useUpdateSizeFonts';

import agenda from '../../assets/agenda.png';
import atendimento from '../../assets/atendimento.png';
// import emergencia from '../../assets/emergencia.png';
import exames from '../../assets/exames.png';
import ouvidoria from '../../assets/ouvidoria.png';
import MediaMatch from '../MediaMatch';

import * as S from './styles';

const MenuBanner = () => {
  const { fontSize } = useFontContext();
  const [openMobile, setOpenMobile] = useState(false);
  const [openDeskTop, setOpenDeskTop] = useState(true);
  const [initialPosition, setInitialPosition] = useState('710px');
  const [bottonPosition, setBottonPosition] = useState('');
  const [positionState, setPosition] = useState('absolute');
  const doc = document.documentElement;

  useEffect(() => {
    if (fontSize === 62.5) {
      setInitialPosition('710px');
    }
    if (fontSize === 63.5) {
      setInitialPosition('720px');
    }
    if (fontSize === 64.5) {
      setInitialPosition('730px');
    }
    if (fontSize === 65.5) {
      setInitialPosition('740px');
    }
    if (fontSize === 66.5) {
      setInitialPosition('750px');
    }
    if (fontSize === 67.5) {
      setInitialPosition('760px');
    }
    if (fontSize === 68.5) {
      setInitialPosition('770px');
    }
    if (fontSize === 69.5) {
      setInitialPosition('780px');
    }
    if (fontSize === 70.5) {
      setInitialPosition('790px');
    }
  }, [fontSize]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        const currentScrollPos = (100 * doc.scrollTop) / (doc.scrollHeight - doc.clientHeight);

        const percent = currentScrollPos.toFixed(0);

        if (percent <= 20) {
          setOpenDeskTop(true);
          setInitialPosition(() => {
            if (fontSize === 62.5) {
              setInitialPosition('710px');
            }
            if (fontSize === 63.5) {
              setInitialPosition('720px');
            }
            if (fontSize === 64.5) {
              setInitialPosition('730px');
            }
            if (fontSize === 65.5) {
              setInitialPosition('740px');
            }
            if (fontSize === 66.5) {
              setInitialPosition('750px');
            }
            if (fontSize === 67.5) {
              setInitialPosition('760px');
            }
            if (fontSize === 68.5) {
              setInitialPosition('770px');
            }
            if (fontSize === 69.5) {
              setInitialPosition('780px');
            }
            if (fontSize === 70.5) {
              setInitialPosition('790px');
            }
          });
          setBottonPosition('');
          setPosition('absolute');
        } else {
          setInitialPosition('');
          setBottonPosition('0px');
          setPosition('fixed');
          setOpenDeskTop(false);
        }

        if (percent >= 97) {
          setInitialPosition('');
          setBottonPosition(() => {
            if (fontSize === 62.5) {
              setBottonPosition('550px');
            }
            if (fontSize === 63.5) {
              setBottonPosition('550px');
            }
            if (fontSize === 64.5) {
              setBottonPosition('560px');
            }
            if (fontSize === 65.5) {
              setBottonPosition('570px');
            }
            if (fontSize === 66.5) {
              setBottonPosition('580px');
            }
            if (fontSize === 67.5) {
              setBottonPosition('590px');
            }
            if (fontSize === 68.5) {
              setBottonPosition('600px');
            }
            if (fontSize === 69.5) {
              setBottonPosition('610px');
            }
            if (fontSize === 70.5) {
              setBottonPosition('615px');
            }
          });
          setPosition('fixed');
        }
      };
    }
  }, [fontSize, doc.clientHeight, doc.scrollHeight, doc.scrollTop]);

  return (
    <S.Container>
      <MediaMatch greaterThan="large">
        <S.ContainerMenu
          style={{
            top: initialPosition,
            position: positionState,
            bottom: bottonPosition,
          }}
        >
          <S.OptionsFastMenuWrapper open={openDeskTop}>
            <S.LinkFastMenu to="#">
              <S.IconFastMenuWrapper>
                <img src={agenda} alt="" />
              </S.IconFastMenuWrapper>
              <div className="wrapperphone">
                <span>Agendar Consulta</span>
                <strong>(51) 2312-1505</strong>
              </div>
            </S.LinkFastMenu>

            <S.LinkFastMenu to="#">
              <S.IconFastMenuWrapper>
                <img src={agenda} alt="" />
              </S.IconFastMenuWrapper>
              <div className="wrapperphone">
                <span>Agendar Exames</span>
                <strong>(51) 2312-1505</strong>
              </div>
            </S.LinkFastMenu>

            <S.LinkFastMenu to="/resultado-de-exames">
              <S.IconFastMenuWrapper>
                <img src={exames} alt="" />
              </S.IconFastMenuWrapper>
              <span>Resultados dos Exames</span>
            </S.LinkFastMenu>

            {/* <S.LinkFastMenu to="/fale-conosco">
              <S.IconFastMenuWrapper>
                <img src={atendimento} alt="" />
              </S.IconFastMenuWrapper>
              <span>Fale Conosco</span>
            </S.LinkFastMenu>
 */}
            <S.ExternalLinkFastMenu href="https://atendimento.ccgsaude.com.br/ccg-saude/ouvidoria">
              <S.IconFastMenuWrapper>
                <img src={ouvidoria} alt="" />
              </S.IconFastMenuWrapper>
              <span>Ouvidoria</span>
            </S.ExternalLinkFastMenu>

            {/* <S.LinkFastMenu to="#">
              <S.IconFastMenuWrapper>
                <img src={atendimento} alt="" />
              </S.IconFastMenuWrapper>
              <span>Atendimento Online</span>
            </S.LinkFastMenu> */}
          </S.OptionsFastMenuWrapper>
          <S.FastMenu
            type="button"
            onClick={() => setOpenDeskTop(!openDeskTop)}
            openDesktop={openDeskTop}
          >
            <FiChevronLeft color="#fff" size={22} />
            <span>Acesso Rápido</span>
          </S.FastMenu>
        </S.ContainerMenu>
      </MediaMatch>
      {/* mobile */}
      <MediaMatch lessThan="large">
        <S.ContainerMenu>
          <S.BodyMenuWrapper>
            <S.FastMenu onClick={() => setOpenMobile(!openMobile)}>
              Acesso Rápido
              {openMobile ? (
                <IoIosArrowDown color="#fff" size={20} />
              ) : (
                <IoIosArrowUp color="#fff" size={20} />
              )}
            </S.FastMenu>
            <S.OptionsFastMenuWrapper open={openMobile}>
              <S.ExternalLinkFastMenu href="tel:5123121505">
                <S.IconFastMenuWrapper>
                  <img src={agenda} alt="" />
                </S.IconFastMenuWrapper>
                <span>Agendar Consulta</span>
              </S.ExternalLinkFastMenu>

              <S.ExternalLinkFastMenu href="tel:5130304050">
                <S.IconFastMenuWrapper>
                  <img src={agenda} alt="" />
                </S.IconFastMenuWrapper>
                <span>Agendar Exames</span>
              </S.ExternalLinkFastMenu>

              <S.LinkFastMenu href="/resultado-de-exames">
                <S.IconFastMenuWrapper>
                  <img src={exames} alt="" />
                </S.IconFastMenuWrapper>
                <span>Resultados dos Exames</span>
              </S.LinkFastMenu>

              {/* <S.LinkFastMenu to="/fale-conosco">
                <S.IconFastMenuWrapper>
                  <img src={atendimento} alt="" />
                </S.IconFastMenuWrapper>
                <span>Fale Conosco</span>
              </S.LinkFastMenu> */}

              <S.ExternalLinkFastMenu to="https://atendimento.ccgsaude.com.br/ccg-saude/ouvidoria">
                <S.IconFastMenuWrapper>
                  <img src={ouvidoria} alt="" />
                </S.IconFastMenuWrapper>
                <span>Ouvidoria</span>
              </S.ExternalLinkFastMenu>

              {/* <S.LinkFastMenu to="#">
                <S.IconFastMenuWrapper>
                  <img src={atendimento} alt="" />
                </S.IconFastMenuWrapper>
                <span>Atendimento Online</span>
              </S.LinkFastMenu> */}
            </S.OptionsFastMenuWrapper>
          </S.BodyMenuWrapper>
        </S.ContainerMenu>
      </MediaMatch>
    </S.Container>
  );
};

export default MenuBanner;
