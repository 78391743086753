import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.maxwidth};
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `}
`;

const buttonModifiers = {
  normal: (theme) => css`
    border: 0.1rem solid ${theme.colors.secondary};
    div.icon {
      color: ${theme.colors.primary};
    }
  `,
  contraste: (theme) => css`
    color: ${theme.colors.globalYellow};
    border: 0.1rem solid ${theme.colors.globalYellow};
  `,
};

export const Button = styled.button`
  ${({ theme }) => css`
    height: 4.8rem;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    padding: 0 ${theme.spacings.small};
    border-radius: ${theme.border.radius};
    font-family: ${theme.font.family};
    justify-content: space-between;

    span.uppercase {
      font-size: ${theme.font.sizes.small};
      text-transform: uppercase;
      color: ${theme.font.colors.primary};
      letter-spacing: 0.05rem;
      font-weight: bold;
    }
    span.nouppercase {
      font-size: ${theme.font.sizes.small};
      color: ${theme.font.colors.primary};
      letter-spacing: 0.05rem;
      font-weight: bold;
    }

    div.icon {
      height: 4.8rem;
      display: flex;
      align-items: center;
    }

    ${buttonModifiers[theme.name](theme)}
  `}
`;

const menuFilterModifiers = {
  normal: () => css`
    background: #fff;
  `,
  contraste: () => css`
    background: #000;
  `,
};

export const MenuFilter = styled.div`
  ${({ theme, isOpen }) => css`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 40;
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;

    ${ButtonWrapper} {
      padding: 0 ${theme.spacings.small} 3.2rem;
    }

    ${menuFilterModifiers[theme.name](theme)};
  `}
`;

const modifiers = {
  normal: (theme) => css`
    svg {
      color: ${theme.colors.primary};
    }
  `,
  contraste: (theme) => css`
    svg {
      color: ${theme.colors.globalYellow};
    }
  `,
};

export const MenuHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 8.8rem;
    width: 100%;
    box-shadow: 0 4px 20px rgba(123, 169, 188, 0.16);
    align-items: center;
    padding: 0 ${theme.spacings.small};
  `}
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    height: 4rem;
    width: 4rem;
    justify-content: flex-start;
    align-items: center;
    border: 0;
    background: transparent;

    ${modifiers[theme.name](theme)};

    svg {
      transform: rotateY(180deg);
    }
  `}
`;
export const MenuBody = styled.div`
  ${({ theme }) => css`
    padding: 3.2rem ${theme.spacings.small} 0;
    flex: 1;
  `}
`;
export const MenuBodyHeader = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};

    span {
      color: ${theme.font.colors.primary};
      text-transform: uppercase;
      font-weight: bold;
    }
  `}
`;
export const MenuFilterWrapper = styled.div`
  &:first-child {
    margin-right: 1.6rem;
  }
`;

const menuOptionsModifiers = {
  selected: (theme) => css`
    background: ${theme.colors.secondary};
    border: 0;
    color: #fff;
  `,
};

const optionModifiers = {
  normal: (theme) => css`
    border: 0.1rem solid ${theme.colors.secondWhite};
  `,
  contraste: (theme, selected) => css`
    border: 0.1rem solid ${theme.colors.globalYellow};

    ${!!selected
    && css`
      background: ${theme.colors.globalYellow};
      color: #000;
    `};
  `,
};

export const MenuOption = styled.button`
  ${({ theme, selected }) => css`
    height: 4.8rem;
    padding: 0 3.8rem;
    background: transparent;
    border-radius: ${theme.border.radius};
    font-weight: bold;
    color: ${theme.font.colors.primary};

    margin-right: 1.6rem;
    margin-bottom: 1.6rem;

    ${!!selected && menuOptionsModifiers.selected(theme)}
    ${optionModifiers[theme.name](theme, selected)};
  `}
`;

export const WebWrapper = styled.div`
  display: flex;
  align-items: center;

  ${MenuBody} {
    padding: 0;

    ${MenuOption} {
      margin: 0 0.8rem 0 0;
    }
  }
`;
export const FilterBy = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3.7rem;

    span.uppercase {
      display: block;
      font-size: ${theme.font.sizes.small};
      text-transform: uppercase;
      color: ${theme.font.colors.primary};
      letter-spacing: 0.05rem;
      font-weight: bold;
    }
  `}
`;
