import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.maxwidth};
    width: 100%;
    margin: 0 auto;

    ${media.greaterThan('medium')`
      padding: 8rem 1rem 0;
    `}
  `}
  .buttons {
    justify-content: space-between;
  }
`;

export const SupporTextWrapper = styled.div`
  margin-left: 3rem;
  ${({ theme }) => css`
    ul {
      align-items: flex-start !important;
    }

    li {
      color: ${theme.colors.secondary};
    }
    p {
      color: ${theme.font.colors.primary};
      font-family: ${theme.font.light};
    }
    .destaque {
      color: ${theme.font.colors.primary};
      font-family: ${theme.font.bold};
      text-decoration: none;
    }
  `}
  margin-bottom: 8rem;
  ${media.greaterThan('medium')`
    margin-bottom: 12rem;
    margin-left: 6rem;
 
  `}
`;

export const TextWrapper = styled.div`
  margin-bottom: 5.5rem;
  margin-left: 4rem;
`;

const buttonModifiers = {
  normal: (theme) => css`
    border: 0.1rem solid ${theme.colors.secondary};
    div.icon {
      color: ${theme.colors.primary};
    }
  `,
  contraste: (theme) => css`
    color: ${theme.colors.globalYellow};
    border: 0.1rem solid ${theme.colors.globalYellow};
  `,
};

export const Button = styled.button`
  ${({ theme }) => css`
    height: 4.8rem;
    width: 20%;
    background: transparent;
    display: flex;
    align-items: center;
    padding: 0 ${theme.spacings.small};
    border-radius: ${theme.border.radius};
    font-family: ${theme.font.family};
    justify-content: space-between;

    span.uppercase {
      font-size: ${theme.font.sizes.small};
      text-transform: uppercase;
      color: ${theme.font.colors.primary};
      letter-spacing: 0.05rem;
      font-weight: bold;
    }
    span.nouppercase {
      font-size: ${theme.font.sizes.small};
      color: ${theme.font.colors.primary};
      letter-spacing: 0.05rem;
      font-weight: bold;
    }

    div.icon {
      height: 4.8rem;
      display: flex;
      align-items: center;
    }

    ${buttonModifiers[theme.name](theme)}
  `}
`;

export const Grid = styled.div`
  width: 100%;
  margin-bottom: 4rem;
  ${media.greaterThan('medium')`
    width: 80%;
    margin-left: 4rem;
  `}
`;

export const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.font.colors.secondary};
    padding-left: 8rem;
  `}
`;

export const LinkWrapper = styled.div`
  margin-bottom: 8rem;
`;
