const string = `<p>No último andar do Hospital, projetamos um espaço amplo, acolhedor e organizado, capaz de receber o Centro Cirúrgico e o Centro de Tratamento Intensivo juntos no mesmo andar, o que facilita o deslocamento e a assistência de pacientes em situações graves. </p><br>
                <p>O Centro Cirúrgico atende a todas as especialidades do Hospital e é composto por dez salas cirúrgicas, com destaque para uma sala de hemodinâmica - onde são realizados diversos procedimentos minimamente invasivos das áreas neurológica, cardíaca e vascular. Completam a estrutura 13 leitos de recuperação de anestesia e uma farmácia satélite. Já o Centro de Terapia Intensiva, com equipamentos Drager de última geração, conta com 20 leitos de CTI individualizados, sendo dois de isolamento.</p><br>
                `;

const slideSettings = {
  slidesToShow: 1,
  arrows: false,
  infinite: true,
  dots: true,
  lazyLoad: 'ondemand',
  speed: 500,
  autoplaySpeed: 4000,
  initialSlide: 0,
  autoplay: true,
};

export { string, slideSettings };
