import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';

import GlobalStyles from './styles/global';
import Routes from './routes';
import store from './store';

import { useTheme } from './hooks/useTheme';

export default function App() {
  const { theme } = useTheme();

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Routes />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}
