import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 84.6rem;
  width: 100%;
  margin: 0 auto;
`;
export const TextWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};

    span.text {
      display: block;
      font-size: 2.4rem;
      text-align: center;
      color: ${theme.font.colors.primary};
      line-height: 3.8rem;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
