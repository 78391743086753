import { Field } from 'formik';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Label = styled.label`
  ${({ theme, error }) => css`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none;
    pointer-events: none;
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.medium};
    color: ${error ? '#c7170a' : theme.font.colors.primary};
    border-bottom: 1px solid ${error ? '#c7170a' : '#ddd'};

    .content__name {
      position: absolute;
      bottom: 0.5rem;
      transition: all 0.3s ease-in-out;
      text-transform: uppercase;
    }

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      bottom: -1px;
      border-bottom: 1px solid ${error ? '#c7170a' : theme.colors.secondary};
      transform: translateX(-100%);
      transition: all 0.5s ease-in-out;

      ${theme.name === 'contraste'
      && css`
        border-bottom: 1px solid
          ${error ? '#c7170a' : theme.colors.globalYellow};
      `}
    }
  `}
`;
export const Input = styled(Field)`
  ${({ theme }) => css`
    padding-top: 3rem;
    width: 100%;
    border: 0;
    background: transparent;
    color: ${theme.font.colors.primary};
    font-size: ${theme.font.sizes.large};

    ::placeholder {
      color: transparent;
    }

    &:focus + ${Label} .content__name,
    &:not(:placeholder-shown) + ${Label} .content__name {
      transform: translateY(-100%);
      left: 0;
      font-size: 1.4rem;
    }

    &:focus + ${Label}::after, &:not(:placeholder-shown) + ${Label}::after {
      transform: translateX(0);
    }
  `};
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: transparent;
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 0;
    bottom: 0;
    svg {
      color: ${theme.colors.primary};
    }
  `}
`;

export const InputWrapper = styled.div`
  ${({ display }) => css`
    width: 100%;
    display: ${display};
    ${media.greaterThan('medium')`
      ${({ size }) => css`
        width: ${size === 'true' && '45%'};
      `}
  `};
  `}
`;
