import styled, { css } from 'styled-components';

const buttonModifiers = {
  small: () => css`
    width: 14.3rem;
    height: 4.3rem;
  `,
  medium: () => css`
    width: 28.5rem;
    height: 4.8rem;
  `,
  large: () => css`
    width: 31.6rem;
    height: 4.8rem;
  `,
  fullsize: () => css`
    width: 100%;
    height: 4.8rem;
  `,
};

export const Button = styled.button`
  ${({ size, theme }) => css`
    background: transparent;
    border-radius: ${theme.border.radius};
    border: 2px solid
      ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};

    span {
      color: ${theme.font.colors.primary};
      font-size: ${theme.font.sizes.small};
      font-weight: bold;
    }

    ${!!size && buttonModifiers[size]()};
  `}
`;
