export const news = [
  {
    id: 1,
    date: '06 de dezembro',
    color: 'blue',
    description:
      'Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital',
    image:
      'https://images.unsplash.com/photo-1617129602319-774ea884bcb7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80',
  },
  {
    id: 2,
    date: '06 de dezembro',
    color: 'blue',
    description:
      'Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital',
    image:
      'https://images.unsplash.com/photo-1617129602319-774ea884bcb7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80',
  },
  {
    id: 3,
    date: '06 de dezembro',
    color: 'blue',
    description:
      'Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital',
    image:
      'https://images.unsplash.com/photo-1617129602319-774ea884bcb7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80',
  },
  {
    id: 4,
    date: '06 de dezembro',
    color: 'blue',
    description:
      'Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital',
    image:
      'https://images.unsplash.com/photo-1617129602319-774ea884bcb7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80',
  },
  {
    id: 5,
    date: '06 de dezembro',
    color: 'rose',
    description:
      'Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital',
    image:
      'https://images.unsplash.com/photo-1617129602319-774ea884bcb7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80',
  },
  {
    id: 6,
    date: '06 de dezembro',
    color: 'blue',
    description:
      'Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital',
    image:
      'https://images.unsplash.com/photo-1617129602319-774ea884bcb7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80',
  },
  {
    id: 7,
    date: '06 de dezembro',
    color: 'poloBlue',
    description:
      'Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital',
    image:
      'https://images.unsplash.com/photo-1617129602319-774ea884bcb7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80',
  },
  {
    id: 8,
    date: '06 de dezembro',
    color: 'blue',
    description:
      'Hospital Humaniza, do CCG Saúde, tem início das atividades previsto para dezembro na Capital',
    image:
      'https://images.unsplash.com/photo-1617129602319-774ea884bcb7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80',
  },
];
