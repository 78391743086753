import React, {
  createContext, useContext, useEffect, useState,
} from 'react';

const FontContext = createContext({} || null);

function FontSizeProvider({ children }) {
  const elementHTML = document.querySelector('html');
  const [fontSize, setFontSize] = useState(62.5);

  function enlargeGlobalFonts() {
    const size = fontSize;

    if (fontSize >= 62.5 && fontSize <= 69.5) {
      setFontSize(size + 1);
    }
  }

  function decreaseGlobalFonts() {
    const size = fontSize;

    if (fontSize >= 63.5) {
      setFontSize(size - 1);
    }
  }

  useEffect(() => {
    elementHTML.style.fontSize = `${fontSize}%`;
  }, [elementHTML.style, fontSize]);

  return (
    <FontContext.Provider
      value={{ enlargeGlobalFonts, decreaseGlobalFonts, fontSize }}
    >
      {children}
    </FontContext.Provider>
  );
}

function useFontContext() {
  const context = useContext(FontContext);

  if (!context) {
    throw new Error('The hook useFontContext must be used with FontContext');
  }

  return context;
}

export { useFontContext, FontSizeProvider };
