import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

export default function ImageBanner({ backgrounds }) {
  return <S.Banner backgrounds={backgrounds} />;
}

ImageBanner.propTypes = {
  backgrounds: PropTypes.shape({
    bannerWeb: PropTypes.string,
    bannerMobile: PropTypes.string,
  }).isRequired,
};
