import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import banner from '../../assets/banner-home.jpg';
import graph from '../../assets/home/asset-banner.svg';

export const Container = styled.div`
  width: 100%;
  height: 63.6rem;
`;

export const Banner = styled.div`
  ${({ theme }) => css`
    background: url(${banner}) center;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    padding: ${theme.spacings.large};
    position: relative;

    .bg-banner {
      position: absolute;
      top: 0;
      right: 0;
      background: transparent url(${graph});
      background-position-x: 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    ${media.greaterThan('medium')`
      background: url(${banner}) center;
      background-size: cover;
      height: 100%;
      background-repeat: no-repeat;
  `};
  `}
`;

export const LittleText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    margin-bottom: 1rem;
    line-height: 16px;
    font-family: ${theme.font.medium};
  `}
`;

export const MainText = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxextraLarge};
    margin-bottom: 1rem;
    font-family: ${theme.font.bold};

    ${media.lessThan('small')`
    font-size: ${theme.font.sizes.extraLarge};
    `}
  `}
`;

export const Descrption = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    margin-bottom: 2.4rem;
    text-align: justify;
    ${media.lessThan('small')`
      font-size: ${theme.font.sizes.medium};
    `};
  `}
`;

export const TitleBannerWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: ${theme.maxwidth};
    margin: 0 auto;

    ${LittleText}, ${MainText}, ${Descrption} {
      color: #fff;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.xextraLarge};
    ${media.lessThan('medium')`
        color: ${theme.colors.secondary};
    `}
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`;

export const MediumTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    text-transform: uppercase;
    font-size: ${theme.font.sizes.large};
    letter-spacing: 0.2rem;
    line-height: 1.6rem;
    font-family: ${theme.font.medium};
  `}
`;

export const Button = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.border.radius};
    border: 1px solid ${theme.colors.lilac};
    background: ${theme.colors.lilac};
    padding: ${theme.spacings.xsmall} ${theme.spacings.small};
    width: 100%;
    color: #fff;
    font-size: ${theme.font.sizes.medium};
    transition: all 0.3s ease;

    :hover {
      background: transparent;
      color: ${theme.colors.lilac};
    }

    ${media.greaterThan('medium')`
          width: 20rem;
        `}
  `}
`;
