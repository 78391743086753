import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

import MediaMatch from '../MediaMatch';

const NewsCard = ({
  date, color, description, image,
}) => (
  <S.Container color={color}>
    <MediaMatch greaterThan="large">
      <S.Image>
        <img src={image} alt="" />
      </S.Image>
    </MediaMatch>
    <S.Info>
      <S.Date color={color}>{date}</S.Date>
      <S.Description>{description}</S.Description>
    </S.Info>
  </S.Container>
);

NewsCard.propTypes = {
  date: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['blue', 'rose', 'poloBlue']).isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default NewsCard;
