import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
    ${({ display }) => css`
        justify-content: center;
        align-items: center;
        pointer-events: all;
        display: ${display ? 'flex' : 'none'};
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1000;
    `}

`;

export const Content = styled.div`
        width: auto;
        overflow: visible;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        pointer-events: all;
        margin: auto;

`;

export const PopUp = styled.div`
    ${({ theme }) => css`
        background:  #00205B;
        width: 900px;
        box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
        ${media.lessThan('medium')`
            width: 350px;
            h2 {
            font-size: 1rem;
            margin-top: 2rem;
        }

        `}


        h2 {
            color: #fff;
            font-size: 2rem;
            margin-left: 0;
            text-align: center;
            margin-top: 2rem;
        }

        div {
                font-size: 16px;
                padding: 30px;
                text-align: justify;

                span {
                    color: #fff;
                }

            }

    `}
`;

export const Icon = styled.div`
    float: right ;
    cursor: pointer;
`;
