export const text = `<p>Nos casos em que é necessário socorro imediato, nossa equipe de emergencistas entra em ação no
subsolo. Ali, fazemos os primeiros-socorros, e é por ali também que as ambulâncias acessam o Hospital.
Independente de como chegam e do momento em que chegam, o atendimento a emergências clinicas e
traumáticas é realizado seguindo protocolos e padrões internacionais.</p><br>
<p>Vinte e quatro horas por dia, os pacientes são recebidos por uma equipe multidisciplinar de profissionais
da saúde. Equipes médicas e de enfermagem muito qualificadas e que têm ao seu dispor equipamentos
de ponta para realizar suas funções. De acordo com a necessidade, cada caso é direcionado aos
andares das especialidades médicas correspondentes.</p><br>
<p>Toda a parte estrutural também foi muito bem planejada. Além dos leitos-padrão, o espaço conta
também com dois leitos de sala vermelha, destinados a pacientes que necessitam de cuidados e
vigilância intensivos. Além disso, a emergência tem acesso rápido a uma máquina de ressonância
magnética e outros equipamentos de imagem (tomografia e ultrassom).</p>`;

export const settings = {
  slidesToShow: 1,
  arrows: false,
  infinite: true,
  dots: true,
  lazyLoad: 'ondemand',
  speed: 500,
  autoplaySpeed: 4000,
  initialSlide: 0,
  autoplay: true,
};
