import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import AccessibilityMenu from '../../components/AccessibilityMenu';
import Menu from '../../components/Menu';
import GoogleMaps from '../../components/GoogleMaps';
import Footer from '../../components/Footer';
import Filter from '../../components/FiltroClinicas';
import arrayLocations from '../../utils/static/static';

import * as S from './styles';
import Breadcrumbs from '../../components/Breadcrumbs';

const Clinicas = () => {
  const location = useSelector((state) => state.location.location);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <AccessibilityMenu />
      <Menu />
      <S.Wrapper>
        <div className="wrapper_1">
          <Breadcrumbs />
          <S.TitleWrapper>
            <S.Title>Clínicas</S.Title>
            <S.Description>
              Com 21 unidades de atendimento em 12 cidades no sul do país, o CCG
              Saúde conta com ampla rede de suporte para todos os seus clientes.
              São clínicas e laboratórios sempre a disposição, prontos para
              oferecer a melhor solução em saúde.
            </S.Description>
          </S.TitleWrapper>
          <Filter />
        </div>
        <div className="wrapper_2">
          <S.MapWrapper>
            <GoogleMaps
              initialPosition={{ lat: -30.0282758, lng: -51.221474 }}
              height="80vh"
              arrayLocations={arrayLocations}
              marker={location}
            />
          </S.MapWrapper>
        </div>
      </S.Wrapper>
      <Footer />
    </S.Container>
  );
};

export default Clinicas;
