import React, { useEffect } from 'react';
import AccessibilityMenu from '../../components/AccessibilityMenu';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';

import * as S from './styles';
import Form from '../../components/FormOuvidoria';
import Breadcrumbs from '../../components/Breadcrumbs';
import ButtonOutlined from '../../components/Button/Outline';

const ResultadosExames = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <AccessibilityMenu />
      <Menu />
      <Breadcrumbs />

      <S.Wrapper>
        <S.TitleWrapper>
          {/* <S.Title>Ouvidoria</S.Title> */}
          <S.Description size="2rem" lineHeight="2.5rem">
            Com um clique você pode consular os resultados dos seus exames feitos no Hospital Humaniza.
          </S.Description>
        </S.TitleWrapper>
        <S.Row>
          <S.Col>
            Para resultados de exames <br/>
            realizados ate 30/04/2023
            <ButtonOutlined
              onClick={()=> window.open("http://optixone.com.br/dist/home.html", "_blank")}
              size="large"
              label="Consulte Aqui"
            />
          </S.Col>
          <S.Col>
            Para resultados de exames <br/>
            realizados a partir de 01/05/2023
            <ButtonOutlined
              onClick={()=> window.open("https://resultadoexame.ccgsaude.com.br/resultado-exame/autenticacaoCCG.xhtml", "_blank")}
              size="large"
              label="Consulte Aqui"
            />
          </S.Col>
        </S.Row>
      </S.Wrapper>
      <Footer />
    </S.Container>
  );
};

export default ResultadosExames;
