import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;
export const Fields = styled.div``;

export const Error = styled.span`
  ${({ theme }) => css`
    color: #c7170a;
    font-family: ${theme.font.medium};
    font-size: ${theme.font.sizes.small};
    display: block;
    margin-top: 1rem;
  `}
`;
