import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import banner from '../../assets/infoCovid/bannerOuvidoria.svg';

export const Container = styled.div`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          background: #fff url(${banner});

          ${media.lessThan('medium')`
             background: #fff;  
          `}

          background-repeat: no-repeat;
          background-position-x: 100%;
          height: 100%;
        `
    : css`
          background: #000 url(${banner});

          ${media.lessThan('medium')`
            background: #000;  
          `}

          background-repeat: no-repeat;
          background-position-x: 100%;
          height: 100%;
        `)}
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 96rem;
  margin: 8rem auto 0;
`;
export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
    margin-bottom: 5rem;
  `}
`;
export const SubTitleWrapper = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxlarge};
    font-family: ${theme.font.bold};
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
    margin-bottom: ${theme.spacings.xxsmall};
  `}
`;
export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxxlarge};
    font-family: ${theme.font.bold};
    color: ${theme.font.colors.primary};
    margin-bottom: ${theme.spacings.xxsmall};

    ${media.greaterThan('medium')`
      font-size: 4.2rem;
    `}
  `}
`;
export const Description = styled.div`
  ${({ theme, size, lineHeight }) => css`
    font-size: ${size};
    color: ${theme.colors.blackFonts};
    margin-bottom: ${theme.spacings.xxsmall};
    line-height: ${lineHeight};
  `}
`;
