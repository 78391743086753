/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable import/named */
import React from 'react';
import {
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosAddCircleOutline,
} from 'react-icons/io';

import {
  Wrapper,
  Header,
  TextHeader,
  Icon,
  Content,
  ContentItem,
  ItemText,
  ItemPreco,
  ButtonOrcacamento,
  Text,
  A,
} from './styles';
import { useItem } from '../../context/Item';

export default function Accordion({
  pacotes, id, titulo, open, onclick,
}) {
  const { item, setItem } = useItem();
  return (
    <Wrapper>
      <Header role="button" onClick={onclick}>
        <TextHeader>{titulo}</TextHeader>
        <Icon>
          {open[id] ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />}
        </Icon>
      </Header>
      <Content open={open[id]}>
        {pacotes.map((pacote) => (
          <>
            <ContentItem>
              <ItemText>{pacote.texto}</ItemText>
              <ItemPreco>{pacote.preco}</ItemPreco>
              <A href="#form">
                <ButtonOrcacamento
                  onClick={() => setItem({
                    id: pacote.id,
                    pacote: pacote.texto,
                    especialidade: titulo,
                  })}
                >
                  <Icon>
                    <IoIosAddCircleOutline size={24} color="white" />
                  </Icon>
                  <Text>Solicitar orçamento</Text>
                </ButtonOrcacamento>
              </A>
            </ContentItem>
            <hr />
          </>
        ))}
      </Content>
    </Wrapper>
  );
}
