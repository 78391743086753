import React, { useEffect } from 'react';

import GlobalContainer from '../../components/_layout/Container';
import {
  Title,
  Description,
  SupportText,
} from '../../components/TextComponents';
import {
  ImageBanner,
  VideoBanner,
} from '../../components/MediaContainer/modules';

import * as S from './styles';

import bannerWeb from '../../assets/modelo_assistencial_assets/modeloAss_banner.jpg';
import bannerMobile from '../../assets/modelo_assistencial_assets/modeloAss_mobile.jpg';

export function ModeloAssistencial() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const string = ` 
  <p>Aplicar o modelo de medicina de valor, em um local moderno e com tratamento humanizado. A forma como vamos receber o paciente - durante a estada no Hospital e depois - é a nossa grande diferença. Da porta de entrada até a alta, vamos atender o cliente de forma contínua e resolutiva.</p><br>
  <p>Implantamos um modelo assistencial para atuar em todas as áreas, enquanto o Hospital cuida principalmente das doenças mais prevalentes. Entre as principais causas de internação, estão doenças crônicas como insuficiência cardíaca, cardiopatia isquemia, diabetes, doenças pulmonares, infarto e suas consequências. Todas essas áreas médicas serão atendidas no Hospital. Sabendo disso, dimensionamos a estrutura e a equipe de forma que pudéssemos atender com a máxima eficiência possível a cada uma das mais recorrentes necessidades de internação.</p><br>
  <p>Com isso, iniciamos um processo de consultoria da prática hospitalar que nos direcionou para diversas linhas de cuidado e a dimensionar as estruturas necessárias. Somamos nossa experiência com a do Hospital Israelita Albert Einstein para desenhar melhor os nossos próprios protocolos de cuidado.</p><br>
  <p>A partir desta metodologia - que se integra como parte da cultura de todo o Hospital, da emergência e do pronto-atendimento até a alta - a dor do paciente será atendida em uma única sequência lógica de decisões e acontecimentos. Desse modo, as definições sobre o tratamento de cada doença acontecem da forma mais rápida e assertiva possível. Ao longo da jornada, o profissional das equipes multidisciplinares sabe exatamente o que se espera dele, o que fazer e como cuidar daquela condição de saúde. Os conceitos da medicina de valor aplicados para ter o paciente como centro do atendimento, com eficiência e alta resolutividade médicas.</p><br>
  <p>Algumas atividades, como a hemodiálise, serão conduzidas por prestadores de serviço que passam por um rigoroso processo de qualidade para alocar dentro do Hospital. Eles também tomarão decisões com base em evidências, de acordo com protocolos rígidos, o que facilita a atuação de toda equipe. Com um conceito de atendimento único, conseguimos concentrar o foco no que é realmente necessário para cada paciente.</p><br>
  <p>Esse cuidado diferenciado com a saúde também apresenta resultados positivos na economia. Ao estabelecer tudo que precisamos fazer com o paciente durante seus dias de internação, estamos respeitando um modelo eficiente de atendimento, o que aumenta a celeridade desta jornada. Controlando os indicadores certos de qualidade, melhoramos o bem-estar e a vida dos pacientes, em sintonia com o melhor funcionamento de nossa operação. É o nosso caminho para levar saúde para mais gente.</p>
  `;

  const string2 = `
  <p>A gestão por indicadores de um hospital com corpo clínico restrito faz ainda mais sentido quando pensamos em oferecer saúde em larga escala para todas as pessoas. Com toda a equipe seguindo com a mesma aderência aos protocolos que o Hospital pratica, conseguimos validar a compra de materiais e equipamentos em maior volume, com valores mais adequados. Mas o que torna o Hospital Humaniza especial é que o foco maior é na necessidade do paciente. Os médicos que forem operar poderão observar que tipo de resultado estão tendo nas cirurgias e o quanto nossos pacientes estão ficando satisfeitos com isso.</p><br>  
  <p>Ao longo de muito tempo, viemos buscando um padrão assistencial que fosse medido, avaliado constantemente e melhorado. Esse é o legado do CCG Saúde que estamos transferindo para o Hospital. Nossa jornada de aprendizado nos levou a acreditar em um tripé muito forte para manter esse sistema multidisciplinar equilibrado e performando com excelência: determinação institucional, uma equipe motivada e dedicada, e sistema de informática. Quando juntamos tudo isso em forma de indicadores e monitoramos seus resultados, damos uma real garantia de oferecer o melhor cuidado.</p><br> 
  <p>Basicamente, nosso sistema de saúde tem um médico de referência com a sua equipe de assistência que dá suporte ao paciente, conforme suas necessidades. O que nos coloca de fato em outro patamar são as informações que reunimos no software em que monitoramos a experiência do paciente. Agrupados sob uma perspectiva de cuidado humanizado, esses dados alimentarão os indicadores durante todo tratamento das pessoas, desde o ambulatório até sua reabilitação, buscando influenciar para melhor a vida delas. Tudo embasado em evidência.</p><br>
  <p>A avaliação periódica desses dados vai permitir novas leituras da performance do time e a otimização dos processos. Teremos a possibilidade contínua de encontrar novas oportunidades de melhoria no desempenho da equipe, ganhando velocidade na implantação de ações para solucioná-las.</p>

  
  `;

  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TextWrapper>
          <Title
            size={{ web: '7.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '8.3rem', mobile: '4.8rem' }}
            label="Modelo Assistencial"
          />
          <Description
            width="90rem"
            label="O Hospital Humaniza é a realização da Medicina de Valor, que guia todas as ações do CCG Saúde. Focando em ações resolutivas, o foco vai além de tratar o agora, é fazer tudo para garantir o bem-estar duradouro para todos os pacientes."
          />
        </S.TextWrapper>
      </S.Wrapper>
      <S.BannerWrapper>
        <ImageBanner backgrounds={{ bannerWeb, bannerMobile }} />
      </S.BannerWrapper>
      <S.Wrapper>
        <S.SupporTextWrapper>
          <SupportText
            title="Conhecer bem para atender melhor"
            text={string.replace(/\n/, '<br />')}
          />
        </S.SupporTextWrapper>
      </S.Wrapper>
      <S.ApplyBackground>
        {/*  <S.VideoWrapper>
          <VideoBanner url="https://www.youtube.com/embed/h8yU14KDW7o" />
      </S.VideoWrapper> */}
        <S.SupporTextWrapper>
          <SupportText
            title="Gestão por indicadores"
            subTitle="
            Qualidade na prestação de serviços e segurança para o doente
            no século XXI"
            text={string2}
          />
        </S.SupporTextWrapper>
      </S.ApplyBackground>
    </GlobalContainer>
  );
}
