import styled, { css } from 'styled-components';
import { Field } from 'formik';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled(Field)`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 2rem;
    height: 2rem;
    border: 0.2rem solid
      ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
    border-radius: 50%;
    transition: background border 0.3s ease;
    position: relative;
    outline: none;
    &:before {
      content: '';
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
      transition: opacity 0.3s ease;
      opacity: 0;
      position: absolute;
      top: 15%;
    }
    &:focus {
      box-shadow: 0 0 0.5rem ${theme.colors.primary};
    }
    &:checked {
      &:before {
        opacity: 1;
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    cursor: pointer;
    font-family: ${theme.font.medium};
    padding-left: ${theme.spacings.xxsmall};
    line-height: 1.8rem;
    user-select: none;
    color: ${theme.font.colors.primary};
  `}
`;
