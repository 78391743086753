import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as TitleStyles from '../../../../components/TextComponents/Title/styles';
import * as SupportStyles from '../../../../components/TextComponents/SupportText/styles';

export const Wrapper = styled.div`
  ${({ theme, color, mobileNone }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors[color]};
    padding: 10rem ${theme.spacings.xsmall};

    ${media.greaterThan('medium')`
      padding: 14rem;
    `}

    ${media.lessThan('medium')`
      ${
  mobileNone
        && css`
          display: none;
        `
}
    `}


    ${TitleStyles.Title}, ${SupportStyles.Paragraph} {
      color: ${theme.name === 'contraste' ? theme.colors.globalYellow : '#fff'};
    }

    ${SupportStyles.Paragraph} {
      margin-top: 2rem;
    }
  `}
`;
export const TextWrapper = styled.div`
  max-width: 47rem;
`;
