import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const colorDateModifier = {
  blue: (theme) => css`
    color: ${theme.colors.secondary};
  `,
  rose: (theme) => css`
    color: ${theme.colors.rose};
  `,
  poloBlue: (theme) => css`
    color: ${theme.colors.poloBlue};
  `,
};

const borderContainerModifier = {
  blue: (theme) => css`
    border-bottom: 4px solid ${theme.colors.secondary};
  `,
  rose: (theme) => css`
    border-bottom: 4px solid ${theme.colors.rose};
  `,
  poloBlue: (theme) => css`
    border-bottom: 4px solid ${theme.colors.poloBlue};
  `,
};

export const Container = styled.div`
  ${({ theme, color }) => css`
    background: #fff;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.08);
    max-width: 31.6rem;

    ${media.lessThan('medium')`
      height: 12.7rem;
      max-width:100%;
      width: 100%;
      padding: 1.8rem 2rem;
    `}

    ${!!color && borderContainerModifier[color](theme)};
  `}
`;
export const Info = styled.div`
  margin: 4rem;

  ${media.lessThan('medium')`
      margin: 0;
    `}
`;
export const Date = styled.span`
  ${({ color, theme }) => css`
    font-family: 'diodrum-bold';
    font-size: ${theme.font.sizes.xsmall};
    letter-spacing: 0.2rem;
    ${!!color && colorDateModifier[color](theme)};
  `}
`;
export const Description = styled.p`
  margin-top: 1rem;
  ${({ theme }) => css`
    color: #424242;
    font-size: ${theme.font.sizes.large};
    line-height: 25px;

    ${media.lessThan('medium')`
      font-size: 1.7rem;
      margin-top: 0;

    `}
  `}
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  max-height: 20rem;
  height: 100%;
  img {
    object-fit: cover;
    width: 100%;
  }
`;
