import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom'; // import do hook
import * as S from './styles';
import ButtonOutlined from '../Button/Outline';

export default function KnowMore({ text, to }) {
  const history = useHistory();
  const goToModeloAssistencial = () => {
    history.push('/o-hospital/modelo-assistencial');
  };
  return (
    <S.Wrapper>
      <S.TextWrapper>
        <span className="text">{text}</span>
      </S.TextWrapper>
      <S.ButtonWrapper>
        <ButtonOutlined
          onClick={goToModeloAssistencial}
          size="large"
          label="Saiba mais sobre Modelo Assistencial"
        />
      </S.ButtonWrapper>
    </S.Wrapper>
  );
}

KnowMore.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
