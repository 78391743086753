import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.menu`
  ${({ theme }) => css`
    background: ${theme.colors.primary};
    position: relative;
  `}
`;

export const NavMenu = styled.nav`
  ${({ theme }) => css`
    height: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: ${theme.maxwidth};
    margin: 0 auto;
    .button {
      border: 0;
      background: none;
      color: ${theme.font.colors.white};
      margin-top: 0.6rem;
      margin-right: 2rem;
      border-bottom: 0.5rem solid ${theme.colors.secondary};
      height: 3.1rem;
      ${media.lessThan('medium')`
        margin-top: 0rem;
        margin-right: 0.2rem;
        height: auto ;
      `}
    }
    ${media.lessThan('medium')`
    padding: ${theme.spacings.xsmall};
    `}
  `}
`;

export const MenuTitle = styled.div`
  ${({ theme }) => css`
    button {
      border: 0;
      background: none;
      color: ${theme.font.colors.white};
      font-family: ${theme.font.bold};
      font-size: ${theme.font.sizes.small};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 ${theme.spacings.xxsmall};
    }

    .first-icon {
      margin-right: ${theme.spacings.xxsmall};
    }

    .secound-icon {
      margin-left: ${theme.spacings.xxsmall};
    }
  `}
`;

export const MenuGroup = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.bg_contraste};
    transition: 0.3s ease;
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? 'all' : 'none')};
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    z-index: 50;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacings.xsmall};

    ${media.lessThan('large')`
    padding: ${theme.spacings.small};

    `}
  `}
`;

export const IconWrapper = styled.div`
  margin-right: 0.5rem;
`;
export const OptionWrapper = styled.button`
  ${({ theme }) => css`
    background: none;
    border: 0;
    display: flex;
    align-items: center;
    padding: ${theme.spacings.xxsmall}
    font-size: ${theme.font.sizes.medium};
    margin: 0 ${theme.spacings.xsmall};
    user-select: none;
  
    ${media.lessThan('small')`
      margin: 0 ${theme.spacings.xxsmall};
      
      ${IconWrapper} {
        img {
          width: 1.8rem
        }
      }

    `}
  `}
`;
export const OptionName = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-family: ${theme.font.diodrum};
    text-transform: uppercase;
    font-weight: 500;

    ${media.greaterThan('medium')`
      font-size: ${theme.font.sizes.small};
      
    `};
  `}
`;

export const LinkWrapper = styled.div`
  margin: 0 1.5rem;
`;

export const NavLink = styled(Link)`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.white};
    padding: ${theme.spacings.xxsmall};
    border-bottom: 0.3rem solid ${theme.colors.primary};
    transition: border 0.3s ease;
    font-weight: bold;
    font-size: ${theme.font.sizes.small};

    :hover {
      border-bottom: 0.3rem solid ${theme.colors.turquoise};
    }
  `}
`;

export const WrapperMenu = styled.div`
  display: flex;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${theme.maxwidth};
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .define__clolor {
      svg {
        color: ${theme.colors.logo};
      }
    }

    ${media.lessThan('large')`
      justify-content: center;
    `}
  `}
`;
