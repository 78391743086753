import React, { useEffect } from 'react';
import AccessibilityMenu from '../../components/AccessibilityMenu';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';

import * as S from './styles';
import Form from '../../components/FormOuvidoria';
import Breadcrumbs from '../../components/Breadcrumbs';

const Ouvidoria = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <AccessibilityMenu />
      <Menu />
      <Breadcrumbs />

      <S.Wrapper>
        <S.TitleWrapper>
          <S.Title>Ouvidoria</S.Title>
          <S.Description size="2rem" lineHeight="2.5rem">
            A Ouvidoria CCG Saúde é um canal aberto para você registrar e
            encaminhar suas manifestações não solucionadas pelo atendimento
            habitual. Para ter acesso à Ouvidoria é necessário informar o número
            de protocolo do atendimento anterior.
          </S.Description>
        </S.TitleWrapper>
        <S.TitleWrapper>
          <S.SubTitleWrapper>Prazo de retorno</S.SubTitleWrapper>
          <S.Description size="1.6rem" lineHeight="2.8rem">
            Conforme RN nº. 323 da Agência Nacional de Saúde Suplementar (ANS),
            as solicitações serão respondidas em até sete (7) dias úteis após a
            abertura do chamado. Casos que necessitarem de prazo mais extenso
            serão comunicados ao cliente, podendo levar até 30 dias para
            retorno.
          </S.Description>
        </S.TitleWrapper>
        <Form />
      </S.Wrapper>
      <Footer />
    </S.Container>
  );
};

export default Ouvidoria;
