import React from 'react';

import * as S from './styles';

export default function Counter({ length = 0, children, display }) {
  return (
    <S.Container display={display}>
      {length !== 0 && (
        <span>
          <strong>{length}</strong>
          {' '}
          resultados encontrados
        </span>
      )}
      {children}
    </S.Container>
  );
}
