const theme = {
  name: 'contraste',

  maxwidth: '136rem',
  colors: {
    primary: '#000',
    secondary: '#009cde',
    white: '#eee',
    black: '#000',
    turquoise: '#6EC6E6',
    lilac: '#be99bb',
    rose: '#E2AAAE',
    poloBlue: '#8290C8',
    gray: '#ddd',
    secondWhite: '#f3f9fc',
    blackFonts: '#fff',
    bg_contraste: '#000',
    logo: '#fff',
    globalYellow: '#fff333',
  },

  border: {
    radius: '0.8rem',
  },

  font: {
    colors: {
      primary: '#fff333',
      secondary: '#fff333',
      white: '#fff333',
    },
    diodrum: 'diodrum',
    family:
      "'diodrum', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    bold: 'diodrum-bold',
    light: 'diodrum-light',
    medium: 'diodrum-medium',
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
      xxxlarge: '3.2rem',
      extraLarge: '4.2rem',
      xextraLarge: '5.8rem',
      xxextraLarge: '6.3rem',
      xxxextraLarge: '7.6rem',
    },
  },

  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    xmedium: '2.8rem',
    medium: '3.2rem',
    large: '4.4rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
    xxxlarge: '9rem',
    extraLarge: '16rem',
  },
};

export default theme;
