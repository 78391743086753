/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import * as S from './styles';

const Breadcrumbs = (props) => {
  const theme = useContext(ThemeContext);
  const {
    history,
    location: { pathname },
  } = props;

  const pathnames = pathname.split('/').filter((x) => x);
  console.log(pathnames);
  return (
    <S.Wrapper>
      <MUIBreadcrumbs
        separator={
          <RiArrowRightSLine size={14} color={theme.colors.secondary} />
        }
        aria-label="breadcrumb"
      >
        {pathnames.length > 0 ? (
          <Link
            style={{
              textTransform: 'uppercase',
              color: theme.font.colors.primary,
              fontSize: theme.font.sizes.xsmall,
              fontFamily: theme.font.medium,
              cursor: 'pointer',
            }}
            onClick={() => history.push('/')}
          >
            Home
          </Link>
        ) : (
          <Typography> Home </Typography>
        )}
        {pathnames.map((name, index) => {
          const newName = name.replace(/-/g, ' ');
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography
              style={{
                textTransform: 'uppercase',
                color: theme.colors.secondary,
                fontSize: theme.font.sizes.xsmall,
                fontFamily: theme.font.medium,
              }}
              key={name}
            >
              {newName}
            </Typography>
          ) : (
            <Link
              style={{
                textTransform: 'uppercase',
                color: theme.colors.primary,
                fontSize: theme.font.sizes.xsmall,
                fontFamily: theme.font.medium,
                cursor: 'pointer',
              }}
              key={name}
              onClick={() => history.push('/')}
            >
              {newName}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </S.Wrapper>
  );
};

export default withRouter(Breadcrumbs);
