import React, { useState, useCallback } from 'react';

import GlobalContainer from '../../components/_layout/Container';
import * as S from './styles';
import {
  LittleTitle,
  Title,
  Description,
  SupportText,
  SimpleText,
} from '../../components/TextComponents';
import PranchetaEmpresa from './components/PranchetaEmpresa';
import PranchetaFamilia from './components/PranchetaFamilia';
import Form from '../../components/FormPlanosCcg';

export default function PlanosCcg() {
  const options = ['Para Empresas', 'Para você e sua Família'];
  const [button, setButton] = useState('Para Empresas');

  const [selected, setSelected] = useState({
    'Para Empresas': true,
  });

  const toggleSelected = useCallback(
    (name) => {
      setSelected({
        [name]: !selected[name],
      });
    },
    [selected],
  );

  function callFunctions(option) {
    toggleSelected(option);
    setButton(option);
    // console.log(option);
  }

  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TextWrapper>
          <Title
            size={{ web: '7.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '8.3rem', mobile: '4.8rem' }}
            label="Planos de Saúde - CCG Saúde"
          />
          <Description
            width="90rem"
            label="Os planos do CCG Sáude garatem o cuidado e atenção que você precisa. Com opções de planos para familía e para empresa, uma das opções disponiveis para o atendimento de suas necessidades e do seu momento de vida."
          />
          <br />
          <Description
            width="90rem"
            label="Conheça os planos disponíveis para empresas e para você"
          />
        </S.TextWrapper>
      </S.Wrapper>
      <S.ButtonDiv>
        {options.map((option) => (
          <S.Button
            key={option}
            selected={selected[option]}
            onClick={() => callFunctions(option)}
          >
            {option}
          </S.Button>
        ))}
      </S.ButtonDiv>
      <S.WrapperDiv display={button === 'Para Empresas' ? 'block' : 'none'}>
        <PranchetaEmpresa />
        <div id="form">
          <Form />
        </div>
      </S.WrapperDiv>
      <S.WrapperDiv
        display={button === 'Para você e sua Família' ? 'block' : 'none'}
      >
        <PranchetaFamilia />
        <div id="form1">
          <Form />
        </div>
      </S.WrapperDiv>
    </GlobalContainer>
  );
}
