import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.small};
    width: 100%;
    max-width: 95rem;
    margin: 8rem auto 0;

    ${media.greaterThan('medium')`
      padding: 0 1rem;
    `}
  `}
`;
export const TextWrapper = styled.div``;
export const Text = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.font.colors.primary};
    width: 100%;
    display: block;
  `}
`;
export const InformationWrapper = styled.div`
  margin-top: 1.6rem;
`;
