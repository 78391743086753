import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 8rem;
  max-width: 40rem;
`;

export const IconWrapper = styled.div`
  margin-right: 0.8rem;
  margin-top: 0.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const MessageWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blackFonts};
    display: flex;
    align-items: center;
  `}
`;

export const Message = styled.span`
  ${({ theme }) => css`
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.primary};
  `}
`;
