import React from 'react';

import * as S from './styles';

const InfoCard = () => (
  <S.Container>
    <S.Wrapper>
      <S.SmallTitle>HOSPITAL HUMANIZA</S.SmallTitle>
      <S.BigTitle>Sua nova referência em saúde em Porto Alegre</S.BigTitle>
      <S.Descrption>
        O Hospital Humaniza é o mais novo espaço do CCG Saúde em Porto
        Alegre, criado para atender todas as necessidades da região, com
        soluções de saúde integradas a uma estrutura completa de atendimento.
      </S.Descrption>

      <S.Button to="/institucional">Saiba mais</S.Button>
    </S.Wrapper>

    <S.VideoWrapper>
      <S.IframeVideo
        title="video"
        src="https://www.youtube.com/embed/h8yU14KDW7o"
        frameBorder="0"
        allowFullScreen
      />
    </S.VideoWrapper>
  </S.Container>
);

export default InfoCard;
