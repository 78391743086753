import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as NewsCardStyles from '../../components/NewsCard/styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.maxwidth};
    padding: 0 ${theme.spacings.small};
    width: 100%;
    margin: 0 auto;

    ${media.greaterThan('medium')`
      padding: 0 1rem; 
    `}
  `}
`;
export const TextWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 1.5rem;

  ${media.greaterThan('medium')`
    margin-top: 8rem; 
    margin-bottom: 4rem; 
  `}
`;

export const NewsLetterWrapper = styled.div``;
export const FilterWrapper = styled.div`
  margin-bottom: 1.5rem;
`;
export const GridNews = styled.div`
  display: grid;
  grid-gap: 0.4rem;
  margin-bottom: 4rem;

  ${media.greaterThan('medium')`
    ${NewsCardStyles.Container} {
      max-width: 100%;
    }

    row-gap: 2.4rem;
    column-gap: 0.4rem;
    margin-bottom: 8rem;

    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const LoadMoreNewWrapper = styled.div`
  display: flex;
  margin-bottom: 8rem;
  justify-content: center;
`;
