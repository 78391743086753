import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const wrapperModifiers = {
  left: () => css`
    justify-content: flex-start;
  `,
  right: () => css`
    justify-content: flex-end;
  `,
};

export const Wrapper = styled.div`
  ${({ alignment }) => css`
    width: 100%;
    display: flex;
    align-items: center;

    ${media.greaterThan('medium')`
      margin: 1.6rem 0;
      ${wrapperModifiers[alignment]()}
  `}
  `}
`;
export const TextWrapper = styled.div`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.05rem;
    font-weight: bold;
    margin-right: 1.6rem;

    color: ${theme.font.colors.primary};
  `}
`;

const colorIconsModifier = {
  normal: (theme) => css`
    color: ${theme.colors.secondary};
  `,
  contraste: (theme) => css`
    color: ${theme.colors.globalYellow};
  `,
};

export const SocialShareWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    &:last-child {
      margin-right: 0;
    }
    svg {
      ${colorIconsModifier[theme.name](theme)}
    }
  `}
`;
export const Item = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 0.8rem;
`;
