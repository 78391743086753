import React from 'react';
import { FontSizeProvider } from './useUpdateSizeFonts';
import { LocalThemeProvider } from './useTheme';
import { FilterProvider } from './useNewsletterFilter';
import { FilterProvider2 } from './useGuidanceFilter';
import ItemProvider from '../context/Item';
import PlanoProvider from '../context/plano';

export default function AppProvider({ children }) {
  return (
    <FontSizeProvider>
      <FilterProvider>
        <FilterProvider2>
          <LocalThemeProvider>
            <ItemProvider>
              <PlanoProvider>{children}</PlanoProvider>
            </ItemProvider>
          </LocalThemeProvider>
        </FilterProvider2>
      </FilterProvider>
    </FontSizeProvider>
  );
}
