import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 47.6rem;
    border-bottom: 1px solid
      ${theme.name === 'contraste' ? theme.colors.globalYellow : '#ddd'};

    .label {
      display: block;
      text-transform: uppercase;
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.medium};
      color: ${theme.font.colors.primary};
    }

    .wrapper {
      position: relative;
    }
  `}
`;

export const Select = styled.select`
  ${({ theme }) => css`
    appearance: none;
    width: 100%;
    background: none;
    padding: 1rem 6rem 1rem 0rem;
    border: 0;
    font-size: 20px;
    option {
      background: ${theme.name === 'contraste' ? '#000' : '#fff'};
      border: 1px solid ${theme.colors.globalYellow};
    }

    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.blackFonts};
  `}
`;
export const Icon = styled.div`
  background-color: transparent;
  max-width: 4rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
`;
export const Option = styled.option`
  color: #555;
  ${({ theme }) => css`
    font-family: ${theme.font.family};
  `}
`;
