import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import Slider from 'react-slick';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.bg_contraste};
    padding: 7rem 0 7rem;
    height: 38.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lessThan('large')`
      height: 47.5rem;
      display: block;
    `}
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
    width: 100%;
    max-width: 95.2rem;
    margin: 0 auto;

    .wrapper {
      width: 50%;

      ${media.lessThan('medium')`
        width: 100%;
      `}
    }

    ${media.greaterThan('large')`
      display: flex;

      .wrapper {
        width: 20rem;
        margin-right: 3rem;
      }
    `}
  `}
`;
export const SmallTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.medium};
    letter-spacing: 0.2rem;
  `}
`;
export const BigTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-family: ${theme.font.bold};
    width: 200px;
    font-size: 3.6rem;
    line-height: 4rem;
    display: block;
    margin-top: 1.6rem;

    margin-bottom: 2rem;
  `}
`;

export const WrapperPagination = styled.div`
  margin-top: 5rem;
  width: 100%;
`;
export const Button = styled.button`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          width: 8.8rem;
          height: 5.4rem;
          background: none;
          border: 1px solid ${theme.colors.secondary};
          padding: ${theme.spacings.xsmall} ${theme.spacings.small};
          border-radius: ${theme.border.radius};
          svg {
            color: ${theme.colors.secondary};
          }

          & + button {
            margin-left: 1rem;
          }
        `
    : css`
          width: 8.8rem;
          height: 5.4rem;
          background: none;
          border: 1px solid ${theme.colors.globalYellow};
          padding: ${theme.spacings.xsmall} ${theme.spacings.small};
          border-radius: ${theme.border.radius};
          svg {
            color: ${theme.colors.globalYellow};
          }

          & + button {
            margin-left: 1rem;
          }
        `)}
`;

export const SliderWrapper = styled.div`
  width: 100%;
  ${media.greaterThan('large')`
    width: 70rem;
  `};
`;

export const Helper = styled.div`
  display: flex;
`;

export const SliderT = styled(Slider)`
  width: 95%;
`;
