import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Form as Formik } from 'formik';

export const Form = styled(Formik)`
  ${({ theme }) => css`
    padding: 5rem ${theme.spacings.xsmall};

    .content_add {
      padding: 0 0 2rem;

      span {
        display: block;
        margin: 1rem 0 2rem;
        font-size: ${theme.font.sizes.large};
        color: ${theme.font.colors.primary};
      }
    }
  `}
`;
export const TextWrapper = styled.div`
  ${({ theme }) => css`
    h1 {
      font-family: ${theme.font.bold};
      font-size: ${theme.font.sizes.xxlarge};
      color: ${theme.colors.primary};
      margin-bottom: 5rem;
    }
  `}
`;

export const RadioButtonsWrapper = styled.div``;

export const Label = styled.span`
  ${({ theme }) => css`
    display: block;
    font-family: ${theme.font.medium};
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.primary};
    margin-bottom: 2rem;
  `}
`;
export const BoxRadioButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25rem;
`;

export const FieldsWrapper = styled.div``;

export const InputBox = styled.div`
  padding: 2rem 0;
  display: ${({ display }) => display};

  .wrapperFlex {
    width: 45%;
  }

  ${media.lessThan('small')`
    div + div {
      margin-top: 4rem;
    }
  `}

  ${media.greaterThan('medium')`
    padding: 4rem 0;
    ${({ flex }) => !!flex
      && css`
        display: flex;
        justify-content: space-between;
      `}
    `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton = styled.button`
  ${({ theme }) => css`
    width: 31.6rem;
    background-color: ${theme.colors.secondary};
    padding: ${theme.spacings.xsmall};
    color: #fff;
    font-size: ${theme.font.sizes.small};
    border: 0;
    border-radius: 0.4rem;
    transition: 0.3s all ease-in-out;

    :hover {
      background: ${lighten(0.1, theme.colors.secondary)};
    }
  `}
`;

export const WithSelectWrapper = styled.div`
  margin-bottom: 8rem;
`;

export const RequiredMessage = styled.span`
  ${({ theme }) => css`
    color: #c7170a;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    display: block;
    margin-bottom: 2rem;
  `}
`;

export const Error = styled.span`
  ${({ theme }) => css`
    color: #c7170a;
    font-family: ${theme.font.medium};
    font-size: ${theme.font.sizes.small};
    display: block;
    margin-top: 1rem;
  `}
`;
