import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

export default function ButtonOutlined({ label, size, ...rest }) {
  return (
    <S.Button size={size} {...rest}>
      <span>{label}</span>
    </S.Button>
  );
}

ButtonOutlined.defaultProps = {
  size: 'medium',
};

ButtonOutlined.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullsize']),
};
