import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import theme from '../../../styles/themes/theme';

export default function Title({
  label, size, lineHeight, color,
}) {
  return (
    <S.Title color={color} lineHeight={lineHeight} size={size}>
      {label}
    </S.Title>
  );
}

Title.defaultProps = {
  size: { web: '7.6rem', mobile: '4.2rem' },
  lineHeight: { web: '4.5rem', mobile: '3rem' },
  color: theme.font.colors.primary,
};

Title.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.shape({
    web: PropTypes.string,
    mobile: PropTypes.string,
  }),
  lineHeight: PropTypes.shape({
    web: PropTypes.string,
    mobile: PropTypes.string,
  }),
  color: PropTypes.string,
};
