import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.small};
    width: 100%;
    max-width: ${theme.maxwidth};
    margin: 8rem auto 0;
  `}
`;
export const TitleWrapper = styled.div``;
export const Grid = styled.div`
  padding: 4rem 0;
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  grid-template-areas:
    'info'
    'details';
  row-gap: 2.4rem;

  ${media.greaterThan('medium')`
    column-gap: 4rem;
    grid-template-areas: 'details info';
    grid-template-columns: 1.7fr 1fr;
    padding: 8rem 0 12rem;
  `};
`;

export const SupporTextWrapper = styled.div`
  margin-bottom: 8rem;
  padding: 0 2rem;

  ${media.greaterThan('medium')`
    margin-bottom: 6rem;
    padding: 0 1rem;

  `}
`;
