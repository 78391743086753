const multipleLocation = [
  {
    name: 'Clínica Mais',
    telefone: '(51) 2312-2700',
    lat: -30.0282758,
    lng: -51.221474,
  },
  {
    name: 'Clínica Zona Sul',
    telefone: '(51) 3030-9191',
    lat: -30.1588134,
    lng: -51.1910388,
  },
  {
    name: 'Clínica Wallig',
    telefone: '(51) 3500-8370',
    lat: -30.0119314,
    lng: -51.1607565,
  },

  {
    name: 'Clínica Estar Bem',
    telefone: '(51) 3287-9695',
    lat: -30.0161556,
    lng: -51.2071812,
  },
  {
    name: 'Clínica da Mulher',
    telefone: '(51) 3227-1022',
    lat: -30.0254259,
    lng: -51.212595,
  },

  {
    name: 'Clínica Cachoeirinha',
    telefone: '(51) 3441-9000',
    lat: -29.9491207,
    lng: -51.0968812,
  },
  {
    name: 'Unidade Multiclínica Campo Bom',
    telefone: '(51) 3598-1200',
    lat: -29.6802453,
    lng: -51.0545159,
  },

  {
    name: 'Clínica Canoas',
    telefone: '(51) 3462-9200',
    lat: -29.904179,
    lng: -51.1760986,
  },

  {
    name: 'Clínica Gravataí',
    telefone: '(51) 3484-8400',
    lat: -29.9390261,
    lng: -50.9947448,
  },

  {
    name: 'Clínica Guaíba',
    telefone: '(51) 3491-2999',
    lat: -30.1052371,
    lng: -51.317701,
  },
  {
    name: 'Clínica Montenegro',
    telefone: '(51) 3178-3222',
    lat: -29.6834669,
    lng: -51.4578542,
  },
  {
    name: 'Unidade Multiclínica Novo Hamburgo',
    telefone: '(51) 3584-1000',
    lat: -29.6848324,
    lng: -51.1294256,
  },
  {
    name: 'Clínica São Leopoldo',
    telefone: '(51) 3579-4600',
    lat: -29.7776183,
    lng: -51.1484849,
  },
  {
    name: 'Clínica Viamão',
    telefone: '(51) 3434-3505',
    lat: -30.0824213,
    lng: -51.0355666,
  },
];

export default multipleLocation;
