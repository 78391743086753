import React from 'react';

import * as S from './styles';

import { ButtonLink } from '../Button/modules';

const CardServices = ({
  smallTitle,
  title,
  description,
  backgroundImage,
  buttonColor,
  services,
  especification,
}) => (
  <S.Container>
    {services && (
    <S.Wrapper backgroundImage={backgroundImage}>
      <S.SmallTitle>{smallTitle}</S.SmallTitle>
      <S.BigTitle>{title}</S.BigTitle>
      <S.Description>{description}</S.Description>

      <ButtonLink color={buttonColor} to="/">
        Saiba mais
      </ButtonLink>
    </S.Wrapper>
    )}

    {especification && (
    <S.TextWrapper backgroundImage={backgroundImage}>
      <S.BigTitle>{title}</S.BigTitle>

      <S.Description>{description}</S.Description>
    </S.TextWrapper>
    )}
  </S.Container>
);

export default CardServices;
