import styled, { css } from 'styled-components';

export const Input = styled.input`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 1.4rem;
    height: 1.4rem;
    border: 0.2rem solid ${theme.colors.secondary};
    transition: background border 0.3s;
    position: relative;
    outline: none;
    opacity: 0;

    &:before {
      content: '';
    }
    &:focus {
      box-shadow: 0 0 0.5rem ${theme.colors.secondary};
    }
    &:checked {
      border-color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
      background: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
      opacity: 1;

      &:before {
        opacity: 1;
      }
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Label = styled.label`
  ${({ theme }) => css`
    cursor: pointer;
    line-height: 1.8rem;
    margin-left: 1rem;
    font-size: ${theme.font.sizes.small};

    font-family: ${theme.font.bold};

    color: ${theme.font.colors.primary};
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid
      ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;
