import { createGlobalStyle, css } from 'styled-components';

import diodrum from '../assets/fonts/Diodrum-Regular.otf';
import diodrumBold from '../assets/fonts/Diodrum-Bold.otf';
import diodrumLight from '../assets/fonts/Diodrum-Light.otf';
import diodrumMedium from '../assets/fonts/Diodrum-Semibold.otf';
import helveticaNeue from '../assets/fonts/Helvetica-Neue-Condensed-Black.ttf';
import helveticaNeueMedium from '../assets/fonts/Helvetica-Neue-Medium.ttf';
import helveticaNeueLight from '../assets/fonts/Helvetica-Neue-Light.ttf';

export default createGlobalStyle`


@font-face {
  font-family: 'helvetica-medium';
  font-style: normal;
  font-display: swap;
  src: url(${helveticaNeueMedium}) format("opentype");
}

@font-face {
  font-family: 'helvetica-light';
  font-style: normal;
  font-display: swap;
  src: url(${helveticaNeueLight}) format("opentype");
}

@font-face {
  font-family: 'helvetica';
  font-style: normal;
  font-display: swap;
  src: url(${helveticaNeue}) format("opentype");
}


@font-face {
  font-family: 'diodrum';
  font-style: normal;
  font-display: swap;
  src: url(${diodrum}) format("opentype");
}

@font-face {
  font-family: 'diodrum-light';
  font-style: normal;
  font-display: swap;
  src: url(${diodrumLight}) format("opentype");
}

@font-face {
  font-family: 'diodrum-bold';
  font-style: normal;
  font-display: swap;
  src: url( ${diodrumBold}) format("opentype");
}

@font-face {
  font-family: 'diodrum-medium';
  font-style: normal;
  font-display: swap;
  src: url( ${diodrumMedium}) format("opentype");
}


  * {
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing: border-box;
  }


  ${({ theme }) => css`
    html {
      font-size: 62.5%;
    }
    body,
    #root {
      font-family: 'diodrum';
      font-size: ${theme.font.sizes.medium};
      background: ${theme.colors.white};
    }

    button,
    input {
      font-family: ${theme.font.family};
    }

    button {
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }
  `}
`;
