/* eslint-disable react/no-unescaped-entities */
import React, { useState, useCallback } from 'react';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import * as S from './styles';

import backgroundPop from '../../../../assets/image_card_planos_ccg_empresa/CCG_POP.png';
import logoPop from '../../../../assets/image_card_planos_ccg_empresa/ccg-pop.png';
import backgroundMulti from '../../../../assets/image_card_planos_ccg_empresa/CCG_MULTI.png';
import logoMulti from '../../../../assets/image_card_planos_ccg_empresa/ccg-multi.png';
import backgroundTop from '../../../../assets/image_card_planos_ccg_empresa/CCG_TOP.png';
import logoTop from '../../../../assets/image_card_planos_ccg_empresa/ccg-top.png';
import { usePlano } from '../../../../context/plano';
import MediaMatch from '../../../../components/MediaMatch';

export default function PranchetaFamilia() {
  const { plano, setPlano } = usePlano();

  const [open, setOpen] = useState({});

  const toggleSelected = useCallback(
    (name) => {
      setOpen({
        [name]: !open[name],
      });
    },
    [open],
  );

  return (
    <S.Wrapper>
      <S.Card>
        <S.CardImage backgroundImage={backgroundPop}>
          <S.CardImageUp>
            <S.Logo backgroundImage={logoPop} />
            <S.SubTitulo>Prático e acessível como a saúde deve ser</S.SubTitulo>
          </S.CardImageUp>
          <S.CardImageDown>
            <S.DivButtn>
              <S.P>A partir de</S.P>
              <S.Span>R$ 47</S.Span>
              <S.Small>,03*</S.Small>
              <S.LittleP>*valor de 0 a 18 anos</S.LittleP>
            </S.DivButtn>
            <S.DivButtn>
              <a href="#form1">
                <S.Button
                  onClick={() => setPlano('CCG Pop Familia')}
                  border="0.2rem solid #f1be48"
                  background="#f1be48"
                >
                  Contrate Agora
                </S.Button>
              </a>
            </S.DivButtn>
          </S.CardImageDown>
        </S.CardImage>
        <MediaMatch greaterThan="medium">
          <S.Description>
            <S.TitleDescription>
              Acesso a mais de 20 clínicas próprias do CCG
            </S.TitleDescription>
            <S.SubTitleDescription>
              Incluindo a Cliníca Mais rede credenciada
            </S.SubTitleDescription>
            <S.Hr />
            <S.TitleDescription>
              Possibilidade de contratação
            </S.TitleDescription>
            <S.SubTitleDescription>
              ambulatorial ou hospitalar
            </S.SubTitleDescription>
            <br />
            <S.TitleDescription>Exames laboratorias</S.TitleDescription>
            <S.SubTitleDescription>
              Na rede Marques D'Almeida
            </S.SubTitleDescription>
            <S.Hr />
            <S.TitleDescription>Acesso a rede de hospitais</S.TitleDescription>
            <S.SubTitleDescription>
              credenciados em todo estado
            </S.SubTitleDescription>
          </S.Description>
        </MediaMatch>
        <MediaMatch lessThan="medium">
          <S.Header
            onClick={() => toggleSelected('CCG Pop Familia')}
            hole="button"
            open={() => setOpen()}
          >
            <S.Icon>
              {open['CCG Pop Familia'] ? (
                <IoIosArrowUp size={24} color="#009cde" />
              ) : (
                <IoIosArrowDown size={24} color="#009cde" />
              )}
            </S.Icon>
          </S.Header>
          <S.Content open={open['CCG Pop Familia']}>
            <S.Description>
              <S.TitleDescription>
                Acesso a mais de 20 clínicas próprias do CCG
              </S.TitleDescription>
              <S.SubTitleDescription>
                Incluindo a Cliníca Mais rede credenciada
              </S.SubTitleDescription>
              <S.Hr />
              <S.TitleDescription>
                Possibilidade de contratação
              </S.TitleDescription>
              <S.SubTitleDescription>
                ambulatorial ou hospitalar
              </S.SubTitleDescription>
              <br />
              <S.TitleDescription>Exames laboratorias</S.TitleDescription>
              <S.SubTitleDescription>
                Na rede Marques D'Almeida
              </S.SubTitleDescription>
              <S.Hr />
              <S.TitleDescription>
                Acesso a rede de hospitais
              </S.TitleDescription>
              <S.SubTitleDescription>
                credenciados em todo estado
              </S.SubTitleDescription>
            </S.Description>
          </S.Content>
        </MediaMatch>
      </S.Card>
      {/* ---------Multi------*/}
      <S.Card>
        <S.CardImage backgroundImage={backgroundMulti}>
          <S.CardImageUp>
            <S.Logo backgroundImage={logoMulti} />
            <S.SubTitulo>
              Versátil e flexível para atender às suas prioridades
            </S.SubTitulo>
          </S.CardImageUp>
          <S.CardImageDown>
            <S.DivButtn>
              <S.P>A partir de</S.P>
              <S.Span>R$ 77</S.Span>
              <S.Small>,60*</S.Small>
              <S.LittleP>*valor de 0 a 18 anos</S.LittleP>
            </S.DivButtn>
            <S.DivButtn>
              <a href="#form1">
                <S.Button
                  onClick={() => setPlano('CCG Multi Familia')}
                  border="0.2rem solid #e56a54"
                  background="#e56a54"
                >
                  Contrate Agora
                </S.Button>
              </a>
            </S.DivButtn>
          </S.CardImageDown>
        </S.CardImage>
        <MediaMatch greaterThan="medium">
          <S.Description>
            <S.TitleDescription>
              Acesso a mais de 20 clínicas próprias do CCG
            </S.TitleDescription>
            <S.SubTitleDescription>
              Incluindo a Cliníca Mais rede credenciada
            </S.SubTitleDescription>
            <S.Hr />
            <S.TitleDescription>Consultas com médicos</S.TitleDescription>
            <S.SubTitleDescription>
              de rede externa de referência
            </S.SubTitleDescription>
            <br />
            <S.TitleDescription>Exames laboratorias</S.TitleDescription>
            <S.SubTitleDescription>
              Weimmann, clínicas Serdil e Radimagem
            </S.SubTitleDescription>
            <S.Hr />
            <S.TitleDescription>Plano hospitalar em</S.TitleDescription>
            <S.SubTitleDescription>
              quarto privativo ou semi-privativo
            </S.SubTitleDescription>
            <S.TitleDescription>
              Acesso a rede de hospitais credenciados emem todo o Estado
            </S.TitleDescription>
          </S.Description>
        </MediaMatch>
        <MediaMatch lessThan="medium">
          <S.Header
            onClick={() => toggleSelected('CCG Multi Familia')}
            hole="button"
            open={() => setOpen()}
          >
            <S.Icon>
              {open['CCG Multi Familia'] ? (
                <IoIosArrowUp size={24} color="#009cde" />
              ) : (
                <IoIosArrowDown size={24} color="#009cde" />
              )}
            </S.Icon>
          </S.Header>
          <S.Content open={open['CCG Multi Familia']}>
            <S.Description>
              <S.TitleDescription>
                Acesso a mais de 20 clínicas próprias do CCG
              </S.TitleDescription>
              <S.SubTitleDescription>
                Incluindo a Cliníca Mais rede credenciada
              </S.SubTitleDescription>
              <S.Hr />
              <S.TitleDescription>Consultas com médicos</S.TitleDescription>
              <S.SubTitleDescription>
                de rede externa de referência
              </S.SubTitleDescription>
              <br />
              <S.TitleDescription>Exames laboratorias</S.TitleDescription>
              <S.SubTitleDescription>
                Weimmann, clínicas Serdil e Radimagem
              </S.SubTitleDescription>
              <S.Hr />
              <S.TitleDescription>Plano hospitalar em</S.TitleDescription>
              <S.SubTitleDescription>
                quarto privativo ou semi-privativo
              </S.SubTitleDescription>
              <S.TitleDescription>
                Acesso a rede de hospitais credenciados emem todo o Estado
              </S.TitleDescription>
            </S.Description>
          </S.Content>
        </MediaMatch>
      </S.Card>
    </S.Wrapper>
  );
}
