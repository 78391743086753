import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.maxwidth};
    width: 100%;
    margin: 0 auto;
    padding: 3.8rem 2.4rem 0;

    ${media.greaterThan('medium')`
      padding: 8rem 1rem 0;
    `}
  `}
`;
export const TextWrapper = styled.div`
  margin-bottom: 5.5rem;
`;
export const BannerWrapper = styled.div`
  margin-bottom: 8rem;

  ${media.greaterThan('medium')`
    margin-bottom: 1rem;
  `}
`;

export const SupporTextWrapper = styled.div``;

export const VideoWrapper = styled.div`
  margin-top: 8rem;

  ${media.greaterThan('medium')`
    margin-top: 9.2rem;
  `};
`;

export const ApplyBackground = styled.div`
  ${SupporTextWrapper} {
    margin: 8rem 0;
    padding: 8rem 2.4rem 0;

    ${media.greaterThan('medium')`
    margin: 12rem 0;
    padding: 8rem 1rem 0;
    `}
  }
`;
