import { shade } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  box-shadow: 0px 4px 20px rgba(123, 169, 188, 0.16);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'timer';

  ${media.greaterThan('medium')`
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'header timer';    
  `}
`;
export const HeaderCard = styled.div`
  ${({ color }) => css`
    grid-area: 'header';
    display: grid;
    grid-template-columns: 1fr 1fr;

    background: ${color};
  `}
`;
export const TitleWrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  span {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6rem;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
  }
`;
export const ImageWrapper = styled.div`
  margin-right: 1rem;
`;
export const Title = styled.div`
  font-size: 2.1rem;
  color: #fff;
  margin-top: 0.4rem;
  font-weight: bold;
`;
export const TimerWrapper = styled.div`
  ${({ color }) => css`
    display: flex;
    align-items: center;
    background: ${shade(0.1, color)};
    padding: 1.5rem;
    grid-area: 'timer';

    span {
      font-size: 2.1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.6rem;
      text-align: left;
      color: #fff;
    }
  `}
`;
export const Footer = styled.div`
  background: #fff;
  padding: 2.4rem;

  p {
    color: #424242;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: left;
  }
`;
