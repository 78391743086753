import React from 'react';

import GlobalContainer from '../../components/_layout/Container';
import {
  Title,
  Description,
  SupportText,
} from '../../components/TextComponents';
import SectionInfoCard from '../../components/SectionInfoCard';

import * as S from './styles';

import virtual from '../../assets/institucional-assets/virtual.svg';
import InfoCardCovid from './components/InfoSectionCovid';
import DetailsCovid from './components/Detailscovid';
import InfoTimerCard from './components/InfoTimerCard';
import { string } from './utils/mock';

export default function ProntoAtendimento() {
  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TitleWrapper>
          <Title
            lineHeight={{ mobile: '4.5rem;' }}
            size={{
              web: '4.2rem',
              mobile: '4.2rem',
            }}
            label="Pronto Atendimento Adulto"
          />
          <Description width="95rem" />
        </S.TitleWrapper>
        <S.Grid>
          {/* <InfoCardCovid gridArea="info" /> */}
          <DetailsCovid gridArea="details" />
        </S.Grid>
      </S.Wrapper>
      <S.SupporTextWrapper>
        <SupportText text={string} />
      </S.SupporTextWrapper>

      <InfoTimerCard />
      {/* <SectionInfoCard
        title="Planos e convênios atendidos pelo Hospital Humaniza"
        littleTitle="PLANOS E CONVÊNIOS"
        description="Confira os planos e convênios atendidos pelo Hospital Humaniza."
        background="white"
        url="/"
        image={virtual}
        alignment="right"
     /> */}
    </GlobalContainer>
  );
}
