import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
  `}
`;

export const Box = styled.div`
  width: 90%;
  height: 400px;
  background: #fff;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  ${({ theme }) => css`
    display: flex;
    width: 40%;
    height: 80px;
    justify-content: center;
    text-align: center;
    background: ${theme.colors.primary};
    color: ${theme.colors.secondWhite};
    border: 0px;
    padding-top: 1.2rem;
    padding-bottom: 0;
    border-radius: 0.5rem;
    font-size: 2.2rem;
    padding-top: 2rem;
    margin-left: 14rem;
  `}
`;

export const TitleWrapper = styled.div`
  padding: 6rem 1rem;

  ${media.lessThan('medium')`
    padding: 6rem 2.4rem;
  `}
`;

export const Br = styled.br``;
