import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import assetBg from '../../assets/infoCovid/assetBackground.svg';
import bg_info from '../../assets/infoCovid/asset-covid.svg';

export const Container = styled.div`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          background: linear-gradient(
            0deg,
            ${theme.colors.secondWhite} 77.5%,
            rgba(255, 255, 255, 1) 50%
          );

          ${media.greaterThan('medium')`
           background: url(${assetBg}), linear-gradient(
            0deg,
            ${theme.colors.secondWhite} 77.5%,
            rgba(255, 255, 255, 1) 50%
          );
          background-position-x: 100%;
          background-repeat: no-repeat;
        `}
        `
    : css`
          background: #000;
        `)}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium} 0 0;
    width: 100%;
    user-select: none;
    padding-bottom: 14rem;

    .bg {
      background-image: url(${bg_info});
      background-repeat: no-repeat;
      background-position-x: -93px;
      background-position-y: 10%;

      ${media.lessThan('medium')`
        background: transparent;
      `}
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
    max-width: 96.5rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ajust__sizes .title {
      font-family: ${theme.font.bold};
      color: ${theme.font.colors.primary};
      font-size: 4.8rem;
      margin-bottom: ${theme.spacings.xsmall};

      ${media.greaterThan('medium')`
        width: 54rem;
        font-size: 7.6rem;
        .ajust__sizes {
          width: 79rem;
        }
      `}
    }
  `}
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    max-width: 70rem;
    font-size: ${theme.font.sizes.large};
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.blackFonts};
    margin-bottom: ${theme.spacings.medium};
    line-height: 2.4rem;
  `}
`;

export const Banner = styled.div`
  ${({ background, theme }) => css`
    background: url(${background[0]});
    background-size: cover;
    background-position: 60% 0;
    background-repeat: no-repeat;
    margin-bottom: ${theme.spacings.large};
    height: 34rem;
    width: 100%;

    ${media.greaterThan('medium')`
    background-position: center 0;
    background-size: cover;
    height: 44rem;
    margin-bottom: ${theme.spacings.xxlarge};
    `}

    ${media.greaterThan('huge')`
    background-position: center 0;
    background-size: cover;
    height: 64rem;
    margin-bottom: ${theme.spacings.xxlarge};
    `}
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    max-width: 96.5rem;
    margin: 0 auto;
    margin-top: 16rem;
    margin-bottom: 3.7rem;
    padding: 0 ${theme.spacings.xsmall};
    span {
      display: block;
      text-align: left;
      color: ${theme.colors.primary};
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  `}
`;

export const InformationWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
    max-width: 96.5rem;
    margin: 0 auto;
  `}
`;

export const FooterInformation = styled.div`
  ${({ theme }) => css`
    padding: 5rem ${theme.spacings.xsmall} 5rem;
    max-width: 96.5rem;
    margin: 0 auto;
    p {
      color: ${theme.colors.primary};
      font-size: ${theme.font.sizes.large};
      margin-bottom: ${theme.spacings.medium};
      line-height: 2.4rem;
    }

    span {
      color: ${theme.colors.primary};
      font-family: ${theme.font.bold};
      font-size: ${theme.font.sizes.large};
    }
  `}
`;
