/* eslint-disable react/no-unescaped-entities */
import React, { useState, useCallback } from 'react';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import * as S from './styles';
import backgroundPop from '../../../../assets/image_card_planos_ccg_empresa/CCG_POP.png';
import logoPop from '../../../../assets/image_card_planos_ccg_empresa/ccg-pop.png';
import backgroundMulti from '../../../../assets/image_card_planos_ccg_empresa/CCG_MULTI.png';
import logoMulti from '../../../../assets/image_card_planos_ccg_empresa/ccg-multi.png';
import backgroundTop from '../../../../assets/image_card_planos_ccg_empresa/CCG_TOP.png';
import logoTop from '../../../../assets/image_card_planos_ccg_empresa/ccg-top.png';
import { usePlano } from '../../../../context/plano';
import MediaMatch from '../../../../components/MediaMatch';

export default function PranchetaEmpresa() {
  const { plano, setPlano } = usePlano();
  const [open, setOpen] = useState({});

  const toggleSelected = useCallback(
    (name) => {
      setOpen({
        [name]: !open[name],
      });
    },
    [open],
  );

  console.log(open);

  return (
    <S.Wrapper>
      <S.Card>
        <S.CardImage backgroundImage={backgroundPop}>
          <S.CardImageUp>
            <S.Logo backgroundImage={logoPop} />
            <S.SubTitulo>Prático e acessível como a saúde deve ser</S.SubTitulo>
          </S.CardImageUp>
          <S.CardImageDown>
            <S.DivButtn>
              <S.P>A partir de</S.P>
              <S.Span>R$ 40</S.Span>
              <S.Small>,76*</S.Small>
              <S.LittleP>*valor de 0 a 18 anos</S.LittleP>
            </S.DivButtn>
            <S.DivButtn>
              <a href="#form">
                <S.Button
                  onClick={() => setPlano('CCG Pop Empresas')}
                  border="0.2rem solid #f1be48"
                  background="#f1be48"
                >
                  Contrate Agora
                </S.Button>
              </a>
            </S.DivButtn>
          </S.CardImageDown>
        </S.CardImage>
        <MediaMatch greaterThan="medium">
          <S.Description>
            <S.TitleDescription>
              Acesso a mais de 20 clínicas próprias do CCG
            </S.TitleDescription>
            <S.SubTitleDescription>
              Incluindo a Cliníca Mais rede credenciada
            </S.SubTitleDescription>
            <S.Hr />
            <S.TitleDescription>
              Possibilidade de contratação
            </S.TitleDescription>
            <S.SubTitleDescription>
              ambulatorial ou hospitalar
            </S.SubTitleDescription>
            <br />
            <S.TitleDescription>Exames laboratorias</S.TitleDescription>
            <S.SubTitleDescription>
              Na rede Marques D'Almeida
            </S.SubTitleDescription>
            <S.Hr />
            <S.TitleDescription>Acesso a rede de hospitais</S.TitleDescription>
            <S.SubTitleDescription>
              credenciados em todo estado
            </S.SubTitleDescription>
          </S.Description>
        </MediaMatch>
        <MediaMatch lessThan="medium">
          <S.Header
            onClick={() => toggleSelected('CCG Pop Empresas')}
            hole="button"
            open={() => setOpen()}
          >
            <S.Icon>
              {open['CCG Pop Empresas'] ? (
                <IoIosArrowUp size={24} color="#009cde" />
              ) : (
                <IoIosArrowDown size={24} color="#009cde" />
              )}
            </S.Icon>
          </S.Header>
          <S.Content open={open['CCG Pop Empresas']}>
            <S.Description>
              <S.TitleDescription>
                Acesso a mais de 20 clínicas próprias do CCG
              </S.TitleDescription>
              <S.SubTitleDescription>
                Incluindo a Cliníca Mais rede credenciada
              </S.SubTitleDescription>
              <S.Hr />
              <S.TitleDescription>
                Possibilidade de contratação
              </S.TitleDescription>
              <S.SubTitleDescription>
                ambulatorial ou hospitalar
              </S.SubTitleDescription>
              <br />
              <S.TitleDescription>Exames laboratorias</S.TitleDescription>
              <S.SubTitleDescription>
                Na rede Marques D'Almeida
              </S.SubTitleDescription>
              <S.Hr />
              <S.TitleDescription>
                Acesso a rede de hospitais
              </S.TitleDescription>
              <S.SubTitleDescription>
                credenciados em todo estado
              </S.SubTitleDescription>
            </S.Description>
          </S.Content>
        </MediaMatch>
      </S.Card>

      {/* ---------Multi------*/}
      <S.Card>
        <S.CardImage backgroundImage={backgroundMulti}>
          <S.CardImageUp>
            <S.Logo backgroundImage={logoMulti} />
            <S.SubTitulo>
              Versátil e flexível para atender às suas prioridades
            </S.SubTitulo>
          </S.CardImageUp>
          <S.CardImageDown>
            <S.DivButtn>
              <S.P>A partir de</S.P>
              <S.Span>R$ 81</S.Span>
              <S.Small>,71*</S.Small>
              <S.LittleP>*valor de 0 a 18 anos</S.LittleP>
            </S.DivButtn>
            <S.DivButtn>
              <a href="#form">
                <S.Button
                  onClick={() => setPlano('CCG Multi Empresas')}
                  border="0.2rem solid #e56a54"
                  background="#e56a54"
                >
                  Contrate Agora
                </S.Button>
              </a>
            </S.DivButtn>
          </S.CardImageDown>
        </S.CardImage>
        <MediaMatch greaterThan="medium">
          <S.Description>
            <S.TitleDescription>
              Acesso a mais de 20 clínicas próprias do CCG
            </S.TitleDescription>
            <S.SubTitleDescription>
              Incluindo a Cliníca Mais rede credenciada
            </S.SubTitleDescription>
            <S.Hr />
            <S.TitleDescription>Consultas com médicos</S.TitleDescription>
            <S.SubTitleDescription>
              de rede externa de referência
            </S.SubTitleDescription>
            <br />
            <S.TitleDescription>Exames laboratorias</S.TitleDescription>
            <S.SubTitleDescription>
              Weimmann, clínicas Serdil e Radimagem
            </S.SubTitleDescription>
            <S.Hr />
            <S.TitleDescription>Plano hospitalar em</S.TitleDescription>
            <S.SubTitleDescription>
              quarto privativo ou semi-privativo
            </S.SubTitleDescription>
            <S.TitleDescription>
              Acesso a rede de hospitais credenciados emem todo o Estado
            </S.TitleDescription>
          </S.Description>
        </MediaMatch>
        <MediaMatch lessThan="medium">
          <S.Header
            onClick={() => toggleSelected('CCG Multi Empresas')}
            hole="button"
          >
            <S.Icon>
              {open['CCG Multi Empresas'] ? (
                <IoIosArrowUp size={20} color="#009cde" />
              ) : (
                <IoIosArrowDown size={20} color="#009cde" />
              )}
            </S.Icon>
          </S.Header>
          <S.Content open={open['CCG Multi Empresas']}>
            <S.Description>
              <S.TitleDescription>
                Acesso a mais de 20 clínicas próprias do CCG
              </S.TitleDescription>
              <S.SubTitleDescription>
                Incluindo a Cliníca Mais rede credenciada
              </S.SubTitleDescription>
              <S.Hr />
              <S.TitleDescription>Consultas com médicos</S.TitleDescription>
              <S.SubTitleDescription>
                de rede externa de referência
              </S.SubTitleDescription>
              <br />
              <S.TitleDescription>Exames laboratorias</S.TitleDescription>
              <S.SubTitleDescription>
                Weimmann, clínicas Serdil e Radimagem
              </S.SubTitleDescription>
              <S.Hr />
              <S.TitleDescription>Plano hospitalar em</S.TitleDescription>
              <S.SubTitleDescription>
                quarto privativo ou semi-privativo
              </S.SubTitleDescription>
              <S.TitleDescription>
                Acesso a rede de hospitais credenciados emem todo o Estado
              </S.TitleDescription>
            </S.Description>
          </S.Content>
        </MediaMatch>
      </S.Card>
      {/* -------CCG TOP-------*/}
      <S.Card>
        <S.CardImage backgroundImage={backgroundTop}>
          <S.CardImageUp>
            <S.Logo backgroundImage={logoTop} />
            <S.SubTitulo>
              Personalizado e completo, como o seu jeito de cuidar da sua saúde
            </S.SubTitulo>
          </S.CardImageUp>
          <S.CardImageDown>
            <S.DivButtn>
              <S.P>A partir de</S.P>
              <S.Span>R$ 171</S.Span>
              <S.Small>,84*</S.Small>
              <S.LittleP>*valor de 0 a 18 anos</S.LittleP>
            </S.DivButtn>
            <S.DivButtn>
              <a href="#form">
                <S.Button
                  border="0.2rem solid #3773c2"
                  background="#3773c2"
                  onClick={() => setPlano('CCG Top Empresas')}
                >
                  Contrate Agora
                </S.Button>
              </a>
            </S.DivButtn>
          </S.CardImageDown>
        </S.CardImage>
        <MediaMatch greaterThan="medium">
          <S.Description>
            <S.SubTitleDescription>
              Abrangência Nacional Cobertura em todo o Brasil
            </S.SubTitleDescription>
            <br />
            <S.TitleDescription>
              Acesso a mais de 20 clinícas próprias do CCG
            </S.TitleDescription>
            <S.SubTitleDescription>
              incluindo a Clínica Mais e rede referenciada
            </S.SubTitleDescription>
            <br />
            <S.SubTitleDescription>
              Consultas em extensa rede externa de médicos
            </S.SubTitleDescription>
            <br />
            <S.SubTitleDescription>
              Acesso a rede de laboratórios Weimann
            </S.SubTitleDescription>
            <S.Hr />
            <S.SubTitleDescription>
              Plano Hospitalar em auqrto privativo ou semi-privativo
            </S.SubTitleDescription>
            <S.SubTitleDescription>
              Acesso a rede nacional de hospitais credenciados
            </S.SubTitleDescription>
          </S.Description>
        </MediaMatch>
        <MediaMatch lessThan="medium">
          <S.Header
            onClick={() => toggleSelected('CCG Top Empresas')}
            hole="button"
          >
            <S.Icon>
              {open['CCG Top Empresas'] ? (
                <IoIosArrowUp size={20} color="#009cde" />
              ) : (
                <IoIosArrowDown size={20} color="#009cde" />
              )}
            </S.Icon>
          </S.Header>
          <S.Content open={open['CCG Top Empresas']}>
            <S.Description>
              <S.SubTitleDescription>
                Abrangência Nacional Cobertura em todo o Brasil
              </S.SubTitleDescription>
              <br />
              <S.TitleDescription>
                Acesso a mais de 20 clinícas próprias do CCG
              </S.TitleDescription>
              <S.SubTitleDescription>
                incluindo a Clínica Mais e rede referenciada
              </S.SubTitleDescription>
              <br />
              <S.SubTitleDescription>
                Consultas em extensa rede externa de médicos
              </S.SubTitleDescription>
              <br />
              <S.SubTitleDescription>
                Acesso a rede de laboratórios Weimann
              </S.SubTitleDescription>
              <S.Hr />
              <S.SubTitleDescription>
                Plano Hospitalar em auqrto privativo ou semi-privativo
              </S.SubTitleDescription>
              <S.SubTitleDescription>
                Acesso a rede nacional de hospitais credenciados
              </S.SubTitleDescription>
            </S.Description>
          </S.Content>
        </MediaMatch>
      </S.Card>
    </S.Wrapper>
  );
}
