import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.maxwidth};
    width: 100%;
    ${media.greaterThan('medium')`
      padding: 8rem 3.5rem 0;
    `}
    ${media.lessThan('medium')`
      margin: 0 auto;
      padding-left: 1rem;
    `}
  `}
`;

export const WrapperDiv = styled.div`
  width: 100%;
  justify-content: space-between;
  display: ${({ display }) => display};
`;

export const TextWrapper = styled.div`
  margin-bottom: 5.5rem;
`;

export const ButtonDiv = styled.div`
  margin: 0;
  display: flex;
  ${media.greaterThan('medium')`
    padding-left: 24rem;    
    margin-bottom: 10rem;
    margin-top: 10rem;
    align-content: space-between;

  `}
  ${media.lessThan('medium')`
    padding-right: 1rem;
    padding-left: 1rem;
    margin-bottom: 4rem;
    margin-top: 10rem;
    align-content: space-between;

  `}
`;

const menuOptionsModifiers = {
  selected: (theme) => css`
    background: ${theme.colors.secondary};
    border: 0;
    color: #fff;
  `,
};

const optionModifiers = {
  normal: (theme) => css`
    border: 0.1rem solid ${theme.colors.secondary};
  `,
  contraste: (theme, selected) => css`
    border: 0.1rem solid ${theme.colors.globalYellow};

    ${!!selected
    && css`
      background: ${theme.colors.globalYellow};
      color: #000;
    `};
  `,
};

export const Button = styled.button`
  ${({ theme, selected }) => css`
    height: 8rem;
    padding: 0 3.8rem;
    background: transparent;
    border-radius: ${theme.border.radius};
    font-weight: bold;
    margin-left: 4rem;
    color: ${theme.font.colors.primary};
    width: 35%;
    ${media.greaterThan('medium')`
      height: 6rem;
    
    `}
    ${media.lessThan('medium')`
      height: 5rem;
      width: 44%;
      margin-left: 2rem;
    `}

    ${!!selected && menuOptionsModifiers.selected(theme)}
    ${optionModifiers[theme.name](theme, selected)};
  `}
`;
