const string = `<p>O Centro de Endoscopia e Colonoscopia junta três fatores importantes para o modelo de saúde e de gestão de todo o Hospital Humaniza: equipe qualificada, equipamentos de qualidade, corpo clínico eficiente e gestão de dados.</p><br>
                <p>Nossas equipes multidisciplinares estão preparadas para trabalhar visando o tratamento precoce das doenças digestivas, através de protocolos voltados à prevenção e investigação. Com isso, buscamos resolver os problemas antes que seja necessário chegar ao ponto de precisar de uma cirurgia.</p><br>
                <p>Para estudar cada caso e embasar as tomadas de decisões, montamos duas salas de exames e as equipamos com excelentes máquinas de endoscopia e colonoscopia. Quando necessários exames complementares, o Centro de Diagnóstico de Imagem fica a um elevador de distância. Isso significa diagnósticos mais precisos e, consequentemente, resultados melhores. </p><br>`;

const slideSettings = {
  slidesToShow: 1,
  arrows: false,
  infinite: true,
  dots: true,
  lazyLoad: 'ondemand',
  speed: 500,
  autoplaySpeed: 4000,
  initialSlide: 0,
  autoplay: true,
};

export { string, slideSettings };
