import React, { createContext, useContext, useState } from 'react';

const ItemContext = createContext({} || null);

export default function ItemProvider({ children }) {
  const [item, setItem] = useState({
    id: '',
    pacote: '',
    especialidade: '',
  });

  return (
    <ItemContext.Provider
      value={{
        item,
        setItem,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
}

export function useItem() {
  const context = useContext(ItemContext);
  if (!context) throw new Error('useCount must be used within a ItemContext');
  return context;
}
