import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.bg_contraste};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 90rem;
  `}
`;

export const InfoWrapper = styled.div`
  width: 31.2rem;
  margin-left: 6.6rem;

  ${media.lessThan('medium')`
  margin-left: 0;
    
  `}
`;
export const ImageWrapper = styled.div`
  ${media.lessThan('medium')`
    img {
      width: 14.3rem;
    }
  `}
  ${media.greaterThan('medium')`
    img {
      width: 21rem;
    }
  `}
`;
export const Title = styled.h2`
  ${({ theme }) => css`
    text-transform: uppercase;
    color: ${theme.font.colors.primary};
    margin-bottom: 1rem;

    ${media.greaterThan('medium')`
      font-size: ${theme.font.sizes.xxlarge};
    `}
  `}
`;
export const Descrpition = styled.p`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.blackFonts};
    font-size: ${theme.font.sizes.xsmall};
    line-height: 1.8rem;

    ${media.greaterThan('medium')`
      font-size: ${theme.font.sizes.large};
    line-height: 2.4rem;

    
    `}
  `}
`;
