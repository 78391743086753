const string = `
<p>O Centro de Oncologia foi montado no oitavo andar com consultórios médicos dedicados, cabines individualizadas para aplicação dos quimioterápicos e leitos privativos para infusões de antineoplásicos de longa duração.</p><br>
<p>Toda a estrutura, dimensionada para 39 leitos de internação clínica e cirúrgica neste andar, foi pensada para fornecer um ambiente de trabalho agradável aos profissionais de saúde e proporcionar a melhor experiência ao paciente, com conforto e comodidade durante toda a sua jornada. Sem sair do Hospital, fazemos os exames necessários para o melhor diagnóstico e tratamento, operacionalizamos tratamento de infusão oncológica (com exceção da radioterapia) e realizamos cirurgias.</p><br>
<p>Conhecemos muito bem os desafios envolvidos no tratamento oncológico, então proporcionamos todo o suporte para os pacientes, para a equipe médica e para a família de quem está doente. Oferecemos uma linha de medicina integral com abordagem personalizada, que envolve todo o cuidado para a manutenção de uma vida equilibrada, focada na saúde física e mental desde o início. Tudo acompanhado de perto por um time multidisciplinar.</p><br>
<p>Entendemos que quando aumentamos a transparência e a confiança na relação entre médicos, equipe multidisciplinares e pacientes, fica cada vez mais possível colocar o paciente no centro das decisões, em uma posição em que pode participar ativamente das discussões e das escolhas sobre as melhores práticas durante seu tratamento.</p><br>`;

const slideSettings = {
  slidesToShow: 1,
  arrows: false,
  infinite: true,
  dots: true,
  lazyLoad: 'ondemand',
  speed: 500,
  autoplaySpeed: 4000,
  initialSlide: 0,
  autoplay: true,
};

export { string, slideSettings };
