/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slider from 'react-slick';
import SliderTimeLine from './SliderTimeLine';

import * as S from './styles';

import image1991 from '../../assets/institucional-assets/time_line/1991.png';
import image1992_1994 from '../../assets/institucional-assets/time_line/1992_1994.png';
import image1995 from '../../assets/institucional-assets/time_line/1995.png';
import image1996_1999 from '../../assets/institucional-assets/time_line/1996-1999.png';
import image2000 from '../../assets/institucional-assets/time_line/2000.png';
import image2001_2003 from '../../assets/institucional-assets/time_line/2001-2003.png';
import image2004 from '../../assets/institucional-assets/time_line/2004.png';
import image2005 from '../../assets/institucional-assets/time_line/2005.png';
import image2008 from '../../assets/institucional-assets/time_line/2008.png';
import image2011 from '../../assets/institucional-assets/time_line/2011.png';
import image2012 from '../../assets/institucional-assets/time_line/2012.png';
import image2014 from '../../assets/institucional-assets/time_line/2014.png';
import image2017 from '../../assets/institucional-assets/time_line/2017.png';
import image2019 from '../../assets/institucional-assets/time_line/2019.png';
import image2020 from '../../assets/institucional-assets/time_line/2020.png';
import image2021 from '../../assets/institucional-assets/time_line/2021.png';

const TimeLine = () => {
  const settings = {
    slidesToShow: 5,
    arrows: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1375,
        settings: {
          arrows: false,
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3.3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          arrows: false,
          slidesToShow: 2.8,
        },
      },
      {
        breakpoint: 570,
        settings: {
          arrows: false,
          slidesToShow: 2.3,
        },
      },
      {
        breakpoint: 375,
        settings: {
          arrows: false,
          slidesToShow: 1.1,
        },
      },
    ],
  };

  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleWrapper>
          <div className="title_ajust__sizes">
            <S.SmallTitle>O GRUPO CCG SAÚDE</S.SmallTitle>
            <S.Title>Linha do Tempo</S.Title>
            <S.Description>
              Nascido pelas mãos de profissionais da saúde com extensa
              experiência em prestação de serviços, o Centro Clínico Gaúcho
              iniciou sua trajetória já reconhecida pela comunidade. Com a
              criação do Centro Gaúcho de Medicina Ocupacional (CGMO) e a
              integração do Laboratório Marques D’Almeida, o Grupo CCG continuou
              a crescer e oferecer soluções cada vez mais completas a todos os
              seus clientes.
            </S.Description>
          </div>
        </S.TitleWrapper>

        <S.SlideWrapper>
          <Slider {...settings}>
            <SliderTimeLine cardStyle="date" years="anos 90" />
            <SliderTimeLine
              dateYears="1991"
              years="anos 90"
              description={['Fundação do Centro Clínico Gaúcho']}
              image={image1991}
            />
            <SliderTimeLine
              dateYears="1992 a 1994"
              description={[
                'Inauguração das clínicas em Esteio (RS), Gravataí (RS) e Cachoeirinha (RS)',
              ]}
              image={image1992_1994}
            />
            <SliderTimeLine
              dateYears="1995"
              description={[
                'Inauguração da primeira clínica em Porto Alegre (RS)',
                'Inauguração da Maternidade em Canoas, onde mais de 1.600 crianças nasceram.',
              ]}
              image={image1995}
            />
            <SliderTimeLine
              dateYears="1996 a 1999"
              description={[
                'Modernização da Clínica de Canoas com a implantação de bloco cirúrgico e expansão para as cidades de Viamão e Guaíba',
              ]}
              image={image1996_1999}
            />

            <SliderTimeLine cardStyle="date" years="Anos 2000" />
            <SliderTimeLine
              dateYears="2000"
              years="2000"
              description={['Inauguração da primeira clínica em Alvorada (RS)']}
              image={image2000}
            />
            <SliderTimeLine
              dateYears="2001 a 2003"
              description={[
                'Inauguração de mais duas clínicas em Porto Alegre',
              ]}
              image={image2001_2003}
            />
            <SliderTimeLine
              dateYears="2004"
              years="anos 90"
              description={[
                'Aquisição da operadora Serclin, de São Leopoldo (RS), dando início à atuação na região do Vale do Sinos.',
              ]}
              image={image2004}
            />
            <SliderTimeLine
              dateYears="2005"
              description={[
                'Introdução de um novo modelo de gestão com a missão de fortalecer e preservar a solidez do negócio.',
              ]}
              image={image2005}
            />
            <SliderTimeLine
              dateYears="2008"
              description={[
                'Criação do Conselho de Administração do Centro Clínico Gaúcho, com o objetivo de garantir a gestão dos serviços a partir de práticas constantemente atualizadas.',
              ]}
              image={image2008}
            />
            <SliderTimeLine
              dateYears="2011"
              description={[
                'Aniversário de 20 anos',
                'Unificação dos serviços de São Leopoldo em uma nova Central de Atendimento, criando um novo modelo a ser seguido por todas as unidades. ',
              ]}
              image={image2011}
            />
            <SliderTimeLine
              dateYears="2012"
              description={[
                'Fundação do Centro Gaúcho de Medicina Ocupacional',
              ]}
              image={image2012}
            />
            <SliderTimeLine
              dateYears="2014"
              description={[
                'Inauguração de clínica em Alvorada (RS) com 700m²',
              ]}
              image={image2014}
            />
            <SliderTimeLine
              dateYears="2017"
              description={[
                'Inauguração de clínicas em Taquari (RS) e Montenegro (RS)',
              ]}
              image={image2017}
            />

            <SliderTimeLine
              dateYears="2019"
              description={[
                'Aporte de investimento do Fundo Kinea',
                'Inauguração da Clínica Zona Sul, em Porto Alegre',
              ]}
              image={image2019}
            />

            <SliderTimeLine
              dateYears="2020"
              description={[
                'Nasce a marca CCG Saúde. Lançamento da nova identidade visual, além de lançamento de novos serviços e produtos',
                'Inauguração da Clínica Mais e expansão para Santa Catarina',
              ]}
              image={image2020}
            />

            <SliderTimeLine
              dateYears="2021"
              description={[
                'Inauguração do Hospital Humaniza, uma nova referência em saúde em uma estrutura completa.',
              ]}
              image={image2021}
            />
            <SliderTimeLine
              dateYears="2021 a 2022"
              description={[
                'O CCG Saúde e as demais empresas do grupo, Centro Clínico Rio Grande, Hospital Humaniza e Laboratório Marques D´Almeida passaram a fazer parte do Grupo Hapvida NotreDame Intermédica, a maior operadora de Saúde do Brasil, com mais de 15 milhões de beneficiários de saúde e odontologia.',
              ]}
              image={image2021}
            />
          </Slider>
        </S.SlideWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default TimeLine;
