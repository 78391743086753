/* eslint camelcase: 0 */
export const actions_types = {
  SET_LOCATION: '@location/SET_LOCATION',
};

export function setLocationToMap(coords) {
  return {
    type: actions_types.SET_LOCATION,
    payload: { coords },
  };
}
