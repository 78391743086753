import React from 'react';

import GlobalContainer from '../../components/_layout/Container';
import {
  Title,
  Description,
  LittleTitle,
} from '../../components/TextComponents';
import Counter from '../../components/Counter';
import MediaMatch from '../../components/MediaMatch';

import * as S from './styles';
import Form from './Form';
import initialValues from './utils/valuesForm';

export default function Orcamento() {
  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TextWrapper>
          <Title size={{ web: '4.2rem' }} label="Orçamento" />
          <Description
            width="95rem"
            label="Preencha o formulário e solicite um orçamento para cirurgias e procedimentos particulares no Hospital Humaniza.
                   O prazo de retorno com o valor orçado é de 48 horas via e-mail. Após a aprovação do valor orçado, entre em contato por e-mail ou telefone para formalizar o agendamento."
          />
        </S.TextWrapper>
        <Counter>
          <LittleTitle
            style={{ fontSize: '1.2rem' }}
            color="secondary"
            label="Horário de funcionamento:"
          />
          <MediaMatch lessThan="medium">
            <S.InformationWrapper>
              <S.Text>De segunda a sexta-feira, das 9h às 17h.</S.Text>
              <br />
              <S.Text>
                Informações pelo telefone (51) 99712-9392, ou pelo e-mail
                {' '}
                <strong>comercial@hospitalhumaniza.com.br</strong>
              </S.Text>
            </S.InformationWrapper>
          </MediaMatch>
          <MediaMatch greaterThan="medium">
            <S.InformationWrapper>
              <S.Text>De segunda a sexta-feira, das 9h às 17h.</S.Text>
              <br />
              <S.Text>
                Informações pelo telefone (51) 99712-9392, ou pelo e-mail
                {'  '}
                <strong>comercial@hospitalhumaniza.com.br</strong>
              </S.Text>
            </S.InformationWrapper>
          </MediaMatch>
        </Counter>
        <Form initialValues={initialValues} />
      </S.Wrapper>
    </GlobalContainer>
  );
}
