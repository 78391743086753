import image from '../../../assets/planosEconvenios/image.png';

export const data = [
  {
    id: 1,
    title: 'Centro Clínico Gaúcho',
    image,
    phone: '(63) 9999-9999',
    siteUrl: 'www.humaniza.com.br',
  },
  {
    id: 2,
    title: 'Centro Clínico Gaúcho',
    image,
    phone: '(63) 9999-9999',
    siteUrl: 'www.humaniza.com.br',
  },
  {
    id: 3,
    title: 'Centro Clínico Gaúcho',
    image,
    phone: '(63) 9999-9999',
    siteUrl: 'www.humaniza.com.br',
  },
  {
    id: 4,
    title: 'Centro Clínico Gaúcho',
    image,
    phone: '(63) 9999-9999',
    siteUrl: 'www.humaniza.com.br',
  },
  {
    id: 5,
    title: 'Centro Clínico Gaúcho',
    image,
    phone: '(63) 9999-9999',
    siteUrl: 'www.humaniza.com.br',
  },
  {
    id: 6,
    title: 'Centro Clínico Gaúcho',
    image,
    phone: '(63) 9999-9999',
    siteUrl: 'www.humaniza.com.br',
  },
  {
    id: 7,
    title: 'Centro Clínico Gaúcho',
    image,
    phone: '(63) 9999-9999',
    siteUrl: 'www.humaniza.com.br',
  },
  {
    id: 8,
    title: 'Centro Clínico Gaúcho',
    image,
    phone: '(63) 9999-9999',
    siteUrl: 'www.humaniza.com.br',
  },
];
