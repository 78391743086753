import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

export default function CardClinic({
  image, title, phone, siteUrl,
}) {
  return (
    <S.Wrapper>
      <S.Image src={image} />
      <S.InfoWrapper>
        <S.Title>{title}</S.Title>
        <S.BoxInformation>
          <S.Info>
            <span>Fone: </span>
            <p>{phone}</p>
          </S.Info>
          <S.Info>
            <span>Site :</span>
            <p>{siteUrl}</p>
          </S.Info>
        </S.BoxInformation>
      </S.InfoWrapper>
    </S.Wrapper>
  );
}

CardClinic.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
};
