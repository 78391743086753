import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AccessibilityMenu from '../../components/AccessibilityMenu';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';

import * as S from './styles';

import small_bg from '../../assets/institucional-assets/small_bg.svg';
import big_bg from '../../assets/institucional-assets/big_bg.svg';
import heart from '../../assets/institucional-assets/heart.svg';
import virtual from '../../assets/institucional-assets/virtual.svg';
import CardsInstitucional from '../../components/CardsInstitucional';
import TimeLine from '../../components/TimeLine';
import { ButtonLink } from '../../components/Button/modules';
import Breadcrumbs from '../../components/Breadcrumbs';

const Institucional = () => {
  const data = [
    {
      image: heart,
      title: 'Disposição e energia positiva',
    },
    {
      image: heart,
      title: 'Cuidado e carinho a cada paciente',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <AccessibilityMenu />
      <Menu />
      <Breadcrumbs />
      <div className="bg">
        <S.Wrapper>
          <S.TitleWrapper>
            <S.SmallTitle>Institucional</S.SmallTitle>
            <S.Title>Sobre o Grupo Hapvida NotreDame Intermédica</S.Title>
            <S.Description>
              <p>O Grupo Hapvida NotreDame Intermédica é, hoje, a maior operadora de saúde do Brasil, com mais 15 milhões de beneficiários de saúde e odontologia. Fruto da fusão entre o Hapvida, que começou suas operações em 1979 com a inauguração do Hospital Antonio Prudente, e a NotreDame Intermédica, fundada em 1968 e pioneira em Medicina Preventiva no País, oferece saúde integral e odontologia para Clientes empresariais e individuais em todas as regiões: Norte, Nordeste, Centro-Oeste, Sudeste e Sul.</p>
              <p>Com ampla participação no mercado em planos de saúde e odontológicos, o Grupo Hapvida NotreDame Intermédica é uma das maiores empresas verticalizadas do mundo, atuando com intensidade para se consolidar em seu segmento por meio de uma combinação de crescimento orgânico e aquisições. Com mais de 68 mil colaboradores, cerca de 27 mil médicos e 33 mil dentistas credenciados, tem a missão de garantir o acesso à saúde de qualidade a um custo eficiente.</p>
              <p>Conta com rede assistencial própria composta por 87 hospitais, 77 prontos atendimentos e 318 clínicas médicas. Também dispõe aos seus beneficiários 269 centros de diagnóstico por imagem e coleta laboratorial, dos quais cerca de 100 operações fazem parte do Laboratório NotreLabs, uma rede nacional com capacidade de processar 4,5 milhões de análises clínicas e 500 mil exames de imagens por mês.</p>
              <p>Um dos importantes marcos da empresa ocorreu com a abertura de capital, em 2018, quando passou a negociar suas ações na B3, o que potencializou ainda mais a capacidade de investir em inovação, tecnologia, processos, infraestrutura e capacitação de pessoal, sempre tendo como objetivo final a satisfação dos beneficiários.</p>
              <p>A operadora pensa em cada etapa do atendimento médico. Por isso, tem como foco oferecer sempre a melhor experiência: agendamento prático de consultas, atendimento humanizado e Medicina Preventiva, bem como uma gestão eficiente da saúde dos colaboradores das empresas Clientes.</p>
              <p>Como parte de sua estratégia, o Grupo Hapvida NotreDame Intermédica também conta com um time especializado e dedicado que cuida dos aspectos Ambientais, Sociais e de Governança (ASG), buscando perpetuar a sustentabilidade do negócio. Em 2021, por exemplo, pelo segundo ano consecutivo a empresa foi considerada “carbono neutro” por seu trabalho para a compensação de 100% das emissões.</p>
              <p>Além disso, os beneficiários, dentistas, corretores e corpo clínico do Grupo Hapvida NotreDame Intermédica têm acesso a clubes de vantagens e descontos em drogarias e parceiros dos segmentos de compras on-line, educação e cursos, entretenimento, saúde e bem-estar, comércio, serviços, viagens e produtos odontológicos.</p>
            </S.Description>
          </S.TitleWrapper>
          <S.Banner background={[big_bg, small_bg]} />
          <TimeLine />

          {/* <S.VirtualWrapper>
            <S.ImageWrapper>
              <img src={virtual} alt="" />
            </S.ImageWrapper>
            <S.TitleWrapper>
              <S.SmallTitle>ESTRUTURA</S.SmallTitle>
              <S.Title>Tour Virtual</S.Title>
              <S.Description>
                Placerat sed ultrices vel eu. Ut a tempus malesuada viverra
                congue fermentum, cras. Sed dolor sagittis fermentum nullam.
              </S.Description>

              <ButtonLink color="secondary" url="/estrutura">
                Saiba mais
              </ButtonLink>
            </S.TitleWrapper>
          </S.VirtualWrapper> */}
        </S.Wrapper>
      </div>
      <Footer />
    </S.Container>
  );
};

export default Institucional;
