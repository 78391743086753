import React from 'react';
import { Title } from '../../../../../components/TextComponents';
import Input from '../../../../../components/InputText';

import * as S from './styles';
import { InputBox, Wrapper } from '../common';

export default function InformacoesPaciente({ errors, touched }) {
  // console.log(errors);
  // console.log(touched);
  return (
    <Wrapper>
      <Title
        size={{ web: '2.8rem', mobile: '2.4rem' }}
        label="Informações do paciente"
      />
      <S.Fields>
        <InputBox>
          <Input name="fullName" label="Nome Completo *" />
          {errors.fullName && touched.fullName && (
            <S.Error>{errors.fullName}</S.Error>
          )}
        </InputBox>
        <InputBox flex>
          <div className="wrapperFlex">
            <Input name="email" label="Email*" />
            {errors.email && touched.email && <S.Error>{errors.email}</S.Error>}
          </div>
          <div className="wrapperFlex">
            <Input name="phone" label="Telefone" />
            {errors.phone && touched.phone && <S.Error>{errors.phone}</S.Error>}
          </div>
        </InputBox>
        <InputBox>
          <Input
            sizeLimited="true"
            name="dateBorn"
            label="data de nascimento"
          />
          {errors.dateBorn && touched.dateBorn && (
            <S.Error>{errors.dateBorn}</S.Error>
          )}
        </InputBox>
      </S.Fields>
    </Wrapper>
  );
}
