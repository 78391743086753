export default {
  fullName: '',
  email: '',
  phone: '',
  dateBorn: '',
  codigoCartaoConvenio: '',
  planoSaude: 'ccg',
  doctorName: '',
  doctorEmail: '',
  doctorPhone: '',
  tipoProcedimento: 'ambulatorial',
  codigo: '',
  procedimento: '',
  dataPrevista: '',
  diasInternacao: '',
  tipoInternacao: 'privativo',
  materiais: 'sim',
  descricao: '',
  quantidade: '',
  marca: '',
  observacoes: '',
};
