import React, { useState } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Field, Formik } from 'formik';

import InputText from '../InputText';
import Radio from '../Radio';
import TextArea from '../TextArea';
import Select from '../Select';

import * as S from './styles';
import api from '../../services/api';
import { usePlano } from '../../context/plano';

const Form = () => {
  const { plano } = usePlano();
  const [loading, setLoading] = useState(false);
  const [planoCcg, setPlanoCcg] = useState();
  const [radio, setRadio] = useState('nao');
  console.log(radio);
  const handleSubmit = async (values) => {
    setLoading(true);

    console.log({
      plano,
      nome: values.name,
      email: values.email,
      telefone: values.telefone,
      mensagem: values.message,
    });

    try {
      await api.post('/email-send/planos-ccg/', {
        plano,
        nome: values.name,
        email: values.email,
        telefone: values.telefone,
        mensagem: values.message,
      });

      setLoading(false);

      Swal.fire({
        title: '<h1 style="font-family:diodrum-bold">Formulário enviado</h1>',
        html:
          '<span style="font-size: 1.6rem; font-family:diodrum">Agradecemos o seu contato. Reforçamos que conforme RN nº. 323 da Agência Nacional de Saúde Suplementar (ANS), as solicitações serão respondidas em até sete (7) dias úteis após a abertura do chamado.</span>',
        icon: 'success',
        width: '50rem',

        confirmButtonColor: '#6EC6E6',
        confirmButtonText: 'Fechar',
      });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: '<h1 style="font-family:diodrum-bold">Oops...</h1>',

        html:
          '<span style="font-size: 1.6rem; font-family:diodrum">Algo deu errado no envio, tente novamente</span>',
      });
    }
  };

  const schemaValidate = Yup.object().shape({
    name: Yup.string().required('O nome e obrigatório'),
    email: Yup.string()
      .email('Digite um e-mail valido')
      .required('O e-mail e obrigatório'),
    telefone: Yup.string().required('O numero de telefone e obrigatório'),
    message: Yup.string().required('A mensagem e obrigatória'),
  });

  const initialValues = {
    plano: '',
    alert: 'nao',
    name: '',
    email: '',
    telefone: '',
    message: '',
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={schemaValidate}
      initialValues={initialValues}
      render={({ errors, touched }) => (
        <S.Form>
          <S.TextWrapper>
            <h1>Preencha o formulário para contratar o plano:</h1>
          </S.TextWrapper>
          <S.FieldsWrapper>
            <S.InputBox>
              <InputText
                error={errors.name && touched.name && errors.name}
                name="name"
                type="text"
                label="nome completo *"
                disabled={radio === 'nao'}
              />
              {errors.name && touched.name && <S.Error>{errors.name}</S.Error>}
            </S.InputBox>

            <S.InputBox flex>
              <div className="wrapperFlex">
                <InputText
                  error={errors.email && touched.email && errors.email}
                  name="email"
                  type="text"
                  label="e-mail *"
                  disabled={radio === 'nao'}
                />
                {errors.email && touched.email && (
                  <S.Error>{errors.email}</S.Error>
                )}
              </div>
              <div className="wrapperFlex">
                <InputText
                  error={errors.telefone && touched.telefone && errors.telefone}
                  name="telefone"
                  type="text"
                  label="telefone *"
                  disabled={radio === 'nao'}
                />
                {errors.telefone && touched.telefone && (
                  <S.Error>{errors.telefone}</S.Error>
                )}
              </div>
            </S.InputBox>
            <S.WithSelectWrapper>
              <Field name="plano">
                {({ field }) => (
                  <Select
                    onChange={(event) => setPlanoCcg(event.target.value)}
                    {...field}
                    label="plano"
                    disabled={radio === 'nao'}
                  >
                    <option onClick={() => setPlanoCcg(plano)} value={plano}>
                      {plano}
                    </option>
                  </Select>
                )}
              </Field>
            </S.WithSelectWrapper>

            <S.InputBox>
              <Field
                name="message"
                render={({ field }) => (
                  <TextArea
                    {...field}
                    placeholder="Digite sua mensagem"
                    label="mensagem *"
                    error={errors.message && touched.message && errors.message}
                    disabled={radio === 'nao'}
                  />
                )}
              />
              {errors.message && touched.message && (
                <S.Error>{errors.message}</S.Error>
              )}
            </S.InputBox>
            <S.RequiredMessage>Campos obrigatórios *</S.RequiredMessage>

            <div className="content_add">
              <span>
                Para adequado encaminhamento administrativo desta ocorrência,
                solicitamos autorização para disponibilizar o referido texto na
                íntegra às partes envolvida
              </span>
              <S.RadioButtonsWrapper>
                <S.BoxRadioButtons>
                  <Radio
                    id="alertSim"
                    label="Sim"
                    name="alert"
                    value="sim"
                    onClick={() => setRadio('sim')}
                  />
                  <Radio
                    id="alertNao"
                    label="Não"
                    name="alert"
                    value="nao"
                    onClick={() => setRadio('nao')}
                  />
                </S.BoxRadioButtons>
              </S.RadioButtonsWrapper>
            </div>

            <S.ButtonWrapper>
              <S.SubmitButton
                type="submit"
                disabled={radio === 'nao'}
                cursor={radio === 'nao' ? 'no-drop' : 'pointer'}
              >
                {loading ? 'Enviando, aguarde...' : 'Enviar Mensagem'}
              </S.SubmitButton>
            </S.ButtonWrapper>
          </S.FieldsWrapper>
        </S.Form>
      )}
    />
  );
};

export default Form;
