import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import theme from '../../../styles/themes/theme';

export default function LittleTitle({ label, color, ...rest }) {
  return (
    <S.LittleTitle s {...rest} color={color}>
      {label}
    </S.LittleTitle>
  );
}

LittleTitle.defaultProps = {
  color: theme.font.colors.primary,
};

LittleTitle.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
};
