import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import LittleTitle from '../TextComponents/LittleTitle';
import Title from '../TextComponents/Title';
import Description from '../TextComponents/Description';
import { ButtonLink } from '../Button/modules';

export default function SectionInfoCard({
  background,
  littleTitle,
  title,
  description,
  url,
  image,
  alignment,
  botao,
}) {
  return (
    <S.Wrapper background={background}>
      <S.GridContainer alignment={alignment}>
        <S.SectionTxt>
          <div className="wrapper__text">
            <div className="little__text">
              <LittleTitle label={littleTitle} />
            </div>
            <div className="title">
              <Title
                lineHeight={{ web: '4.5rem', mobile: '3rem' }}
                size={{ web: '4.2rem', mobile: '2.4rem' }}
                label={title}
              />
            </div>
            <div className="description">
              <Description label={description} />
            </div>
            <ButtonLink color="secondary" size="large" to={url}>
              {botao}
            </ButtonLink>
          </div>
        </S.SectionTxt>
        <S.SectionImage>
          <S.ImageWrapper>
            <img src={image} alt="Esta e uma imagem" />
          </S.ImageWrapper>
        </S.SectionImage>
      </S.GridContainer>
    </S.Wrapper>
  );
}

SectionInfoCard.defaultProps = {
  background: 'white',
  botao: 'Saiba mais...',
};

SectionInfoCard.propTypes = {
  background: PropTypes.oneOf(['white', 'blue']),
  littleTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['left', 'right']).isRequired,
  botao: PropTypes.string,
};
