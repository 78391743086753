/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import * as S from './styles';

const Radio = ({
  name, label, id, value, onClick,
}) => (
  <S.Container>
    <S.Input
      type="radio"
      name={name}
      id={id}
      value={value}
      onClick={onClick}
    />
    <S.Label htmlFor={id}>{label}</S.Label>
  </S.Container>
);

export default Radio;
