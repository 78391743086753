const string = `<p>Responsável por um grande número de entradas no Hospital, o Centro de Ortopedia e Traumatologia foi instalado em uma estrutura ampla e organizada no quinto andar do prédio, reunindo um grupo experiente de ortopedistas e outros especialistas, para que todos atendam juntos e sigam o mesmo modelo assistencial, com um atendimento mais humanizado e muito ágil.</p><br>
                <p>Assim, configuramos um centro de resolução rápida de traumatologia e ortopedia e conseguimos atender aos pacientes, confortavelmente, por ordem de chegada, nos consultórios médicos. Na hora do diagnóstico, se necessário fazer um exame, basta acessar o Centro de Diagnóstico por Imagem e realizar o que é preciso.</p><br>
                <p>Caso seja necessária a internação, estamos preparados para receber nos leitos de internação clínica e cirúrgica. Uma sala de gesso e redução de fraturas completa a estrutura, oferecendo apoio às cirurgias do aparelho locomotor (coluna, quadril, joelho, ombro, mãos e pés).</p><br>`;

const slideSettings = {
  slidesToShow: 1,
  arrows: false,
  infinite: true,
  dots: true,
  lazyLoad: 'ondemand',
  speed: 500,
  autoplaySpeed: 4000,
  initialSlide: 0,
  autoplay: true,
};

export { string, slideSettings };
