import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Description = styled.p`
  ${({ theme, width }) => css`
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.blackFonts};

    font-size: ${theme.font.sizes.medium};
    line-height: 2.5rem;
    max-width: ${width};
    width: 100%;

    ${media.greaterThan('medium')`
      font-size: ${theme.font.sizes.xlarge}
    `}
  `}
`;
