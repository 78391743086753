import React from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import GlobalContainer from '../../components/_layout/Container';

import {
  LittleTitle,
  Title,
  Description,
  SupportText,
  SimpleText,
} from '../../components/TextComponents';
import Filter from './components/Filter';
import Grid from './components/Grid';
import Table from './components/Table';

import * as S from './styles';

export default function OrientacaoInternacao() {
  const horarioVisita = `
  <ul>
    <li><p>Quando a sua visita for no Centro de Tratamento Intensivo, combine previamente com a equipe assistencial, o horário da sua chegada;</p></li>
    <li><p>A sua saída do Hospital está condicionada à alta médica. Após a alta, é importante que a liberação do leito ocorra em até uma hora. O seu leito livre gera espaço para outro paciente;</p></li>
    <li><p>Nossos ambientes foram projetados para oferecer conforto, segurança e funcionalidade;</p></li>
    <li><p>Quando necessitar acesse a equipe de enfermagem através da chamada de Enfermagem;</p></li>
    <li><p>Em caso de dúvidas sobre o uso do chuveiro, ar-condicionado, cama e outras funcionalidades, entre em contato com a nossa equipe.</p></li>
  </ul>`;

  const InfoGeral = `
  <ul>
  <li><p>Recomendamos que o seu celular e outros dispositivos eletrônicos sejam mantidos no modo silencioso. A voltagem de todas as tomadas é 220 volts.</p></li>
  <li><p>Em caso de sinistro/incêndio, primeiramente, procure e siga as orientações de nossos funcionários. Caso seja necessário abandonar o andar e/ou o estabelecimento, obedeça a sinalização de emergência localizada nos corredores da instituição e dirija-se para as escadas de emergência. <a class="destaque">Lembre-se: nunca use o elevador nessas circunstâncias.</a> </p></li>
  </ul>`;

  const boletimGeral = '<ul><li><p>Pacientes internados no Centro de Terapia Intensiva: as informações do quadro de saúde deverão ser combinadas entre a equipe assistencial e familiar/responsável.</p></li></ul>';

  const aliemtacao = `<ul>
    <li><p>Oferece assistência nutricional aos pacientes
    internados, além de produzir e distribuir refeições
    de acordo com a dieta prescrita pelo médico e/ou
    nutricionista. O atendimento nutricional, assim
    como as preparações alimentares fornecidas aos
    pacientes, está incluído no valor da diária
    hospitalar, tendo a cobertura do plano de saúde.</p></li>
  </ul>`;

  const enxoval = '<p>O Hospital fornece todo o enxoval exclusivo para o bem-estar do paciente. As trocas são realizadas conforme a política Institucional.</p>';

  const mobiliario = '<p>Para o seu conforto, nossos quartos possuem camas elétricas, televisores, frigobar, poltronas e/ou cadeiras para o acompanhante. Em caso de dúvidas sobre o manuseio, solicite auxílio a um profissional do setor.</p>';

  const cafeteria = '<p>O Hospital Humaniza conta com um espaço de café e lanches. A cafeteria funciona de segunda a sábado, das 7h às 22h, localizada ao lado da recepção principal do Hospital.</p>';

  const facilite = `<ul>
  <li><p>Após realizar as refeições deixe a  bandeja organizada com todos os utensílios que acompanham a refeição (talheres, térmica, prato, xícara, pires, sopeira, etc.) para futuro recolhimento e higienização</p></li>
  </ul>`;

  const consumo = `
  <ul>
    <li><p>Consuma somente os alimentos oferecidos pelo Departamento de Nutrição e Dietética deste hospital, respeitando a
    prescrição nutricional do médico e/ou nutricionista a fim de assegurar o sucesso do tratamento proposto.</p></li>
    <li><p>Por uma questão de segurança ao paciente, não é indicado o consumo de alimentos de origem externa. Eles podem
    significar risco de contaminação</p></li>
  </ul>`;

  const internacao = `
    <p>No ato da internação, o paciente deverá estar acompanhado por familiar (maior de 18 anos) ou responsável e estar munido de RG, CPF do titular e do acompanhante e carteira do convênio. </p><br />
    <p>O familiar deverá assinar o termo de responsabilidade e demais documentações necessárias em caso de menor ou incapaz. É de suma importância que todos os dados fornecidos estejam corretos para que, em caso de necessidade, o hospital possa entrar em contato.</p>
    `;

  const assistenciaMedica = `
    <p>Nosso corpo de enfermagem é formado por profissionais capacitados que prestam assistência de qualidade diretamente aos nossos pacientes, algumas dúvidas podem ser questionadas aos profissionais de enfermagem como por exemplo aceitação da alimentação, temperatura corporal, higiene e conforto, sono e repouso. Dúvidas relacionadas a conduta médica como medicamentos prescritos, exames solicitados, condições clínicas do paciente podem ser esclarecidas junto ao médico responsável no momento da visita que é realizada diariamente.</p></br />
    <p>Em decorrência da constante capacitação e da continuada atualização de nossos profissionais, considerando as medidas de segurança e a qualidade no atendimento, esta unidade hospitalar não admite a prestação de serviços por profissionais de saúde não cadastrados e autorizados.</p>
  `;

  const diariasDespesas = `
    <p>Todo paciente deverá ter um responsável pelas suas despesas, que assinará o contrato de prestação de serviços. Nem todos os materiais, medicamentos e procedimentos têm cobertura integral. Assim, recomenda-se que o paciente tome ciência de todos os direitos e exclusões junto ao Convênio/Seguro Saúde durante o período de internação.</p><br />
    <p>Os serviços cobertos por uma diária hospitalar são:</p>
    <ul>
      <li><p>Uso dos aposentos;</p></li>
      <li><p>Refeições dos pacientes; </p></li>
      <li><p>Assistência e cuidados de enfermagem; </p></li>
      <li><p>Honorários da equipe do hospital.</p></li>
    </ul><br />
    <p>A diária passa a vigorar a partir das 12h e vence às 12h do dia seguinte. O hospital reserva-se o direito de cobrar qualquer despesa não incluída nos serviços cobertos, refeições para acompanhantes ou despesas extras por danos eventualmente causados em seu patrimônio por pacientes, acompanhantes e visitantes. 
    Em caso de optar por acomodação superior àquela a que tenha direito pelo convênio, será cobrada uma taxa diária de diferença, além dos honorários médicos, disponíveis para conhecimento no setor de internações. É necessária a verificação da disponibilidade de leitos.</p><br/>
    <p>O CCG Saúde | NotreDame Intermédica esclarece que os médicos ou, qualquer profissional, da empresa não realizam ligações telefônicas ou enviam mensagens solicitando novos exames mediante depósitos bancários. Caso seja necessário a realização de novos exames será orientado nas consultas presenciais ou online (telemedicina).</p>
    `;

  const hotelaria = `
      <p>Para o seu conforto, nossos quartos possuem camas elétricas. Em caso de dúvidas sobre o manuseio, solicite auxílio a um profissional de enfermagem.</p>
    `;
  const nutricacao = `
    <p>O Departamento de Nutrição e Dietética do Hospital Humaniza oferece assistência nutricional aos pacientes internados, além de produzir e distribuir refeições de acordo com a dieta prescrita pelo médico e/ou nutricionista. O atendimento nutricional, assim como as preparações alimentares fornecidas aos pacientes, está incluído no valor da diária hospitalar, tendo a cobertura do plano de saúde. As refeições para pacientes obedecerão aos seguintes horários:</p>`;

  const mesaAuxiliar = `
    <p>A mesa auxiliar localizada ao lado do paciente serve exclusivamente de apoio para receber a bandeja com a refeição. Mantenha livre de objetos pessoais para evitar o risco de contaminação alimentar.</p><br />
    <p>Para agilizar o atendimento ao paciente, após realizar as refeições, solicitamos que deixe a bandeja organizada com todos os utensílios que acompanham a refeição (talheres, térmica, prato, xícara, pires, sopeira etc.) para futuro recolhimento e higienização.</p><br />
    <p class="destaque">Atenção: Não poderá ser servido ao paciente nenhum tipo de alimento que não esteja autorizado pelo serviço de nutrição. É proibida a entrada no hospital com alimentos e bebidas.</p><br />
    <p>Consuma somente os alimentos oferecidos pelo Departamento de Nutrição e Dietética deste hospital, respeitando a prescrição nutricional do médico e/ou nutricionista a fim de assegurar o sucesso do tratamento proposto. Por uma questão de segurança ao paciente, não é indicado o consumo de alimentos de origem externa. Eles podem significar risco de contaminação. </p><br />
    <p>Caso exista a necessidade de incluir alimentos extras na dieta do paciente, converse com o nutricionista. Ele é o profissional capacitado para adaptar o cardápio, esclarecer dúvidas relacionadas à alimentação e orientar, levando em consideração as particularidades da dieta prescrita.</p>
    `;

  const refeições = `
      <p>Os acompanhantes dos pacientes com menos de 18 anos, mais de 60 anos ou com deficiência têm direito à refeição que será servida no quarto (café da manhã, almoço e jantar). Para os acompanhantes que o convênio não oferece cobertura para as refeições, o Hospital tem em suas dependências restaurante, cujo pagamento é de responsabilidade do acompanhante.</p>
  `;

  const acompanhantes = `
    <p>Deverão dirigir-se à portaria para identificação</p><br />
    <p>As trocas poderão ocorrer diariamente, em dois horários: às 8h e às 19h. Se o paciente for transferido para a UTI, o quarto deverá ser liberado pelo acompanhante.</p><br />
    <p><strong>Enfermaria/Apartamento:</strong> poderão ser registrados 2 acompanhantes com permanência individual nas 24 horas, o horário para visitas, são: 10h, 16h e 20h – limitada a 1 por horário.</p><br />
    <p><strong>UTI</strong>: poderão ser registrados 2 acompanhantes com permanência individual das 8h às 20h. Além disso, o horário para visitas – limitada a 1 por dia – será das 16h às 16h30</p><br />
    <p>As informações clínicas serão passadas uma vez por dia, presencialmente, das 10h ao 12h somente para o familiar responsável pela internação.</p>
  `;

  const visitas = `
    <p>Somente é permitida a entrada de visitantes maiores de 18 (dezoito) anos, com apresentação de documento de identidade. Menores de 18 (dezoito) anos, somente com autorização do hospital e em horários acordados.</p><br />
    <p>Na UTI, poderão ficar 2 acompanhantes com permanência individual das 8h às 20h. Além disso, o horário para visitas – limitada a 1 por dia.</p>
  `;

  const horariosVisitas = `
    <p class="destaque">Enfermaria / Apartamentos</p><br />
    <p>Todos os dias nos seguintes horários:</p><br />
    <p>Manhã – 10h</p><br />
    <p>Tarde – 16h</p><br />
    <p>Noite – 20h</p><br />
    <p class="destaque">Unidade de Terapia Intensiva (UTI)</p><br />
    <p>Todos os dias nos seguintes horários: Manhã – 16h às 16h30</p>
  `;

  const orientacoesVisitas = `
    <ul>
    <li><p>Evite circulação pela unidade; </p></li>
    <li><p>Evite entradas e saídas sucessivas;</p></li>
    <li><p>Colabore para um ambiente tranquilo e silencioso;</p></li>
    <li><p>Conheça e respeite a vontade do paciente.</p></li>
    <li><p>Se desejar, traga calendários, músicas e fotos da família;</p></li>
    <li><p>Traga óculos, prótese dentária e aparelhos de audição, conforme solicitação da equipe;</p></li>
    <li><p>Fale sobre a família e amigos;</p></li>
    <li><p>Fique ao lado do paciente e ajude a acalmá-lo; fale devagar e use palavras/frases simples;</p></li>
    <li><p>Lembre o paciente sobre o dia atual e datas;</p></li>
    <li><p>Estimule que permaneça acordado durante o dia; </p></li>
    <li><p>No momento da alta para Unidade de Internação o paciente precisará estar acompanhado pelo familiar;</p></li>
    <li><p>Não traga bebidas ou alimentos de fora do hospital para o paciente ou para consumo próprio.</p></li>
    </ul>
  `;

  const objetosPessoais = `
    <ul>
      <li><p>Os objetos esquecidos e/ou perdidos no Hospital Humaniza, são encaminhados para o setor de Hotelaria. O serviço funciona de segunda a sexta-feira, das 8h às 18h, todos os materiais recolhidos são registrados no sistema e informados à recepção. Caso necessite de auxílio, procure a recepção do Hospital para maiores informações.</p></li>
      <li><p>O hospital não se responsabiliza por objetos de valor ou outros pertences do paciente, deixados nas áreas de uso compartilhado, como quartos, hall de espera, banheiros ou cafeterias.</p></li>
      <li><p>Evite se internar portando objetos de valor, como carteira, celulares, objetos eletrônicos (tablets, ipods etc.), brincos, pulseiras, relógios, entre outros adornos.</p></li>
      <li><p>Deixe-os com o seu familiar durante a internação. Todos os objetos de adornos, como relógios, alianças, brincos e prótese dentária serão retirados em caso de encaminhamento ao centro cirúrgico ou realização de exames (internos ou externos).</p></li>
      <li><p>No momento da internação, os medicamentos trazidos pelo paciente deverão ser entregues para avaliação da enfermagem.</p></li>
    </ul>
  `;

  const estacionamento = `
    <p>O estacionamento do Hospital Humaniza, administrado pela empresa PareBem, funciona de segunda a sábado, das 7h às 23h. Para comodidade e segurança de todos os pacientes e funcionários, o estacionamento do terceiro andar está direcionado exclusivamente para pacientes com sintomas gripais e/ou covid. 
    Consulte os valores.
    </p>
  `;

  const ouvidoria = `
    <p>Canal disponível para pacientes, familiares e acompanhantes que desejam manifestar suas críticas, solicitações, elogios e sugestões. Caso necessite, entre em contato com a Ouvidoria, através do formulário no site do Hospital: <a href="https://www.hospitalhumaniza.com.br/ouvidoria">https://www.hospitalhumaniza.com.br/ouvidoria</a></p>
  `;

  const altas = `
    <p>Durante a internação, o paciente não terá autorização para saída sem a alta médica. Qualquer necessidade de contato com os familiares, por motivo de alta, por exemplo, a equipe de enfermagem será responsável por solicitar.</p><br />
    <p>No momento da alta, o paciente deverá estar acompanhado por um familiar, de preferência pelo responsável pela internação, para o procedimento dos trâmites burocráticos. O familiar receberá as orientações finais. Uma vez finalizado o processo, solicitamos a liberação do leito em até 1 hora, caso não aconteça, acarretará uma nova diária.</p><br />
    <p>As remoções que necessitarem de ambulância seguirão critérios exclusivamente médicos. Na hipótese de o familiar ou responsável desejar retirar o paciente do hospital sem alta médica, deverá ser preenchido um termo de responsabilidade no setor de internações.</p>
    `;

  const informacoesImportantes = `
    <p>Não caia em golpes. O Hospital Humaniza não solicita pagamentos por meio de ligações telefônicas, realizadas pelas equipes médicas ou de enfermagem. </p><br/>
    <p>Importante: Em caso de dúvida, procure a área administrativa do Hospital.</p>
  `;
  const centralRelacionamento = `<ul><li><p>Canal de atendimento disponível para pacientes, familiares e acompanhantes que desejam manifestar suas críticas, solicitações, elogios e sugestões. 	
  Caso necessite, entre em contato pelo telefone 0800 942 9702. Se preferir, envie e-mail para: faleconosco@hospitalhumaniza.com.br.</p></li></ul>`;

  return (
    <GlobalContainer>
      <div id="inicio">
        <S.Wrapper>
          <S.TextWrapper>
            <Title
              size={{ web: '7.6rem', mobile: '4.2rem' }}
              lineHeight={{ web: '8.3rem', mobile: '4.8rem' }}
              label="Orientações para Internação"
            />
            <Description
              width="90rem"
              label="Desejamos que você se sinta acolhido em todos os momentos. Preocupados com seu bem-estar preparamos informações importantes para a sua permanência."
            />
          </S.TextWrapper>
          <div className="buttons" />
        </S.Wrapper>
      </div>
      <Filter />
      <S.Grid>
        <Grid />
      </S.Grid>
      <div id="Internação">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '8.3rem', mobile: '4.8rem' }}
            label="Internação"
          />
          <SimpleText text={internacao} />
        </S.SupporTextWrapper>
      </div>
      <div id="Assistência Médica e Enfermagem">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '8.3rem', mobile: '4.8rem' }}
            label="Assistência Médica e Enfermagem"
          />
          <SimpleText text={assistenciaMedica} />
        </S.SupporTextWrapper>
      </div>
      <div id="Diárias e Despesas">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Diárias e Despesas"
          />
          <SimpleText text={diariasDespesas} />
        </S.SupporTextWrapper>
      </div>
      <div id="Hotelaria">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Hotelaria"
          />
          <Title
            size={{ web: '2.8rem', mobile: '1.8rem' }}
            label="Cafeteria"
            color="#009cde"
          />
          <SimpleText text={cafeteria} />
          <Title
            size={{ web: '2.8rem', mobile: '1.8rem' }}
            label="Enxoval Hospitalar"
            color="#009cde"
          />
          <SimpleText text={enxoval} />
          <Title
            size={{ web: '2.8rem', mobile: '1.8rem' }}
            label="Mobiliário"
            color="#009cde"
          />
          <SimpleText text={mobiliario} />
        </S.SupporTextWrapper>
      </div>
      <div id="Nutrição">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Nutrição"
          />
          <SimpleText text={nutricacao} />
          <Table />
          <Title
            size={{ web: '2.8rem', mobile: '1.8rem' }}
            label="Mesa auxiliar"
            color="#009cde"
          />
          <SimpleText text={mesaAuxiliar} />
          <br />
          <Title
            size={{ web: '2.8rem', mobile: '1.8rem' }}
            label="Refeições para acompanhantes"
            color="#009cde"
          />
          <SimpleText text={refeições} />
        </S.SupporTextWrapper>
      </div>

      <div id="Acompanhantes">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Acompanhantes"
          />
          <SimpleText text={acompanhantes} />
        </S.SupporTextWrapper>
      </div>
      <div id="Visitas">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Visitas"
          />
          <SimpleText text={visitas} />
          <br />
          <Title
            size={{ web: '2.8rem', mobile: '1.8rem' }}
            label="HORÁRIOS DE VISITAS"
            color="#009cde"
          />
          <SimpleText text={horariosVisitas} />
          <Title
            size={{ web: '2.8rem', mobile: '1.8rem' }}
            label="ORIENTAÇÕES PARA VISITAS"
            color="#009cde"
          />
          <SimpleText text={orientacoesVisitas} />
        </S.SupporTextWrapper>
      </div>
      <div id="Objetos Pessoais">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Objetos Pessoais"
          />
          <SimpleText text={objetosPessoais} />
        </S.SupporTextWrapper>
      </div>
      <div id="Estacionamento">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Estacionamento"
          />
          <SimpleText text={estacionamento} />
        </S.SupporTextWrapper>
      </div>
      <div id="Ouvidoria">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Ouvidoria"
          />
          <SimpleText text={ouvidoria} />
        </S.SupporTextWrapper>
      </div>
      <div id="Altas">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Altas"
          />
          <SimpleText text={altas} />
        </S.SupporTextWrapper>
      </div>
      <div id="Informações importantes">
        <S.SupporTextWrapper>
          <Title
            size={{ web: '4.6rem', mobile: '4.2rem' }}
            lineHeight={{ web: '7rem', mobile: '4.8rem' }}
            label="Informações importantes"
          />
          <SimpleText text={informacoesImportantes} />
        </S.SupporTextWrapper>
        <S.LinkWrapper>
          <S.Link href="#inicio">
            VOLTAR PARA O INÍCIO
            {' '}
            <AiOutlineArrowUp size={18} />
            {' '}
          </S.Link>
        </S.LinkWrapper>
      </div>
    </GlobalContainer>
  );
}
