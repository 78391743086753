import React, { useRef } from 'react';
import SlickSlider from 'react-slick';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import GlobalContainer from '../../components/_layout/Container';
import DepoimentCard from '../../components/DepoimentCard';
import MediaMatch from '../../components/MediaMatch';
import Slider from '../../components/Slider';
import KnowMore from '../../components/KnowMore';
import {
  ImageBanner,
  VideoBanner,
} from '../../components/MediaContainer/modules';
import {
  Title,
  Description,
  SupportText,
} from '../../components/TextComponents';
import Especialidades from '../../components/Especialidades';
import News from '../../components/News';

import bannerWeb from '../../assets/image_banner_gastroenterologia/web.jpg';
import bannerMobile from '../../assets/image_banner_gastroenterologia/mobile.jpg';
import imageCourolcel_1 from '../../assets/estrutura-assets/slide/slide_1.png';
import imageCourolcel_2 from '../../assets/estrutura-assets/slide/slide_2.png';
import imageCourolcel_3 from '../../assets/estrutura-assets/slide/slide_3.png';

import { string, slideSettings } from './utils/mock';

import * as S from './styles';

import DoctorSection from './components/DoctorSection';

export default function Gastroenterologia() {
  const sliderRef = useRef(null);

  const next = () => sliderRef.current.slickNext();
  const prev = () => sliderRef.current.slickPrev();

  const settings = {
    arrows: false,
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 500,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
      },
    ],
  };

  return (
    <GlobalContainer>
      <S.Wrapper>
        <S.TitleWrapper>
          <Title
            size={{ web: '7.6rem', mobile: '4.8rem' }}
            label="Centro de Endoscopia e Colonoscopia"
          />
          <Description width="79rem" />
        </S.TitleWrapper>
      </S.Wrapper>
      <S.BannerWrapper>
        <ImageBanner backgrounds={{ bannerMobile, bannerWeb }} />
      </S.BannerWrapper>
      <S.Wrapper>
        <S.SupporTextWrapper>
          <SupportText text={string} />
        </S.SupporTextWrapper>
      </S.Wrapper>
      <S.KnowMoreWrapper>
        <KnowMore
          text="O tratamento dos sintomas é prioridade, cuidando do indivíduo como um
        todo e não da doença somente."
        />
      </S.KnowMoreWrapper>

      <S.SliderWrapper>
        <S.SliderBox>
          <SlickSlider {...settings} ref={sliderRef}>
            <div className="image">
              <img src={imageCourolcel_1} alt="" />
              <S.TitleWrapper>
                <S.TitleInfo>
                  Pronto atendimento e emergência 24 horas
                </S.TitleInfo>
                <S.DescriptionInfo>
                  Estrutura completa para atendimento de casos complexos.
                </S.DescriptionInfo>
              </S.TitleWrapper>
            </div>
            <div className="image">
              <img src={imageCourolcel_2} alt="" />

              <S.TitleWrapper>
                <S.TitleInfo>
                  Modernidade e tecnologia no centro cirúrgico e UTI
                </S.TitleInfo>
                <S.DescriptionInfo>
                  Instalações e equipamentos modernos, com corpo clínico
                  qualificado.
                </S.DescriptionInfo>
              </S.TitleWrapper>
            </div>
            <div className="image">
              <img src={imageCourolcel_3} alt="" />
              <S.TitleWrapper>
                <S.TitleInfo>
                  Maior conforto e atendimento completo na jornada do paciente
                </S.TitleInfo>
              </S.TitleWrapper>
            </div>
          </SlickSlider>
        </S.SliderBox>
        <S.InfoBox>
          <div className="button__wrapper">
            <S.Button onClick={() => prev()}>
              <IoIosArrowBack size={20} />
            </S.Button>
            <S.Button onClick={() => next()}>
              <IoIosArrowForward size={20} />
            </S.Button>
          </div>
        </S.InfoBox>
      </S.SliderWrapper>
      <Especialidades title="Outras Especialidades" />
      <S.BgNews>
        <News btnFullsize btnLabel="Ver todas" />
      </S.BgNews>
    </GlobalContainer>
  );
}
