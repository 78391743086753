import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const border = {
  normal: (theme) => css`
    border-bottom: 0.2rem solid ${theme.colors.secondary};
  `,
  contraste: (theme) => css`
    border-bottom: 0.2rem solid ${theme.colors.globalYellow};
  `,
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.08);

    ${media.greaterThan('medium')`
      flex-direction: column;
    `}

    ${border[theme.name](theme)};
  `}
`;
export const Image = styled.img`
  width: 12rem;
  height: 14rem;
  object-fit: cover;
  margin-right: 1.6rem;

  ${media.greaterThan('medium')`
    width: 100%;
    min-height: 20rem;
  `}
`;
export const InfoWrapper = styled.div`
  padding: 1.6rem;

  ${media.greaterThan('medium')`
    padding: 2.4rem;
  `}
`;
export const Title = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.small};
    line-height: 2rem;
    letter-spacing: 0em;
    color: ${theme.font.colors.primary};

    ${media.greaterThan('medium')`
      font-size: ${theme.font.sizes.small};
      margin-top: 2.4rem;
    `}
  `}
`;
export const BoxInformation = styled.div`
  margin-top: 0.8rem;
`;
export const Info = styled.div`
  & + div {
    margin-top: 1rem;
  }

  ${({ theme }) => css`
    span {
      font-weight: bold;
      color: ${theme.font.colors.secondary};
      font-size: ${theme.font.sizes.xsmall};
    }

    p {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.font.colors.primary};
      font-weight: bold;
      margin-top: 0.1rem;
      ${media.greaterThan('medium')`
        font-size: ${theme.font.sizes.small};
      `}
    }
  `}
`;
