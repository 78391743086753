import styled, { css } from 'styled-components';
import * as TitleStyles from '../Title/styles';

export const LittleTitle = styled.span`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.bold};
    color: ${theme.font.colors[color]};
    letter-spacing: 0.2rem;

    & + ${TitleStyles.Title} {
      margin-top: 1.6rem;
    }
  `}
`;
