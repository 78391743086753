import React, { useState } from 'react';

import { AiOutlinePlusCircle } from 'react-icons/ai';

import {
  Fields,
  InputBox,
  Input,
  Title,
  RadioWrapper,
  RadioLabel,
  Radio,
  Wrapper,
  Button,
  ButtonWrapper,
} from '../common';

import * as S from './styles';

export default function Procedimentos() {
  const [tipo, setTipo] = useState('ambulatorial');
  const [codigo, setCodigo] = useState();
  const [procedimento, setProcedimento] = useState();
  const [data, setData] = useState();
  const [dia, setDia] = useState();
  const [internacao, setInternacao] = useState();
  const [item, setItem] = useState({});

  return (
    <Wrapper>
      <Title label="Procedimento" />
      <Fields>
        <RadioWrapper margin="3.2rem 0 0">
          <RadioLabel>Tipo</RadioLabel>
          <Fields display="flex">
            <Radio
              id="ambulatorial"
              name="tipoProcedimento"
              value="ambulatorial"
              label="Ambulatorial"
              onClick={() => setTipo('ambulatorial')}
            />
            <Radio
              id="internacao"
              name="tipoProcedimento"
              value="internacao"
              label="Internação"
              onClick={() => setTipo('internação')}
            />
            <Radio
              id="exame"
              name="tipoProcedimento"
              value="exame"
              label="Exame"
              onClick={() => setTipo('exame')}
            />
            <Radio
              id="tratamento"
              name="tipoProcedimento"
              value="tratamento"
              label="Tratamento"
              onClick={() => setTipo('tratamento')}
            />
          </Fields>
        </RadioWrapper>
        <InputBox>
          <Input
            name="codigo"
            label="Código"
            onChange={(event) => setCodigo(event.target.value)}
            value={codigo}
          />
        </InputBox>
        <InputBox>
          <Input
            name="procedimento"
            label="Procedimento"
            onChange={(event) => setProcedimento(event.target.value)}
            value={procedimento}
          />
        </InputBox>
        <InputBox>
          <Input
            name="dataPrevista"
            label="data prevista para realização"
            onChange={(event) => setData(event.target.value)}
            value={data}
            type="date"
          />
        </InputBox>
        <InputBox>
          <Input
            name="diasInternacao"
            label="dias de internação"
            type="number"
            onChange={(event) => setDia(event.target.value)}
            value={dia}
          />
        </InputBox>
      </Fields>
      <RadioWrapper margin="4.8rem 0 0">
        <RadioLabel>Internação</RadioLabel>
        <Fields display="flex">
          <Radio
            id="privativo"
            name="tipoInternacao"
            value="privativo"
            label="Privativo"
            onClick={() => setInternacao('privativo')}
          />
          <Radio
            id="semiPrivativo"
            name="tipoInternacao"
            value="semiPrivativo"
            label="Semi Privativo"
            onClick={() => setInternacao('semi privativo')}
          />
          <Radio
            id="enfermaria"
            name="tipoInternacao"
            value="enfermaria"
            label="Enfermaria"
            onClick={() => setInternacao('enfermaria')}
          />

          <Radio
            id="hospital"
            name="tipoInternacao"
            value="hospital"
            label="Hospital dia/ambulatório"
            onClick={() => setInternacao('hospital')}
          />

          <Radio
            id="recuperacao"
            name="tipoInternacao"
            value="recuperacao"
            label="Sala de recuperação"
            onClick={() => setInternacao('sala de recuperação')}
          />
        </Fields>
      </RadioWrapper>
      <ButtonWrapper>
        <Button
          onClick={() => setItem({
            tipo,
            codigo,
            procedimento,
            data,
            dia,
            internacao,
          })}
        >
          <AiOutlinePlusCircle size={20} />
          <span>Adicionar Procedimento</span>
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}
