import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.name === 'contraste'
    ? theme.colors.bg_contraste
    : theme.colors.secondWhite};
    width: 100%;
    padding: 13rem 0;

    ${media.lessThan('medium')`
      padding: 5rem 0;
    `}
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 60rem;
    margin: 0 auto 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 4rem ${theme.spacings.xsmall} 0;
  `}
`;

export const SmallTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-size: ${theme.font.sizes.xsmall};
    text-transform: uppercase;
    margin-bottom: ${theme.spacings.xsmall};
    font-family: ${theme.font.bold};
    letter-spacing: 0.2rem;
  `}
`;

export const BigTitle = styled.h2`
  ${({ theme }) => css`
    font-size: 4.2rem;
    color: ${theme.font.colors.primary};
    text-align: center;
    line-height: 3.6rem;
    font-weight: bold;
    margin-bottom: ${theme.spacings.small};
    font-family: ${theme.font.bold};
    line-height: 4.5rem;

    ${media.lessThan('small')`
      font-size: 3.2rem;

    `}
  `}
`;

export const Descrption = styled.p`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.blackFonts};
    font-size: ${theme.font.sizes.medium};
    line-height: 28px;
    margin-bottom: 3rem;
  `}
`;

export const Button = styled(Link)`
  ${({ theme }) => (theme.name === 'normal'
    ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: ${theme.border.radius};
          border: 1px solid ${theme.colors.secondary};
          background: transparent;
          padding: ${theme.spacings.xsmall} ${theme.spacings.small};
          width: 100%;
          color: ${theme.colors.primary};
          font-size: ${theme.font.sizes.medium};
          transition: all 0.3s ease;

          :hover {
            background: ${theme.colors.secondary};
            color: #fff;
          }

          ${media.greaterThan('medium')`
          width: 20rem;
        `}
        `
    : css`
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: ${theme.border.radius};
          border: 1px solid ${theme.colors.globalYellow};
          background: transparent;
          padding: ${theme.spacings.xsmall} ${theme.spacings.small};
          width: 100%;
          color: ${theme.font.colors.primary};
          font-size: ${theme.font.sizes.medium};
          transition: all 0.3s ease;

          :hover {
            background: #fff;
            color: #000;
          }

          ${media.greaterThan('medium')`
          width: 20rem;
        `}
        `)}
`;

export const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const IframeVideo = styled.iframe`
  ${({ theme }) => css`
    width: 100%;
    max-width: 95rem;
    height: 46rem;
    margin-bottom: 4.8rem;
    border-radius: ${theme.border.radius};

    ${media.lessThan('medium')`
      width:100%;
      height: 30rem;
      border-radius: 0;
    `}
  `}
`;
