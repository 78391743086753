import React, { createContext, useContext, useState } from 'react';

const PlanoContext = createContext({} || null);

export default function PlanoProvider({ children }) {
  const [plano, setPlano] = useState('');

  return (
    <PlanoContext.Provider
      value={{
        plano,
        setPlano,
      }}
    >
      {children}
    </PlanoContext.Provider>
  );
}

export function usePlano() {
  const context = useContext(PlanoContext);
  if (!context) throw new Error('useCount must be used within a PlanoContext');
  return context;
}
