import React, { useEffect } from 'react';

import AccessibilityMenu from '../../components/AccessibilityMenu';
import AskedQuestions from '../../components/AskedQuestions';
import Breadcrumbs from '../../components/Breadcrumbs';
import Especification from '../../components/Especification';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import Symptoms from '../../components/Symptoms';
import TogetherAgainstCOVID from '../../components/TogetherAgainstCOVID';

import { Container } from './styles';

const InfoCovid = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <AccessibilityMenu />
      <Menu />
      <Breadcrumbs />

      <TogetherAgainstCOVID />
      <Symptoms />
      <Especification />
      <AskedQuestions />
      <Footer />
    </Container>
  );
};

export default InfoCovid;
