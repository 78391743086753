import { Field } from 'formik';
import styled, { css } from 'styled-components';

export const Container = styled.div``;
export const Label = styled.label`
  ${({ theme, error }) => css`
    width: 100%;
    height: 100%;
    border: none;
    pointer-events: none;
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.bold};
    color: ${error ? '#c7170a' : theme.font.colors.primary};
    margin-bottom: 1rem;
    display: block;

    span {
      text-transform: uppercase;
      font-family: ${theme.font.medium};
    }
  `}
`;
export const TextArea = styled.textarea`
  ${({ error }) => css`
    max-width: 100%;
    max-height: 12rem;
    min-width: 100%;
    min-height: 12rem;
    padding: 2rem;
    font-family: 'diodrum';
    border: 1px solid ${error ? '#c7170a' : '#ddd'};
    font-size: 1.8rem;
    resize: none;
    background: transparent;
  `}
`;
