import React, { useContext } from 'react';
import { Field } from 'formik';
import { AiOutlinePaperClip, AiOutlineCloseCircle } from 'react-icons/ai';
import { BsFileEarmarkCheck } from 'react-icons/bs';
import { ThemeContext } from 'styled-components';

import * as S from './styles';

import {
  Title, Wrapper, Description, Fields,
} from '../common';

export default function Files() {
  const theme = useContext(ThemeContext);

  return (
    <Wrapper>
      <Title label="Anexar arquivos" />
      <Description label="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi aenean malesuada enim neque vulputate. Cursus ut aenean." />
      <S.FilesWrapper>
        <S.FilesCard>
          <S.IconWrapper color={theme.font.colors.primary}>
            <BsFileEarmarkCheck size={20} />
          </S.IconWrapper>
          <S.FilesName>lore.pdf</S.FilesName>
          <S.Exclude>
            <S.IconWrapper>
              <AiOutlineCloseCircle size={20} />
            </S.IconWrapper>
          </S.Exclude>
        </S.FilesCard>
      </S.FilesWrapper>
      <Fields>
        <Field name="files">
          {({ field }) => (
            <S.InputWrapper>
              <input {...field} type="file" name="file" id="file" />
              <S.Label htmlFor="file">
                <S.IconWrapper>
                  <AiOutlinePaperClip size={20} />
                </S.IconWrapper>
                <span>Selecionar arquivo..</span>
              </S.Label>
            </S.InputWrapper>
          )}
        </Field>
      </Fields>
    </Wrapper>
  );
}
