import styled, { css } from 'styled-components';
import { Formik, Form as FormikFirm } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import Files from './FormGroups/Files';
import InformacoesMedicas from './FormGroups/InformacoesMedicas';

import InformacoesPaciente from './FormGroups/InformacoesPaciente';
import MateriaisEspeciais from './FormGroups/MateriaisEspeciais';
import Observacoes from './FormGroups/Observacoes';
import Procedimentos from './FormGroups/Procedimentos';

const SubmitButton = styled.button`
  ${({ theme }) => css`
    display: block;
    width: 31rem;
    height: 4.8rem;
    font-weight: bold;
    border-radius: ${theme.border.radius};
    margin: 8rem auto 12rem;
    border: 0.2rem solid
      ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
    color: ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.whitePure};
    background: ${theme.name === 'contraste' ? 'none' : theme.colors.secondary};
  `}
`;

export default function Form({ initialValues }) {
  const schemaValidate = Yup.object().shape({
    cartao: Yup.string(),
    fullName: Yup.string().required('O nome é obrigatório'),
    email: Yup.string()
      .email('Digite um e-mail valido')
      .required('O e-mail é obrigatório'),
    phone: Yup.string().required('O numero de telefone é obrigatório'),
    dateBorn: Yup.date().required('A data de nascimento é obrigatória'),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={schemaValidate}>
      {({ errors, touched }) => (
        <FormikFirm>
          <InformacoesPaciente errors={errors} touched={touched} />
          <InformacoesMedicas />
          <Procedimentos />
          <MateriaisEspeciais />
          <Files />
          <Observacoes />
          <SubmitButton>Enviar Solicitação</SubmitButton>
        </FormikFirm>
      )}
    </Formik>
  );
}
