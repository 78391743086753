import React from 'react';
import { IoCallOutline } from 'react-icons/io5';

import * as S from './styles';

export default function DoctorCard() {
  return (
    <S.Container>
      <S.ImageWrapper>
        <img
          src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg"
          alt=""
        />
      </S.ImageWrapper>
      <S.DetailsWrapper>
        <S.TitleWrapper>
          <S.DoctorName>Abel Castanho</S.DoctorName>
          <S.DoctorCRM>CRM 163020</S.DoctorCRM>
        </S.TitleWrapper>
        <S.Specialties>
          <S.DetailsFields>
            <S.FielsdsOptions>Especialidades: </S.FielsdsOptions>
          </S.DetailsFields>
          <S.FielsdsOptions>Cardiologia</S.FielsdsOptions>
          <S.FielsdsOptions>Clínica Medica</S.FielsdsOptions>
          <S.FielsdsOptions>Medicina Intenciva</S.FielsdsOptions>
        </S.Specialties>
        <S.Phone>
          <S.DetailsFields>Fone:</S.DetailsFields>
          <S.FielsdsOptions>(51) 3287.9220</S.FielsdsOptions>
        </S.Phone>

        <S.ButtonWrapper>
          <S.Button>
            <S.IconButton>
              <IoCallOutline size={18} />
            </S.IconButton>
            <span>Ligar</span>
          </S.Button>
        </S.ButtonWrapper>
      </S.DetailsWrapper>
    </S.Container>
  );
}
