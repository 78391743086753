import React from 'react';

import Slider from '../../../../components/Slider';
import CardNewsLetter from '../CardNewsLetter';

import * as S from './styles';
import MediaMatch from '../../../../components/MediaMatch';

export default function NewsLetter() {
  const settings = {
    dots: true,
    slideToShow: 1,
    speed: 500,
    arrows: false,
    infinite: false,
  };

  const dataCards = [
    {
      id: 1,
      img:
        'https://images.unsplash.com/photo-1576750540989-682154c115d8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=657&q=80',
      date: '06 de novembro',
      title: 'CCG Saúde lança novo hospital em Porto Alegre',
    },
    {
      id: 2,
      img:
        'https://images.unsplash.com/photo-1576750540989-682154c115d8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=657&q=80',
      date: '06 de novembro',
      title: 'CCG Saúde lança novo hospital em Porto Alegre',
    },
    {
      id: 3,
      img:
        'https://images.unsplash.com/photo-1576750540989-682154c115d8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=657&q=80',
      date: '06 de novembro',
      title: 'CCG Saúde lança novo hospital em Porto Alegre',
    },
  ];

  return (
    <S.Wrapper>
      <MediaMatch lessThan="medium">
        <Slider {...settings}>
          {dataCards.map((card) => (
            <CardNewsLetter
              key={card.id}
              date={card.date}
              title={card.title}
              img={card.img}
              itemID={card.id}
            />
          ))}
        </Slider>
      </MediaMatch>

      <MediaMatch greaterThan="medium">
        <S.NewsHighlights>
          {dataCards.map((card) => (
            <S.Item key={card.id}>
              <CardNewsLetter
                date={card.date}
                title={card.title}
                img={card.img}
                itemID={card.id}
              />
            </S.Item>
          ))}
        </S.NewsHighlights>
      </MediaMatch>
    </S.Wrapper>
  );
}
