import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        width: 90rem;
        height: 15rem;
        margin: auto;
        text-align: center;
        color: ${theme.colors.primary};
        background-color: ${theme.colors.rose};
        margin-bottom: 4rem;
    `}
`;

export const Title = styled.h2`
    ${({ theme }) => css`
        padding-top: 4rem;
    `}
`;

export const Number = styled.h1`
    padding-top: 1rem;
`;
