import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 5rem ${theme.spacings.xsmall} 5rem;
    height: 100%;
    ${media.lessThan('medium')`
        height: 100%;
      `}
  `}
`;

export const Header = styled.div`
  width: 100%;
  max-width: 136rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 128rem;
  margin: 0 auto 2rem;
`;
export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-family: ${theme.font.bold};
    font-size: ${theme.font.sizes.extraLarge};
  `}
`;
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
`;

export const LinkOutlined = styled(Link)`
  ${({ theme, fullsize }) => css`
    border: 1px solid
      ${theme.name === 'contraste'
    ? theme.colors.globalYellow
    : theme.colors.secondary};
    padding: ${theme.spacings.xsmall} ${theme.spacings.medium};
    color: ${theme.font.colors.primary};
    border-radius: ${theme.border.radius};
    transition: 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    ${fullsize
    && css`
      width: 100%;
    `}

    :hover {
      background: ${theme.name === 'contraste'
    ? '#fff'
    : theme.colors.secondary};
      color: ${theme.name === 'contraste' ? '#000' : '#fff'};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  margin-top: 2rem;
`;
