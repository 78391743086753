import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  margin-left: 4rem;
  margin-bottom: 6rem;
  ${({ theme }) => css`
    ul {
      align-items: flex-start !important;
      margin-left: 2rem;
    }

    li {
      color: ${theme.colors.secondary};
    }

    p {
      color: ${theme.font.colors.primary};
      font-family: ${theme.font.light};
    }

    a {
      color: ${theme.colors.secondary};
    }
  `}
`;

export const TextWrapper = styled.div`
  margin-bottom: 1rem;
  margin: 0 2rem;
  ${({ theme }) => css`
    .destaqueTittle {
      color: ${theme.font.colors.primary};
      font-family: ${theme.font.bold};
    }
  `}
`;

export const Container = styled.div`
  margin-bottom: 15rem;
  margin-top: 5rem;
  padding-top: 5rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.bold};
    color: ${theme.font.colors.primary};
    font-size: 6.3rem;

    ${media.lessThan('medium')`
      font-size: 4.2rem;
    `}
  `}
`;
