import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import Title from '../Title';
import theme from '../../../styles/themes/ContrasteTheme';

export default function SimpleText({
  title,
  subTitle,
  text,
  justify,
  size,
  lineHeight,
}) {
  return (
    <S.Wrapper>
      {!!title && (
        <Title
          lineHeight={{ web: '4rem', mobile: '3.8rem' }}
          size={{ mobile: '3.2rem', web: '3.6rem' }}
          label={title}
        />
      )}
      {!!subTitle && (
        <Title
          lineHeight={{ web: '4rem', mobile: '3.8rem' }}
          size={{ mobile: '2.2rem', web: '2.6rem' }}
          label={subTitle}
        />
      )}
      <br />
      <S.Paragraph
        lineHeight={lineHeight}
        size={size}
        justify={justify}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </S.Wrapper>
  );
}

SimpleText.defaultProps = {
  title: '',
  subTitle: '',
  text: '',
  justify: 'left',
  size: {
    web: theme.font.sizes.medium,
    mobile: theme.font.sizes.medium,
  },

  lineHeight: {
    web: '3rem',
    mobile: '2.8rem',
  },
};

SimpleText.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
  justify: PropTypes.oneOf(['right', 'left', 'center']),
  size: PropTypes.shape({
    web: PropTypes.string,
    mobile: PropTypes.string,
  }),

  lineHeight: PropTypes.shape({
    web: PropTypes.string,
    mobile: PropTypes.string,
  }),
};
