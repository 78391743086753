/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import CardEspecialidades from '../CardEspecialidades';

import services from '../../assets/services/services.png';
import covid from '../../assets/services/covid.png';

import * as S from './styles';

const Especialidades = ({ title }) => {
  const settings = {
    slidesToShow: 4,
    arrows: false,
    infinite: false,
    lazyLoad: 'ondemand',
    speed: 500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1375,
        settings: {
          arrows: false,
          slidesToShow: 3.2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 570,
        settings: {
          arrows: false,
          slidesToShow: 1.2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          arrows: false,
          slidesToShow: 1.1,
        },
      },
    ],
  };

  const sliderRef = React.createRef();

  const next = () => sliderRef.current.slickNext();
  const prev = () => sliderRef.current.slickPrev();

  return (
    <S.Container>
      <S.Wrapper>
        <S.InfoWrapper>
          <div className="wrapper">
            <div>
              <S.SmallTitle>Especialidades</S.SmallTitle>
              <S.BigTitle>{title}</S.BigTitle>
              <S.Descrption>
                Com mais de XX especialidades médicas, o quadro de profissionais
                do Hospital Humaniza oferece soluções para as mais diversas
                necessidades.
              </S.Descrption>
            </div>
            <S.ButtonWrapper>
              <S.LinkButton to="#">Conheça nossas especialidades</S.LinkButton>
            </S.ButtonWrapper>
          </div>
        </S.InfoWrapper>

        <S.SlideWrapper>
          <S.SliderCards {...settings} ref={sliderRef} className="slider">
            <CardEspecialidades
              image={covid}
              title="COVID"
              description="Faucibus est tortor lacus consectetur inisiut lectusnisl neque eu ullamcorper sit a."
            />

            <CardEspecialidades
              image={services}
              title="Cardiologia"
              description="Faucibus est tortor lacus consectetur inisiut lectusnisl neque eu ullamcorper sit a."
            />

            <CardEspecialidades
              image={covid}
              title="Terapia"
              description="Faucibus est tortor lacus consectetur inisiut lectusnisl neque eu ullamcorper sit a."
            />

            <CardEspecialidades
              image={services}
              title="Psicologia"
              description="Faucibus est tortor lacus consectetur inisiut lectusnisl neque eu ullamcorper sit a."
            />
          </S.SliderCards>
        </S.SlideWrapper>
        <div className="buttom__wrapper">
          <S.SlideButtonWrapper>
            <S.Button onClick={() => prev()}>
              <IoIosArrowBack size={20} />
            </S.Button>
            <S.Button onClick={() => next()}>
              <IoIosArrowForward size={20} />
            </S.Button>
          </S.SlideButtonWrapper>
        </div>
      </S.Wrapper>
    </S.Container>
  );
};

Especialidades.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Especialidades;
