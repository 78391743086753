import React, { useState } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Field, Formik } from 'formik';

import InputText from '../InputText';
import Radio from '../Radio';
import TextArea from '../TextArea';

import * as S from './styles';
import api from '../../services/api';

const Form = () => {
  const [radio, setRadio] = useState('ccg');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (radio === 'outro') {
        values.cartao = '';
      }

      await api.post(
        '/api/humaniza/ouvidoria',
        {
          plano: values.ccg,
          protocolo: values.sac,
          codigo: values.cartao,
          nome: values.name,
          email: values.email,
          telefone: values.telefone,
          celular: values.celular,
          mensagem: values.message,
        },
        {
          headers: {
            crossorigin: true,
          },
        },
      );

      setLoading(false);

      Swal.fire({
        title: '<h1 style="font-family:diodrum-bold">Formulário enviado</h1>',
        html:
          '<span style="font-size: 1.6rem; font-family:diodrum">Agradecemos o seu contato. Reforçamos que conforme RN nº. 323 da Agência Nacional de Saúde Suplementar (ANS), as solicitações serão respondidas em até sete (7) dias úteis após a abertura do chamado.</span>',
        icon: 'success',
        width: '50rem',

        confirmButtonColor: '#6EC6E6',
        confirmButtonText: 'Fechar',
      });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: '<h1 style="font-family:diodrum-bold">Oops...</h1>',

        html:
          '<span style="font-size: 1.6rem; font-family:diodrum">Algo deu errado no envio, tente novamente</span>',
      });
    }
  };

  const schemaValidate = Yup.object().shape({
    cartao: Yup.string(),
    name: Yup.string().required('O nome e obrigatório'),
    email: Yup.string()
      .email('Digite um e-mail valido')
      .required('O e-mail e obrigatório'),
    telefone: Yup.string().required('O numero de telefone e obrigatório'),
    celular: Yup.string().required('O numero de celular e obrigatório'),
    message: Yup.string().required('A mensagem e obrigatória'),
    sac: Yup.string().required('O código SAC e obrigatório'),
  });

  const initialValues = {
    ccg: 'ccg',
    alert: 'sim',
    sac: '',
    cartao: '',
    name: '',
    email: '',
    telefone: '',
    celular: '',
    message: '',
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={schemaValidate}
      initialValues={initialValues}
      render={({ errors, touched }) => (
        <S.Form>
          <S.TextWrapper>
            <h1>Insira seus dados</h1>
          </S.TextWrapper>

          <S.RadioButtonsWrapper>
            <S.Label>SELECIONE SEU PLANO DE SAÚDE</S.Label>
            <S.BoxRadioButtons>
              <Radio
                id="ccg"
                name="ccg"
                label="CCG Saúde"
                value="ccg"
                onClick={() => setRadio('ccg')}
              />
              <Radio
                id="outro"
                label="Outro"
                name="ccg"
                value="outro"
                onClick={() => setRadio('outro')}
              />
            </S.BoxRadioButtons>
          </S.RadioButtonsWrapper>
          <S.FieldsWrapper>
            <S.InputBox display={radio === 'ccg' ? 'block' : 'none'}>
              <InputText
                name="cartao"
                type="text"
                label="Código do cartão do convênio"
              />
            </S.InputBox>
            <S.InputBox>
              <InputText
                error={errors.sac && touched.sac && errors.sac}
                name="sac"
                type="text"
                label="Código do protocolo SAC *"
              />
              {errors.sac && touched.sac && <S.Error>{errors.sac}</S.Error>}
            </S.InputBox>
            <S.InputBox>
              <InputText
                error={errors.name && touched.name && errors.name}
                name="name"
                type="text"
                label="nome completo *"
              />
              {errors.name && touched.name && <S.Error>{errors.name}</S.Error>}
            </S.InputBox>

            <S.InputBox flex>
              <div className="wrapperFlex">
                <InputText
                  error={errors.email && touched.email && errors.email}
                  name="email"
                  type="text"
                  label="e-mail *"
                />
                {errors.email && touched.email && (
                  <S.Error>{errors.email}</S.Error>
                )}
              </div>
              <div className="wrapperFlex">
                <InputText
                  error={errors.telefone && touched.telefone && errors.telefone}
                  name="telefone"
                  type="text"
                  label="telefone *"
                />
                {errors.telefone && touched.telefone && (
                  <S.Error>{errors.telefone}</S.Error>
                )}
              </div>
            </S.InputBox>

            <S.InputBox>
              <InputText
                error={errors.celular && touched.celular && errors.celular}
                name="celular"
                sizeLimited="true"
                type="text"
                label="celular *"
              />
              {errors.celular && touched.celular && (
                <S.Error>{errors.celular}</S.Error>
              )}
            </S.InputBox>

            <S.InputBox>
              <Field
                name="message"
                render={({ field }) => (
                  <TextArea
                    {...field}
                    placeholder="Digite sua mensagem"
                    label="mensagem *"
                    error={errors.message && touched.message && errors.message}
                  />
                )}
              />
              {errors.message && touched.message && (
                <S.Error>{errors.message}</S.Error>
              )}
            </S.InputBox>
            <S.RequiredMessage>Campos obrigatórios *</S.RequiredMessage>

            <div className="content_add">
              <span>
                Para adequado encaminhamento administrativo desta ocorrência,
                solicitamos autorização para disponibilizar o referido texto na
                íntegra às partes envolvida
              </span>
              <S.RadioButtonsWrapper>
                <S.BoxRadioButtons>
                  <Radio id="alertSim" label="Sim" name="alert" value="sim" />
                  <Radio id="alertNao" label="Não" name="alert" value="nao" />
                </S.BoxRadioButtons>
              </S.RadioButtonsWrapper>
            </div>

            <S.ButtonWrapper>
              <S.SubmitButton type="submit">
                {loading ? 'Enviando, aguarde...' : 'Enviar Mensagem'}
              </S.SubmitButton>
            </S.ButtonWrapper>
          </S.FieldsWrapper>
        </S.Form>
      )}
    />
  );
};

export default Form;
