import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import Slider from 'react-slick';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;

    .buttom__wrapper {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      max-width: 161rem;
    }

    ${media.greaterThan('medium')`
    display: flex;
    align-items: flex-end;
    flex-direction: column; 
  `}

    width: 100%;
    padding: 5rem ${theme.spacings.xsmall};
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 5rem;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  max-width: 161rem;

  ${media.greaterThan('medium')`
    .wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 136rem;

      ${ButtonWrapper} {
        width: 30rem;
        margin-bottom: 5rem;
      }
      align-items: flex-end;
    }
  `}
`;

export const SmallTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.medium};
    text-transform: uppercase;
    margin-bottom: 0.7rem;
    display: block;
    letter-spacing: 0.2rem;
  `}
`;
export const BigTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.font.colors.primary};
    font-family: ${theme.font.bold};
    margin-bottom: 1rem;
  `}
`;
export const Descrption = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blackFonts};
    font-size: ${theme.font.sizes.small};
    margin-bottom: 3rem;
    line-height: 2.4rem;
    max-width: 51rem;
  `}
`;

export const LinkButton = styled(Link)`
  ${({ theme }) => (theme.name === 'contraste'
    ? css`
          color: ${theme.font.colors.primary};
          font-size: ${theme.font.sizes.medium};
          width: 100%;
          border: 1px solid ${theme.colors.globalYellow};
          border-radius: ${theme.border.radius};
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ${theme.spacings.xsmall} 0;
          transition: all 0.3s;

          &:hover {
            background-color: #fff;
            color: #000;
          }

          ${media.greaterThan('medium')`
            width: 28rem;
          `}
        `
    : css`
          color: ${theme.font.colors.primary};
          font-size: ${theme.font.sizes.medium};
          width: 100%;
          border: 1px solid ${theme.colors.secondary};
          border-radius: ${theme.border.radius};
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ${theme.spacings.xsmall} 0;
          transition: all 0.3s;

          &:hover {
            background-color: ${theme.colors.secondary};
            color: #fff;
          }

          ${media.greaterThan('medium')`
            width: 28rem;
          `}
        `)}
`;

// slide
export const SliderCards = styled(Slider)`
  width: 100%;
  margin: 0 auto;
`;
export const SlideWrapper = styled.div`
  width: 100%;
  max-width: 162rem;
  ${({ theme }) => css`
    .slick-track,
    .slick-list {
      display: flex;
    }
    .slick-slide > div {
      margin: 0 5px;
      flex: 1 0 auto;
      height: 100%;
    }
    .slick-list {
      margin: 0 -${theme.spacings.xxsmall};
    }
  `}
`;
export const SlideButtonWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`;
export const Next = styled.div`
  ${({ theme }) => css`
      background: none;
      border: 1px solid ${theme.colors.secondary};
      height: 5.4rem;
      width: 8.8rem;
      border-radius: ${theme.border.radius};
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: ${theme.colors.secondary};
      }
    `}
`;
export const Button = styled.div`
  ${({ theme }) => (theme.name === 'contraste'
    ? css`
          background: none;
          border: 1px solid ${theme.colors.globalYellow};
          border-radius: ${theme.border.radius};
          margin-right: 1rem;
          height: 5.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 8.8rem;
          svg {
            color: ${theme.colors.globalYellow};
          }
          cursor: pointer;
        `
    : css`
          background: none;
          border: 1px solid ${theme.colors.secondary};
          border-radius: ${theme.border.radius};
          margin-right: 1rem;
          height: 5.4rem;
          display: flex;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          width: 8.8rem;
          svg {
            color: ${theme.colors.secondary};
          }
        `)}
`;
